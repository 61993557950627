import * as React from 'react';
import {client} from "../../../../../Apollo/ApolloClient";
import {Props} from "./types";
import {CarrierSelector} from "./CarrierSelector";
import {Gender, useMedigapCompaniesQuery} from "../../../../../medigap-types";
import {isEffectiveDateValid} from "../../lib/lib";

type CarrierSelectorProps = Omit<Props, 'planYear' | 'onChange'> & {
  effectiveDate?: string,
  age: number,
  tobacco?: boolean,
  gender?: Gender,
  onChange: (data: {naic: string, name?: string}) => void,
}

export const MgCarrierSelector = (props: CarrierSelectorProps) => {
  const {zip, county, age, effectiveDate, gender, tobacco, onChange} = props;
  const {data, loading} = useMedigapCompaniesQuery({
    client,
    variables: {
      filter: {
        zip,
        county,
        age,
        effectiveDate,
        tobacco: tobacco!,
        companies: [],
        gender: gender!,
        monthlyPlanPremiumRanges: [],
        planNames: [],
      }
    },
    skip: !county || !zip || !isEffectiveDateValid(effectiveDate) || !age || !gender,
    fetchPolicy: 'no-cache'
  })


  const companies = data?.medigapCompanies
      .filter(c => c.count)
      .map(c => ({...c, title: `${c.name} (${c.count})`}))
    || [];

  const handleChange = (key: string) => {
    const company = companies.find(c => c.name === key);
    onChange({
      naic: company?.naics[0] || '', name: key
    })
  }

  return <CarrierSelector {...props}
                          label={loading ? 'Loading...' : undefined}
                          onChange={handleChange}
                          disabled={props.disabled || loading}
                          companies={companies.map(c => c.title)}  />
};
