import React, {useState} from "react";
import {Box, Collapse, ListItemText, MenuItem, Typography} from "@mui/material";
import FormSelect from "../../shared/FormSelect";
import moment from "moment";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import TextInput from "../../shared/TextInput";

interface Option {
  value: number,
  label: string,
  secondary?: string
}

interface Props {
  options: Option[],
  initialValue?: Date,
  value: Date | null,
  onChange: (value: Date | null) => void,
  error?: string,
  label: string
}

export function ConnectClientDateSelector({onChange, value, error, label, options, initialValue}: Props) {
  const [selectorValue, setSelectorValue] = useState<number | null>(() => {
    if (initialValue !== undefined) {
      const diff = moment(initialValue).diff(moment().startOf('day'), "days");
      const found = options.find(o => o.value === diff);
      if (found) {
        return found.value;
      }
      return 0;
    }
    if (value) {
      const diff = moment(value).diff(moment().startOf('day'), "days");
      const found = options.find(o => o.value === diff);
      if (found) {
        return found.value;
      }
      return 0;
    }
    return null;
  });

  const onSelectorChange = (val: number) => {
    setSelectorValue(val);
    if (val > 0) {
      onChange(moment().add(val, 'days').toDate());
    }
  }

  return <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
    <FormSelect label={label}
                value={selectorValue}
                onChange={event => {
                  onSelectorChange(event.target.value as number);
                }}
                sx={{
                  '& .MuiSelect-select': {
                    display: "flex",
                    alignItems: "center",
                    mr: 3
                  }
                }}
                error={Boolean(error)}
                helperText={error}
                className={'mb-0'}
    >
      {options.map(option => (
        <MenuItem value={option.value} key={option.value}>
          <ListItemText>{option.label}</ListItemText>
          {option.secondary && <Typography variant="body2" color="text.secondary">
            {option.secondary}
          </Typography>}
        </MenuItem>
      ))}
    </FormSelect>

    <Collapse in={selectorValue === 0}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className={'mb-0'}
          slotProps={{
            textField: {
              inputProps: {
                placeholder: 'MM / DD / YYYY'
              }
            }
          }}
          disablePast={!document.location.host.includes('localhost') && !document.location.host.includes('staging')}
          openTo={'day'}
          slots={{
            textField: TextInput as any,
          }}
          format="MM/dd/yyyy"
          value={value}
          onChange={onChange}
        />
      </LocalizationProvider>
    </Collapse>
  </Box>
}