import {useSubscription} from "@apollo/client";
import {UrgentRequestCallOutput} from "../../enrollment-types";
import {gql} from "@apollo/client";


export default function useUrgentCallRequests() {
  return useSubscription<{urgentCallRequests: UrgentRequestCallOutput[]}>(gql(subsription))
}

const subsription = `
  subscription {
    urgentCallRequests {
      clientId
      email
      firstName
      lastName
      urgentCallType
    }
  }
`