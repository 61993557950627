// @flow
import * as React from 'react';
import { MenuItem } from '@mui/material';
import * as _ from 'lodash';
import FormSelect from "../../../../../shared/FormSelect";
import {CalcDrugFrequencyPeriod} from "../../../../../types";


const frequencies = [
  CalcDrugFrequencyPeriod.Daily,
  CalcDrugFrequencyPeriod.Weekly,
  CalcDrugFrequencyPeriod.Monthly,
  CalcDrugFrequencyPeriod.Quarterly,
  CalcDrugFrequencyPeriod.HalfYearly,
  CalcDrugFrequencyPeriod.Yearly
];

type Props = {
  value?: string,
  onChange: (value: string) => void,
};

export function FrequencySelect({value = 'month', onChange}: Props) {
  return (
    <FormSelect
      label={'Frequency'}
      placeholder={'Please select'}
      value={value}
      data-test={'frequency-select'}
      onChange={e => onChange(e.target.value as string)}
    >
      {frequencies.map((f) => (
        <MenuItem className={'regular'} key={f} value={f}>
          {_.upperFirst(_.lowerCase(f))}
        </MenuItem>
      ))}
    </FormSelect>
  );
};
