import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {
  Box,
  Button,
  Chip,
  Dialog, DialogActions,
  DialogContent, DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography
} from "@mui/material";
import * as _ from "lodash";
import Card from "../../shared/Card";
import ChipWrapper from "../../shared/ChipWrapper";
import {AssignedTagViewOutput, ClientViewOutput} from "../../enrollment-types";
import useSaveClientTag from "../hooks/useSaveClientTag";
import useTags from "../../Tags/hooks/useTags";
import FormSelect from "../../shared/FormSelect";
import moment from "moment";

const useStyles = makeStyles(() =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    icon: {
      position: 'absolute!important' as any,
      top: 8,
      right: 8,
    }
  }),
);

interface ClientTagProps {
  client?: ClientViewOutput
}

const ClientTags = ({client}: ClientTagProps) => {
  const [clientTags, setClientTags] = React.useState<AssignedTagViewOutput[]>([]);
  const [tempTags, setTempTags] = React.useState<AssignedTagViewOutput[]>([]);
  const [save] = useSaveClientTag();
  const [editMode, setEditMode] = React.useState(false);

  React.useEffect(() => {
      if (client?.tags) {
        setClientTags(client?.tags);
      }
  }, [client?.tags]);

  const saveTags = React.useCallback(() => {
    setEditMode(false);
    save({variables: {input: {clientId: client?.id, tags: clientTags.map(t => ({id: t.tag.id, expiresAt: t.expiresAt}))}}});
  }, [clientTags, client]);

  return <Card className={'relative'}>
    <div className={'flex-space-between flex-align-center'}>
      <Typography color={'textPrimary'} variant={'h4'} className={'mb-15'}>Client tags</Typography>
      {!editMode && <IconButton size={'small'} onClick={() => {
        setTempTags(clientTags);
        setEditMode(true)
      }}><img src={'/img/mode.svg'}/></IconButton>}
    </div>
    {!editMode && <ChipWrapper title={'Tags'}>
      {clientTags?.map(tag => <Chip style={{backgroundColor: tag.tag.color as string}} key={tag.tag.id} size="small" label={getTagLabel(tag)}/>)}
    </ChipWrapper>}
    {editMode && <>
      <TagSelector label={'Edit client tags'} selectedValues={clientTags} onChange={setClientTags} />
      <div className={'mt-8 flex-space-between flex-align-center'}>
        <Button onClick={() => {
          setClientTags(tempTags);
          setEditMode(false)
        }} variant={'outlined'} >CLOSE</Button>
        <Button onClick={saveTags} variant={'contained'} color={'primary'}>SAVE</Button>
      </div>
    </>}
  </Card>;
}

export default ClientTags;

interface TagSelectorProps {
  onChange: (values: AssignedTagViewOutput[]) => void;
  fullWidth?: boolean;
  className?: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  selectedValues: AssignedTagViewOutput[];
  label: string;
  readOnly?: boolean;
}
export const TagSelector = (props: TagSelectorProps) => {
  const {data, loading} = useTags();
  const classes = useStyles();
  const [expiresAt, setExpiresAt] = React.useState<number>();
  const [open, setOpen] = React.useState(false);
  const [output, setOutput] = React.useState<AssignedTagViewOutput[]>([]);

  const getTagById = React.useCallback((id: string) => {
    return data?.tags.find(t => t.id === id)
  }, [data?.tags]);

  const onClose = (value: boolean) => {
    if (value) {
      const o = [...output];
      if (expiresAt) {
        o.find(t => t.tag.name === 'Temporary do not call')!.expiresAt = moment().add(expiresAt, 'days').toDate();
      }
      props.onChange(o);
      setExpiresAt(undefined);
      setOutput([]);
    }
    setOpen(false);
  }

  return <>
    <Dialog
      fullWidth
      maxWidth={'xs'}
      open={open}
      onClose={() => onClose(false)}
    >
      <DialogTitle>Expires at</DialogTitle>
      <DialogContent>
        <FormSelect label={'Select the number of days'}
                    value={expiresAt}
                    onChange={event => {
                      setExpiresAt(event.target.value as number);
                    }}
        >
          <MenuItem value={undefined}>-</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={7}>7</MenuItem>
          <MenuItem value={14}>14</MenuItem>
        </FormSelect>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Cancel
        </Button>
        <Box color={'red'}>
          <Button onClick={() => onClose(true)} color="inherit">
            ok
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
    <FormSelect label={props.label}
                fullWidth={props.fullWidth}
                className={props.className}
                value={props.selectedValues.map(t => t.tag.id)}
                onChange={event => {
                  const out = data?.tags
                    .filter(tag => (event.target.value as string[]).includes(tag.id))
                    .map(tag => ({
                      tag,
                      expiresAt: props.selectedValues.find(t => t.tag.id === tag.id)?.expiresAt,
                    })) as AssignedTagViewOutput[];
                  const lastTagId = _.difference(out.map(t => t.tag.id), props.selectedValues.map(t => t.tag.id))[0];
                  if (!props.readOnly && getTagById(lastTagId)?.name === 'Temporary do not call') {
                    setOutput(out)
                    setOpen(true);
                  } else {
                    props.onChange(out);
                  }
                }}
                multiple
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {(selected as string[]).map((value) => (
                      <Chip style={{backgroundColor: getTagById(value)?.color as string}}
                            className={'ml-8 mt-5'}
                            size="small" key={value}
                            label={getTagLabel(props.selectedValues.find(t => t.tag.id === value))}  />
                    ))}
                  </div>
                )}
                disabled={loading || props.disabled}
                helperText={props.helperText}
                error={props.error}
    >
      {data && data.tags.map(tag => <MenuItem key={tag.id} value={tag.id}><div className={'flex'}>
        <Box sx={{backgroundColor: tag.color}} marginRight={2} width={15} height={15} borderRadius={10} /> {tag.name}
      </div></MenuItem>)}
    </FormSelect>
  </>
}

export const getTagLabel = (tag?: AssignedTagViewOutput): string => {
  if (!tag) {
    return '';
  }
  if (tag.expiresAt) {
    return tag.tag.name + ' in ' + Math.ceil(moment.duration(moment(tag.expiresAt).diff(moment())).asDays()) + ' days';
  }
  return _.upperFirst(tag.tag.name.toLowerCase());
}
