import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  PageableAttemptedLeadOutput,
  QueryAttemptedLeadsArgs
} from "../../enrollment-types";
import {client} from "../../Apollo/EnrollmentApolloClient";

export default function useAttemptedLeads(options?: LazyQueryHookOptions<{attemptedLeads: PageableAttemptedLeadOutput}, QueryAttemptedLeadsArgs>) {
  return useLazyQuery<{attemptedLeads: PageableAttemptedLeadOutput}, QueryAttemptedLeadsArgs>(gql(query), {
    fetchPolicy: 'no-cache',
    client,
    ...options
  });
}

const query = `
  query($filter: AttemptedLeadFilterInput!, $pageInput: PageInput!, $sort: AttemptedLeadSortInput) {
    attemptedLeads(filter: $filter, pageInput: $pageInput, sort: $sort) {
      data {
        birthDate
        callsCount
        clientId
        firstName
        iepFlag
        lastCallDate
        lastName
        leadDate
        leadGroup
        phoneNumber
        state
        status
        urgent
        lastCallAgentFullName
        tags {
          expiresAt
          tag {
            color
            id
            name
          }
        }
      }
      totalElements
    }
  }
`