import React from "react";
import {gql, useSubscription} from "@apollo/client";
import {NewLeadsStatisticsOutput} from "../enrollment-types";
import useInterval from "../shared/useInterval";
import {Box, Typography} from "@mui/material";
import useIsAdmin from "../shared/hooks/useIsAdmin";
import { calcDurationToNow } from "../shared/tools";

const newLeadsStatisticsSubscription = `
  subscription {
    newLeadsStatistics {
      count
      oldestCreatedAt
    }
  }
`

const NewLeadsStatistics = () => {
  const [time, setTime] = React.useState<string>();

  const {data} = useSubscription<{newLeadsStatistics: NewLeadsStatisticsOutput}>(gql(newLeadsStatisticsSubscription));
  const isAdmin = useIsAdmin();

  useInterval(() => {
    setTime(calcDurationToNow(data?.newLeadsStatistics?.oldestCreatedAt));
  }, 1000);

  if (!isAdmin || !data?.newLeadsStatistics?.count) {
    return null
  }

  return <>
    {!!data?.newLeadsStatistics?.count && <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{backgroundColor: '#5ecb9e'}} p={'10px 16px'}>
      <Typography color={'textPrimary'} className={'medium'}>{data?.newLeadsStatistics?.count} NEW LEADS - Time to oldest: {time}</Typography>
    </Box>}
  </>
}

export default NewLeadsStatistics;
