import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem} from "@mui/material";
import FormSelect from "../shared/FormSelect";
import {DisqualifiedReasonDto} from "../enrollment-types";
import React, {useEffect, useState} from "react";

interface Props {
  open: boolean,
  onClose: (value?: DisqualifiedReasonDto) => void,
}

export function DisqualifiedReasonModal({onClose, open}: Props) {
  const [disqualifiedReason, setDisqualifiedReason] = useState<DisqualifiedReasonDto | null>(null)

  useEffect(() => {
    if (open) {
      setDisqualifiedReason(null)
    }
  }, [open]);

  return <Dialog
    fullWidth
    maxWidth={'xs'}
    open={open}
    onClose={() => onClose()}
  >
    <DialogTitle>Select Disqualified Reason</DialogTitle>
    <DialogContent>
      <FormSelect label={'Select'}
                  value={disqualifiedReason}
                  onChange={event => {
                    setDisqualifiedReason(event.target.value as DisqualifiedReasonDto);
                  }}
      >
        <MenuItem value={DisqualifiedReasonDto.NoLongerInterestedInSwitching}>No Longer Interested In Switching</MenuItem>
        <MenuItem value={DisqualifiedReasonDto.UnableToConnect}> Unable To Connect</MenuItem>
        <MenuItem value={DisqualifiedReasonDto.WentToAnotherBroker}> Went To Another Broker</MenuItem>
      </FormSelect>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose()} color="primary">
        Cancel
      </Button>
      <Box color={'red'}>
        <Button disabled={!disqualifiedReason} onClick={() => onClose(disqualifiedReason || undefined)} color="inherit">
          Ok
        </Button>
      </Box>
    </DialogActions>
  </Dialog>
}