import moment from "moment";
import momenttz from "moment-timezone";

export function getAgeByBirthday(birthday: string): number {
  return getAge(birthday)
}

export function getAge(birthday: string, secondDate?: string): number {
  return Math.floor(moment.duration(moment(secondDate).diff(moment(birthday))).asYears())
}

export const getTimeInState = (state: string, time?: any) => {
  let zone = momenttz().zoneName();

  const calcZone = getStateTimeZone(state);
  if (calcZone) {
    zone = calcZone;
  }

  const result = momenttz(time);
  return result.tz(zone).format('h:mmA');
}

export const getStateTimeZone = (state: string) => {
  const zones: {[key: string]: string[]} = {
    'America/New_York': ["FL", "GA", "SC", "NC", "KY", "VA", "WV", "DC", "MD", "DE", "NJ", "PA", "OH", "IN", "MI", "NY", "CT", "RI", "MA", "NH", "VT", "ME"],
    'America/Chicago': ["TX", "OK", "LA", "AR", "MS", "AL", "TN", "MO", "KS", "IL", "IA", "NE", "WI", "MN", "SD", "ND"],
    'America/Denver': ["AZ", "NM", "CO", "UT", "WY", "ID", "MT"],
    'America/Los_Angeles': ["CA", "NV", "OR", "WA"],
    'America/Anchorage': ["AK"],
    'US/Hawaii': ["HI"],
  };

  for (const zone of Object.keys(zones)) {
    if (zones[zone].includes(state)) {
      return zone;
    }
  }
}

export function formatPhoneLink(
    phoneNumberString?: string | null,
    useFormat: boolean = false
): string | null {
  if (!phoneNumberString) {
    return null;
  }

  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");

  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})/);
  if (useFormat && match) {
    return [match[2], match[3], match[4]].join("-");
  }
  return cleaned ?? null;
}
