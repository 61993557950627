import {Box, Card, Divider, IconButton, Stack, Typography} from "@mui/material";
import React, {useMemo, useState} from "react";
import {getUserName, WithCalls} from "../tools";
import {CallHistoryOutput, ClientViewOutput, StatusDto} from "../../enrollment-types";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import FormSelect from "../FormSelect";

export default function TranscriptDrawerContent({client, onClose}: {client: WithCalls<ClientViewOutput>, onClose: () => void}) {
  const [index, setIndex] = useState<number>()

  const transcripts = useMemo(() => {
    let calls = client.calls;
    if (typeof index === 'number') {
      calls = [calls[index]];
    }
    return calls.map(c => toCallDetails(c, client.firstName || 'Client'));
  }, [index, client.calls]);


  return <Card sx={{p: 4, width: 594, overflowY: 'auto'}}>
    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      <IconButton size={'small'} onClick={onClose}>
        <img className={'w-32 h-32'} src={'/img/X.svg'}/>
      </IconButton>
    </Box>
    <Typography variant={'h3'}>{getUserName(client)}’s Transcript</Typography>
    <Divider sx={{mb: 3, mt: 2}} />
    <Stack spacing={2}>
      <FormSelect label={'Select transcript date'}
                  sx={{maxWidth: 270}}
                  value={index}
                  onChange={event => {
                    setIndex(event.target.value as number)
                  }}
      >
        <MenuItem >All Transcripts</MenuItem>
        {transcripts.map((t, i) => <MenuItem key={i} value={i}>{moment(t.createdAt).format('MMM D, YYYY | h:mm A')}</MenuItem>)}
      </FormSelect>
      {transcripts.map((t, i) => (
        <Call key={t.createdAt + i} call={t} />
      ))}
    </Stack>
  </Card>
}

const Call = ({call}: {call: CallDetails}) => {
  return <Stack spacing={2}>
    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
      <img src={'/img/Phone.svg'} width={18} height={18} />
      <Typography className={'semi-bold fs-14 lh-24'}>{moment(call.createdAt).format('MMM D, YYYY')}</Typography>
    </Box>
    {call.phrases.map((p, i) => (
      <CallPhraseView key={call.createdAt + i}
                      phrase={p}
      />
    ))}
  </Stack>
}

const CallPhraseView = ({phrase}: {phrase: CallPhrase}) => {
  return <div>
    <Typography className={'fs-14 lh-24'} sx={{color: '#666', textAlign: phrase.isAgent ? 'left' : 'right'}}>{phrase.name}</Typography>
    <Box sx={{p: 1, backgroundColor: phrase.isAgent ? 'white' : '#F8F6F2'}}>
      <Typography className={'fs-14 lh-24'} sx={{textAlign: phrase.isAgent ? 'left' : 'right'}}>{phrase.phrase}</Typography>
    </Box>
  </div>
}

interface CallPhrase {
  name: string,
  phrase: string,
  isAgent: boolean
}

interface CallDetails {
  phrases: CallPhrase[],
  createdAt: string
}

const toCallDetails = (call: CallHistoryOutput, firstName: string): CallDetails => {
  const phrases = call.transcription!.split('<br/><br/>')
    .map(p => {
      let [name, phrase] = p.split('</strong> - ');
      name = name.replace('<strong>', '')
      return { name: name === 'Agent' ? 'Operator' : firstName, phrase, isAgent: name === 'Agent' };
    });

  return { phrases, createdAt: call.createdAt }
}

