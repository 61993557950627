import useSessionStorageState from "../../shared/useSessionStorageState";
import {AttemptedLeadGroupDto} from "../../enrollment-types";


export default function() {
  return useSessionStorageState<string | undefined>(
    'attemptedLeadState',
    undefined
  )
}
