import DataGrid, {DataGridColumn, DataGridProps} from "../DataGrid";
import {Box, Drawer, SxProps, Typography} from "@mui/material";
import {calcDurationToNow, getUserName, WithCalls} from "../tools";
import React, {useMemo, useState} from "react";
import moment from "moment/moment";
import {formatPhoneLink, getTimeInState} from "../utils";
import {colorByStatus, labelByStatus} from "../../features/StatusView";
import * as _ from "lodash";
import TranscriptDrawerContent from "./TranscriptDrawerContent";
import TagsDrawerContent from "./TagsDrawerContent";
import StatusDrawerContent from "./StatusDrawerContent";
import NotesDrawerContent from "./NotesDrawerContent";
import FollowUpDrawerContent from "./FollowUpDrawerContent";
import {ClientViewOutput, Field} from "../../enrollment-types";
import ExpectedCloseDateDrawerContent from "./ExpectedCloseDateDrawerContent";
import {Link} from "react-router-dom";
import {keycloak} from "../keycloak";


export default function ClientViewTable(props: Omit<DataGridProps<WithCalls<ClientViewOutput>, Field>, 'columns'> & {onRefetch: () => void}) {
  const [drawerContent, setDrawerContent] = useState<JSX.Element>();

  const onTranscriptionClick = (client: WithCalls<ClientViewOutput>) => {
    setDrawerContent(<TranscriptDrawerContent client={client} onClose={() => setDrawerContent(undefined)} />)
  }

  const onDrawerClose = (refresh?: boolean) => {
    setDrawerContent(undefined)
    if (refresh) {
      props.onRefetch();
    }
  }

  const onTagClick = (client: WithCalls<ClientViewOutput>) => {
    setDrawerContent(<TagsDrawerContent client={client} onClose={onDrawerClose} />)
  }

  const onStatusClick = (client: WithCalls<ClientViewOutput>) => {
    setDrawerContent(<StatusDrawerContent client={client} onClose={onDrawerClose} />)
  }

  const onNotesClick = (client: WithCalls<ClientViewOutput>) => {
    setDrawerContent(<NotesDrawerContent client={client} onClose={onDrawerClose} />)
  }

  const onFollowUpClick = (client: WithCalls<ClientViewOutput>) => {
    setDrawerContent(<FollowUpDrawerContent client={client} onClose={onDrawerClose} />)
  }

  const onExpectedCloseDateClick = (client: WithCalls<ClientViewOutput>) => {
    setDrawerContent(<ExpectedCloseDateDrawerContent client={client} onClose={onDrawerClose} />)
  }

  const columns = useMemo(() => {
    return getColumns({onTranscriptionClick, onTagClick, onStatusClick, onFollowUpClick, onExpectedCloseDateClick});
  }, [onTranscriptionClick, onTagClick, onStatusClick, onFollowUpClick, onExpectedCloseDateClick]);

  const getRowClassName = (client: WithCalls<ClientViewOutput>, i: number) => {
    let className = 'client-table-row-' + i;
    const daysFromLastCallMatches = /[0-9]+d/g.exec(calcDurationToNow(client.lastCall?.createdAt));
    if (!daysFromLastCallMatches?.length) {
      return className
    }

    const daysFromLastCall = parseFloat(daysFromLastCallMatches[0].replace('d', ''));

    if (!daysFromLastCall) {
      return className
    }

    const tagNames = client.tags?.map(v => v.tag.name) || [];

    if (
      daysFromLastCall > 3 && tagNames.includes('C1') ||
      daysFromLastCall > 5 && tagNames.includes('C2') ||
      daysFromLastCall > 10 && tagNames.includes('C3') ||
      daysFromLastCall > 14 && tagNames.includes('C4')
    ) {
      return className + ' warning-data-grid-row';
    }

    return className;
  }

  return <>
    <Drawer
      anchor={'right'}
      open={!!drawerContent}
      onClose={() => setDrawerContent(undefined)}
    >
      {drawerContent}
    </Drawer>
    <DataGrid columns={columns}
              extraRowBuilder={(row, i) => <NotesRow key={'row_extra' + i}
                                                     onClick={() => onNotesClick(row)}
                                                     onMouseEnter={e => onMouseEnter(e, row, i)}
                                                     onMouseLeave={e => onMouseLeave(e, row, i)}
                                                     className={getRowClassName(row, i)}
                                                     client={row}/>}
              sx={styleOverrides}
              getRowClassName={(row, i) => getRowClassName(row, i)}
              onRowMouseEnter={onMouseEnter}
              onRowMouseLeave={onMouseLeave}
              {...props}
    />
  </>
}

const onMouseEnter = (e: any, row: WithCalls<ClientViewOutput>, i: number) => {
  const className = 'client-table-row-' + i;
  document.querySelectorAll(`.${className}`).forEach(el => {
    if (!el.classList.contains('client-table-hover')) {
      el.classList.add('client-table-hover')
    }
  })
}

const onMouseLeave = (e: any, row: WithCalls<ClientViewOutput>, i: number) => {
  const className = 'client-table-row-' + i;
  document.querySelectorAll(`.${className}`).forEach(el => {
    el.classList.remove('client-table-hover')
  })
}

const styleOverrides: SxProps = {
  '& tbody tr': {
    transition: 'background-color 200ms linear',
    '&:hover': {
      backgroundColor: 'white',
    },
    '&.client-table-hover': {
      backgroundColor: 'rgba(249, 230, 154, 0.30)',
    },
    '& td:not(.notes)': {
      maxWidth: 200,
    }
  },
}

interface Handlers {
  onTranscriptionClick: (client: WithCalls<ClientViewOutput>) => void,
  onTagClick: (client: WithCalls<ClientViewOutput>) => void,
  onStatusClick: (client: WithCalls<ClientViewOutput>) => void,
  onFollowUpClick: (client: WithCalls<ClientViewOutput>) => void,
  onExpectedCloseDateClick: (client: WithCalls<ClientViewOutput>) => void,
}

const getColumns: (handlers: Handlers) => DataGridColumn<WithCalls<ClientViewOutput>, Field>[] = (handlers) => [
  {
    title: 'Contact',
    value: client => (
      <ClickableCell title={getUserName(client)}
                     href={'/client/' + client.id}
                     icon={client.watchers?.map(w => w.id).includes(keycloak.tokenParsed.sub) ? <img src={'/img/Eye.svg'} width={16} height={16} /> : <></>}
                     extra={<>
                       <Typography sx={{color: '#666'}} className={'fs-14 no-wrap'}>{client.state ? `${getTimeInState(client.state)} in ${client.state}` : 'Missing State'}</Typography>
                       <Typography sx={{color: '#666'}} className={'fs-14 no-wrap'}>{client.email}</Typography>
                     </>}
      />
    )
  },
  {
    title: 'Birthdate',
    value: client => <div>
      <Typography className={'semi-bold fs-14 lh-24'}>{moment(client.birthDate).format('L')}</Typography>
      <Typography sx={{color: '#666'}} className={'fs-14'}>{Math.floor(moment.duration(moment().diff(moment(client.birthDate))).asYears())} yo</Typography>
    </div>,
    sortKey: Field.BirthDate
  },
  {
    title: 'Lead Date',
    value: client => (
      <Typography className={'semi-bold fs-14 lh-24'}>{moment(client.createdAt).format('L')}</Typography>
    ),
    sortKey: Field.CreatedAt
  },
  {
    title: 'Phone',
    value: client => (
      <Typography className={'semi-bold fs-14 lh-24'}>{formatPhoneLink(client.phoneNumber, true)}</Typography>
    ),
    sortKey: Field.PhoneNumber
  },
  {
    title: 'Calls',
    value: client => (
      <Typography className={'semi-bold fs-14 lh-24'}>{client.calls.length}</Typography>
    )
  },
  {
    title: 'Status',
    value: client => (
      <ClickableCell title={<Typography sx={{color: colorByStatus(client.status)}}
                                        className={'semi-bold fs-14 lh-24'}>{labelByStatus(client.status)}</Typography>}
                     onClick={() => handlers.onStatusClick(client)}
      />
    )
  },
  {
    title: 'Tags',
    value: client => (
      <ClickableCell title={client.tags.map(tag => tag.tag.name).sort().join(', ')}
                     onClick={() => handlers.onTagClick(client)}
      />
    )
  },
  {
    title: 'Last Call',
    value: client => (
      client.lastCall ? <>
          {client.lastCall?.transcription && <ClickableCell title={'Transcript'}
                                                            onClick={() => handlers.onTranscriptionClick(client)}
                                                            extra={<>
                                                              <Typography sx={{color: '#666'}} className={'fs-14 no-wrap'}>{moment(client.lastCall.createdAt).format('MMM D, YYYY')}</Typography>
                                                              <Typography sx={{color: '#666'}} className={'fs-14 no-wrap'}>{moment(client.lastCall.createdAt).format('h:mm A')} EST</Typography>
                                                            </>}
          />}
          {!client.lastCall?.transcription && <>
            <Typography sx={{color: '#666'}} className={'fs-14 no-wrap'}>{moment(client.lastCall.createdAt).format('MMM D, YYYY')}</Typography>
            <Typography sx={{color: '#666'}} className={'fs-14 no-wrap'}>{moment(client.lastCall.createdAt).format('h:mm A')} EST</Typography>
          </>}
      </> : ''
    ),
    sortKey: Field.LastCallDate
  },
  {
    title: 'Next Contact',
    value: client => (
      <ClickableCell title={moment(client.followUpDate).format('L')}
                     onClick={() => handlers.onFollowUpClick(client)}
                     extra={<>
                       <Typography sx={{color: '#666'}} className={'fs-14 no-wrap'}>{moment(client.followUpDate).format('MMM D, YYYY')}</Typography>
                       <Typography sx={{color: '#666'}} className={'fs-14 no-wrap'}>{moment(client.followUpDate).format('h:mm A')} EST</Typography>
                     </>}
      />
    ),
    sortKey: Field.FollowUpDate
  },
  {
    title: 'Expected Close Date',
    value: client => (
      <ClickableCell title={client.expectedCloseDate ? moment(client.expectedCloseDate).format('L') : ''}
                     onClick={() => handlers.onExpectedCloseDateClick(client)}
      />
    ),
    sortKey: Field.FollowUpDate
  },
]

interface NotesRowProps {
  client: WithCalls<ClientViewOutput>,
  onClick: () => void,
  className?: string,
  onMouseEnter?: (event: any) => void,
  onMouseLeave?: (event: any) => void,
}

const NotesRow = ({client, onClick, className, onMouseEnter, onMouseLeave}: NotesRowProps) => {
  const notes = _.orderBy(client.notes || [], 'createdAt');
  let note = notes[notes.length - 1]?.note || '';

  let words = note.split(' ');

  if (words.length > 20) {
    words = words.slice(0, 20);
    words[words.length - 1] = words[words.length - 1] + '...';
  }

  return <tr className={className} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
    <Box component={'td'} sx={{borderBottom: '1px solid #B3B3B3',}} className={'notes'} colSpan={10}>
      <Box component={'a'} onClick={onClick} sx={{display: 'flex', alignItems: 'center', gap: .5, width: 'fit-content'}}>
        <img src={'/img/PencilSimple.svg'} width={16} height={16} />
        <Typography sx={{color: '#1C434F'}} className={'semi-bold fs-14 lh-24'}>Notes</Typography>
        <Typography sx={{color: '#666'}} className={'fs-14 no-wrap'}>{words.join(' ')}</Typography>
      </Box>
    </Box>
  </tr>
}

interface ClickableCellProps {
  href?: string,
  title: string | JSX.Element,
  onClick?: (event: any) => void,
  icon?: JSX.Element, extra?: JSX.Element
}

const ClickableCell = (props: ClickableCellProps) => {
  const icon = props.icon || <img src={'/img/PencilSimple.svg'} width={16} height={16} />;

  const content = <Box sx={{display: 'flex', alignItems: 'center', gap: .5, width: 'fit-content'}}>
    {icon}
    {typeof props.title === 'string' && <Typography sx={{color: '#1C434F'}} className={'semi-bold fs-14 lh-24'}>{props.title}</Typography>}
    {typeof props.title !== 'string' && <>{props.title}</>}
  </Box>

  return <div>
    {props.href && <Link to={props.href}>
      {content}
    </Link>}

    {!props.href && <div className={'pointer'} onClick={props.onClick}>
      {content}
    </div>}

    {props.extra}
  </div>
}

