import * as _ from "lodash";
import React from "react";
import {
  AvailableStateDto,
  EnabledPbpContractInput, EnabledPbpContractOutput, EnabledPbpStatesOutput,
} from "../../../types";
import {Box, Checkbox, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@mui/material";
import {Row} from "./Row";
import {SearchField} from "./SearchField";
import {ParentCompanyFilter} from "./ParentCompanyFilter";

interface Api<T, S> {
  data: T[],
  save: (data: {variables: S}) => void,
  loading: boolean
}

interface Props {
  contractsApi: Api<WithoutTypeName<EnabledPbpContractOutput>, {input: EnabledPbpContractInput}>,
  statesApi: Api<WithoutTypeName<EnabledPbpStatesOutput>, {state: AvailableStateDto, enabled: boolean}>,
}

export function PlanCoverage<T>({statesApi, contractsApi}: Props) {
  const {data: contracts, loading: contractsLoading, save: saveContract} = contractsApi;
  const {data: states, loading: statesLoading, save: saveState} = statesApi;

  const [parentCompany, setParentCompany] = React.useState('');
  const [term, setTerm] = React.useState('');

  const parentCompanies = _.uniq(contracts.map(c => c.parentCompany)).sort();
  let filteredMaContracts = contracts;

  if (term) {
    filteredMaContracts = contracts.filter(filterByTerm(term))
  }

  if (parentCompany) {
    filteredMaContracts = contracts.filter(filterByParentCompany(parentCompany))
  }


  const onStateToggle = (state: AvailableStateDto, enabled: boolean) => {
    saveState({variables: {state, enabled}})
  }

  const onContractToggle = (input: EnabledPbpContractInput) => {
    saveContract({variables: {input}})
  }


  return <Box display={'flex'} p={'30px'} alignItems={'top'} width={'100%'}>
    <Box flex={1}>
      <Typography className={'mb-16'} color={'textPrimary'} variant={'h4'}>Contract Coverage</Typography>

      <Box sx={{display: 'flex', gap: 2}}>
        <SearchField onChange={setTerm} />
        <ParentCompanyFilter parentCompanies={parentCompanies} onChange={setParentCompany} />
      </Box>

      <Box component={'table'} sx={{opacity: contractsLoading ? .3 : 1, pointerEvents: contractsLoading ? 'none' : undefined}} >
        <thead>
        <tr>
          <Box component={'td'} sx={{minWidth: 100}}><b>Contract</b></Box>
          <td><b>Parent Company</b></td>
          <td><b>Company</b></td>
          <td></td>
        </tr>
        </thead>
        <tbody>
        {filteredMaContracts.map((item, i) => (
          <Row item={item}
               key={Object.values(item).join(':') + i}
               onClick={(checked) => onContractToggle({...item, enabled: checked})}/>
        ))}
        </tbody>
      </Box>
    </Box>
    <Box flex={1} ml={20}>
      <Typography color={'textPrimary'} variant={'h4'}>State Coverage</Typography>
      <Box sx={{maxWidth: 120, width: '100%'}}>
        <List sx={{opacity: statesLoading ? .3 : 1, pointerEvents: statesLoading ? 'none' : undefined}}>
          {states.map((item, i) => <ListItem
            key={item.state}
          >
            <ListItemText primary={item.state} />
            <ListItemSecondaryAction>
              <Checkbox
                color={'primary'}
                edge="end"
                onChange={(e) => {onStateToggle(item.state, e.target.checked)}}
                checked={item.enabled}
              />
            </ListItemSecondaryAction>
          </ListItem>)}
        </List>
      </Box>
    </Box>
  </Box>
}

const filterByTerm = (term: string) => (contract: {contract: string, company: string, parentCompany: string}) => (
  [contract.contract.toLowerCase(), contract.company.toLowerCase(), contract.parentCompany.toLowerCase()].some(t => t.indexOf(term.toLowerCase()) >= 0)
)

const filterByParentCompany = (parentCompany: string) => (contract: {parentCompany: string}) => (
  contract.parentCompany === parentCompany
)