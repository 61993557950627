import React from "react";
import Card from "../../../../shared/Card";
import {Box, IconButton, Typography} from "@mui/material";
import {ClientViewOutput, StatusDto,} from "../../../../enrollment-types";
import {StatusView} from "../../../../features";
import moment from "moment";
import {useHistory} from "react-router-dom";
import paths from "../../../../router-paths";
import _ from "lodash";
import {getAgentDisplayName} from "../../../../Agent/AgentSelector";
import {useAssignmentHistoryDrawer, withAssignmentHistoryDrawer} from "./ui/AssignmentHistoryDrawer";
import {StatusForm} from "./ui/StatusForm";
import {Watchers} from "./ui/Watchers";
import {useSaveStatus} from "./api/useSaveStatus";

interface StatusProps {
  client?: ClientViewOutput
}

const Status = ({client}: StatusProps) => {
  const [showForm, setShowForm] = React.useState(false);
  const history = useHistory();

  const {setOpen} = useAssignmentHistoryDrawer();

  const closeForm = () => setShowForm(false);

  const {save, loading} = useSaveStatus(closeForm, client?.id)

  return <Card>
    <div className={'flex-space-between flex-align-center'}>
      <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>Status / Agent</Typography>
      {!showForm && <IconButton size={'small'} onClick={() => setShowForm(true)}><img src={'/img/mode.svg'}/></IconButton>}
    </div>
    {!showForm && <div>
      <Box sx={{display: 'flex', gap: 2, mb: 1}}>
        <Typography>{client?.agent ? getAgentDisplayName(client.agent) : 'No agent'}</Typography>
        <Watchers watchers={client?.watchers} onClick={() => setOpen(true)} />
        <Typography className={'pointer underline'} onClick={() => setOpen(true)}>(show history)</Typography>
      </Box>

      <div className={'flex'}>
        <StatusView status={client?.status || undefined} />
        {client?.originalClientId && <Typography  onClick={() => history.push(paths.client + '/' + client?.originalClientId)}
          className={'pointer fs-12 ml-20 underline'} color={'textPrimary'}>Original</Typography>}
      </div>
      <Typography color={"textPrimary"} variant={"body1"} className={"mt-5"}>Next contact date: {client?.followUpDate ? moment(client?.followUpDate).format('L') : 'N/A'}</Typography>
      {client?.status === StatusDto.SalesQualified && (
        <Typography color={"textPrimary"} variant={"body1"} className={"mt-5"}>Expected close date: {client?.expectedCloseDate ? moment(client?.expectedCloseDate).format('L') : 'N/A'}</Typography>
      )}
    </div>}
    {showForm && <StatusForm agentId={client?.agent?.id}
                             status={client?.status || ''}
                             expectedCloseDate={client?.expectedCloseDate}
                             followUpDate={client?.followUpDate}
                             loading={loading}
                             onCancel={closeForm}
                             onSubmit={save}/>}
    <Box sx={{mt: 1}}>
      {!!client?.scheduledTagPlacement && <Typography color={"textPrimary"} variant={"body2"}>Tag will be placed in the end of day.</Typography>}
      {(!!client?.disqualifiedReason && client?.status === StatusDto.Disqualified) && <Typography className={'mt-15'} color={"textPrimary"} variant={"body2"}>{toHuman(client?.disqualifiedReason)}</Typography>}
    </Box>
  </Card>
};

export default withAssignmentHistoryDrawer(Status);

const toHuman = (val?: string) => _.upperFirst(_.lowerCase(val || ''))

