import {forwardRef} from "react";
import * as React from "react";
import * as _ from "lodash";
import TextInput from "./TextInput";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import moment from "moment/moment";
import {DatePickerProps} from "@mui/x-date-pickers/DatePicker/DatePicker.types";

interface Props extends DatePickerProps<Date> {
  formik: any,
  name: string,
  'data-test'?: string,
}

export const FormikDateInput = forwardRef((props: Props, ref: any) => {
  const {formik, name, ...rest} = props;

  return <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      ref={ref}
      slotProps={{
        textField: {
          fullWidth: true,
          error: formik.touched[name] && Boolean(formik.errors[name]),
          helperText: formik.touched[name] && formik.errors[name],
          inputProps: {
            placeholder: 'MM / DD / YYYY'
          }
        }
      }}
      slots={{
        textField: TextInput as any,
      }}
      name={name}
      format="MM/dd/yyyy"
      label={rest.label || _.startCase(name)}
      value={moment(formik.values[name]).toDate()}
      onChange={value => formik.setFieldValue(name, value)}
      data-test={props['data-test'] || _.snakeCase(name)}
      {...rest}
    />
  </LocalizationProvider>
})
