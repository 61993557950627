import React, {useState} from "react";
import {Card, Chip} from "@mui/material";
import useContacted from "../hooks/useContacted";
import useSessionStorageState from "../../shared/useSessionStorageState";
import {assignCallsToClients} from "../../shared/tools";
import ClientViewTable from "../../shared/ClientViewTable/ClientViewTable";
import {ClientViewOutput, Field} from "../../enrollment-types";
import moment from "moment/moment";
import {DataGridSortModel} from "../../shared/DataGrid";

const Contacted = (props: {agentId?: string, onRefetch?: () => void}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(1000);
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = React.useState<any[]>([]);
  const [sort, setSort] = useState<DataGridSortModel<Field>>();

  const decorateContacted = async (input: ClientViewOutput[]) => {
    const updated = await assignCallsToClients(input);
    setRows(updated)
    setLoading(false)
  }

  const [getContacted, {data, refetch}] = useContacted({
    fetchPolicy: 'no-cache',
    onCompleted: res => res.contacted && decorateContacted(res.contacted.data),
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    if (props.agentId) {
      setLoading(true)
      getContacted({variables: {
          agentId: props.agentId,
          sort,
          pageInput: {
            page: page,
            size: pageSize
          },
        }
      }).catch(() => {
        setLoading(false)
      })
    }
  }, [props.agentId, pageSize, page, sort]);


  const total = React.useMemo(() => {
    return data?.contacted.totalElements ? parseFloat(data?.contacted.totalElements) : 0;
  }, [data]);


  const isLastPage = React.useMemo(() => {
    return typeof data?.contacted.isLast === 'undefined' ? true : data?.contacted.isLast;
  }, [data]);

  const onRefetch = () => {
    refetch();
    props.onRefetch && props.onRefetch();
  }

  return <Card sx={{p: 4}}>
    <ClientViewTable rows={rows}
                     loading={loading}
                     page={page}
                     rowsPerPage={pageSize}
                     total={total}
                     onRefetch={onRefetch}
                     isLastPage={isLastPage}
                     sort={sort}
                     onSortModelChange={setSort}
                     onShowMoreClick={() => setPage(prev => prev + 1)}
    />
  </Card>
}

export default Contacted;

function usePage() {
  return useSessionStorageState<number>(
    'contactedPage',
    0
  )
}

function usePageSize() {
  return useSessionStorageState<number>(
    'contactedPageSize',
    20
  )
}
