import {
  Avatar,
  Card,
  Divider,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  CircularProgress, Box
} from "@mui/material";
import React, {MutableRefObject, useMemo, useRef, useState} from "react";
import {stringAvatar} from "../lib/lib";
import {Conversation} from "@twilio/conversations";
import {RippleBadge} from "../../shared/RippleBadge";
import {getUserName} from "../../shared/tools";
import useBadgesForChats from "../lib/use-badges-for-chats";
import ChatListFilter from "./chat-list-filter";
import {useScrollbar} from "../../shared/hooks/useScrollbar";

interface Props {
  onClick: (value: Conversation) => void,
  conversations: Conversation[],
  active?: Conversation
}
export default function ChatList({onClick, active, conversations}: Props) {
  const [searchTerm, setSearchTerm] = useState('');
  const badges = useBadgesForChats(conversations);

  const listRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  useScrollbar(listRef?.current)

  const filteredConversations = useMemo(() => {
    return conversations
      .sort(sortFunc(badges))
      .filter(c => (
        getUserName(c.attributes as any).toLowerCase().includes(searchTerm.toLowerCase()) ||
        (c.attributes as any)?.email.toLowerCase()?.includes(searchTerm.toLowerCase())
      ))
  }, [searchTerm, conversations, badges]);

  return <Card sx={{ width: 250 }}>
    {!conversations.length && <Box sx={{display: 'flex', justifyContent: 'center', width: 1, alignItems: 'center', height: 100}} >
      <CircularProgress/>
    </Box>}
    {!!conversations.length && <>
      <ChatListFilter value={searchTerm} onChange={setSearchTerm} />

      <Box sx={{maxHeight: 'calc(100% - 52px)'}} ref={listRef}>
        <List>
          {filteredConversations.map((c, i) => (
            <React.Fragment  key={c.sid}>
              <ListItemButton alignItems="flex-start" selected={active === c} onClick={() => onClick(c)}>
                <ListItemAvatar>
                  <RippleBadge overlap="circular"
                               invisible={!badges.includes(c.sid)}
                               anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                               variant="dot">
                    <Avatar {...stringAvatar(getUserName(c.attributes as any))} />
                  </RippleBadge>
                </ListItemAvatar>

                <ListItemText
                  primary={getUserName(c.attributes as any)}
                  secondary={(c.attributes as any)?.email}
                />
              </ListItemButton>
              {i < conversations.length - 1 && <Divider/>}
            </React.Fragment>
          ))}

        </List>
      </Box>

    </>}

  </Card>
}

const sortFunc = (hasNewMessagesSids: string[]) => (c1: Conversation, c2: Conversation) => {
  if (hasNewMessagesSids.includes(c1.sid) && !hasNewMessagesSids.includes(c2.sid)) {
    return -1;
  } else if (hasNewMessagesSids.includes(c2.sid) && !hasNewMessagesSids.includes(c1.sid)) {
    return 1
  } else {
    return (c1.friendlyName || '') < (c2.friendlyName || '') ? -1 : 1;
  }
}


