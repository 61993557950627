import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveTagArgs} from "../../enrollment-types";

export default function useSaveTag(options?: MutationHookOptions<any, MutationSaveTagArgs>) {
  return useMutation<any, MutationSaveTagArgs>(gql(saveMutation), options)
}

const saveMutation = `
mutation ($input: TagInput!) {
  saveTag(input: $input)
}
`;
