import React from "react";
import {useCreateConversationForClientMutation} from "../../chat-types";
import {chatClient} from "../../Apollo/ChatApolloClient";
import {LoadingButton} from "@mui/lab";


interface Props {
  clientId: string,
  clientUserId: string,
  onCompleted?: () => void
}

export default function CreateChatButton({clientId, clientUserId, onCompleted}: Props) {
  const [createChat, {loading}] = useCreateConversationForClientMutation({
    client: chatClient,
    variables: { clientId, clientUserId },
    onCompleted
  })

  return <LoadingButton variant={'contained'} loading={loading} onClick={() => createChat()}>
    Create chat
  </LoadingButton>
}
