import React from "react";
import {useMaContracts} from "./hooks/use-ma-contracts";
import {useMaStates} from "./hooks/use-ma-states";
import {PlanCoverage} from "./ui/PlanCoverage";

export function MaPlanCoverage() {
  const contractsApi = useMaContracts();
  const statesApi = useMaStates();

  return <PlanCoverage statesApi={statesApi}
                       contractsApi={contractsApi} />
}