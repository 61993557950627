import {CallHistoryCallType, CallHistoryResultInput} from "../../../../enrollment-types";

export const stringifyCallResult = (type?: CallHistoryResultInput) => {
  switch (type) {
    case CallHistoryResultInput.SuccessfulSeeNotesForCallDetails: return 'Successful - See notes for call details';
    case CallHistoryResultInput.UnsuccessfulInvalidNumber: return 'Unsuccessful - Invalid Number (Wrong/Disconnected)';
    case CallHistoryResultInput.UnsuccessfulLeftText: return 'Unsuccessful - Left Text';
    case CallHistoryResultInput.UnsuccessfulLeftVm: return 'Unsuccessful - Left VM';
    case CallHistoryResultInput.SuccessfulBookedCall: return 'Successful - Booked Call';
    case CallHistoryResultInput.UnsuccessfulLeftEmail: return 'Unsuccessful - Left Email';
    case CallHistoryResultInput.SuccessfulWarmTransfer: return 'Successful - Warm Transfer';
    default: return '';
  }
}

export const stringifyCallType = (type?: CallHistoryCallType) => {
  switch (type) {
    case CallHistoryCallType.Inbound: return 'Inbound call';
    case CallHistoryCallType.Outbound: return 'Outbound attempt';
    default: return '';
  }
}