import {Conversation} from "@twilio/conversations";

export enum ChatActionTypes {
  ADD_CONVERSATION,
  UPDATE_CONVERSATION,
  SET_STATUS,
  SET_HAS_NEW_MESSAGES,
  SET_CHAT_IDENTITY_ID,
}

export type ChatAction = AddConversationAction |
  SetStatusAction |
  SetHasNewMessagesAction |
  UpdateConversationAction |
  SetChatIdentityIdAction
  ;

export interface SetStatusAction {
  payload: ChatStatus,
  type: ChatActionTypes.SET_STATUS
}

export interface SetChatIdentityIdAction {
  payload: string,
  type: ChatActionTypes.SET_CHAT_IDENTITY_ID
}

export interface AddConversationAction {
  payload: Conversation,
  type: ChatActionTypes.ADD_CONVERSATION,
}

export interface UpdateConversationAction {
  payload: Conversation,
  type: ChatActionTypes.UPDATE_CONVERSATION,
}

export interface SetHasNewMessagesAction {
  payload: boolean,
  type: ChatActionTypes.SET_HAS_NEW_MESSAGES,
}

export interface ChatState {
  status: ChatStatus,
  conversations: Conversation[],
  hasNewMessages: boolean,
  chatIdentityId?: string,
}

export type ChatStatus = 'success' | 'default' | 'error'

export interface ChatMessage {
  index?: number | null,
  dateCreated?: Date | null,
  author?: string | null,
  body?: string | null,
  participantSid?: string | null,
  attributes?: any
}

export type MessageMenuType = 'setUnread';