import {Client as ConversationsClient} from "@twilio/conversations";
import useGetAuthToken from "./use-get-auth-token";
import {useEffect, useState} from "react";

export function useConversationsClient(chatIdentityId?: string) {
  const token = useGetAuthToken(chatIdentityId);
  const [conversationsClient, setConversationsClient] = useState<ConversationsClient>();

  useEffect(() => {
    if (token) {
      setConversationsClient(new ConversationsClient(token));
    }
  }, [token]);

  return conversationsClient;

}
