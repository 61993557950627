import React, {useState} from "react";
import {Box, CircularProgress, Divider, IconButton, Popover, Typography} from "@mui/material";
import {DoctorAddressViewOutput, DoctorViewOutput} from "../../../../../../../enrollment-types";

type DoctorLineProps = {
  doctor: DoctorViewOutput,
  showDelete?: boolean,
  showPCP?: boolean,
  onDeleteClick?: () => void,
  onEditClick?: () => void,
  onPopupClose?: () => void,
  loading?: boolean;
}

export const DoctorLine = (props: DoctorLineProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  return <Box sx={{
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: {xs: 'column', md: 'row'},
    opacity: props.loading ? .5 : 1
  }}>
    <Box flex={'1 1 50%'} overflow={'hidden'}>
      <Typography variant={'body1'} color={'textPrimary'}>{props.doctor.name}</Typography>
      {props.showPCP && <Typography variant={'body1'} color={'textSecondary'}>PCP: {props.doctor.pcpDetails ? props.doctor.pcpDetails[0]?.pcpId : '-'}</Typography>}
    </Box>
    <Box sx={{ml: 1, overflow: 'hidden', flex: '1 1 50%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
      <Typography noWrap align={'right'} color={'textSecondary'}>{props.doctor?.addresses[0]?.specialty}</Typography>
      <AddressBlock address={props.doctor.addresses[0]} />
      {props.doctor.addresses.length > 1 && <>
          <Typography sx={{width: 'fit-content'}} onMouseEnter={(event: any) => {
            setAnchorEl(event.currentTarget);
          }}
                      onMouseLeave={() => {
                        props.onPopupClose && props.onPopupClose();
                        setAnchorEl(null);
                      }}
                      align={'right'}
                      className={'fs-12 lh-15 mb-4'}>+ {props.doctor.addresses.length - 1} more</Typography>
          <Popover
              style={{pointerEvents: 'none'}}
              slotProps={{
                paper: {
                  className: 'ph-16 pv-8 doctor-address-popover'
                }
              }}
              open={open}
              anchorEl={anchorEl}
              elevation={2}
              onClose={() => {
                setAnchorEl(null);
                props.onPopupClose && props.onPopupClose();
              }}
              disableRestoreFocus
              hideBackdrop
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
          >
            {props.doctor.addresses.slice(1).map((a, i, array) => <div key={i}>
              {i < array.length - 1 && <Divider className={'mb-4 mt-8'}/>}
              <AddressBlock address={a} />
            </div>)}
          </Popover>
      </>}
    </Box>
    {props.loading && <CircularProgress size={20} sx={{opacity: .5, ml: 3}} />}
    {(!props.loading && props.showDelete) && <Box display={'flex'} alignItems={'center'}>
        <IconButton size={'small'} className={'ml-16'} onClick={props.onEditClick}><img src={'/img/mode.svg'}/></IconButton>
        <IconButton size={'small'} className={'ml-8 mr-35'} onClick={props.onDeleteClick}><img src={'/img/delete-icon.svg'}/></IconButton>
    </Box>}
  </Box>
}

const AddressBlock = ({address}: {address?: DoctorAddressViewOutput}) => (
  <Box display={'flex'} flexDirection={'column'} marginTop={0} alignItems={{xs: 'flex-start', sm: 'flex-end'}}>
    <Typography variant={'body2'}>{address?.addressLine1}</Typography>
    <Typography variant={'body2'}>{address?.addressLine2}</Typography>
  </Box>
)
