import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSendComparisonEmailArgs} from "../../../enrollment-types";
import {client} from "../../../Apollo/EnrollmentApolloClient";

export function useSendComparisonEmail(options?: MutationHookOptions<{sendComparisonEmail: boolean}, MutationSendComparisonEmailArgs>) {
  return useMutation<{sendComparisonEmail: boolean}, MutationSendComparisonEmailArgs>(gql(mutation), {
    client,
    ...options
  })
}

const mutation = `
mutation ($clientId: UUID!, $link: String!) {
  sendComparisonEmail(clientId: $clientId, link: $link)
}
`;
