import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './shared/helpers.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import Preloader from "./shared/Preloader";
import { SnackbarProvider } from 'notistack';
import AppTheme from "./app/AppTheme";
import {ThemeProvider} from "@mui/material";

import * as Sentry from "@sentry/react";
import {config} from "./config/config";

Sentry.init({
  dsn: "https://a835ed5d5244220f2692bb6fee1dfaa2@o4507526906511360.ingest.us.sentry.io/4508296668577792",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/*"localhost",*/ /^https:\/\/admin\.coverright\.com/],
  environment: config.production ? 'production' : 'develop',
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <SnackbarProvider preventDuplicate classes={{
    // variantSuccess: 'success-snack'
  }} anchorOrigin={{horizontal: 'center', vertical: 'bottom'}} autoHideDuration={6000} maxSnack={3}>
    <Suspense fallback={<Preloader />}>
      <ThemeProvider theme={AppTheme}>
        <App />
      </ThemeProvider>
    </Suspense>
  </SnackbarProvider>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

