import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Box, IconButton, Typography} from "@mui/material";
import moment from "moment";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import Card from "../../../shared/Card";
import {LeadSourceOutput, useClientLeadSourcesQuery, useUpdateLeadSourcesMutation} from "../../../enrollment-types";

const columns: GridColDef[] = [
  { field: 'eventDate', headerName: 'Event Date', width: 150,
    renderCell: (params) => moment(params.value?.toString()).format('L')
  },
  { field: 'campaign', headerName: 'Campaign', width: 200 },
  { field: 'medium', headerName: 'Medium', width: 150 },
  { field: 'source', headerName: 'Source', width: 150 },
  { field: 'term', headerName: 'Term', width: 150 },
  { field: 'content', headerName: 'Content', width: 150 },
];

const useStyles = makeStyles(() =>
  createStyles({
    refreshButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1000,
    },
    disabled: {
      opacity: 0.6,
    }
  }),
);

interface LeadSourcesProps {
  leadSources?: LeadSourceOutput[];
  loading: boolean;
  clientId?: string;
}

const LeadSources = (props: LeadSourcesProps) => {
  const classes = useStyles();
  const {loading, refetch, data} = useClientLeadSourcesQuery({
    fetchPolicy: 'no-cache',
    skip: !props.clientId,
    variables: {clientId: props.clientId}
  });
  const [update, {loading: updateLoading}] = useUpdateLeadSourcesMutation();

  const rows = data?.clientLeadSources || [];

  const updateRows = () => {
    update({variables: {clientId: props.clientId}}).then(() => refetch({clientId: props.clientId}))
  }

  return <div>
    <Typography color={'textPrimary'} className={'fs-16 medium flex flex-align-center'}>
      Lead Sources <Box component={'span'} sx={{ml: 2, fontSize: 12, color: 'gray'}}>{props.clientId}</Box>
    </Typography>

    <Card padding={0} className={'relative'}>
      <IconButton className={`${classes.refreshButton} ${loading ? classes.disabled : ''}`}
                  disabled={loading}
                  onClick={updateRows}>
        <img src={'/img/replay.svg'}/>
      </IconButton>
      <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
        <div style={{height: 300, width: '100%'}}>
          <DataGrid rows={rows.map((i, index) => ({...i, id: index}))}
                    columns={columns}
                    paginationMode={'client'}
                    loading={props.loading || loading || updateLoading}
                    paginationModel={{
                      page: 0,
                      pageSize: 100
                    }}
                    rowCount={rows.length}
          />
        </div>
      </Box>
    </Card>
  </div>
  ;
}

export default LeadSources;
