
import { enqueueSnackbar } from 'notistack';
import {
  useMedicareQuoteQuery,
  useSaveMedicareQuoteMutation
} from "../../../../../../../types";
import {cache, client as apolloClient} from "../../../../../../../Apollo/ApolloClient";
import {ClientViewOutput, DoctorViewOutput} from "../../../../../../../enrollment-types";
import {useCreateMedicareQuoteForClient} from "../../../../../../../shared/hooks/useCreateMedicareQuoteForClient";


export function useSaveDoctors(client?: ClientViewOutput) {
  const [saveQuote] = useSaveMedicareQuoteMutation({client: apolloClient});
  const createQuote = useCreateMedicareQuoteForClient();
  const {data, refetch} = useMedicareQuoteQuery({
    client: apolloClient,
    variables: {id: client?.quoteId},
    skip: !client?.quoteId
  });
  let quote = data?.medicareQuote

  const save = async (doctors: DoctorViewOutput[]) => {
    if (!quote && client) {
      const quoteId = await createQuote(client);
      const res = await refetch({id: quoteId});
      quote = res.data.medicareQuote;
    }
    if (quote) {
      try {
        await saveQuote({
          variables: {
            data: {
              preferredDoctors: doctors.map((d) => ({
                npi: d.npi,
                addresses: d.addresses.map((a) => ({ addressId: a.id })),
              })),
              planYear: quote.planYear,
              county: quote.county,
              zip: quote.zip,
              id: quote.id,
            },
          },
        });
        enqueueSnackbar('Doctors has been saved', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Error saving doctors', { variant: 'error' });
      }
    }


  };

  return save;
}
