import {ClientViewOutput} from "../../../enrollment-types";
import {createRoot} from "react-dom/client";
import {ThemeProvider} from "@mui/material";
import AppTheme from "../../../app/AppTheme";
import {ExpiredFollowUpDateModalDataWrapper} from "./ExpiredFollowUpDateModalDataWrapper";
import React from "react";


export const createConfirmModal = (client: ClientViewOutput): Promise<boolean> => (
  new Promise((resolve) => {
    const container = getContainer();
    const root = createRoot(container);
    root.render(
      <ThemeProvider theme={AppTheme}>
        <ExpiredFollowUpDateModalDataWrapper client={client}
                                             followUpOpen
                                             onClose={value => {
                                               root.unmount()
                                               document.body.removeChild(container);
                                               resolve(value)
                                             }}
        />
      </ThemeProvider>
    )
  })
)

const getContainer = () => {
  const id = 'confirmation-container'
  const prev = document.getElementById(id);

  if (prev) {
    document.body.setAttribute('style', '')
    document.body.removeChild(prev);
  }
  const modal = document.getElementById('expired-follow-up-date-modal');
  if (modal) {
    document.body.removeChild(modal);
  }
  const div = document.createElement('div');
  div.id = id;
  document.body.appendChild(div);
  return div;
}

export * from './CloseClientPrompt'
export * from './ExpiredFollowUpDateModalDataWrapper'