import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveClientTagArgs} from "../../enrollment-types";

export default function useSaveClientTag(options?: MutationHookOptions<any, MutationSaveClientTagArgs>) {
  return useMutation<any, MutationSaveClientTagArgs>(gql(saveMutation), options)
}

const saveMutation = `
mutation ($input: ClientTagInput!) {
  saveClientTag(input: $input)
}
`;
