import {config} from "../config/config";

import { HttpLink, ApolloClient, InMemoryCache, from } from '@apollo/client';
import {authLink, elink} from "./ApolloClient";

const httpLink = new HttpLink({
  uri: config.graphQLChat,
  headers: {
    "content-type": "application/json",
  }
});


const link =
  from([
    authLink,
    elink,
    httpLink,
  ])
;

export const chatClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false
  })
});
