import * as React from 'react';
import {
  Field, PdpField, PdpSort,
  PlanYear,
  Sort,
  useMaPlansLazyQuery, usePdpPlansLazyQuery
} from "../../../../types";
import {MenuItem, SelectChangeEvent} from "@mui/material";
import FormSelect from "../../../../shared/FormSelect";
import {useDebouncedEffect} from "../../../../shared/hooks/useDebouncedEffect";
import {SnpType} from "../../../../enrollment-types";
import {client} from "../../../../Apollo/ApolloClient";
import {Gender, MedigapPlansField, MedigapPlansQuery, useMedigapPlansLazyQuery} from "../../../../medigap-types";
import {Simulate} from "react-dom/test-utils";
import select = Simulate.select;

type Plan = {bidId: string, planName?: Nullable<string>, planYear?: Nullable<PlanYear>};
type MgPlan = MedigapPlansQuery['medigapPlans']['data'][0]

type Props = {
  value: string;
  onChange: (plan: Plan) => void;
  onLoaded?: (plans: Plan[]) => void;
  error?: string,
  carrierName?: string,
  zip: string;
  county: string;
  planYear?: PlanYear;
  disabled?: boolean;
};

interface MgProps extends Omit<Props ,'onChange' | 'onLoaded'> {
  onChange: (plan: MgPlan) => void;
  onLoaded?: (plans: MgPlan[]) => void;
  age: number;
  tobacco: boolean;
  gender: Gender;
  effectiveDate?: string;
}

export const MgPlanSelector = (props: MgProps) => {
  const {zip, county, planYear, carrierName, onChange, disabled, onLoaded, gender, age, effectiveDate, tobacco, ...rest} = props;

  const [plans, setPlans] = React.useState<MgPlan[]>([]);

  const [getPlans, {loading}] = useMedigapPlansLazyQuery({
    client,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setPlans(data.medigapPlans.data);
      onLoaded && onLoaded(data.medigapPlans.data);
    }
  })

  useDebouncedEffect(() => {
    if (county && zip && effectiveDate) {
      getPlans({
        variables: {
          page: {
            size: 1000,
            page: 0
          },
          sort: [{direction: Sort.Asc, field: MedigapPlansField.PlanName}],
          filter: {
            zip,
            county,
            age,
            effectiveDate,
            tobacco,
            gender,
            monthlyPlanPremiumRanges: [],
            planNames: [],
            companies: carrierName ? [carrierName] : [],
          }
        }
      })
    }
  }, 300, [carrierName, county, zip, planYear, tobacco, gender, age, effectiveDate]);

  const handleChange = (val: Plan) => {
    const plan = plans.find(p => p.key === val.bidId);
    if (plan) {
      onChange(plan);
    } else {
      throw Error('Can\'t select plan ' + JSON.stringify(val));
    }
  }

  return <PlanSelector {...rest}
                       zip={zip}
                       county={county}
                       onChange={handleChange}
                       disabled={disabled || loading}
                       hideBids
                       plans={plans.map(p => ({
                         planName: `${p.title} (${p.planName})`,
                         bidId: p.key
                       }))}/>;
};

export const PdpPlanSelector = (props: Props) => {
  const {zip, county, planYear, carrierName, onLoaded} = props;

  const [plans, setPlans] = React.useState<Plan[]>([]);

  const [getPlans] = usePdpPlansLazyQuery({
    client,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setPlans(data.PdpPlans.data);
      onLoaded && onLoaded(data.PdpPlans.data);
    }
  })

  useDebouncedEffect(() => {
    if (county && zip) {
      const filter = {
        countyName: county,
        zip,
        companies: carrierName ? [carrierName] : null,
        showAllPlans: true,
        planYear
      };

      getPlans({
        variables: {
          page: {
            size: 1000,
            page: 0
          },
          sort: [{direction: PdpSort.Asc, pdpField: PdpField.Name}],
          filter
        }
      })
    }
  }, 300, [carrierName, county, zip, planYear])

  return <PlanSelector {...props} plans={plans} />;
};

export const MaPlanSelector = (props: Props) => {
  const {zip, county, planYear, carrierName, onLoaded} = props;

  const [plans, setPlans] = React.useState<Plan[]>([]);

  const [getPlans] = useMaPlansLazyQuery({
    client,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const planList = [...data.plans?.data, ...data.snpPlans?.data];
      setPlans(planList);
      onLoaded && onLoaded(planList);
    }
  })

  useDebouncedEffect(() => {
    if (county && zip) {
      const filter = {
        countyName: county,
        zip,
        companies: carrierName ? [carrierName] : null,
        showAllPlans: true,
        planYear
      };

      getPlans({
        variables: {
          page: {
            size: 1000,
            page: 0
          },
          sort: [{direction: Sort.Asc, field: Field.Name}],
          filter,
          snpFilter: {
            ...filter,
            SNPTypes: [SnpType.DSnp]
          }
        }
      })
    }
  }, 300, [carrierName, county, zip, planYear])

  return <PlanSelector {...props} plans={plans} />;
};

const PlanSelector = (props: Props & {hideBids?: boolean, plans: {bidId: string, planName?: Nullable<string>}[]}) => {
  const {value, error, disabled, plans} = props;

  const onChange = (event: SelectChangeEvent<unknown>) => {
    const bidId = event.target.value as string;
    props.onChange(plans.find(p => p.bidId === bidId)!)
  }

  return (
    <FormSelect label={'Plan'}
                value={value}
                onChange={onChange}
                disabled={disabled}
                error={Boolean(error)}
                helperText={error ? error : undefined}
    >
      {plans.map(p => <MenuItem key={p.bidId} value={p.bidId}>
        {`${props.hideBids ? '' : p.bidId} ${p.planName}`.trim()}
      </MenuItem>)}
    </FormSelect>
  );
};