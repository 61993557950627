import {Divider, IconButton, Typography} from "@mui/material";
import React from "react";
import moment from "moment";
import {useHistory} from "react-router";
import {EnrollmentListItemOutput} from "../../../../enrollment-types";
import Card from "../../../../shared/Card";
import Param from "../../../../shared/Param";
import {client} from "../../../../Apollo/ApolloClient";
import {useMedigapCompanyLazyQuery} from "../../../../medigap-types";

interface MedigapEnrollmentInfoProps {
  enrollment: EnrollmentListItemOutput,
  onDeleteClick?: () => void,
  onEditClick?: () => void,
  viewOnly?: boolean
}

const MedigapEnrollmentInfo = ({enrollment, onDeleteClick, onEditClick, viewOnly}: MedigapEnrollmentInfoProps) => {
  const [carrier, setCarrier] = React.useState<string>();
  const [parent, setParent] = React.useState<string>();
  const history = useHistory();


  const [getCompany, data] = useMedigapCompanyLazyQuery({
    client,
  })

  React.useEffect(() => {
      if (data.data?.medigapCompany) {
        setCarrier(data.data.medigapCompany.name || undefined)
        setParent(data.data.medigapCompany.parentCompanyName || undefined)
      }
  }, [data]);

  React.useEffect(() => {
    if (enrollment?.naic) {
      getCompany({variables: { naic: enrollment.naic }})
    }
  }, [enrollment]);

  const name = React.useMemo(() => {
    let name = '';
    if (!enrollment?.firstName && !enrollment?.lastName) {
      name = 'Unknown user';
    } else {
      name = enrollment?.firstName + ' ' + enrollment?.lastName || '';
    }
    return name
  }, [enrollment?.firstName, enrollment?.lastName])

  return <Card className={'w-260'}>
    <div className={'flex-space-between flex-align-center'}>
      <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>MG Enrollment</Typography>
      {!viewOnly && <div className={'flex-space-between flex-align-center'}>
        <IconButton size={'small'} onClick={onEditClick}><img src={'/img/mode.svg'}/></IconButton>
        <IconButton size={'small'} className={'ml-10'} onClick={onDeleteClick}><img src={'/img/delete-icon.svg'}/></IconButton>
      </div>}
    </div>
    <Param className={'flex-column'} label={"NAME"} value={name}/>
    <div className={"flex-space-between"}>
      {enrollment?.mgPlanName && <Param className={'flex-column'} label={"PLAN"} value={enrollment?.mgPlanName}/>}
      {enrollment?.status && <Param className={'flex-column'} label={"STATUS"} valueClassName={'yellow'} value={enrollment?.status}/>}
    </div>
    {parent && <Param className={'flex-column'} label={"PARENT COMPANY NAME"} value={parent}/>}
    {carrier && <Param className={'flex-column'} label={"COMPANY NAME"} value={carrier}/>}
    <Divider className={'mb-15'} />
    <div className={"flex-space-between"}>
      {!!enrollment?.age && <Param className={'flex-column'} label={"AGE"} value={enrollment?.age.toString()}/>}
      <Param className={'flex-column'} label={"TOBACCO"} value={enrollment?.tobacco ? 'Yes' : 'No'}/>
    </div>
    <div className={"flex-space-between"}>
      {enrollment?.gender && <Param className={'flex-column'} label={"GENDER"} value={enrollment?.gender}/>}
      {enrollment?.effectiveDate && <Param className={'flex-column'} label={"EFFECTIVE DATE"} value={moment(enrollment?.effectiveDate).format('MM/DD/YYYY')}/>}
    </div>
    <Divider className={'mb-15'} />
    <div className={"flex-space-between"}>
      {enrollment?.started && <Param className={'flex-column'} label={"ENROLLMENT STARTED"} value={moment(enrollment?.started).format('MM/DD/YYYY')}/>}
      {enrollment?.approvedAt && <Param className={'flex-column'} label={"ENROLLMENT APPROVED"} value={moment(enrollment?.approvedAt).format('MM/DD/YYYY')}/>}
    </div>
  </Card>
}

export {MedigapEnrollmentInfo};
