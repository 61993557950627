import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import DaterangeFilter from "./DaterangeFilter";
import {DateRangeInput} from "../../enrollment-types";

type Props = {
  open: boolean,
  onClose: (range?: DateRangeInput) => void,
  initial?: DateRangeInput,
}

export default function DateRangeFilterModal(props: Props) {
  const [values, setValues] = useState<DateRangeInput>()

  useEffect(() => {
    if (props.open) {
      setValues(props.initial);
    } else {
      setValues(undefined)
    }
  }, [props]);

  return <Dialog
    sx={{'& .MuiPaper-root': {width: 500}}}
    open={props.open}
    onClose={() => props.onClose()}
  >
    <DialogContent>
      <Typography variant={'h4'} sx={{mb: 1}}>Select range</Typography>
      <DaterangeFilter value={values}
                       label={''}
                       onChange={setValues} />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => props.onClose()} color="primary" variant={'outlined'}>
        Cancel
      </Button>
      <Button onClick={() => props.onClose(values)} disabled={!values} color={'primary'} variant={'contained'}>
        Ok
      </Button>
    </DialogActions>
  </Dialog>
}
