import React, {PropsWithChildren} from "react";
import {Typography, styled} from "@mui/material";

const ChipWrapper = (props: PropsWithChildren<{title: string}>) => {
  return <Container>
    <Title color={'textSecondary'}>{props.title}</Title>
    {props.children}
  </Container>
}

export default ChipWrapper;

const Container = styled('div')({
  border: '1px solid #CCD8DC',
  borderRadius: 4,
  padding: 14,
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  '& > *': {
    margin: 4,
  },
})

const Title = styled(Typography)({
  background: 'white',
  paddingLeft: 5,
  paddingRight: 5,
  fontSize: 9,
  position: 'absolute',
  top: -12,
  left: 5,
})
