import {DateRangeInput} from "../../enrollment-types";
import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import TextInput from "../TextInput";
import moment from "moment";

interface FilterProps {
  value?: DateRangeInput | null,
  onChange: (value?: DateRangeInput) => void,
  label: string
}

const DaterangeFilter = ({value, onChange, label}: FilterProps) => {

  const handleFromChange = (date: Date | null) => {
    const from = date ? moment(date).startOf('day') : date;
    let to = value?.to ? moment(value?.to) : moment().endOf('day')

    if (from && from.isSameOrAfter(to)) {
      to = moment(date).endOf('day')
    }

    onChange({from: from?.toISOString(), to: to?.toISOString()})
  }

  const handleToChange = (date: Date | null) => {
    let from = value?.from ? moment(value?.from) : moment(date).startOf('day');
    let to = date ? moment(date).endOf('day') : date

    if (to && to.isSameOrBefore(from)) {
      from = moment(date).startOf('day')
      to = moment(date).endOf('day')
    }

    onChange({from: from?.toISOString(), to: to?.toISOString()})
  }

  return <Box display={'flex'} alignItems={'center'} gap={1}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        slotProps={{
          textField: {
            inputProps: {
              placeholder: 'MM / DD / YYYY',
              className: 'mb-0'
            },
          }
        }}
        className={'mb-0'}
        slots={{
          textField: TextInput as any,
        }}
        format="MM/dd/yyyy"
        label={label + ' from'}
        value={value?.from ? moment(value!.from).toDate() : null}
        onChange={handleFromChange}
      />
    </LocalizationProvider>

    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        slotProps={{
          textField: {
            inputProps: {
              placeholder: 'MM / DD / YYYY',
              className: 'mb-0'
            },
          }
        }}
        className={'mb-0'}
        slots={{
          textField: TextInput as any,
        }}
        format="MM/dd/yyyy"
        label={label + ' to'}
        value={value?.to ? moment(value!.to).toDate() : null}
        onChange={handleToChange}
      />
    </LocalizationProvider>
  </Box>
}

export default DaterangeFilter;
