import {useBindChatIdentityIdMutation, useChatIdentityIdQuery} from "../../chat-types";
import {chatClient} from "../../Apollo/ChatApolloClient";
import {useEffect, useState} from "react";
import {generateUUID} from "../lib/lib";

export default function useChatIdentityId(): string | undefined {
  const [chatIdentityId, setChatIdentityId] = useState<string>();
  const {data} = useChatIdentityIdQuery({client: chatClient});
  const [bindId] = useBindChatIdentityIdMutation({client: chatClient});

  useEffect(() => {
    if (data?.chatIdentityId === null) {
      const id = generateUUID();
      bindId({variables: {chatIdentityId: id}}).then(() => setChatIdentityId(id));
    } else if (data?.chatIdentityId) {
      setChatIdentityId(data?.chatIdentityId);
    }
  }, [data]);

  return chatIdentityId;
}
