import Card from "../../../../shared/Card";
import {useEmailTemplatesQuery} from "../../../../enrollment-types";
import {Box, CircularProgress, List, ListItem, ListItemButton, ListItemText, Typography} from "@mui/material";
import React, {useState} from "react";

export function ClientEmailTemplates() {
  const [template, setTemplate] = useState<string>()
  const {data, loading} = useEmailTemplatesQuery({
    variables: { pageInput: {page: 0, size: 1000} },
    fetchPolicy: "no-cache"
  })

  const templates = data?.emailTemplates.data || [];

  if (loading) return <CircularProgress />;

  return <Card sx={{flexDirection: 'row', gap: 3, minHeight: 600, alignItems: 'flex-start'}}>
    <List sx={{minWidth: 200}}>
      {templates?.map((t) => (
        <ListItem key={t.id}>
          <ListItemButton onClick={() => setTemplate(t.template)}>
            <ListItemText primary={t.title} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>


    <Box sx={{borderLeft: '1px solid #d6d6d6', ml: 3, pl: 10}}>
      {template && <div id={'content'} dangerouslySetInnerHTML={{__html: template}}/>}
    </Box>

  </Card>
}