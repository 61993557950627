import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {uploadClient} from "../../Apollo/UploadApolloClient";
import {MutationUploadAgentAvatarArgs} from "../../enrollment-types";

export function useUploadAgentAvatar(options?: MutationHookOptions<{uploadAgentAvatar: string}, MutationUploadAgentAvatarArgs>) {
  return useMutation<{uploadAgentAvatar: string}, MutationUploadAgentAvatarArgs>(gql(mutation), {
    client: uploadClient,
    ...options
  })
}

const mutation = `
mutation ($img: Upload!) {
  uploadAgentAvatar(img: $img)
}
`;
