import {PdpEnrollmentType} from "../../../../../enrollment-types";

export const getEnrollmentTypeSubTitle = (type: PdpEnrollmentType): string => ({
  [PdpEnrollmentType.PdpNew]: 'New enrollments (i.e., age-in, New to Medicare, or enrolls from Orignal Medicare getting MA for first time)',
  [PdpEnrollmentType.PdpUnlikeChange]: 'PDP > MAPD or MAPD > PDP // PDP > PDP or MAPD > MAPD (Yr 1 Change)',
  [PdpEnrollmentType.PdpReplacement]: 'Like plan change in Year 2+'
}[type])

export const getEnrollmentTypeTitle = (type: PdpEnrollmentType): string => ({
  [PdpEnrollmentType.PdpNew]: 'PDP - New-to-CMS',
  [PdpEnrollmentType.PdpUnlikeChange]: 'PDP - Unlike plan change / Yr 1 change',
  [PdpEnrollmentType.PdpReplacement]: 'PDP - Replacement / \'Like\' Plan Change'
}[type])

export const getEnrollmentTypeDescription = (type: PdpEnrollmentType): string => ({
  [PdpEnrollmentType.PdpNew]: 'Initial Commissions is paid for the first year product is in effect, followed by Renewal Rate Commission in subsequent calednar years (monthly basis)',
  [PdpEnrollmentType.PdpUnlikeChange]: 'Commission on original Medicare Product will be charged back according to Rapid Disenrollment of LT Disenrollment.  Inital Commission is paid on a pro-rated basis based on effective date.  Renewal Rate Commission is paid in subsequent calendar years',
  [PdpEnrollmentType.PdpReplacement]: 'Commission on original Medicare Product will be charged back according to Rapid Disenrollment of LT Disenrollment. Renewal Rate Commission is paid on a pro-rated basis based on effective date and then Renewal Rate Commission is paid in subsequent calendar years'
}[type])