import {Box, CircularProgress, Typography} from "@mui/material";
import ChatList from "./ui/chat-list";
import React, {MutableRefObject, useContext, useEffect, useRef, useState} from "react";
import MessageList from "./ui/message-list";
import InputArea from "./ui/input-area";
import {Conversation, JSONObject} from "@twilio/conversations";
import useMessagesForConversation from "./api/use-messages-for-conversation";
import {ChatMessage, MessageMenuType} from "./lib/types";
import {keycloak} from "../shared/keycloak";
import {RouteComponentProps} from "react-router";
import {ChatContext} from "./lib/chat-context";
import CreateChatButton from "./ui/create-chat-button";
import useSetConversationAsReadOnDelay from "./lib/use-set-conversation-as-read-on-delay";
import useParticipantSid from "./lib/use-participant-sid";
import useSendMessage from "./lib/use-send-message";
import useLastReadIndex from "./lib/use-last-read-index";

export default function(props: RouteComponentProps<{id?: string, userId?: string}>) {
  const {id, userId} = props.match.params;
  const [{conversations, chatIdentityId}] = useContext(ChatContext)
  const [currentConversation, setCurrentConversation] = useState<Conversation>();
  const participantSid = useParticipantSid(currentConversation);
  const {messages, loading} = useMessagesForConversation(currentConversation);
  const sendMessage = useSendMessage(currentConversation);
  const lastReadMessageIndex = useLastReadIndex(currentConversation);

  useSetConversationAsReadOnDelay(3000, currentConversation);

  const directionDetection = (m: ChatMessage) => (
    participantSid === m.participantSid ? 'outgoing' : 'incoming'
  )

  const setConversationById = () => {
    const result = conversations.find(c => (c.attributes as any)?.clientId === props.match.params.id);
    if (result) {
      setCurrentConversation(result);
    }
  }

  useEffect(() => {
    if (id && conversations) {
      setConversationById()
    }
  }, [id]);

  const onMessageMenuClick = async (type: MessageMenuType, message: ChatMessage) => {
    switch (type) {
      case "setUnread": {
        await currentConversation?.updateLastReadMessageIndex(message.index === null ? null : message.index! - 1)
      } break;
    }
  }

  const showNoConversationMessage = !!id && !!conversations.length && !currentConversation;

  return <Box sx={{display: 'flex', gap: 3, width: '100%', height: 'calc(60vh + 160px)',}}>
    <ChatList onClick={setCurrentConversation} conversations={conversations} active={currentConversation} />
    <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, gap: 3}}>
      {!!currentConversation && <>
        {!loading && <MessageList messages={messages}
                                  onMenuClick={onMessageMenuClick}
                                  lastReadIndex={lastReadMessageIndex}
                                  directionDetect={directionDetection} />}
        {loading && <Box sx={{display: 'flex', justifyContent: 'center', width: 1, alignItems: 'center', height: '60vh'}} >
          <CircularProgress/>
        </Box>}
        <InputArea onSubmit={sendMessage} />
      </>}
      {showNoConversationMessage && <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', width: 1, alignItems: 'center', height: '60vh', gap: 2}} >
        <Typography variant={'h4'}>Looks like there is no chat for this client yet.</Typography>
        {(userId && id) && <CreateChatButton clientUserId={userId} clientId={id} onCompleted={setConversationById}/>}
      </Box>}
    </Box>
  </Box>
}
