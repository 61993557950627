import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSetDefaultAgentArgs} from "../../enrollment-types";

export default function useSetDefaultAgent(options?: MutationHookOptions<{setDefaultAgent: boolean}, MutationSetDefaultAgentArgs>) {
  return useMutation<{setDefaultAgent: boolean}, MutationSetDefaultAgentArgs>(gql(save), options)
}

const save = `
mutation ($agentId: UUID!) {
  setDefaultAgent(agentId: $agentId)
}
`;

