// @flow
import * as React from 'react';
import {Box, CircularProgress} from "@mui/material";
import {DoctorLine} from "./DoctorLine";
import {ScrollbarList} from "../../../../../../../shared/ScrollbarList";
import TextInput from "../../../../../../../shared/TextInput";
import AscendAutocomplete from "../../../../../../../shared/AscendAutocomplete";
import {useDoctors} from "../hooks/useDoctors";
import {DoctorViewOutput} from "../../../../../../../enrollment-types";

type Props = {
  zip: string,
  radius: number,
  onChange: (doctor: DoctorViewOutput) => void,
  selectedNpi: string[]
};

export function DoctorSearchAutocomplete({zip, radius, onChange, selectedNpi}: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [autocompleteValue, setAutocompleteValue] = React.useState<string>('');
  const [open, setOpen] = React.useState(true);
  const {data, loading} = useDoctors({zipCode: zip, searchTerm: autocompleteValue, radius})

  const autocompleteOpen = !!autocompleteValue && !loading && !!data.length && open

  return (
    <AscendAutocomplete fullWidth
                        open={!!autocompleteOpen}
                        onClose={(e, reason) => {
                          if (reason === 'blur' && (e as any).relatedTarget?.classList.contains('doctor-address-popover')) {
                            return;
                          }
                          setTimeout(() => setOpen(false), 300) // todo: hack - fix closing autocomplete by blur
                        }}
                        onFocus={() => setOpen(true)}
                        options={data.filter(doctor =>
                          !selectedNpi.some(npi => npi === doctor.npi))
                        }
                        value={autocompleteValue}
                        onInputChange={(event, newInputValue) => {
                          setAutocompleteValue(newInputValue);
                        }}
                        sx={{
                          '& > div': {
                            mb: 0
                          }
                        }}
                        clearOnBlur
                        filterOptions={(options) => options}
                        popupIcon={loading ?
                          <CircularProgress className={'w-20 h-20'}/> : undefined}
                        renderOption={(option, doctor: any) => (
                          <Box sx={{'&:hover': {background: '#eee'}, cursor: 'pointer', p: 1}}
                               onClick={() => {
                                 setAutocompleteValue('');
                                 onChange(doctor);
                               }}>
                            <DoctorLine doctor={doctor} onPopupClose={() => {
                              setTimeout(() => inputRef.current?.focus(), 100)
                            }}/>
                          </Box>
                        )}
                        id={'doctor-search-input'}
                        ListboxComponent={ScrollbarList as any}
                        renderInput={params => <TextInput inputRef={inputRef}
                                                          placeholder={'Search doctor name'}
                                                          {...params} />}/>
  );
}