import React, {useCallback} from "react";
import Card from "../../shared/Card";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  Typography
} from "@mui/material";
import {
  AffiliationPartnershipOutput,
  ClientViewOutput
} from "../../enrollment-types";
import * as _ from 'lodash';
import FormSelect from "../../shared/FormSelect";
import useSaveChannelAttribution from "../hooks/useSaveChannelAttribution";
import useAffiliationPartnerships from "../hooks/useAffiliationPartnerships";
import TextInput from "../../shared/TextInput";
import AscendAutocomplete from "../../shared/AscendAutocomplete";

interface ChannelAttributionProps {
  client?: ClientViewOutput
}

const ChannelAttribution = ({client}: ChannelAttributionProps) => {
  const [showForm, setShowForm] = React.useState(false);
  const [save] = useSaveChannelAttribution();

  const submit = useCallback(async (source?: string, campaign?: string) => {
    if (client) {
      await save({variables: {clientId: client!.id, marketingSource: source || null, marketingCampaign: campaign || null}})
    }
    setShowForm(false);
  }, [client]);

  const cancel = useCallback(() => {
    setShowForm(false);
  }, [setShowForm]);

  return <Card>
    <div className={'flex-space-between flex-align-center mb-15'}>
      <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>Channel Attribution</Typography>
      {!showForm && <IconButton size={'small'} onClick={() => setShowForm(true)}><img src={'/img/mode.svg'}/></IconButton>}
    </div>
    {!showForm && <div>
      <Typography color={"textPrimary"} className={"mb-10"}>Source: {client?.marketingSource || '-'}</Typography>
      <Typography color={"textPrimary"}>Campaign: {client?.marketingCampaign || '-'}</Typography>
    </div>}
    {showForm && <ChannelAttributionForm source={client?.marketingSource} campaign={client?.marketingCampaign} onCancel={cancel} onSubmit={submit}/>}
  </Card>
};

export default ChannelAttribution;

interface ChannelAttributionFormProps {
  source?: string | null;
  campaign?: string | null;
  onCancel: () => void;
  onSubmit: (source?: string, campaign?: string) => void;
}

const ChannelAttributionForm = (props: ChannelAttributionFormProps) => {
  const [source, setSource] = React.useState(props.source || '');
  const [campaign, setCampaign] = React.useState(props.campaign || '');
  const [partnerships, setPartnerships] = React.useState<AffiliationPartnershipOutput[]>([]);
  const [getPartnerships, {loading}] = useAffiliationPartnerships();
  const [autocompleteValue, setAutocompleteValue] = React.useState<string>('');

  React.useEffect(() => {
    getPartnerships().then(res => (
      setPartnerships(res.data?.affiliationPartnerships || [])
    ))
  }, [])

  const sources = React.useMemo(() => {
    return _.uniq(partnerships
      .map(p => p.source)
      .filter(s => s.indexOf(autocompleteValue) >= 0)
      .sort());
  }, [partnerships, autocompleteValue])

  const campaigns = React.useMemo(() => {
    return partnerships.filter(p => p.source === source).map(p => p.campaign)
  }, [source, partnerships])

  return <div className={'mt-15'}>
    <AscendAutocomplete fullWidth
                        loading={loading}
                        options={sources || []}
                        value={autocompleteValue}
                        onInputChange={(event, newInputValue) => {
                          setAutocompleteValue(newInputValue);
                        }}
                        onChange={(event, value) => {
                          setSource(value as any);
                          setCampaign('')
                        }}
                        clearOnBlur
                        renderInput={params => <TextInput
                                                          placeholder={'Source'}
                                                          {...params} />} />
    <Collapse className={'mt-10'} in={!!source}>
      <FormSelect label={'Campaign'}
                  fullWidth
                  disabled={!sources.length || !campaigns.length}
                  value={campaign}
                  onChange={event => {
                    setCampaign(event.target.value as any);
                  }}
      >
        <MenuItem value={''}></MenuItem>
        {campaigns.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>)}
      </FormSelect>
    </Collapse>

    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
      <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
      <Button variant={'contained'} color={'primary'} onClick={() => props.onSubmit(source, campaign)}>SAVE</Button>
    </Box>
  </div>
};
