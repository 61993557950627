import {
  Box,
  Card,
  Checkbox, CircularProgress,
  Divider,
  FormControlLabel,
  IconButton, MenuItem,
  Typography
} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";
import {getUserName, WithCalls} from "../tools";
import useTags from "../../Tags/hooks/useTags";
import useSaveClientTag from "../../Client/hooks/useSaveClientTag";
import {LoadingButton} from "@mui/lab";
import {ClientViewOutput, TagViewOutput} from "../../enrollment-types";
import FormSelect from "../FormSelect";
import {useConfirm} from "../ConfirmDialog/ConfirmDialogContext";
import moment from "moment/moment";

export default function TagsDrawerContent({client, onClose}: {client: WithCalls<ClientViewOutput>, onClose: (refresh?: boolean) => void}) {
  const {data, loading} = useTags();
  const [clientTags, setClientTags] = useState(client.tags);
  const [save, {loading: saveLoading}] = useSaveClientTag();
  const confirm = useConfirm();

  const onDoNotCallSelect = useCallback((tag: TagViewOutput) => (
    confirm({
      content: props => (
        <Box sx={{pt: 2}}>
          <ExpiresAtSelect onChange={props.onValueChange} />
        </Box>
      ),
      title: 'Expires at',
      okButtonTitle: 'Save',
      okButtonColor: 'primary',
      cancelButtonTitle: 'Close',
      width: 500
    })
      .then(val => {
        if (typeof val === "number") {
          setClientTags(prev => {
            return [...prev, {tag, expiresAt: moment().add(val, 'days').toDate()}]
          })
        }
      })
  ), [clientTags]);

  const onSaveClick = () => {
    save({variables: {
      input: {
        clientId: client?.id,
        tags: clientTags.map(t => (
          {id: t.tag.id, expiresAt: t.expiresAt}
        ))
      }
    }})
      .finally(() => onClose(true))
  }

  const tags = useMemo(() => {
    return data?.tags || [];
  }, [data]);

  return <Card sx={{p: 4, width: 594, overflowY: 'auto'}}>
    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      <IconButton size={'small'} onClick={() => onClose()}>
        <img className={'w-32 h-32'} src={'/img/X.svg'}/>
      </IconButton>
    </Box>
    <Typography variant={'h3'}>{getUserName(client)}’s Tags</Typography>
    <Divider sx={{mb: 3, mt: 2}} />
    {loading && <Box sx={{display: 'flex', justifyContent: 'center', width: 1, alignItems: 'center', height: 200}} >
      <CircularProgress/>
    </Box>}
    {!loading && <>
      <Box sx={{mx: -.5, mb: 2}}>
        {tags.map((tag, i) => <Box key={tag.id} sx={{width: 'calc(50% - 8px)', display: 'inline-block', mx: .5, mb: 1}}>
          <DrawerCheckbox value={clientTags.map(t => t.tag.id).includes(tag.id)}
                          onClick={val => {
                            setClientTags(prev => {
                              if (val && !clientTags.map(t => t.tag.id).includes(tag.id)) {
                                if (tag.name === 'Temporary do not call') {
                                  onDoNotCallSelect(tag as TagViewOutput);
                                  return prev;
                                } else {
                                  return [...prev, {tag: tag as TagViewOutput}]
                                }
                              }
                              if (!val && clientTags.map(t => t.tag.id).includes(tag.id)) {
                                return prev.filter(t => t.tag.id !== tag.id)
                              }
                              return prev;
                            })
                          }}
                          label={tag.name} />
        </Box>)}
      </Box>
      <LoadingButton variant={'contained'}
                     loading={saveLoading}
                     onClick={onSaveClick}
                     color={'primary'}>Save</LoadingButton>
    </>}
  </Card>
}

const DrawerCheckbox = ({value, onClick, label}: {label: string, value: boolean, onClick: (newVal: boolean) => void}) => {

  return <FormControlLabel sx={{
                              borderRadius: '8px',
                              backgroundColor: value ? '#1E95A0' : 'white',
                              color: value ? 'white' : '#000',
                              width: 1,
                              mx: 0,
                              border: '1px solid #666',
                            }}
                           control={<Checkbox checked={value}
                                              onClick={() => onClick(!value)}
                                              sx={{
                                                p: 1,
                                                color: '#1C434F',
                                                '&.Mui-checked': {
                                                  color: 'white',
                                                },
                                              }} />}
                           label={label} />
}

const ExpiresAtSelect = ({onChange}: {onChange: (value: number) => void}) => (
  <FormSelect label={'Select the number of days'}
              onChange={event => {
                onChange(event.target.value as number);
              }}
  >
    <MenuItem value={undefined}>-</MenuItem>
    <MenuItem value={1}>1</MenuItem>
    <MenuItem value={2}>2</MenuItem>
    <MenuItem value={3}>3</MenuItem>
    <MenuItem value={4}>4</MenuItem>
    <MenuItem value={5}>5</MenuItem>
    <MenuItem value={7}>7</MenuItem>
    <MenuItem value={14}>14</MenuItem>
  </FormSelect>
)

