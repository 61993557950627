import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationClientCommunicationNoShowArgs} from "../../enrollment-types";

export default function useClientCommunicationNoShow(options?: MutationHookOptions<{clientCommunicationNoShow: boolean}, MutationClientCommunicationNoShowArgs>) {
  return useMutation<{clientCommunicationNoShow: boolean}, MutationClientCommunicationNoShowArgs>(gql(save), options)
}

const save = `
mutation ($clientId: UUID!) {
  clientCommunicationNoShow(clientId: $clientId)
}
`;

