import React, {useEffect} from "react";
import {useFormik} from "formik";
import {
  Box,
  Button,
  Divider,
  ListItemText,
  MenuItem, Typography
} from "@mui/material";
import {
  EnrollmentListItemOutput,
  PdpEnrollmentStatus,
  PdpEnrollmentType, PlanYear
} from "../../../../../../enrollment-types";
import {IEnrollmentForm} from "../../../lib/types";
import {fillFormData, isEffectiveDateValid, pdpEnrollmentFormValidationSchema as validationSchema} from "../../../lib/lib";
import {FormikTextInput} from "../../../../../../shared/formik-text-input";
import {FormikDateInput} from "../../../../../../shared/formik-date-input";
import moment from "moment";
import {ZipSelector} from "../../../../../../features/zip-selector";
import {PdpCarrierSelector} from "../../carrier-selector";
import {FormikSelectInput} from "../../../../../../shared/formik-select-input";
import AgentSelector from "../../../../../../Agent/AgentSelector";
import {
  getEnrollmentTypeDescription,
  getEnrollmentTypeSubTitle,
  getEnrollmentTypeTitle
} from "../lib";
import {PdpPlanSelector} from "../../PlanSelector";

interface FormProps {
  input?: Partial<EnrollmentListItemOutput>,
  onSubmit: (data: IEnrollmentForm) => void,
  onCancel: () => void,
  loading: boolean,
}

export const PdpEnrollmentForm = ({input, onSubmit, onCancel, loading}: FormProps) => {
  const formik = useFormik({
    initialValues: fillFormData(input, validationSchema.default()),
    validationSchema,
    onSubmit,
  });

  const reset = (input: any) => {
    formik.resetForm({
      errors: [] as any,
      values: fillFormData(input, formik.values),
    })
  }

  useEffect(() => {
    if (input) {
      reset(input)
    }
  }, [input]);

  useEffect(() => {
    if (formik.values.zip.length && formik.values.zip.length < 5) {
      formik.setFieldValue('county', '');
      formik.setFieldValue("bidId", '');
      formik.setFieldValue("planYear", '');
      formik.setFieldValue("planName", '');
      formik.setFieldValue("carrierName", '');
    }
  }, [formik.values.zip]);

 /* const [companies] = useCompanies<{zip: string, countyName: string, planYear?: PlanYear, showAllPlans: boolean}>(
    companiesQuery,
    data => data.pdpCompanies.filter((c: any) => c.count).map((c: any) => `${c.name} (${c.count})`) || [],
    {zip: formik.values.zip, countyName: formik.values.county, planYear, showAllPlans: true},
    filter => !!filter.countyName
  );
**/

  const effectiveDate = formik.values.effectiveDate || input?.effectiveDate;
  const planYear = isEffectiveDateValid(effectiveDate) ? 'Year' + moment(effectiveDate).year() as PlanYear : undefined

  return (
    <form onSubmit={formik.handleSubmit} className={'mt-15 w-500'}>
      <Box sx={{display: 'flex', gap: 1}}>
        <FormikTextInput formik={formik}
                         fullWidth
                         name={'firstName'} />
        <FormikTextInput formik={formik}
                         fullWidth
                         name={'lastName'} />
      </Box>
      <Box sx={{display: 'flex', gap: 1}}>
        <FormikDateInput formik={formik}
                         name={'birthDate'} />
        <FormikTextInput formik={formik}
                         fullWidth
                         name={'phoneNumber'} />
      </Box>
      <FormikTextInput formik={formik}
                       fullWidth
                       name={'email'} />
      <Divider />
      <Box sx={{display: 'flex', mt: 3, gap: 1}}>
        <FormikDateInput formik={formik}
                         onChange={value => {
                           if (value === null || value > new Date(2000, 1, 1)) {
                             formik.setFieldValue("effectiveDate", value)
                           }
                         }}
                         name={'effectiveDate'} />
        <FormikDateInput formik={formik}
                         onChange={value => {
                           if (value === null || value > new Date(2000, 1, 1)) {
                             formik.setFieldValue("applicationDate", value)
                           }
                         }}
                         name={'applicationDate'} />
      </Box>
      <ZipSelector zip={formik.values.zip}
                   county={formik.values.county}
                   zipError={formik.touched.zip ? formik.errors.zip : undefined}
                   countyError={formik.touched.county ? formik.errors.county : undefined}
                   onChange={data => {
                     formik.setFieldValue('zip', data.zip);
                     formik.setFieldValue('county', data.county);
                     if (formik.values.county !== data.county) {
                       formik.setFieldValue("bidId", '');
                       formik.setFieldValue("planYear", '');
                       formik.setFieldValue("planName", '');
                       formik.setFieldValue("carrierName", '');
                     }
                   }} />

      <Box sx={{display: 'flex', gap: 1}}>
        <PdpCarrierSelector zip={formik.values.zip}
                         county={formik.values.county}
                         value={formik.values.carrierName}
                         disabled={!(formik.values.zip && formik.values.county && formik.values.effectiveDate)}
                         onChange={val => formik.setFieldValue("carrierName", val)}
                         planYear={planYear}
        />

        <FormikSelectInput formik={formik} name={'status'}>
          <MenuItem value={PdpEnrollmentStatus.Draft}>Draft</MenuItem>
          <MenuItem value={PdpEnrollmentStatus.Enrolled}>Enrolled</MenuItem>
          <MenuItem value={PdpEnrollmentStatus.New}>New</MenuItem>
          <MenuItem value={PdpEnrollmentStatus.Rejected}>Rejected</MenuItem>
          <MenuItem value={PdpEnrollmentStatus.Sent}>Sent</MenuItem>
        </FormikSelectInput>
      </Box>

      <PdpPlanSelector zip={formik.values.zip}
                      county={formik.values.county}
                      value={formik.values.bidId}
                      onChange={plan => {
                        formik.setFieldValue("bidId", plan.bidId);
                        formik.setFieldValue("planYear", plan.planYear);
                        formik.setFieldValue("planName", plan.planName);
                      }}
                      planYear={planYear}
                      error={formik.touched.bidId ? formik.errors.bidId : undefined}
                      disabled={!formik.values.carrierName}
                      carrierName={formik.values.carrierName}
      />

      <AgentSelector error={formik.touched.agentId && Boolean(formik.errors.agentId) ? formik.errors.agentId as string : undefined}
                     value={formik.values.agentId || undefined}
                     onChange={id => formik.setFieldValue("agentId", id)} />
      <FormikSelectInput formik={formik}
                         sx={{
                           '& p': {display: 'none'},
                           '& .MuiListItemText-primary': {
                             overflow: 'hidden',
                             textOverflow: 'ellipsis'
                           }
                         }}
                         onChange={event => {
                           formik.setFieldValue("enrollmentType", event.target.value as string);
                         }}
                         name={'enrollmentType'}>
        {[PdpEnrollmentType.PdpNew, PdpEnrollmentType.PdpUnlikeChange, PdpEnrollmentType.PdpReplacement].map(type => <MenuItem key={type} onClick={() => {
          formik.setFieldValue("enrollmentType", type)
        }} value={type as string}>
          <ListItemText primary={getEnrollmentTypeTitle(type)} secondary={getEnrollmentTypeSubTitle(type)} />
        </MenuItem>)}
      </FormikSelectInput>

      {!!formik.values['enrollmentType'] && <Typography color={'textPrimary'} variant={'body1'} className={'mb-20'}>
        {getEnrollmentTypeDescription(formik.values['enrollmentType'] as PdpEnrollmentType)}
      </Typography>}

      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Button variant={'outlined'} onClick={onCancel}>CANCEL</Button>
        <Button variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
      </Box>
    </form>
);
}


const plansQuery = `
query($filter: PdpPlansFilterInput!, $page: PageInput!, $sort: [PdpPlansSortInput!]!) {
    PdpPlans(filter: $filter, page: $page, sort: $sort) {
      data  {
        planName
        planType
        bidId
        orgName
        parentOrgName
        planYear
      }
      totalElements
    }
}
`;




