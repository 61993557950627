// @flow
import * as React from 'react';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { Drug } from './lib';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';

type Props = {
  drug?: Drug;
  onSelect: (useGeneric: boolean) => void;
};

export function DrugSelectionModalBranded(props: Props) {
  return (
    <Stack spacing={5} >
      <Stack spacing={{ xs: 3, md: 2 }} direction={{sx: 'column', md: 'row'}} alignItems={{ md: 'center' }}>
        <Container>
          <Label variant={'caption'}>Selected Branded Drug</Label>
          <Typography variant={'h4'}>{props.drug?.name}</Typography>
        </Container>

        <MultipleStopIcon />

        <Container>
          <Label variant={'caption'}>Available Generic Version</Label>
          <Typography variant={'h4'}>{props.drug?.genericName}</Typography>
        </Container>
      </Stack>

      <Box sx={{display: 'flex', mt: 3, gap: 1.5, flexDirection: {xs: 'column', sm: 'row'}, alignItems: { sm: 'center' }, justifyContent: 'flex-end'}}>
        <Button
          data-test={'add_generic_drug_button'}
          size={'large'}
          variant={'outlined'}
          onClick={() => props.onSelect(true)}
        >
          Add generic drug
        </Button>
        <Button size={'large'} variant={'contained'}
                data-test={'add_branded_drug_button'}
                className={'branded-drug-button'}
                onClick={() => props.onSelect(false)}>Continue with branded</Button>
      </Box>
    </Stack>
  );
}

const Label = styled(Typography)({
  position: 'absolute',
  backgroundColor: '#FFFFFF',
  top: -9,
  paddingRight: 8,
  paddingLeft: 8,
  fontWeight: 500,
})

const Container = styled('div')({
  alignItems: 'flex-end',
  border: '1px solid #1C434F',
  padding: 16,
  flex: 1,
  borderRadius: '8px',
  position: 'relative'
})
