import {Badge} from "@mui/material";
import {styled} from "@mui/styles";
import {Conversation} from "@twilio/conversations";

function stringToColor(string: string | null) {

  if (!string) {
    return '000'
  }

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string | null) {
  return {
    sx: {
      background: stringToColor(name || ''),
    },
  };
}

export function generateUUID() { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16;//random number between 0 and 16
    if(d > 0){//Use timestamp until depleted
      r = (d + r)%16 | 0;
      d = Math.floor(d/16);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r)%16 | 0;
      d2 = Math.floor(d2/16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export const getAgentsLastReadIndex = async function (conversation: Conversation) {
  const participants = await conversation.getParticipants();
  const agentParticipants = participants.filter(p => p.sid !== (conversation.attributes as any).clientParticipantId);
  return agentParticipants.reduce((prev, current) => (
    (current.lastReadMessageIndex ?? -1) > prev ? (current.lastReadMessageIndex ?? -1) : prev
  ), -1)
}

export const checkNewMessages = async function (conversation: Conversation) {
  const lastAgentsReadIndex = await getAgentsLastReadIndex(conversation);
  if (conversation.lastMessage?.index !== undefined) {
    return lastAgentsReadIndex < conversation.lastMessage.index
  }
  return false;
}



