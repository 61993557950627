import {useEffect} from "react";
import {OverlayScrollbars} from 'overlayscrollbars';

const config = {

}

export function useScrollbar(root?: HTMLElement | null) {
  useEffect(() => {
    let scrollbar: OverlayScrollbars;
    if (root) {
      scrollbar = OverlayScrollbars(root, config);
    }
    return () => {
      if (scrollbar) {
        scrollbar.destroy();
      }
    }
  }, [root]);
}
