import {PlanYear} from "../../../../../enrollment-types";
import {ComparablePlan, useCompareOfferLazyQuery, useCompareOfferQuery} from "../../../../../types";
import {client} from "../../../../../Apollo/ApolloClient";
import React, {useEffect, useState} from "react";
import {decoratePlans} from "../lib";

export function useGetCompareOfferPlans(defaultYear: PlanYear, clientId?: string) {
  const [plans, setPlans] = React.useState<Array<ComparablePlan & {title: string}>>([]);
  const [loading, setLoading] = useState(false)
  const [plansHasUnreachableYear, setPlansHasUnreachableYear] = useState(false)

  const [getPlans, {loading: requestLoading, data}] = useCompareOfferLazyQuery({
    client,
    fetchPolicy: "no-cache",
  });

  const refetch = () => {
    setLoading(true)
    return getPlans({variables: {clientId}})
      .then(async (res) => {
        if (res.data?.compareOffer?.plans) {
          setPlansHasUnreachableYear(res.data?.compareOffer?.plans.some(p => !p.planYear))
          setPlans(await decoratePlans(defaultYear, res.data?.compareOffer?.plans))
        }
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (clientId) {
      refetch()
    }
  }, [clientId, defaultYear]);

  return {plans, loading: loading || requestLoading, offerId: data?.compareOffer?.id, refetch, plansHasUnreachableYear}
}