const paths = {
  home: '/',
  enrollments: '/enrollments',
  request: '/request',
  clients: '/clients',
  chats: '/chats',
  videoCategories: '/videoCategories',
  tags: '/tags',
  videos: '/videos',
  maEnrollmentEdit: '/ma-enrollment-edit',
  mgEnrollmentEdit: '/mg-enrollment-edit',
  pdpEnrollmentEdit: '/pdp-enrollment-edit',
  client: '/client',
  plan: '/plan',
  agents: '/agents',
  dashboard: '/dashboard',
  agent: '/agent',
  files: '/files',
  planCoverage: '/plan-coverage',
  attemptedLeads: '/attemptedLeads',
  integrations: '/integrations',
  urgentCalls: '/urgentCalls',
  twoHourSla: '/2-hour-sla',
  missingApps: '/missing-apps',
  urgentMissingApps: '/urgent-missing-apps',
  createClient: '/create-client',
  emailTemplates: '/email-templates',
  emailTemplateForm: '/email-template',
  csrTrackingReport: '/csr-report',
};

export default paths;
