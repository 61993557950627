// @flow
import * as React from 'react';
import {useAgentCallAgendaSubscription, useSetDontDisturbMutation} from "../../enrollment-types";
import {Box, Switch, FormControlLabel, Chip} from "@mui/material";
import {useState} from "react";
import useInterval from "../../shared/useInterval";
import moment from "moment";


export function CallAgenda() {
  const [dontDisturb, setDontDisturb] = useState(false);
  const [reclaimDate, setReclaimDate] = useState<string>()
  const [time, setTime] = React.useState('');

  const [setDontDisturbMutation] = useSetDontDisturbMutation()
  const {data} = useAgentCallAgendaSubscription({
    onData: res => {
      res.data.data && setDontDisturb(res.data.data.agentCallAgenda.dontDisturb)
      setReclaimDate(res.data.data?.agentCallAgenda.scheduledReassignAt)
    }
  });

  useInterval(() => {
    if (reclaimDate) {
      let diff = moment.duration(moment(reclaimDate).diff(moment())).asSeconds()
      if (diff < 0) {
        diff = 0;
      }
      setTime(diff.toFixed(0));
    } else {
      setTime('')
    }
  }, 1000);

  if (!data) return null;

  const {agentCallAgenda: {assignedClientsCallTasks}} = data;

  const handleChange = (value: boolean) => {
    setDontDisturb(value)
    setDontDisturbMutation({variables: {dontDisturb: value} })
  }

  let message = assignedClientsCallTasks.length ? `Clients to call - ${assignedClientsCallTasks.length}` : ''

  if (time) {
    message = `1 client pending transfer - ${time} seconds left to reclaim`
  }

  return (
    <Box sx={{display: 'flex', gap: 3, alignItems: 'center'}}>
      {message && <Chip label={message} color="error" sx={{borderRadius: '8px'}}/>}
      <FormControlLabel control={(
        <Switch checked={dontDisturb}
                color={'error'}
                onChange={(e, checked) => handleChange(checked)} />
      )} label="Do not distrib" />
    </Box>
  );
}