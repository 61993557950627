import {EnabledPbpContractOutput, EnabledPdpContractOutput} from "../../../types";
import React from "react";
import {Checkbox} from "@mui/material";

interface RowProps {
  item: EnabledPbpContractOutput | EnabledPdpContractOutput,
  onClick: (checked: boolean) => void
}

export const Row = React.memo(({item, onClick}: RowProps) => {

  return <tr
    key={item.contract}
  >
    <td>{item.contract}</td>
    <td>{item.parentCompany}</td>
    <td>{item.company}</td>
    <td>
      <Checkbox
        color={'primary'}
        edge="end"
        onChange={e => {
          e.preventDefault();
          e.stopPropagation()
          onClick(e.target.checked)
        }}
        checked={item.enabled}
      />
    </td>
  </tr>
}, areEqual);

function areEqual(prevProps: RowProps, nextProps: RowProps): boolean {
  return prevProps.item.contract === nextProps.item.contract && prevProps.item.enabled === nextProps.item.enabled
}
