import moment from "moment";
import * as _ from "lodash";
import {config} from "../../../../config/config";
import * as yup from "yup";
import {EnrollmentStatusDto} from "../../../../enrollment-types";

export const isEffectiveDateValid = (date?: string | null): boolean => {
  if (!date) {
    return false
  }

  return moment(date).isValid()
};

export const fillFormData = _.memoize((...args: Array<{[key: string]: any} | undefined>) => {
  const valuedArgs: {[key: string]: any}[] = args.filter(Boolean) as {[key: string]: any}[];
  const keys = _.chain(valuedArgs).map(Object.keys).flatten().uniq().value();
  const dateFields = ['birthDate', 'applicationDate', 'effectiveDate'];
  return keys.reduce((previousValue, key) => {
    let val = '';

    for (const obj of valuedArgs) {
      if (obj![key] !== undefined) {
        val = obj![key];
        break;
      }
    }

    return {
      ...previousValue,
      [key]: dateFields.includes(key) ? val ? moment.utc(val).format(config.dateFormat) : null : val
    }
  }, {} as any)
})

export const enrollmentFormValidationSchemaFields = {
  zip: yup.string().required('Zip is required').default(''),
  county: yup.string().required('County is required').default(''),
  firstName: yup
    .string()
    .required('First name is required')
    .default(''),
  lastName: yup
    .string()
    .required('Last name is required')
    .default(''),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required')
    .default(''),
  status: yup
    .string()
    .required('Status is required')
    .default(EnrollmentStatusDto.Draft),
  bidId: yup
    .string()
    .required('Plan is required')
    .default(''),
  carrierName: yup
    .string()
    .required('Carrier is required')
    .default(''),
  planName: yup
    .string()
    .default(''),
  phoneNumber: yup
    .string()
    .required('Phone is required')
    .default(''),
  enrollmentType: yup
    .string()
    .default(''),
  agentId: yup
    .string()
    .required('Agent is required')
    .default(''),
  birthDate: yup
    .date().nullable()
    .required('Birth date is required')
    .typeError('Please enter a valid date')
    .default(null),
  applicationDate: yup
    .date().nullable()
    .required('Application date is required')
    .typeError('Please enter a valid date')
    .default(null),
  effectiveDate: yup
    .date().nullable()
    .required('Effective date is required')
    .typeError('Please enter a valid date')
    .default(null),
};

export const maEnrollmentFormValidationSchema = yup.object(enrollmentFormValidationSchemaFields);
export const pdpEnrollmentFormValidationSchema = yup.object(enrollmentFormValidationSchemaFields);

const mgEnrollmentFormValidationSchemaFields = {
  ..._.omit( enrollmentFormValidationSchemaFields, ['bidId', 'phoneNumber'] ),
  naic: yup
    .string()
    .required('Carrier is required'),
  age: yup
    .string()
    .required('Age is required'),
  planKey: yup
    .string()
    .required('Plan is required'),
  tobacco: yup
    .boolean()
    .nullable()
    .required('Tobacco is required')
    .default(null)
  ,
  gender: yup
    .string()
    .required('Plan is required'),
};
export const mgEnrollmentFormValidationSchema = yup.object(mgEnrollmentFormValidationSchemaFields);
