import {Box, Button, Card, Divider, Drawer, IconButton, RadioGroup, Stack, Typography} from "@mui/material";
import {
  AffiliationPartnershipOutput,
  ClientViewFilterInput,
  ClientViewTimeZone,
} from "../../../enrollment-types";
import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import FormSelect from "../../../shared/FormSelect";
import TextInput from "../../../shared/TextInput";
import AscendAutocomplete from "../../../shared/AscendAutocomplete";
import useAffiliationPartnerships from "../../../Client/hooks/useAffiliationPartnerships";
import * as _ from "lodash";
import {GetLicensedStates} from "../../../Client/widgets/PersonalInfo";
import AgentSelector from "../../../Agent/AgentSelector";
import DaterangeFilter from "../../../shared/filters/DaterangeFilter";
import StatusFilter from "../../../shared/filters/StatusFilter";
import {TagSelector} from "../../../Agent/components/TagSelector";

interface ClientListFilterDrawerProps {
  open: boolean,
  onClose: (filter?: ClientViewFilterInput) => void,
  initial?: ClientViewFilterInput
}

export default function ClientListFilterDrawer({open, onClose, initial}: ClientListFilterDrawerProps) {
  const [filters, setFilters] = useState(initial || {});

  useEffect(() => {
    if (open) {
      setFilters(initial || {});
    } else {
      setFilters({});
    }
  }, [open, initial]);

  const setFilterValues = (values: Array<{name: keyof ClientViewFilterInput, value: any}>) => {
    setFilters(prev => {
      let result = prev ? {...prev} : {};
      for (const value of values) {
        result[value.name] = value.value === '-' ? undefined : value.value || undefined;
      }
      return result;
    })
  }

  return <Drawer
    anchor={'right'}
    open={open}
    onClose={() => onClose()}
  >
    <Card sx={{p: 4, width: 594, overflowY: 'auto'}}>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography variant={'h3'}>Client list filters</Typography>
        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <IconButton size={'small'} onClick={() => onClose()}>
            <img className={'w-32 h-32'} src={'/img/X.svg'}/>
          </IconButton>
        </Box>
      </Box>

      <Divider sx={{mb: 3}} />
      <Stack spacing={3}>
        <DaterangeFilter value={filters.range}
                         label={'Created'}
                         onChange={value => setFilterValues([{name: 'range', value}])} />
        <StatusFilter value={filters.status} onChange={value => setFilterValues([{name: 'status', value}])} />
        <CampaignFilter value={filters.marketingSource ? {campaign: filters.marketingCampaign!, source: filters.marketingSource!} : undefined}
                        onChange={value => {
                          setFilterValues([
                            { name: 'marketingCampaign', value: value?.campaign },
                            { name: 'marketingSource', value: value?.source },
                          ])
                        }} />
        <DaterangeFilter value={filters.birthDate}
                         label={'Birth date'}
                         onChange={value => setFilterValues([{name: 'birthDate', value}])} />
        <StateFilter value={filters.state} onChange={value => setFilterValues([{name: 'state', value}])} />
        <TimezoneFilter value={filters.timeZone} onChange={value => setFilterValues([{name: 'timeZone', value}])} />
        <UtmSourceFilter value={filters.utmSource} onChange={value => setFilterValues([{name: 'utmSource', value}])} />
        <TagSelector selectedValues={filters.tags || []} onChange={value => setFilterValues([{name: 'tags', value}])} />
        <AgentSelector className={'mb-0'}
                       showEmptyOption
                       value={filters.agentId}
                       onChange={value => setFilterValues([{name: 'agentId', value}])}/>

        <div>
          <Button variant={'contained'} onClick={() => onClose(filters)}>Search</Button>
        </div>
      </Stack>
    </Card>
  </Drawer>
}

const CampaignFilter = ({value, onChange}: FilterProps<AffiliationPartnershipOutput>) => {
  const [partnershipAutocompleteValue, setPartnershipAutocompleteValue] = useState('')
  const [partnership, setPartnership] = useState(value);
  const [getPartnerships, partnershipsData] = useAffiliationPartnerships();

  useEffect(() => {
    getPartnerships()
  }, []);

  const partnerships = React.useMemo(() => {
    if (partnershipsData.data) {
      return partnershipsData.data.affiliationPartnerships
    }
    return [];
  }, [partnershipsData.data])


  const filteredPartnerships = useMemo(() => {
    return _.orderBy(partnerships, ['source', 'campaign'])
      .filter(p => `${p.source}: ${p.campaign}`.indexOf(partnershipAutocompleteValue) >= 0) || []
  }, [partnerships, partnershipAutocompleteValue]);


  return <AscendAutocomplete fullWidth
                             loading={partnershipsData.loading}
                             options={filteredPartnerships}
                             value={partnership}
                             onInputChange={(event, newInputValue) => {
                               setPartnershipAutocompleteValue(newInputValue);
                             }}
                             getOptionLabel={(p: any) => {
                               return p ? `${p.source}: ${p.campaign}` : ''
                             }}
                             onChange={(event, value) => {
                               onChange(value as any);
                               setPartnership(value as any);
                             }}
                             clearOnBlur
                             renderInput={params => <TextInput
                               className={'mb-0'}
                               placeholder={'CAMPAIGN'}
                               value={partnershipAutocompleteValue}
                               {...params} />} />
}



const StateFilter = ({value, onChange}: FilterProps<string>) => {
  return <FormSelect className={'mb-0'}
                     label={'State'}
                     value={value}
                     onChange={event => {
                       onChange(event.target.value as string)
                     }}
  >
    <MenuItem value={''}>-</MenuItem>
    {GetLicensedStates().sort().map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
  </FormSelect>
}


const TimezoneFilter = ({value, onChange}: FilterProps<ClientViewTimeZone>) => {
  return <FormSelect className={'mb-0'}
                     label={'Timezone'}
                     value={value}
                     onChange={event => {
                       onChange(event.target.value as ClientViewTimeZone)
                     }}
  >
    <MenuItem value={''}>-</MenuItem>
    <MenuItem value={ClientViewTimeZone.Akst}>{ClientViewTimeZone.Akst}</MenuItem>
    <MenuItem value={ClientViewTimeZone.Cst}>{ClientViewTimeZone.Cst}</MenuItem>
    <MenuItem value={ClientViewTimeZone.Est}>{ClientViewTimeZone.Est}</MenuItem>
    <MenuItem value={ClientViewTimeZone.Hst}>{ClientViewTimeZone.Hst}</MenuItem>
    <MenuItem value={ClientViewTimeZone.Mst}>{ClientViewTimeZone.Mst}</MenuItem>
    <MenuItem value={ClientViewTimeZone.Pst}>{ClientViewTimeZone.Pst}</MenuItem>
  </FormSelect>
}


const UtmSourceFilter = ({value, onChange}: FilterProps<string>) => {
  return <TextInput
    className={'mb-0'}
    fullWidth
    name="utmSource"
    label="UtmSource"
    value={value}
    onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value || '')}
  />
}
