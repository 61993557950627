// @flow
import * as React from 'react';
import { useLayoutEffect, useRef } from 'react';
import {Box, CircularProgress, IconButton, InputAdornment, ListItemButton, ListItemText} from '@mui/material';
import TextInput from "../../../../../shared/TextInput";
import {DrugsQuery, useDrugsQuery} from "../../../../../types";
import { useDebouncedMemo } from '../../../../../shared/use-debounced-memo';
import {client} from "../../../../../Apollo/ApolloClient";

type Props = {
  onSelect: (drug: DrugsQuery['drugs'][0]) => void;
};

export function DrugSelectionModalSearch({ onSelect }: Props) {
  const ref = useRef<HTMLInputElement | null>(null);
  const [searchTerm, setSearchTerm] = React.useState('');
  const deferredValue = useDebouncedMemo(searchTerm, 500);
  const { data, loading } = useDrugsQuery({
    client,
    variables: { name: deferredValue },
    skip: !deferredValue,
  });

  useLayoutEffect(() => {
    ref.current?.focus()
  }, [])

  const drugs = data?.drugs || [];

  return (
    <div>
      <TextInput value={searchTerm}
                 fullWidth
                 className={'mb-0'}
                 onChange={event => setSearchTerm(event.target.value)}
                 placeholder={'Search by name...'}
                 inputRef={ref}
                 InputProps={{
                   endAdornment: <InputAdornment position="end">
                     {searchTerm && !loading && <IconButton size={'small'} onClick={() => {
                       setSearchTerm('');
                       ref.current?.focus()
                     }}>
                         <img className={'w-16 h-16'} src={'/img/X.svg'}/>
                     </IconButton>}
                     {loading && <CircularProgress size={16} />}
                   </InputAdornment>
                 }}
      />

      {drugs?.length > 0 && (
        <Box sx={{maxHeight: 400}}>
          {drugs.map(drug => (
            <ListItemButton onClick={() => onSelect(drug)}>
              <ListItemText primaryTypographyProps={{sx: {fontWeight: 600} }}
                            primary={drug.name} />
            </ListItemButton>
          ))}
        </Box>
      )}
    </div>
  );
}
