import React, {Dispatch, SetStateAction} from "react";
import * as _ from 'lodash';

export default function<S>(sessionStorageKey: string, initialState: S): [S, Dispatch<SetStateAction<S>>] {

  const computedInitialState = React.useMemo<S>(() => {
    let data: S | undefined | null;
    try {
      const jsonStr = sessionStorage.getItem(sessionStorageKey);
      if (jsonStr) {
        data = JSON.parse(jsonStr) as S
      }
    } catch (e) {
      console.error(e)
    }
    return typeof data !== 'undefined' && data !== null ? data : initialState;
  }, []);

  const [state, setState] = React.useState<S>(computedInitialState);

  const setStateWrapper = React.useCallback((value: SetStateAction<S>) => {
    try {
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(_.isFunction(value) ? value(state) : value ));
    } catch (e) {
      console.error(e)
    }
    setState(value)
  }, [setState, state]);

  return [state, setStateWrapper]
}
