import React from "react";
import {ClientViewOutput, MedigapFilterStateBenefit, useSaveUserProfileMutation} from "../../../enrollment-types";
import {Box, Button, Divider, Typography} from "@mui/material";
import Card from "../../../shared/Card";
import Param from "../../../shared/Param";
import moment from "moment";
import {config} from "../../../config/config";
import {useSnackbar} from "notistack";
import {getAgeByBirthday} from "../../../shared/utils";
import {Gender, useCreateMedigapQuoteForClientMutation} from "../../../medigap-types";
import {client as apolloClient} from "../../../Apollo/ApolloClient";


interface MedigapQuoteProps {
  client?: ClientViewOutput
}

const MedigapQuote = ({client}: MedigapQuoteProps) => {
  const {enqueueSnackbar} = useSnackbar();
  const [createQuote] = useCreateMedigapQuoteForClientMutation({client: apolloClient})
  const [saveProfile] = useSaveUserProfileMutation()

  const create = () => {
    if (client) {
      let age = getAgeByBirthday(client.birthDate as string);

      if (!age || age >= 63 && age < 65) {
        age = 65;
      }

      createQuote({
        variables: {
          input: {
            clientId: client.id,
            medigapFilterState: {
              age,
              zip: client.zip as string,
              county: client.countyName as string,
              gender: client.gender as Gender || Gender.F,
              tobacco: client.tobacco === true
            }
          }
        }
      }).then(res => {
        if (client.profileId && res.data?.createMedigapQuoteForClient?.mgQuoteId) {
          return saveProfile({
            variables: {
              data: {
                medigapQuoteId: res.data?.createMedigapQuoteForClient?.mgQuoteId,
                profileId: client.profileId
              }
            }
          })
        }
      })
    }
  };

  if (!client) {
    return null;
  }

  const quote = client.medigapQuote;

  return <Card sx={{flex: 1}}>
    <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>Medigap Quote</Typography>
    <div className={"flex-space-between"}>
      {quote?.createdAt && <Param className={'flex-column'} label={"CREATED"} value={moment(quote?.createdAt).format('L H:mm:ss')}/>}
    </div>
    <div className={"flex-space-between"}>
      {quote?.medigapFilterState?.effectiveDate &&
        <Param className={'flex-column'} label={"EFFECTIVE DATE"} value={moment(quote?.medigapFilterState?.effectiveDate).format('L H:mm:ss')}/>}
      {quote?.medigapFilterState?.planCategory &&
        <Param className={'flex-column'} label={"PLAN CATEGORY"} value={quote?.medigapFilterState?.planCategory}/>}
    </div>
    {(!!quote?.medigapFilterState?.benefits?.length ||!!quote?.medigapFilterState?.companies?.length) && <Divider className={'mb-15'} />}
    {!!quote?.medigapFilterState?.benefits?.length &&
      <Param className={'flex-column'} label={"BENEFITS"} value={quote?.medigapFilterState?.benefits.map(getBenefitLabel).join(', ')}/>}
    {!!quote?.medigapFilterState?.companies?.length &&
      <Param className={'flex-column'} label={"COMPANIES"} value={quote?.medigapFilterState?.companies.join(', ')}/>}
    <div className={"flex-space-between"}>
      {quote?.medigapFilterState?.gender &&
        <Param className={'flex-column'} label={"GENDER"} value={quote?.medigapFilterState?.gender}/>}
      {quote?.medigapFilterState?.age &&
      <Param className={'flex-column'} label={"AGE"} value={quote?.medigapFilterState?.age.toString()}/>}
    </div>
    <Divider className={'mb-15'} />
    {!!quote && <>
      <div className={"flex-space-between"}>
        <Param className={'flex-column'} label={"TOBACCO"} value={quote?.medigapFilterState?.tobacco ? 'Yes' : 'No'}/>
        {!!quote?.medigapFilterState?.monthlyPlanPremiumRanges?.length &&
          <Param className={'flex-column'} label={"MONTHLY PLAN PREMIUM RANGES"} value={quote?.medigapFilterState?.monthlyPlanPremiumRanges.join(', ')}/>}
      </div>
      <Divider className={'mb-15'} />
    </>}
    <Box>
      {(!!client && client?.medigapQuote) && <Button onClick={() => window.open(`${config.medigapUrl}?clientId=${client.id}&y2y`)}
                                                      className={'mb-10'}
                                                      variant={'outlined'}
                                                      color={'primary'}>YEAR TO YEAR</Button>}
      <div>
        {(!!client && !client?.medigapQuote) && <Button onClick={create}
                                                        className={'mb-10'}
                                                        variant={'contained'}
                                                        color={'primary'}>Create quote</Button>}
      </div>
      <Button href={`${config.medigapUrl}plans/${quote?.medigapFilterState?.zip}/${quote?.medigapFilterState?.county}?clientId=${client.id}`}
              target={'_blank'}
              disabled={!client?.medigapQuote}
              variant={'contained'}
              className={'mb-10'}
              color={'primary'}>Open quote</Button>
      <Typography onClick={() => {
        navigator.clipboard.writeText(`${config.medigapUrl}?clientId=${client.id}`)
        enqueueSnackbar('Copied!', {variant: "info"});
      }} className={'pointer fs-12 mb-10 underline'} color={'textPrimary'}>Copy link</Typography>
    </Box>
  </Card>;
}

export default MedigapQuote;

const getBenefitLabel = (input: MedigapFilterStateBenefit): string => {
  switch (input) {
    case MedigapFilterStateBenefit.ForeignTravelEmergency: return 'Foreign Travel Emergency';
    case MedigapFilterStateBenefit.PartADeductible: return 'Part A Deductible';
    case MedigapFilterStateBenefit.PartBDeductible: return 'Part B Deductible';
    case MedigapFilterStateBenefit.PartBExcessCharges: return 'Part B Excess Charges';
    case MedigapFilterStateBenefit.SkilledNursingFacilities: return 'Skilled Nursing Facilities';
    default: return '';
  }
}
