import React from "react";
import {
  Box,
  Button,
  Divider,
  Typography
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridSortModel
} from "@mui/x-data-grid";
import {useSnackbar} from "notistack";
import moment from "moment";
import * as _ from "lodash";
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import useSetUrgentCalRequested from "../../../../Client/hooks/useSetUrgentCalRequested";
import Card from "../../../../shared/Card";
import useCallHistoryPage from "./useCallHistoryPage";
import useCallHistoryPageSize from "./useCallHistoryPageSize";
import {renderResultEditInputCell} from "./ui/result-select";
import {renderCallTypeEditInputCell} from "./ui/call-type-select";
import {stringifyCallResult, stringifyCallType} from "./lib";
import {
  CallHistoryCallType, CallHistoryOutput,
  CallHistoryResultInput, CallHistoryResultOutput,
  ClientViewOutput,
  useCallHistoryItemsLazyQuery, useSaveCallHistoryMutation
} from "../../../../enrollment-types";
import {AudioButton} from "./ui/audio-button";
import {TranscriptionButton} from "./ui/transcription-button";

const columns: GridColDef[] = [
  { field: 'createdAt', headerName: 'Date and Time (ET)', width: 200,
    renderCell: params => moment(params.value?.toString()).format('MM/DD/YYYY H:mm:SS')
  },
  { field: 'callType',
    headerName: 'Call Type',
    renderCell: (params) => stringifyCallType(params.value?.toString() as CallHistoryCallType),
    renderEditCell: renderCallTypeEditInputCell,
    width: 200,
    editable: true
  },
  { field: 'result',
    headerName: 'Result',
    renderCell: (params) => stringifyCallResult(params.value?.toString() as CallHistoryResultInput),
    renderEditCell: renderResultEditInputCell,
    width: 200,
    editable: true },
  { field: 'phoneNumber', headerName: 'Number', width: 150, editable: true },
  { field: 'agent',
    headerName: 'Agent',
    renderCell: (params) => {
      return params.value ? (params.value as any)?.firstName + ' ' + (params.value as any)?.lastName : ''
    } },
  {field: 'audioRecordId',
    headerName: 'Audio',
    renderCell: (params) => {
      return <AudioButton id={params.row.audioRecordId} />
    },
    width: 200
  },
  {field: 'transcription',
    headerName: 'Transcription',
    renderCell: (params) => {
      return <TranscriptionButton transcription={params.row.transcription}  />
    },
    width: 200
  }
];



interface CallLogProps {
  loading: boolean;
  client?: ClientViewOutput;
  phoneNumber?: string | null;
}


const CallHistory = (props: CallLogProps) => {
  const [page, setPage] = useCallHistoryPage();
  const [pageSize, setPageSize] = useCallHistoryPageSize();

  const [getCalls, {loading}] = useCallHistoryItemsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {clientId: props.client?.id, page: {page: 0, size: 1000}},
    onCompleted: data => {
      if (data?.callHistoryItems) {
        setRows(data.callHistoryItems.data)
      }
    }
  });
  const [rows, setRows] = React.useState<any>([]);
  const [save] = useSaveCallHistoryMutation()
  const {enqueueSnackbar} = useSnackbar();
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'createdAt',
      sort: 'desc',
    },
  ]);
  const [setConnected] = useSetUrgentCalRequested()


  const handleEditRow = React.useCallback((params: any) => {
    const newVal = {...params.row, createdAt: undefined, [params.field]: params.value?.toString(), id: _.isNumber(params.row.id) ? undefined : params.row.id};
    delete newVal.agent;
    delete newVal.audioRecordId;
    delete newVal.transcription;
    if (newVal.callType && newVal.result && props.client?.id) {
      save({variables: {input: newVal}})
        .then(() => {
          getCalls({variables: {clientId: props.client?.id, page: {page: 0, size: 1000}}})
        })
        .catch(e => {
          enqueueSnackbar(e.message, {variant: "error"});
        })
    }
  }, [props.client?.id]);

  React.useEffect(() => {
    load();
  }, [props.client?.id]);

  const load = React.useCallback(() => {
    if (props.client?.id) {
      getCalls({variables: {clientId: props.client?.id, page: {page: 0, size: 1000}}})
    }
  }, [props.client?.id])

  const onAddClick = () => {
    const id = Math.random();
    setRows([{id: id, createdAt: new Date(), clientId: props.client?.id, phoneNumber: props.phoneNumber}, ...rows]);
  }

  const onConnectedClick = async () => {
    await setConnected({variables: {clientId: props.client?.id, urgentCallRequested: false}})
  }

  return <>
    <Typography color={'textPrimary'} className={'fs-16 medium'}>Total calls:</Typography>

    <div className={'h-16'}/>
    <div className={'flex-space-between flex-align-end'}>
      <Typography color={'textPrimary'} className={'fs-16 medium'}>Call History</Typography>
      <div className={'flex-space-between'}>
        {props.client?.urgentCallRequested && <Button className={'mb-5 mr-16'}
                                   variant={'contained'}
                                   onClick={() => onConnectedClick()}
                                   color={'secondary'}>REMOVE FROM URGENT CALLS</Button>}
        <Button className={'mb-5 mr-16'}
                variant={'outlined'}
                onClick={load}
                disabled={loading}
                startIcon={<RefreshIcon />}
                color={'primary'}>REFRESH</Button>
        <Button className={'mb-5'}
                variant={'contained'}
                onClick={() => onAddClick()}
                disabled={loading}
                startIcon={<AddIcon />}
                color={'primary'}>ADD CALL</Button>
      </div>
    </div>
    <Card padding={0} className={'relative'} sx={{width: '100%', maxWidth: 1133}}>
      <div style={{height: 500}}>
        <DataGrid rows={rows}
                  columns={columns}
                  paginationModel={{
                    page,
                    pageSize
                  }}
                  onPaginationModelChange={model => {
                    setPage(model.page);
                    setPageSize(model.pageSize)
                  }}
                  loading={loading}
                  editMode="row"
                  pageSizeOptions={[20, 50, 100]}
                  rowCount={rows.length}
                  onRowEditStop={handleEditRow}
                  sortModel={sortModel}
                  onSortModelChange={(model) => setSortModel(model)}
        />
      </div>
    </Card>
  </>;
}

export default CallHistory;
