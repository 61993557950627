import {PlanYear} from "../types";
import moment from "moment/moment";
import {CallHistoryOutput} from "../enrollment-types";
import {client} from "../Apollo/EnrollmentApolloClient";
import {gql} from "graphql-tag";
import * as _ from "lodash";
import {useEffect, useState} from "react";

export type WithCalls<T extends {}> = T & {
  lastCall?: CallHistoryOutput,
  calls: CallHistoryOutput[]
}

export function useClientsWithCalls<T extends {id: string}>(clients: T[]) {
  const [withCalls, setWithCalls] = useState(clients);

  useEffect(() => {
    setWithCalls(clients)
    if (clients.length) {
      assignCallsToClients(clients).then(setWithCalls)
    }
  }, [clients]);

  return withCalls;
}


export async function assignCallsToClients<T extends {id: string}>(clients: T[]): Promise<WithCalls<T>[]> {
  if (!clients.length) {
    return [];
  }

  const query = `
          query {
            ${clients.map((f, i) => 'c' + i + `: callHistoryItems(clientId: "${f.id}", page: {page: 0, size: 50}) {
              data {
                result
                createdAt
              }
              totalElements
            }`).join(' ')}
          }
        `;
  const calls = await client.query({fetchPolicy: 'no-cache', query: gql(query)}).then(res => res.data || {});

  return clients.map((f, i) => ({
    ...f,
    calls: _.orderBy(calls['c' + i].data, (v: any) => moment(v.createdAt).toDate().getTime(), 'desc'),
    lastCall: _.orderBy(calls['c' + i].data, (v: any) => moment(v.createdAt).toDate().getTime(), 'desc')[0]
  }));
}

export function GetPlansYear(): PlanYear {
  return 'Year' + new Date().getFullYear().toString() as PlanYear;
}

export function GetNextPlansYear(): PlanYear {
  return 'Year' + (new Date().getFullYear() + 1).toString() as PlanYear;
}

export function getUserName(client: {firstName?: string | null, lastName?: string | null}): string {
  const firstName = client.firstName;
  const lastName = client.lastName;
  let name = '';
  if (!firstName && !lastName) {
    name = 'Unknown user';
  } else {
    name = [firstName, lastName].join(' ');
  }
  return name
}

export function calcDurationToNow(time?: string) {
  if (!time) {
    return '-';
  }

  const f = (v: number) => v < 10 ? '0' + v : v;

  const duration = moment.duration(moment().diff(moment(time))).asMilliseconds()
  var s = Math.floor( (duration/1000) % 60 );
  var m = Math.floor( (duration/1000/60) % 60 );
  var h = Math.floor( (duration/(1000*60*60)) % 24 );
  var d = Math.floor( duration/(1000*60*60*24) );
  let t = f(h) + ':' + f(m) + ':' + f(s);
  if (d > 0) {
    t = d + 'd ' + t;
  }
  return t;
}
