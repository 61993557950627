import React from "react";
import {
  Box, Button,
  Checkbox,
  Dialog, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
  Typography,
  useMediaQuery
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "../../../../../../../app/AppTheme";
import * as _ from 'lodash';
import {useAddresses} from "../hooks/useAddresses";
import {DoctorAddressViewOutput, DoctorViewOutput} from "../../../../../../../enrollment-types";

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      paddingTop: 8,
      paddingBottom: 6,
      minWidth: 441,
      position: 'relative'
    },
    title: {
      textAlign: 'left',
      lineHeight: '25px',
      fontSize: 20,
      fontWeight: 500,
      marginBottom: 16,
      marginLeft: 10,
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '22px',
      fontWeight: 300,
    },
    radio: {
      background: '#F5F6F7',
    },
    info: {
      background: '#F5F6F7',
      paddingLeft: 30,
      marginTop: 20,
    },
    link: {
      color: colors.green.variant1,
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'none'
    },
    scrollbar: {
      maxHeight: '400px'
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    item: {
      height: 37,
      '&:not(:last-child)': {
        borderBottom: '1px solid #DDDDDD'
      }
    }
  })
);

type AddressSelectionModalProps = {
  onClose: (addresses?: DoctorAddressViewOutput[]) => void,
  open: boolean,
  doctor?: DoctorViewOutput,
  addresses?: DoctorAddressViewOutput[]
}

export function AddressSelectionModal(props: AddressSelectionModalProps) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  const matches = useMediaQuery('(min-height:600px)');

  const addresses = useAddresses(props.open ? props.doctor?.npi : undefined, props.addresses?.map(a => _.omit(a, '__typename')))

  React.useEffect(() => {
    if (props.open && props.doctor) {
      setSelected(props.doctor.addresses.map(a => a.id));
    } else {
      setSelected([]);
    }
  }, [props.open, props.doctor])

  const height = React.useMemo(() => {
    if (!matches || !addresses.length) {
      return undefined
    } else {
      return addresses.length * 37 + 8;
    }
  }, [matches, addresses])

  const onClick = (id: string) => {
    setSelected(prev => {
      const index = prev.indexOf(id);
      if (index >= 0) {
        return _.without(prev, id);
      } else {
        return [...prev, id];
      }
    })
  }

  const isSelected = React.useCallback((id: string) => {
    return selected.includes(id)
  }, [selected])

  const save = () => {
    props.onClose(addresses.filter(a => selected.includes(a.id)).map(a => _.omit(a, '__typename')))
  }

  return <Dialog
    open={props.open}
    onClose={() => props.onClose()}
  >
    <>
      {props.doctor && <div className={classes.wrapper}>
          <Box position={'absolute'} display={'flex'} right={10}>
              <Button onClick={() => props.onClose()} variant={'outlined'} className={'mr-10'}
                      size={'small'}>CLOSE</Button>
              <Button onClick={save} disabled={!selected.length} variant={'contained'} color={'primary'} size={'small'}>SAVE</Button>
          </Box>
          <Typography color={'textPrimary'} className={classes.title}>Choose doctor office</Typography>
        {!!addresses.length && <List>
          {addresses.map(address => (
            <ListItem key={address.id} sx={{p: 0}} dense>
              <ListItemButton role={undefined} onClick={() => onClick(address.id)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    color={'primary'}
                    checked={isSelected(address.id)}
                    sx={{
                      color: '#1C434F',
                      '&.Mui-checked': {
                        color: '#1C434F',
                      },
                    }}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={[address.addressLine1, address.addressLine2].join(', ')} />
              </ListItemButton>
            </ListItem>

          ))}
        </List>}
      </div>
      }
    </>
  </Dialog>
}