import {Conversation} from "@twilio/conversations";
import {keycloak} from "../../shared/keycloak";

export default function useSendMessage(conversation?: Conversation) {
  return async (message: string) => {
    if (conversation) {
      const attributes = {
        firstName: keycloak.tokenParsed?.given_name,
        lastName: keycloak.tokenParsed?.family_name,
        email: keycloak.tokenParsed?.email,
      }
      const lastIndex = conversation.lastMessage?.index || -1;
      await conversation.advanceLastReadMessageIndex(lastIndex + 1);
      await conversation.sendMessage(message, attributes)
    }
  }
}