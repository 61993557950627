import {useEnabledMaStatesQuery, useSaveEnabledMaStateMutation} from "../../../types";
import {client} from "../../../Apollo/ApolloClient";
import * as _ from "lodash";

export function useMaStates() {
  const {data, loading} = useEnabledMaStatesQuery({client, notifyOnNetworkStatusChange: true});
  const [save, {loading: saveStateLoading}] = useSaveEnabledMaStateMutation({client, refetchQueries: ['enabledMaStates']})

  return {
    data: data?.enabledPbpStates ? _.orderBy(data?.enabledPbpStates, 'state') : [],
    loading: loading || saveStateLoading,
    save
  };
}