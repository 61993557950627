import * as React from 'react';
import {usePdpCompaniesQuery} from "../../../../../types";
import {client} from "../../../../../Apollo/ApolloClient";
import {Props} from "./types";
import {CarrierSelector} from "./CarrierSelector";

export const PdpCarrierSelector = (props: Props) => {
  const {zip, county, planYear} = props;
  const {data} = usePdpCompaniesQuery({
    client,
    variables: {
      filter: {zip, countyName: county, planYear, showAllPlans: true},
    },
    skip: !county || !zip || !planYear,
    fetchPolicy: 'no-cache'
  })

  const companies = (data?.pdpCompanies || []).filter((c: any) => c.count).map((c: any) => `${c.name} (${c.count})`) || []

  return <CarrierSelector {...props} companies={companies} />
};
