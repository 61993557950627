import React from "react";
import {Box, Button, Checkbox, Chip, FormControlLabel, IconButton, Typography} from "@mui/material";
import Card from "../shared/Card";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import useAttemptedLeadsPage from "./hooks/useAttemptedLeadsPage";
import {
  AssignedTagViewOutput,
  AttemptedLeadGroupDto,
  AttemptedLeadSortDirection,
  AttemptedLeadSortField,
  ClientViewTimeZone,

} from "../enrollment-types";
import moment from "moment";
import {StatusView} from "../features";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import paths from "../router-paths";
import {useHistory} from "react-router";
import useAttemptedLeads from "./hooks/useAttemptedLeads";
import useAttemptedLeadExcludeBadNumbers from "./hooks/useAttemptedLeadExcludeBadNumbers";
import useAttemptedLeadIEP from "./hooks/useAttemptedLeadIEP";
import useAttemptedLeadSorting from "./hooks/useAttemptedLeadSorting";
import {colors} from "../app/AppTheme";
import {Link} from "react-router-dom";
import useAttemptedLeadDateRange from "./hooks/useAttemptedLeadDateRange";
import useAttemptedLeadState from "./hooks/useAttemptedLeadState";
import FormSelect from "../shared/FormSelect";
import MenuItem from "@mui/material/MenuItem";
import {GetLicensedStates} from "../Client/widgets/PersonalInfo";
import {getTagLabel} from "../Client/widgets/ClientTags";
import useAttemptedLeadTimezone from "./hooks/useAttemptedLeadTimezone";
import DaterangeFilter from "../shared/filters/DaterangeFilter";



const columns: GridColDef[] = [
  { field: 'leadDate', headerName: 'Lead Date', width: 170, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const leadDateStr = params.row.leadDate as string;
      if (leadDateStr) {
        return moment(leadDateStr).format('L H:mm:ss')
      }
      return ''
    }
  },
  { field: 'firstName', headerName: 'Name', width: 150, disableColumnMenu: true, sortable: false,
    renderCell: params => {
      const firstName = params.row.firstName;
      const lastName = params.row.lastName;
      if (!firstName && !lastName) {
        return 'Unknown user';
      } else {
        const name = firstName + ' ' + lastName || '';
        return <Link style={{color: colors.text.primary}} onClick={event => event.stopPropagation()} to={'/client/' + params.row.id}>{name}</Link>
      }
    }
  },
  { field: 'phoneNumber', headerName: 'Number', width: 120, disableColumnMenu: true, sortable: false,},
  { field: 'state', headerName: 'State', width: 120, disableColumnMenu: true, sortable: false,},
  { field: 'birthDate', headerName: 'Birth date', width: 120, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const birthDateStr = params.row.birthDate as string;
      if (birthDateStr) {
        return moment(birthDateStr).format('L');
      }
      return ''
    }
  },
  { field: 'age', headerName: 'Age', width: 100, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const birthDateStr = params.row.birthDate as string;
      if (birthDateStr) {
        return Math.floor(moment.duration(moment().diff(moment(birthDateStr))).asYears())
      }
      return ''
    }
  },
  { field: 'status', headerName: 'Status', width: 200, disableColumnMenu: true, sortable: false,
    renderCell: params => {
      return <StatusView status={params.row.status || undefined} />
    }
  },
  { field: 'iepFlag', headerName: 'IEP flag', width: 120, disableColumnMenu: true, sortable: false,
    renderCell: params =>
      params.row.iepFlag ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <ClearIcon style={{color: 'red'}} fontSize={'small'} />
  },
  { field: 'lastName', headerName: 'Called today', width: 170, disableColumnMenu: true, sortable: false,
    renderCell: params => {
      return params.row.leadGroup === AttemptedLeadGroupDto.CalledToday ? 'Y' : 'N';
    }
  },
  { field: 'lastCallDate', headerName: 'Last Call Date', width: 170, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const lastCallDateStr = params.row.lastCallDate as string;
      if (lastCallDateStr) {
        return moment(lastCallDateStr).format('L H:mm:ss')
      }
      return ''
    }
  },
  { field: 'lastCallAgentFullName', headerName: 'Call Agent', width: 170, disableColumnMenu: true, sortable: false,},
  { field: 'callsCount', headerName: 'Calls Count', width: 150, disableColumnMenu: true, sortable: false,},
  { field: 'urgent', headerName: 'Urgent', width: 120, disableColumnMenu: true, sortable: false,
    renderCell: params =>
      params.row.urgent ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <ClearIcon style={{color: 'red'}} fontSize={'small'} />
  },
  { field: 'tags', headerName: 'Tags', width: 200, disableColumnMenu: true,
    renderCell: params => {
      return <div className={'chips-wrapper'}>
        {params.row.tags.map((value: AssignedTagViewOutput) => (
          <Chip style={{backgroundColor: value.tag.color as string}} className={'ml-8 mt-5'} size="small" key={value.tag.id} label={getTagLabel(value)}  />
        ))}
      </div>
    }
  },
];

const AttemptedLeads = () => {
  const [pageInput, setPageInput] = useAttemptedLeadsPage();
  const [excludeBadNumbers, setExcludeBadNumbers] = useAttemptedLeadExcludeBadNumbers();
  const [dateRange, setDateRange] = useAttemptedLeadDateRange();
  const [iep, setIep] = useAttemptedLeadIEP();
  let history = useHistory();
  const [sorting, setSorting] = useAttemptedLeadSorting();
  const [timezone, setTimezone] = useAttemptedLeadTimezone();
  const [excludeCalledToday, setExcludeCalledToday] = React.useState(true);
  const [excludeDoNotCall, setExcludeDoNotCall] = React.useState(true);
  const [state, setState] = useAttemptedLeadState();

  const [searchRequest, {loading, data, refetch}] = useAttemptedLeads()

  React.useEffect(() => {
    search();
  }, [pageInput, sorting]);

  const search = async () => {
    await searchRequest({
      variables: {
        filter: {
          excludeDoNotCall,
          excludeCalledToday,
          range: dateRange ? {from: moment(dateRange.from).startOf('day').toISOString(), to: moment(dateRange.to).endOf('day').toISOString()} : undefined,
          iep,
          excludeBadNumbers,
          state: state ? state.toUpperCase() : null,
          timeZone: timezone || undefined,
        },
        pageInput,
        sort: sorting ? {
          field: sorting?.field === 'leadDate' ? AttemptedLeadSortField.LeadDate : AttemptedLeadSortField.Birthdate,
          direction: sorting?.sort?.toString().toUpperCase() as AttemptedLeadSortDirection
        } : null
      }
    });
  };

  const onSearch = () => {
    if (pageInput.page > 0) {
      setPageInput(prev => ({page: 0, size: prev.size}));
    } else {
      search();
    }
  }

  return <Card padding={0}>
    <Box display={'flex'} p={'30px'} justifyContent={'space-between'} alignItems={'top'} width={'100%'}>
      <Typography color={'textPrimary'} variant={'h4'}>Attempted Leads</Typography>
      <Box display={'flex'} alignItems={'center'}>
        <div>

          <Box display={'flex'}  alignItems={'center'} mb={1}>
            <DaterangeFilter value={dateRange}
                             label={'Lead date'}
                             onChange={setDateRange} />
            {!!dateRange && <IconButton size={'small'} className={'ml-15'} onClick={() => setDateRange(undefined)}><img
              className={'w-15 h-15'} src={'/img/delete-icon.svg'}/></IconButton>}
          </Box>

          <Box display={'flex'}  alignItems={'center'} gap={1}>
            <FormSelect className={'mb-0'}
                        label={'State'}
                        value={state}
                        onChange={event => {
                          setState(event.target.value as string)
                        }}
            >
              <MenuItem value={''}>-</MenuItem>
              {GetLicensedStates().sort().map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
            </FormSelect>
            <FormSelect className={'mb-0'}
                        label={'Timezone'}
                        value={timezone}
                        onChange={event => {
                          setTimezone(event.target.value as any)
                        }}
            >
              <MenuItem value={''}>-</MenuItem>
              <MenuItem value={ClientViewTimeZone.Akst}>{ClientViewTimeZone.Akst}</MenuItem>
              <MenuItem value={ClientViewTimeZone.Cst}>{ClientViewTimeZone.Cst}</MenuItem>
              <MenuItem value={ClientViewTimeZone.Est}>{ClientViewTimeZone.Est}</MenuItem>
              <MenuItem value={ClientViewTimeZone.Hst}>{ClientViewTimeZone.Hst}</MenuItem>
              <MenuItem value={ClientViewTimeZone.Mst}>{ClientViewTimeZone.Mst}</MenuItem>
              <MenuItem value={ClientViewTimeZone.Pst}>{ClientViewTimeZone.Pst}</MenuItem>
            </FormSelect>
          </Box>
        </div>
        <div className={'mh-16'}>
          <div>
            <FormControlLabel
              className={'mr-10 no-wrap'}
              control={
                <Checkbox
                  checked={iep}
                  onChange={e => setIep(e.target.checked)}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Initial Enrollment Period"
            />
            <FormControlLabel
              className={'mr-10 no-wrap'}
              control={
                <Checkbox
                  checked={excludeBadNumbers}
                  onChange={e => {
                    setExcludeBadNumbers(e.target.checked)
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Exclude Bad Numbers"
            />
          </div>
          <div>
            <FormControlLabel
              className={'mr-10 no-wrap'}
              control={
                <Checkbox
                  checked={excludeCalledToday}
                  onChange={e => {
                    setExcludeCalledToday(e.target.checked)
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Exclude Called Today"
            />
            <FormControlLabel
              className={'mr-10 no-wrap'}
              control={
                <Checkbox
                  checked={excludeDoNotCall}
                  onChange={e => {
                    setExcludeDoNotCall(e.target.checked)
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Exclude Do Not Call"
            />
          </div>
        </div>
        <Button variant={'contained'} color={'primary'} onClick={onSearch}>Search</Button>
      </Box>
    </Box>
    <div style={{ height: '80vh', width: '100%' }}>
      <DataGrid rows={data?.attemptedLeads?.data ? data?.attemptedLeads?.data.map(i => ({...i, id: i.clientId})) : []}
                paginationMode={"server"}
                sortingMode={'server'}
                columns={columns}
                paginationModel={{
                  page: pageInput.page,
                  pageSize: pageInput.size
                }}
                loading={loading}
                onPaginationModelChange={model => {
                  setPageInput({page: model.page, size: model.pageSize});
                }}
                onRowClick={param => history.push(paths.client + '/' + param.row.clientId)}
                sortModel={sorting ? [sorting] : []}
                onSortModelChange={(sortModel) => {
                  setSorting(sortModel[0])
                }}
                pageSizeOptions={[20, 50, 100]}
                rowCount={+data?.attemptedLeads?.totalElements || 0}
                pagination
      />
    </div>
  </Card>;
}

export default AttemptedLeads;


const GroupView = ({group}: {group?: AttemptedLeadGroupDto}) => <Box display={'flex'} alignItems={'center'}>
  <Typography variant={'body1'} color={'textPrimary'}>{mapString(group)}</Typography>
</Box>

const mapString = (group?: AttemptedLeadGroupDto) => {
  switch (group) {
    case AttemptedLeadGroupDto.CalledToday: return 'CalledToday';
    case AttemptedLeadGroupDto.Group_1: return 'Group 1';
    case AttemptedLeadGroupDto.Group_2: return 'Group 2';
    case AttemptedLeadGroupDto.Group_3: return 'Group 3';
    case AttemptedLeadGroupDto.Group_4: return 'Group 4';
    case AttemptedLeadGroupDto.Group_5: return 'Group 5';
    case AttemptedLeadGroupDto.Group_6: return 'Group 6';
    case AttemptedLeadGroupDto.Group_7: return 'Group 7';
  }
}
