import React from "react";
import Card from "../../../../shared/Card";
import {
  Typography
} from "@mui/material";
import {DoctorSelection} from "./ui/DoctorSelection";
import {ClientViewOutput} from "../../../../enrollment-types";
import {useSaveDoctors} from "./ui/DoctorSelection/hooks/use-save-doctors";


interface UserPrioritiesProps {
  client?: ClientViewOutput
}

const Doctors = ({client}: UserPrioritiesProps) => {
  const saveDoctors = useSaveDoctors(client);

  const doctors = client?.doctors || [];

  return <Card>
    <Typography variant={'h4'} className={'mb-5'}>Doctors:</Typography>
    {client && (
      <DoctorSelection zip={client?.zip || ''}
                       county={client?.countyName || ''}
                       selectedDoctors={doctors}
                       onChange={v => saveDoctors(v)} />
    )}
  </Card>;
}

export default Doctors;

