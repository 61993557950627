import {MedicareEnrollmentType} from "../../../../../enrollment-types";

export const getEnrollmentTypeTitle = (type: MedicareEnrollmentType): string => ({
  [MedicareEnrollmentType.MaNew]: 'MA - New-to-CMS',
  [MedicareEnrollmentType.MaUnlikeChange]: 'MA - Unlike plan change / Yr 1 Change',
  [MedicareEnrollmentType.MaReplacement]: 'MA - Replacement / \'Like\' plan change'
}[type])

export const getEnrollmentTypeSubtitle = (type: MedicareEnrollmentType): string => ({
  [MedicareEnrollmentType.MaNew]: 'New enrollments (i.e., age-in, New to Medicare, or enrolls from Orignal Medicare getting MA for first time)',
  [MedicareEnrollmentType.MaUnlikeChange]: 'PDP > MAPD or MAPD > PDP // PDP > PDP or MAPD > MAPD (Yr 1 Change)',
  [MedicareEnrollmentType.MaReplacement]: 'Like plan change in Year 2+'
}[type])

export const getEnrollmentTypeDescription = (type: MedicareEnrollmentType): string => ({
  [MedicareEnrollmentType.MaNew]: 'Initial Commissions is paid for the first year product is in effect, followed by Renewal Rate Commission in subsequent calednar years (monthly basis)',
  [MedicareEnrollmentType.MaUnlikeChange]: 'Commission on original Medicare Product will be charged back according to Rapid Disenrollment of LT Disenrollment.  Inital Commission is paid on a pro-rated basis based on effective date.  Renewal Rate Commission is paid in subsequent calendar years',
  [MedicareEnrollmentType.MaReplacement]: 'Commission on original Medicare Product will be charged back according to Rapid Disenrollment of LT Disenrollment. Renewal Rate Commission is paid on a pro-rated basis based on effective date and then Renewal Rate Commission is paid in subsequent calendar years'
}[type])

