// @flow
import * as React from 'react';
import {Box, Button, Divider, MenuItem, Stack, Typography} from '@mui/material';
import { useMemo, useState } from 'react';
import {DrugInfoWithPackagesFlags} from "../../../../../types";
import FormSelect from "../../../../../shared/FormSelect";

type Props = {
  info?: DrugInfoWithPackagesFlags | null;
  onClose: (switchTo?: { name: string; rxcui: string }) => void;
};

export function DrugSelectionModalNoPackages(props: Props) {
  const [brandedRxcui, setBrandedRxcui] = useState((props.info?.brandedDetails[0]?.rxcui as string) || '');

  const hasPackages =
    props.info?.hasBrandedPackages || props.info?.hasGenericPackages;

  const brandedDetails = props.info?.brandedDetails || [];
  const isGeneric = props.info?.isGeneric;

  const switchTo = useMemo(() => {
    if (isGeneric) {
      if (brandedDetails.length === 1) {
        return {
          rxcui: brandedDetails[0].rxcui as string,
          name: brandedDetails[0].name,
        };
      } else {
        const drug = brandedDetails.find(
          (d) => d.rxcui === brandedRxcui
        );
        if (drug) {
          return { rxcui: drug.rxcui, name: drug.name };
        } else {
          return undefined
        }
      }
    }
    return {
      rxcui: props.info?.genericRxcui as string,
      name: props.info?.genericName as string,
    }
  }, [isGeneric, brandedDetails, brandedRxcui]);

  if (!props.info) {
    return null;
  }

  const handleSwitchClick = () => {
    props.onClose(switchTo);
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
      <Typography variant={'h5'}>{props.info?.name}</Typography>
      <Typography>isn’t currently available</Typography>

      {hasPackages && <>
        {brandedDetails.length <= 1 && <>
          <Typography>
            It has a lower cost {isGeneric ? 'branded' : 'generic'} version called:
          </Typography>
          <Typography variant={'h5'}>{(isGeneric ? brandedDetails[0].name : props.info.genericName) || ''}</Typography>
        </>}

        {brandedDetails.length > 1 && (
          <BrandedDrugSelect items={brandedDetails} value={brandedRxcui} onChange={setBrandedRxcui} />
        )}

        <Divider sx={{my: 1}} />

        <Typography variant={'h4'}>
          Would you like to add the{' '}
          {isGeneric ? 'branded' : 'generic'} version to your
          list instead?
        </Typography>
      </>}

      {!hasPackages && (
        <Typography variant={'h4'} sx={{my: 2}}>
          Speak to one our advisors if this drug is important to you.
        </Typography>
      )}


      <Box sx={{display: 'flex',  flex: 1, gap: 1.5, flexDirection: {xs: 'column', sm: 'row'}, alignItems: { sm: 'center' }, justifyContent: 'flex-end'}}>
        <Button size={'large'} variant={'outlined'} onClick={() => props.onClose()}>Select other drug</Button>

        {hasPackages && <Button size={'large'} variant={'contained'}
                                data-test={'no_package_drug_button'}
                                onClick={handleSwitchClick}>
          Add {isGeneric ? 'branded' : 'generic'} drug
        </Button>}
      </Box>

    </Box>
  );
}

const BrandedDrugSelect = ({items, value, onChange}: { value: string, onChange: (value: string) => void, items: {rxcui: string, name: string}[]}) => (
  <FormSelect
    fullWidth
    label={
      'You can instead select from the following branded drugs:'
    }
    value={value}
    onChange={(event) => {
      onChange(event.target.value as string);
    }}
  >
    {items.map((drug) => (
      <MenuItem
        key={drug.rxcui}
        value={drug.rxcui}
        className={'regular'}
      >
        {drug.name}
      </MenuItem>
    ))}
  </FormSelect>
)
