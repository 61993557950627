// @flow
import * as React from 'react';
import { useEffect, useState } from 'react';
import {Box, CircularProgress, Dialog, IconButton, Stack, Typography} from '@mui/material';
import { DrugSelectionModalSearch } from './drug-selection-modal-search';
import { Drug, DrugDosage, hasNoPackages, toOutput } from './lib';
import { DrugSelectionModalDosage } from './drug-selection-modal-dosage';
import _ from 'lodash';
import { DrugSelectionModalBranded } from './drug-selection-modal-branded';
import { DrugSelectionModalNoPackages } from './drug-selection-modal-no-packages';
import {
  DrugOutput,
  useDrugPackagesInfoLazyQuery,
  useDrugsWithPackageLazyQuery,
  usePackagesQuery
} from "../../../../../types";
import {client} from "../../../../../Apollo/ApolloClient";

enum Mode {
  Search,
  NoPackages,
  SelectGenericOrBranded,
  SelectDosage,
  EditDrug,
}

type Props = {
  open: boolean;
  onClose: (drug?: DrugOutput) => void;
  drug?: DrugOutput;
  zip: string;
};

export function DrugSelectionModal(props: Props) {
  const [mode, setMode] = useState(Mode.Search);
  const [selectedDrug, setSelectedDrug] = React.useState<Drug>();
  const [getPackagesInfo, {data: packagesInfoData}] = useDrugPackagesInfoLazyQuery({client})
  const [getDrugsWithPackage] = useDrugsWithPackageLazyQuery({client});
  const [loading, setLoading] = useState(false);

  const {data: packagesData} = usePackagesQuery({
    variables: {rxcui: selectedDrug?.rxcui as string},
    skip: !selectedDrug?.rxcui,
    client,
  });
  const packages = packagesData?.packages;

  useEffect(() => {
    if (props.open) {
      if (props.drug) {
        setMode(Mode.EditDrug);
        setSelectedDrug({
          ..._.omit(props.drug, '__typename'),
          dosage: props.drug.packRxcui
        });
      }
    } else {
      setSelectedDrug(undefined);
      setMode(Mode.Search)
    }
  }, [props.open, props.drug]);

  const handleSelectDrug = async (drug: Drug) => {
    setLoading(true)
    setSelectedDrug(drug);
    const packRes = await getPackagesInfo({variables: {rxcui: drug.rxcui as string} });
    setLoading(false);

    if (hasNoPackages(!!drug.isGeneric, packRes.data?.drugPackagesInfo)) {
      setMode(Mode.NoPackages);
    } else {
      const hasGeneric = !drug.isGeneric && drug.genericName && packRes.data?.drugPackagesInfo?.hasGenericPackages;
      // show generic select modal for branded drugs who has generics
      if (hasGeneric) {
        setMode(Mode.SelectGenericOrBranded);
      } else {
        if (drug.name && drug.rxcui) {
          setMode(Mode.SelectDosage);
        }
      }
    }
  };

  const handleDosageSelect = async (dosage?: DrugDosage) => {
    if (dosage && selectedDrug?.rxcui) {
      const drugsWithPackage = await getDrugsWithPackage({
        variables: {
          pairs: [
            { packageRxcui: dosage.dosage as string, productRxcui: selectedDrug.rxcui },
          ]
        }
      }).then(res => res.data?.drugsWithPackage || []);
      props.onClose(toOutput(dosage, drugsWithPackage[0]));
    }
    setSelectedDrug(undefined);
    setMode(Mode.Search);
  }

  const handleBrandedSelect = (useGeneric: boolean) => {
    if (useGeneric) {
      setSelectedDrug(prev => ({
        ...prev,
        name: prev?.genericName,
        rxcui: prev?.genericRxcui
      }))
    }
    setMode(Mode.SelectDosage);
  }

  const handleNoPackagesClose = (switchTo?: { name: string; rxcui: string }) => {
    if (switchTo) {
      setSelectedDrug(prev => ({
        ...prev,
        name: switchTo.name,
        rxcui: switchTo.rxcui
      }));
      setMode(Mode.SelectDosage);
    } else {
      setSelectedDrug(undefined);
      setMode(Mode.Search);
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      open={props.open}
      onClose={() => props.onClose()}
    >
      <Box p={3} position={'relative'}>
        {loading && <Loading />}
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
          <Typography variant={'h3'}>{getTitle(mode)}</Typography>
          <IconButton onClick={() => props.onClose()}>
            <img className={'w-32 h-32'} src={'/img/X.svg'}/>
          </IconButton>
        </Stack>

        {mode === Mode.Search && (
          <DrugSelectionModalSearch onSelect={handleSelectDrug}  />
        )}

        {(mode === Mode.SelectDosage || mode === Mode.EditDrug) && selectedDrug && (
          <DrugSelectionModalDosage drug={selectedDrug}
                                    packages={packages}
                                    addDrugButtonLabel={mode === Mode.EditDrug ? 'Update drug' : 'Add drug'}
                                    onClose={handleDosageSelect} />
        )}

        {mode === Mode.SelectGenericOrBranded && selectedDrug && (
          <DrugSelectionModalBranded drug={selectedDrug} onSelect={handleBrandedSelect} />
        )}

        {mode === Mode.NoPackages && selectedDrug && (
          <DrugSelectionModalNoPackages info={packagesInfoData?.drugPackagesInfo} onClose={handleNoPackagesClose}  />
        )}
      </Box>

    </Dialog>
  )
}

const getTitle = (mode: Mode) => {
  switch (mode) {
    case Mode.SelectDosage: return 'Enter dosage and frequency';
    case Mode.SelectGenericOrBranded: return 'Generic version available';
    case Mode.NoPackages: return 'Drug is not available';
    default: return 'Search drug';
  }
}

const Loading = () => (
  <Box sx={{
    position: 'absolute',
    left: 0, top: 0, right: 0, bottom: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.36)'}}>
    <CircularProgress />
  </Box>
)

