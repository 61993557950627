import {ApolloClient, from, InMemoryCache} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import {setContext} from "@apollo/client/link/context";
import {getToken} from "./ApolloClient";
import {ErrorResponse, onError} from "@apollo/client/link/error";
import {errorTracker, graphQLErrorHandler} from "../GraphQLErrorHandler";

const httpLink = createUploadLink({ uri: process.env.REACT_APP_ENROLLMENT_GRAPHQL })

const authLink = setContext(async (_, { headers }: any) => {
  const token = await getToken().catch(() => {});
  if (token) {
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
        ...headers,
      }
    };
  } else {
    return {
      headers
    };
  }
});

const elink = onError((errResponse: ErrorResponse) => {
  try {
    getToken().then(token => errorTracker(errResponse, token as any)).catch(() => errorTracker(errResponse));
  } catch (e) {
    errorTracker(errResponse);
  }
  if (graphQLErrorHandler) {
    graphQLErrorHandler(errResponse);
  }
});

const requestLink =
  from([
    authLink,
    elink,
    httpLink as any,
  ])
;

export const uploadClient = new ApolloClient({
  link: requestLink,
  cache: new InMemoryCache({
    addTypename: false
  })
});
