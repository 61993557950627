import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import CloseIcon from "@mui/icons-material/Close";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    refreshButton: {
      position: 'absolute',
      top: 8,
      right: 5,
      zIndex: 1000,
    },
    disabled: {
      opacity: 0.6,
    },
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: '#666',
    },
    transcription: {
      whiteSpace: 'pre-line',
    }
  }),
);

export const TranscriptionButton = ({transcription}: {transcription?: string}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (!transcription) {
    return null;
  }

  return <>
    <IconButton component="span" onClick={handleClickOpen}>
      <ViewHeadlineIcon fontSize={'small'} />
    </IconButton>
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title">
        Audio transcription
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography className={classes.transcription} dangerouslySetInnerHTML={{__html: transcription as string}} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  </>
}