import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  PageableClientViewOutput,
  QueryContactedArgs
} from "../../enrollment-types";

export default function useContacted(options?: LazyQueryHookOptions<{contacted: PageableClientViewOutput}, QueryContactedArgs>) {
  return useLazyQuery<{contacted: PageableClientViewOutput}, QueryContactedArgs>(gql(query), {
    fetchPolicy: 'no-cache',
    ...options
  });
}

const query = `
  query ($agentId: UUID!, $pageInput: PageInput!, $sort: ClientViewSortInput) {
    contacted(agentId: $agentId, pageInput: $pageInput, sort: $sort) {
      data {
        id
        firstName
        lastName
        createdAt
        state
        followUpDate
        watchers {
          id
        }
        tags {
          expiresAt
          tag {
            color
            id
            name
          }
        }
      }
      totalElements
      isLast
    }
  }
`
