import {IconButton, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
import paths from "../../../../../router-paths";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface Props {
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  id?: string | null,
  error?: boolean,
  onClick?: () => void,
  onIconClick?: () => void,
  isLink?: boolean,
  disableGutters?: boolean,
  dense?: boolean,
}

export const ClientGroupLine = (props: Props) => {
  const {firstName, lastName, email, error, onClick, onIconClick, id, isLink, disableGutters, dense} = props;
  const Component: any = onClick ? ListItemButton : ListItem;
  return <Component style={{backgroundColor: error ? 'rgba(255,0,0,0.2)' : undefined}} disableGutters={disableGutters}
                    dense={dense} onClick={onClick}>
    <ListItemText primary={isLink ? <Link to={paths.client + '/' + id}>{firstName} {lastName}</Link> :
      <span>{firstName} {lastName}</span>}
                  secondary={email}
    />
    {onIconClick && <ListItemSecondaryAction>
        <IconButton size={'small'} onClick={onIconClick}>
            <CloseIcon fontSize={'small'} color={'error'}/>
        </IconButton>
    </ListItemSecondaryAction>}

  </Component>
}
