import {Conversation} from "@twilio/conversations";
import {useEffect, useState} from "react";
import {checkNewMessages} from "./lib";
import * as _ from "lodash";

export default function useBadgesForChats(conversations: Conversation[]) {
  const [badges, setBadges] = useState<string[]>([])

  const updateBadges = ({conversation}: any) => {
    checkNewMessages(conversation)?.then(payload => {
      setBadges(prev => payload ? [..._.without(prev, conversation.sid), conversation.sid] : _.without(prev, conversation.sid))
    })
  }

  const participantUpdatedHandler = ({participant}: any) => {
    updateBadges({conversation: participant.conversation})
  }

  useEffect(() => {
    if (conversations.length) {
      for (const conversation of conversations) {
        updateBadges({conversation})
        conversation.on('updated', updateBadges)
        conversation.on('participantUpdated', participantUpdatedHandler)
      }
    }
    return () => {
      if (conversations.length) {
        for (const conversation of conversations) {
          conversation.off('updated', updateBadges)
          conversation.off('participantUpdated', participantUpdatedHandler)
        }
      }
    }
  }, [conversations]);

  return badges;
}