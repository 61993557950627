import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  AgentPerformanceMetricOutput,
  QueryAgentPerformanceAnalyticsArgs
} from "../../enrollment-types";

export default function useAgentPerformanceAnalytics(options?: LazyQueryHookOptions<{agentPerformanceAnalytics: Array<AgentPerformanceMetricOutput>}, QueryAgentPerformanceAnalyticsArgs>) {
  return useLazyQuery<{agentPerformanceAnalytics: Array<AgentPerformanceMetricOutput>}, QueryAgentPerformanceAnalyticsArgs>(gql(query), {...options, fetchPolicy: 'no-cache'});
}

const query = `
query($agentId: UUID!) {
  agentPerformanceAnalytics(agentId: $agentId) {
    primaryKey
    secondaryKey
    value
  }
}
`;
