import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveAgentGmailCodeArgs} from "../../enrollment-types";

export default function useSaveAgentGmailCode(options?: MutationHookOptions<any, MutationSaveAgentGmailCodeArgs>) {
  return useMutation<any, MutationSaveAgentGmailCodeArgs>(gql(saveMutation), options)
}

const saveMutation = `
mutation ($code: String!) {
  saveAgentGmailCode(code: $code)
}
`;
