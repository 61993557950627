import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";

export default function useAgentGmailAuthUrl(options?: LazyQueryHookOptions<{agentGmailAuthUrl: string}>) {
  return useLazyQuery<{agentGmailAuthUrl: string}>(gql(query), {
    fetchPolicy: 'no-cache',
    ...options
  });
}

const query = `
query {
  agentGmailAuthUrl
}
`
