import {
  Box, Tab, Tabs
} from "@mui/material";
import React from "react";
import Card from "../../shared/Card";
import {MaPlanCoverage} from "./MaPlanCoverage";
import {PdpPlanCoverage} from "./PdpPlanCoverage";

export function Page() {
  const [value, setValue] = React.useState(0);

  return <Box display={'flex'} flex={1}>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>

      <Card padding={0}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, newValue) => setValue(newValue)}
        >
          <Tab label="Medicare Advantage" />
          <Tab label="PDP" />
        </Tabs>
        {value === 0 && <MaPlanCoverage />}
        {value === 1 && <PdpPlanCoverage />}
      </Card>

    </Box>
  </Box>
}




