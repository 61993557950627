import {EnrollmentType, useAllEnrollmentsQuery} from "../../../../enrollment-types";

const useClientEnrollments = (createdByAdmin: boolean, clientId?: string) => {
  const {data, loading, refetch} = useAllEnrollmentsQuery({
    variables: {page: {size: 100, page: 0}, filter: {clientId, createdByAdmin}},
    skip: !clientId,
    fetchPolicy: 'no-cache'
  })

  const allEnrollments = data?.allEnrollments.data || [];

  return {
    maEnrollments: allEnrollments.filter(e => e.type === EnrollmentType.Ma),
    mgEnrollments: allEnrollments.filter(e => e.type === EnrollmentType.Mg),
    pdpEnrollments: allEnrollments.filter(e => e.type === EnrollmentType.Pdp),
    loading,
    refetch
  }
}

export default useClientEnrollments;