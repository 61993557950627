import Card from "../../../../shared/Card";
import {Box, Button, Grid, Typography} from "@mui/material";
import React from "react";
import {config} from "../../../../config/config";
import {
  FormName,
  MedicareQuoteFilterExtraBenefit,
  PlanYear,
  useCreateMedicareQuoteForClientMutation,
  useMedicareQuoteQuery
} from "../../../../types";
import {enqueueSnackbar} from "notistack";
import {ClientViewOutput} from "../../../../enrollment-types";
import moment from "moment";
import * as _ from "lodash";
import Param from "../../../../shared/Param";
import {client as apolloClient} from "../../../../Apollo/ApolloClient";
import {QuoteYear} from "./ui/quote-year";

interface QuoteAnswersProps {
  pin?: string
  client?: ClientViewOutput,
  county?: string
}

const MedicareQuote = ({client, county, pin}: QuoteAnswersProps) => {
  const [createQuote] = useCreateMedicareQuoteForClientMutation({client: apolloClient});
  const {data} = useMedicareQuoteQuery({
    client: apolloClient,
    skip: !client?.quoteId,
    variables: { id: client?.quoteId },
    fetchPolicy: 'cache-and-network'
  });
  const quote = data?.medicareQuote;

  const quoteUrl = (year: PlanYear) => {
    return `${config.resultsUrl}plans/${client?.zip}/${county}?clientId=${client?.id}&resetFilters=true`
  }

  const create = () => {
    if (client) {
      createQuote({
        variables: {
          clientId: client.id,
          data: {
            county: client.countyName,
            zip: client.zip,
            planYear: PlanYear.Year2025,
            filters: {
              extraBenefits: [MedicareQuoteFilterExtraBenefit.DrugCoverage],
            },
          }
        }
      })
    }
  };

  return <Card sx={{flex: 1}}>
    <div className={'flex relative'}>
      <QuoteYear quote={quote} />
      <Typography color={'textPrimary'} variant={'h4'} className={'mb-16'}>Medicare quote</Typography>
    </div>
    {!!quote && <Grid container spacing={2}>
      <Grid item sm={12} md={12}>
        <Param className={'flex-column'} label={'createdAt'} value={moment(quote?.createdAt).format('L H:mm:ss')}/>
      </Grid>
      <Grid  item sm={12} md={6}>
        <Param className={'flex-column'} label={'zip'} value={quote?.zip}/>
      </Grid>
      <Grid  item sm={12} md={6}>
        <Param className={'flex-column'} label={'zip'} value={quote?.county}/>
      </Grid>
      {quote?.filters && Object.keys(quote.filters).filter((key: any) => (quote as any).filters[key]?.length > 0)
        .map((key: string) => <Grid key={key} item sm={12} md={12}>
          <Param className={'flex-column'} label={_.lowerCase(key).toUpperCase()} value={getValue(key, quote.filters)}/>
        </Grid>)}
    </Grid>}
    <Box>
      {(!!client && !client?.quoteId) && <Button onClick={create}
                                                 className={'mb-10'}
                                                 variant={'contained'}
                                                 color={'primary'}>Create quote</Button>}
      <Button disabled={!client?.quoteId}
              href={quoteUrl(PlanYear.Year2022)}
              target={'_blank'}
              className={'mb-10'}
              variant={'contained'}
              color={'primary'}>Open quote</Button>
      {client?.quoteId && <Typography onClick={() => {
        navigator.clipboard.writeText(`${config.resultsUrl}?quote=${client?.quoteId}`)
        enqueueSnackbar('Copied!', {variant: "info"});
      }} className={'pointer fs-12 mb-10 underline'} color={'textPrimary'}>Copy link</Typography>}
      {client?.agentMedicareQuote?.quoteId && <Typography onClick={() => {
        navigator.clipboard.writeText(`${config.resultsUrl}?adminQuote=${client?.agentMedicareQuote?.quoteId}`)
        enqueueSnackbar('Copied!', {variant: "info"});
      }} className={'pointer fs-12 mb-10 underline'} color={'textPrimary'}>Copy offer link</Typography>}
    </Box>

  </Card>
}

export default MedicareQuote;

const getValue = (key: string, request: any) => {
  if (!request)
    return '';
  if (Array.isArray(request[key]) && request[key].length === 0) {
    return '-'
  }
  if (Array.isArray(request[key])) {
    return (request[key] as any).join(', ')
  }
  if (request[key] === null || typeof request[key] === 'undefined') {
    return '-'
  }
  if (_.isBoolean(request[key])) {
    return !!request[key] ? 'Yes' : 'No';
  }
  return request[key];
}
