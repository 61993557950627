import {Box, IconButton} from "@mui/material";
import React from "react";
import {enqueueSnackbar} from "notistack";
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import {useShortenedCostActivationLinkQuery} from "../../../enrollment-types";

export default function CustomerIoLinkButton({clientId}: {clientId: string}) {
  const {data} = useShortenedCostActivationLinkQuery({
    skip: !clientId,
    variables: {clientId}
  })

  const link = data?.shortenedCostActivationLink

  const onClick = async () => {
    if (link) {
      await navigator.clipboard.writeText(link);
      enqueueSnackbar('Copied!', {variant: "info"});
    }
  }

  if (!link) {
    return null
  }

  return <Box className={'flex'}>
    <a style={{color: '#242424'}} href={link} className={'pointer fs-16 mr-8 underline'}>{link}</a>
    <IconButton size={'small'} onClick={onClick}>
      <FilterNoneIcon fontSize={'small'} />
    </IconButton>
  </Box>
}
