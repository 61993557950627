import {
  Box,
  Card,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem, ListItemIcon, ListItemText,
  ListSubheader,
  Typography
} from "@mui/material";
import {
  AgentOutput,
  AssignmentHistoryOutput,
  AssignmentHistoryType,
  ClientViewOutput,
  useAssignmentHistoryQuery,
  useSaveClientWatchersMutation,
} from "../../../../../enrollment-types";
import React, {useContext, useState} from "react";
import moment from "moment";
import AgentSelector from "../../../../../Agent/AgentSelector";
import {getUserName} from "../../../../../shared/tools";
import * as _ from 'lodash';

interface ClientListFilterDrawerProps {
  open: boolean,
  onClose: (watchers: AgentOutput[]) => void,
  history?: AssignmentHistoryOutput[],
  watchers: AgentOutput[],
}

interface Props {
  setOpen: (open: boolean) => void;
}

const AssignmentHistoryDrawerContext = React.createContext<Props>({
  setOpen: (open: boolean) => {},
})

export function withAssignmentHistoryDrawer<P extends {
  client?: ClientViewOutput
}>(WrappedComponent: React.ComponentType<P>) {
  return (props: P) => {
    const [assignmentHistoryOpen, setAssignmentHistoryOpen] = useState(false);
    const [saveClientWatchers] = useSaveClientWatchersMutation()
    const {refetch, data} = useAssignmentHistoryQuery({
      variables: {clientId: props.client?.id},
      skip: !props.client?.id,
      fetchPolicy: "no-cache",
    })

    const assignmentHistory = _.orderBy(data?.assignmentHistory || [], 'assignmentDate', 'desc');

    if (!props.client?.id) {
      return <WrappedComponent {...props} />;
    }

    const onClose = (watchers: AgentOutput[]) => {
      setAssignmentHistoryOpen(false)
      if (!_.isEqual(watchers.map(a => a.id).sort(), props.client?.watchers.map(a => a.id).sort())) {
        saveClientWatchers({
          variables: {
            input: {
              clientId: props.client?.id,
              agentIds: watchers.map(w => w.id)
            }
          }
        })
          .then(() => refetch())
      }
    }

    const setOpen = (open: boolean) => {
      if (open) {
        refetch();
      }
      setAssignmentHistoryOpen(open)
    }

    return <AssignmentHistoryDrawerContext.Provider value={{setOpen}}>
      <AssignmentHistoryDrawer open={assignmentHistoryOpen}
                               onClose={onClose}
                               watchers={props.client?.watchers as AgentOutput[] || []}
                               history={assignmentHistory}/>
      <WrappedComponent {...props} />
    </AssignmentHistoryDrawerContext.Provider>
  }
}

export const useAssignmentHistoryDrawer = () => {
  return useContext(AssignmentHistoryDrawerContext);
}

function AssignmentHistoryDrawer({open, onClose, history, watchers: initialWatchers}: ClientListFilterDrawerProps) {
  const [watchers, setWatchers] = useState<Set<AgentOutput>>(new Set(initialWatchers));

  return <Drawer
    anchor={'right'}
    open={open}
    onClose={() => onClose(Array.from(watchers.values()))}
  >
    <Card sx={{p: 4, width: 594, overflowY: 'auto'}}>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography variant={'h3'}>Assignment History</Typography>
        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <IconButton size={'small'} onClick={() => onClose(Array.from(watchers.values()))}>
            <img className={'w-32 h-32'} src={'/img/X.svg'}/>
          </IconButton>
        </Box>
      </Box>

      <Divider sx={{mb: 3}} />

      <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
        <Typography className={'no-wrap'} variant={'h5'}>Add watcher</Typography>
        <AgentSelector onChange={() => {}}
                       className={'mb-0'}
                       onChangeAgent={v => setWatchers(prev => new Set([...prev, v as AgentOutput]))} />
      </Box>

      {!!watchers.size && <>
        <Divider sx={{my: 3}} />

        <List
          sx={{ width: '100%', maxWidth: 360 }}
          subheader={<ListSubheader>Watcher List</ListSubheader>}
        >
          {Array.from(watchers?.values()).map(item => <ListItem sx={{gap: 2}} key={item.id} secondaryAction={
            <IconButton size={'small'} className={'ml-10'} onClick={() => setWatchers(new Set(Array.from(watchers.values()).filter(a => a.id !== item.id)))}>
              <img src={'/img/delete-icon.svg'}/>
            </IconButton>
          }>
            <ListItemText primary={getUserName(item)} secondary={item.email}/>
          </ListItem>)}
        </List>
      </>}

      <Divider sx={{my: 3}} />

      <List
        sx={{ width: '100%', maxWidth: 360 }}
        subheader={<ListSubheader>Assignment History</ListSubheader>}
      >
        {history?.map(item => <ListItem sx={{gap: 2}} key={item.assignmentDate}>
          <ListItemIcon>
            <Typography sx={{color: getAssignmentHistoryTypeLabelColor(item.assignmentType)}} className={'semi-bold'}>{_.startCase(item.assignmentType.toLowerCase())}</Typography>
          </ListItemIcon>
          <ListItemText primary={item.agentName} secondary={moment(item.assignmentDate).format("L H:mm:ss")}/>
        </ListItem>)}
      </List>
    </Card>
  </Drawer>
}

const getAssignmentHistoryTypeLabelColor = (type: AssignmentHistoryType): string => {
  switch (type) {
    case AssignmentHistoryType.Watch: return '#1bc7d7';
    case AssignmentHistoryType.Unwatch: return '#ce8142';
    case AssignmentHistoryType.ClearAssign: return '#d14040';
    default: return '#00cb09'
  }
}
