import {Box, Divider} from "@mui/material";
import Message from "./message";
import React, {MutableRefObject, useEffect, useMemo, useRef} from "react";
import {ChatMessage, MessageMenuType} from "../lib/types";
import * as _ from 'lodash';

interface Props {
  messages: ChatMessage[],
  directionDetect: (m: ChatMessage) => "outgoing" | "incoming",
  lastReadIndex: number,
  onMenuClick: (type: MessageMenuType, message: ChatMessage) => void
}

export default function MessageList({messages, directionDetect, lastReadIndex, onMenuClick}: Props) {
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const readHorizonRef: MutableRefObject<HTMLHRElement | null> = useRef(null);

  const grouped = useMemo(() => {
    return _.groupBy(messages, m => m.dateCreated?.toDateString())
  }, [messages]);

  useEffect(() => {
    if (ref?.current) {
      if (readHorizonRef.current) {
        ref.current.scrollTop = readHorizonRef.current?.offsetTop - 20;
      } else {
        ref.current.scrollTop = ref.current.children[0].clientHeight;
      }
    }
  }, [ref, readHorizonRef, messages]);

  return <Box ref={ref} sx={{width: '100%', height: '60vh', overflowY: 'auto', pr: .5}}>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, position: 'relative'}}>
      {Object.entries(grouped).map(([key, data]) => (
        <React.Fragment key={key}>
          <Divider textAlign={'center'} sx={{
            background: '#F8F6F2',
            position: 'sticky',
            top: 0,
          }}>{key}</Divider>
          {data.map(m => (
            <React.Fragment key={m.dateCreated?.getTime()}>
              {m.index === lastReadIndex + 1 && (
                <Divider ref={readHorizonRef} id={'read-horizon'} textAlign={'center'} sx={{
                  color: 'red',
                }}>Unread messages</Divider>
              )}
              <Message message={m}
                       onMenuClick={type => onMenuClick(type, m)}
                       direction={directionDetect(m)} />
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </Box>
  </Box>
}
