// @flow
import * as React from 'react';
import {
  Box,
  Button,
  Collapse,
  Divider,
  MenuItem,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { PurchaseFrequencySelect } from './purchase-frequency-select';
import { FrequencySelect } from './frequency-select';
import { useLayoutEffect, useRef } from 'react';
import { Drug, DrugDosage } from './lib';
import {
  CalcDrugFrequencyPeriod,
  FrequencyPeriod,
  PackInfoOuput,
  useDrugsWithPackageQuery, usePackageDescriptionByRxcuiLazyQuery,
  usePackageDescriptionByRxcuiQuery
} from "../../../../../types";
import FormSelect from "../../../../../shared/FormSelect";
import TextInput from "../../../../../shared/TextInput";
import {client} from "../../../../../Apollo/ApolloClient";

type Props = {
  drug: Drug;
  packages?: PackInfoOuput[];
  onClose: (dosage?: DrugDosage) => void;
  addDrugButtonLabel: string;
};

const initialDosage: DrugDosage = {
  dosage: '',
  quantity: undefined,
  frequency: 'MONTHLY',
  purchaseFrequency: 'MONTHLY' as FrequencyPeriod,
};

export function DrugSelectionModalDosage(props: Props) {

  const [getDescription, {loading}] = usePackageDescriptionByRxcuiLazyQuery({client});
  const [description, setDescription] = React.useState<string>();

  const updatePackageDescription = async (rxcui: string) => {
    const res = await getDescription({ variables: { rxcui } });

    setDescription(res.data?.packageDescriptionByRxcui?.packageDescription || '');
    setUnitLabel(res.data?.packageDescriptionByRxcui?.unitType || '-');
  }

  const [unitLabel, setUnitLabel] = React.useState<string>('-');
  const ref = useRef<HTMLInputElement | null>(null);

  const [dosage, setDosage] = React.useState<DrugDosage>(() => {
    if (props.drug) {
      if (props.drug.dosage) {
        updatePackageDescription(props.drug.dosage);
      }
      return {
        dosage: props.drug.dosage,
        frequency: props.drug.frequency || 'MONTHLY',
        purchaseFrequency: props.drug.purchaseFrequency || FrequencyPeriod.Monthly,
        quantity: props.drug.quantity
      }
    }
    return initialDosage;
  });

  // preload drug info
  useDrugsWithPackageQuery({
    client,
    skip: !dosage.dosage || !props.drug.rxcui,
    variables: {
      pairs: [{ packageRxcui: dosage.dosage as string, productRxcui: props.drug.rxcui as string}],
    }
  })

  const handlePackageSelected = async (pack: string) => {

    setDosage({
      ...dosage,
      dosage: pack,
    });
    updatePackageDescription(pack);
  };

  useLayoutEffect(() => {
    if (props.packages?.length === 1 && props.packages[0].rxcui) {
      handlePackageSelected(props.packages[0].rxcui)
    }
  }, [props.packages]);

  const handleFrequencyChanged = (frequency: string) => {
    let purchaseFrequency: FrequencyPeriod | undefined;

    switch (frequency) {
      case CalcDrugFrequencyPeriod.Quarterly:
        purchaseFrequency = FrequencyPeriod.Quarterly;
        break;
      case CalcDrugFrequencyPeriod.HalfYearly:
        purchaseFrequency = FrequencyPeriod.HalfYearly;
        break;
      case CalcDrugFrequencyPeriod.Yearly:
        purchaseFrequency = FrequencyPeriod.Yearly;
        break;
      default:
        purchaseFrequency = dosage.purchaseFrequency;
    }

    setDosage({
      ...dosage,
      frequency,
      purchaseFrequency,
    });
  }

  useLayoutEffect(() => {
    if (description) {
      ref.current?.focus()
    }
  }, [description])

  return (
    <Stack spacing={2}>
      <Typography variant={'h4'}>{props.drug.name || ''}</Typography>

      <Divider />

      <FormSelect
        label={'Select dosage'}
        data-test={'dosage_select'}
        value={dosage?.dosage || ''}
        onChange={e => handlePackageSelected(e.target.value as string)}
      >
        {props.packages?.map((p) => (
          <MenuItem
            data-test={'dosage_select_item_' + p.rxcui}
            key={p.rxcui}
            value={p.rxcui as string}
            className={'regular'}
          >
            {p.name}
          </MenuItem>
        ))}
      </FormSelect>

      <Collapse in={!!dosage?.dosage} sx={{ width: 1 }}>
        <Box sx={{display: 'flex', gap: 2, width: 1, alignItems: { sm: 'flex-end' }, flexDirection: {xs: 'column', sm: 'row'} }}>
          <Box sx={{flex: 1}}>
            <Description description={description} loading={loading} />
          </Box>

          <Box sx={{flex: 1}}>
            <TextInput
              type={'number'}
              label={unitLabel}
              inputRef={ref}
              fullWidth
              className={'mb-0'}
              data-test={'unit_quantity'}
              placeholder={'Enter quantity'}
              value={dosage?.quantity || ''}
              error={(dosage?.quantity || 0) < 0}
              inputProps={{
                onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === 'Enter' && dosage.dosage && dosage.quantity) {
                    props.onClose(dosage)
                  }
                }
              }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setDosage({
                  ...dosage,
                  quantity: parseFloat(event.target.value as string),
                });
              }}
            />
          </Box>
        </Box>

        <Box sx={{display: 'flex', gap: 2, width: 1, flexDirection: {xs: 'column', sm: 'row'}, mt: 2}}>
          <FrequencySelect value={dosage?.frequency} onChange={handleFrequencyChanged} />

          <PurchaseFrequencySelect purchaseFrequency={dosage?.purchaseFrequency}
                                   frequency={dosage?.frequency}
                                   onChange={purchaseFrequency => {
                                     setDosage({
                                       ...dosage,
                                       purchaseFrequency,
                                     });
                                   }} />
        </Box>
      </Collapse>
      <Box sx={{display: 'flex', mt: 3, gap: 1.5, alignItems: 'center', justifyContent: 'flex-end'}}>
        <Button variant={'outlined'} onClick={() => props.onClose()}>Select other drug</Button>
        <Button variant={'contained'}
                onClick={() => props.onClose(dosage)}
                disabled={!dosage.dosage || (dosage.quantity || 0) <= 0}>{props.addDrugButtonLabel}</Button>
      </Box>
    </Stack>
  );
}

const Description = ({description, loading}: {description?: string, loading: boolean}) => {
  if (loading) {
    return <Skeleton variant={'rounded'} width={268} height={96} />
  }

  return <Box sx={{flex: 1, p: 1.5, borderRadius: '8px', border: '1px solid #E5E7EB'}}>
    <Typography variant={'h6'} sx={{mb: .5, color: '#216069'}}><b>PACKAGE CONTAINS:</b></Typography>
    <Typography><b>{description}</b></Typography>
  </Box>
}
