import {Divider, Card, Typography, TextField, Box, Button} from "@mui/material";
import React, {useState} from "react";


interface Props {
  onSubmit?: (text: string) => void
}

export default function InputArea({onSubmit}: Props) {
  const [value, setValue] = useState('')

  const submit = () => {
    onSubmit && onSubmit(value);
    setValue('');
  }

  return <Card sx={{p: '24px 24px 8px'}}>
    <Typography variant={'h4'} className={'fs-24 lh-30'}>Chat now</Typography>
    <Divider sx={{mt: 1, mb: 0}} />
    <TextField
      data-test='chat-input'
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
      autoFocus
      inputProps={{
        onKeyDown: e => {
          if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            submit();
          }
        }
      }}
      id="standard-multiline-static"
      multiline
      className={'mb-0'}
      rows={3}
      variant={"standard"}
    />
    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      <Button onClick={submit} data-test='chat-submit'>
        send
      </Button>
    </Box>
  </Card>
}
