import React, {useCallback} from "react";
import Card from "../../../../shared/Card";
import {
  IconButton,
  List,
  Typography
} from "@mui/material";
import {
  ClientGroupItemOutput,
  ClientViewOutput,
  useClientGroupByClientIdQuery,
  useDeleteClientsFromAnyGroupMutation,
  useMergeClientsInOneGroupMutation
} from "../../../../enrollment-types";
import * as _ from 'lodash';
import {ClientGroupLine} from "./ui/ClientGroupLine";
import {ClientGroupsForm} from "./ui/ClientGroupsForm";

interface ClientGroupsProps {
  client?: ClientViewOutput
}

const ClientGroups = ({client}: ClientGroupsProps) => {
  const [showForm, setShowForm] = React.useState(false);
  const [saveError, setSaveError] = React.useState();
  const [save, {error}] = useMergeClientsInOneGroupMutation();
  const [deleteClients] = useDeleteClientsFromAnyGroupMutation();

  const {data, refetch} = useClientGroupByClientIdQuery({
    variables: {clientId: client?.id},
    skip: !client?.id
  });


  const clients = data?.clientGroupByClientId?.clients || []

  const submit = async (data: ClientGroupItemOutput[]) => {
    const current = clients.map(c => c.clientId)
    const clientsToMerge = _.uniq([...data.map(c => c.clientId), client?.id]);
    const clientsToDelete = _.differenceBy(current, clientsToMerge);

    await save({variables: {clientIds: clientsToMerge}});
    if (clientsToDelete.length) {
      await deleteClients({variables: {clientIds: clientsToDelete}})
    }
    setShowForm(false);
    setSaveError(undefined)
    return  refetch()
  };

  React.useEffect(() => {
    if (error) {
      error.graphQLErrors.map((err: any) => {
          if (err.message.indexOf('The field at path') < 0) {
            switch (err.extensions?.status) {
              case '422': {
                setSaveError(err)
              };
            }
          }
        }
      );
    }
  }, [error])

  const cancel = useCallback(() => {
    setShowForm(false);
    setSaveError(undefined)
  }, [setShowForm]);

  return <Card>
    <div className={'flex-space-between flex-align-center'}>
      <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>Client Groups</Typography>
      {!showForm && <IconButton size={'small'} onClick={() => setShowForm(true)}><img src={'/img/mode.svg'}/></IconButton>}
    </div>
    {!showForm && <List>
    {clients.map(client => (
        <ClientGroupLine firstName={client?.firstName}
                    lastName={client?.lastName}
                    email={client?.email}
                    key={client?.clientId}
                    isLink
                    id={client?.clientId}
                    /*onIconClick={() => submit(clients.filter(c => c.clientId != client.clientId))}*/ />
    ))}
    </List>}
    {showForm && <ClientGroupsForm error={saveError} input={clients} onCancel={cancel} onSubmit={submit}/>}
  </Card>
};

export default ClientGroups;


