import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveAgentArgs} from "../../enrollment-types";

export default function useSaveAgent(options?: MutationHookOptions<{saveAgent: string}, MutationSaveAgentArgs>) {
  return useMutation<{saveAgent: string}, MutationSaveAgentArgs>(gql(save), options)
}

const save = `
mutation ($input: AgentInput!) {
  saveAgent(input: $input)
}
`;

