import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";

interface ScopeOfAppointmentAdminInput {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  profileId?: string | null;
}

export default function useCreateScopeOfAppointment(options?: MutationHookOptions<any, {data: ScopeOfAppointmentAdminInput}>) {
  return useMutation<any, {data: ScopeOfAppointmentAdminInput}>(gql(createScopeOfAppointmentMutation), options)
}

const createScopeOfAppointmentMutation = `
mutation ($data: ScopeOfAppointmentAdminInput!) {
  createScopeOfAppointment(adminInput: $data)
}
`;
