import * as React from 'react';
import TextInput from "../../../shared/TextInput";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import AscendAutocomplete from "../../../shared/AscendAutocomplete";

type Props = {
  parentCompanies: string[];
  onChange: (val: string) => void;
};

export function ParentCompanyFilter({parentCompanies, onChange}: Props) {
  const [searchTerm, setSearchTerm] = React.useState('');

  return (
    <AscendAutocomplete fullWidth
                        options={parentCompanies}
                        value={searchTerm}
                        onInputChange={(event, newInputValue) => {
                          setSearchTerm(newInputValue);
                        }}
                        filterOptions={(options, state) => {
                          return options.filter((i: any) => i.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)
                        }}
                        onChange={(e: any, value: any | null) => {
                          onChange(value);
                        }}
                        renderInput={params => <TextInput label={'Parent company'}
                                                          className={'mb-0'}
                                                          {...params}
                                                          InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment:
                                                              <InputAdornment position="end" style={{position: 'absolute', right: '13px'}}>
                                                                {searchTerm !== '' && <CloseIcon cursor={'pointer'} onClick={() => {
                                                                  setSearchTerm('')
                                                                  onChange('')
                                                                }} fontSize={'small'} color={'inherit'} />}
                                                              </InputAdornment>,
                                                          }}
                        />} />
  );
}