import {Divider, IconButton, Typography} from "@mui/material";
import React from "react";
import moment from "moment";
import {
  EnrollmentListItemOutput,
  ScopeOfAppointmentStatusDto
} from "../../../../enrollment-types";
import {config} from "../../../../config/config";
import Card from "../../../../shared/Card";
import Param from "../../../../shared/Param";
import {getUserName} from "../../../../shared/tools";

interface EnrollmentInfoProps {
  enrollment: EnrollmentListItemOutput,
  email?: string | null,
  scopeOfAppointmentStatus?: ScopeOfAppointmentStatusDto | null,
  onDeleteClick?: () => void,
  onEditClick?: () => void,
  viewOnly?: boolean
}

const EnrollmentInfo = (props: EnrollmentInfoProps) => {
  const {enrollment, onEditClick, scopeOfAppointmentStatus, onDeleteClick, email, viewOnly} = props;

  const birthDate = React.useMemo(() => {
    return enrollment?.birthDate ? moment.utc(enrollment?.birthDate).format(config.dateFormat) : 'N/A'
  }, [enrollment?.birthDate])

  return <Card className={'w-260'}>
    <div className={'flex-space-between flex-align-center'}>
      <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>Enrollment</Typography>
      {!viewOnly && <div className={'flex-space-between flex-align-center'}>
        <IconButton size={'small'} onClick={onEditClick}><img src={'/img/mode.svg'}/></IconButton>
        <IconButton size={'small'} className={'ml-10'} onClick={onDeleteClick}><img src={'/img/delete-icon.svg'}/></IconButton>
      </div>}
    </div>
    <Divider className={'mb-15'} />
    {scopeOfAppointmentStatus && <Param label={"SOA IS SIGNED"}
                                               value={scopeOfAppointmentStatus === ScopeOfAppointmentStatusDto.Signed ? 'Yes' : 'No'}/>}
    <div className={"flex-space-between"}>
      <Param className={'flex-column'} label={"ZIP"} value={enrollment?.zip}/>
      <Param className={'flex-column'} label={"COUNTY"} value={enrollment?.county}/>
    </div>
    <Param className={'flex-column'} label={"NAME"} value={getUserName(enrollment)}/>
    <div className={"flex-space-between"}>
      <Param className={'flex-column'} label={"DATE OF BIRTH"} value={birthDate}/>
      <Param className={'flex-column'} label={"PHONE"} value={enrollment?.phoneNumber || 'N/A'}/>
    </div>
    <Param className={'flex-column'} label={"EMAIL"} value={email}/>
    <Divider className={'mb-15'} />
    <div className={"flex-space-between"}>
      {enrollment?.bidId && <Param className={'flex-column'} label={"PLAN"} value={enrollment.bidId}/>}
      {enrollment?.started && <Param className={'flex-column'} label={"ENROLLMENT STARTED"} value={moment.utc(enrollment?.started).format(config.dateFormat)}/>}
    </div>
    <div className={"flex-space-between"}>
      {enrollment?.carrierName && <Param className={'flex-column'} label={"CARRIER"} value={enrollment?.carrierName}/>}
      {enrollment?.status && <Param className={'flex-column'} label={"STATUS"} valueClassName={'yellow'} value={enrollment?.status}/>}
    </div>
    <div className={"flex-space-between"}>
      {enrollment?.planName && <Param className={'flex-column'} label={"PLAN NAME"} value={enrollment?.planName}/>}

    </div>
  </Card>
}

export {EnrollmentInfo};

