import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationAddClientFilesArgs} from "../../enrollment-types";

export default function useAddClientFiles(options?: MutationHookOptions<any, MutationAddClientFilesArgs>) {
  return useMutation<any, MutationAddClientFilesArgs>(gql(saveMutation), options)
}

const saveMutation = `
mutation ($clientFiles: [ClientFileInput!]!, $clientId: UUID!) {
  addClientFiles(clientFiles: $clientFiles, clientId: $clientId)
}
`;
