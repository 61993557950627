import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {QueryCheckUserExistenceByEmailArgs} from "../../enrollment-types";

export default function useCheckUserExistenceByEmail(options?: LazyQueryHookOptions<{checkUserExistenceByEmail: boolean}, QueryCheckUserExistenceByEmailArgs>) {
  return useLazyQuery<{checkUserExistenceByEmail: boolean}, QueryCheckUserExistenceByEmailArgs>(gql(save), options)
}

const save = `
query ($email: String!) {
  checkUserExistenceByEmail(email: $email)
}
`;

