import {
  QueryHookOptions, QueryOptions,
  useQuery
} from "@apollo/client";
import {gql} from "@apollo/client";
import {TagOutput} from "../../enrollment-types";
import {client} from "../../Apollo/EnrollmentApolloClient";

export default function useTags(options?: QueryHookOptions<{tags: TagOutput[]}, any>) {
  return useQuery<{tags: TagOutput[]}, any>(gql(query), {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

export const getTags = async (options?: QueryOptions<{tags: TagOutput[]}, any>) => {
  return client.query<{tags: TagOutput[]}, any>({
    ...options,
    query: gql(query),
  }).then(res => res.data?.tags || []);
}

export const getTagByName = (name: string) => (
  getTags().then(tags => tags.find((tag) => tag.name === name))
)

const query = `
query {
  tags {
    color
    id
    name
  }
}
`;
