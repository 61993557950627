import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationDeleteClientFileArgs} from "../../enrollment-types";

export default function useDeleteClientFile(options?: MutationHookOptions<any, MutationDeleteClientFileArgs>) {
  return useMutation<any, MutationDeleteClientFileArgs>(gql(saveMutation), options)
}

const saveMutation = `
mutation ($link: String!, $clientId: UUID!) {
  deleteClientFile(link: $link, clientId: $clientId)
}
`;
