import * as React from 'react';
import DateRangeFilterModal from "../../shared/filters/DateRangeFilterModal";
import {Box, Typography} from "@mui/material";
import Card from "../../shared/Card";
import moment from "moment/moment";
import {DateRangeInput, CsrTrackingReportOutput, useCsrTrackingReportQuery} from "../../enrollment-types";
import {useState} from "react";
import DataGrid, {DataGridColumn} from "../../shared/DataGrid";
import useSessionStorageState from "../../shared/useSessionStorageState";
import _ from 'lodash';
import {useAgentAppointmentsDrawer, withAgentAppointmentsDrawer} from "./AgentAppointmentsDrawer";

function Report() {
  const {open} = useAgentAppointmentsDrawer()
  const [dateRangeModalOpen, setDateRangeModalOpen] = useState(false)
  const [dateRange, setDateRange] = useDateRange();
  const {loading, data} = useCsrTrackingReportQuery({
    variables: {
      from: moment(dateRange.from).format('YYYY-MM-DD'),
      to: moment(dateRange.to).format('YYYY-MM-DD'),
    },
  })

  const rows = data?.csrTrackingReport || [];

  const dateRangeStr = moment(dateRange.from).format('L') + ' - ' + moment(dateRange.to).format('L');

  const onDateRangeModalClose = (values?: DateRangeInput) => {
    if (values) {
      setDateRange(values)
    }
    setDateRangeModalOpen(false)
  }

  const totalRow = (
    <Box component="tr" sx={{fontWeight: 600, backgroundColor: '#f3e9d5', pointerEvents: 'none'}}>
      <td>Total</td>
      <td>{_.sumBy(rows, 'bookedCallsCount')}</td>
      <td>{_.sumBy(rows, 'warmTransfersCount')}</td>
      <td>{_.sumBy(rows, 'nonWarmTransfersCount')}</td>
      <td>{_.sumBy(rows, 'nonWarmTransfersAttendedCount')}</td>
      <td>{_.sumBy(rows, 'nonWarmTransfersNotAttendedCount')}</td>
      <td>{(_.sumBy(rows, 'nonWarmTransfersAttendedCount') / _.sumBy(rows, 'nonWarmTransfersCount') * 100).toFixed(0) + '%'}</td>
      <td>{_.sumBy(rows, 'futureAppointmentsCount')}</td>
      <td>{_.sumBy(rows, 'undispositionedAppointmentsCount')}</td>
    </Box>
  )

  return (
    <Card padding={0}>
      <DateRangeFilterModal open={dateRangeModalOpen} onClose={onDateRangeModalClose} initial={dateRange}/>
      <Box display={'flex'} p={'30px'} justifyContent={'space-between'} alignItems={'top'} width={'100%'}>
        <Typography color={'textPrimary'} variant={'h4'}>CSR Tracking Report</Typography>
        <Typography color={'textPrimary'} className={'no-wrap'} variant={'h5'}>
          Date range: <Box component={'span'}
                           sx={{textDecoration: 'underline', cursor: 'pointer'}}
                           onClick={() => setDateRangeModalOpen(true)}>
          {dateRangeStr}
        </Box>
        </Typography>
      </Box>
      <DataGrid columns={columns}
                page={0}
                hidePaginator
                rowsPerPage={1000}
                loading={loading}
                extraRowBuilder={(row, i) => (
                  i === rows.length - 1 ? totalRow : <></>
                )}
                onRowClick={row => open(row.agentId, dateRange)}
                rows={rows}/>
    </Card>
  );
}

const columns: DataGridColumn<CsrTrackingReportOutput, keyof CsrTrackingReportOutput>[] = [
  {
    title: 'Email',
    value: v => v.email,
    width: 500
  },
  {
    title: 'Total Booked Calls',
    value: v => <Typography sx={{fontWeight: 700}}>{v.bookedCallsCount.toString()}</Typography>
  },
  {
    title: 'Warm Transfers',
    value: v => v.warmTransfersCount.toString()
  },
  {
    title: 'Booked Appointments',
    value: v => v.nonWarmTransfersCount.toString()
  },
  {
    title: 'Booked Appt Attended',
    value: v => v.nonWarmTransfersAttendedCount.toString()
  },
  {
    title: 'Not Attended',
    value: v => v.nonWarmTransfersNotAttendedCount.toString(),
  },
  {
    title: '% Attendance Rate',
    value: v => (v.nonWarmTransfersAttendedCount / v.nonWarmTransfersCount * 100).toFixed(0) + '%'
  },
  {
    title: 'Future Appt',
    value: v => v.futureAppointmentsCount.toString()
  },
  {
    title: 'Undispositioned Appt',
    value: v => v.undispositionedAppointmentsCount.toString()
  }
]

function useDateRange() {
  return useSessionStorageState<DateRangeInput>(
    'csrReportDateRange',
    {from: moment().startOf('month').toISOString(), to: moment().endOf('day').toISOString()}
  )
}


export const CsrTrackingReport = withAgentAppointmentsDrawer(Report);