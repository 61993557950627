import * as React from 'react';
import {useMaCompaniesQuery} from "../../../../../types";
import {client} from "../../../../../Apollo/ApolloClient";
import {SnpType} from "../../../../../enrollment-types";
import * as _ from "lodash";
import {Props} from "./types";
import {CarrierSelector} from "./CarrierSelector";

export const MaCarrierSelector = (props: Props) => {
  const {zip, county, planYear} = props;
  const {data} = useMaCompaniesQuery({
    client,
    variables: {
      filter: {zip, countyName: county, planYear, showAllPlans: true},
      snpFilter: {zip, countyName: county, planYear, showAllPlans: true, SNPTypes: [SnpType.DSnp]}
    },
    skip: !county || !zip || !planYear,
    fetchPolicy: 'no-cache'
  })

  const grouped = _.groupBy([...(data?.companies || []), ...(data?.snpCompanies || [])], 'name');

  const companies = Object.keys(grouped).reduce((previousValue, currentValue) => {
    return [...previousValue, {name: currentValue, count: _.sumBy(grouped[currentValue], 'count')}];
  }, [] as any[]).filter((c: any) => c.count).map((c: any) => `${c.name} (${c.count})`) || []

  return <CarrierSelector {...props} companies={companies} />
};
