import React from "react";
import moment from "moment";
import {ConnectClientDateSelector} from "./ConnectClientDateSelector";

interface Props {
  value: Date | null,
  initialValue?: Date,
  onChange: (value: Date | null) => void,
  error?: string
}

enum Options {
  custom = 0,
  tomorrow = 1,
  days2 = 2,
  days3 = 3,
  days5 = 5,
  days7 = 7,
}

const options = [{
  value: Options.tomorrow,
  label: 'Tomorrow',
  secondary: moment().add(1, 'days').format('L')
}, {
  value: Options.days2,
  label: '2 days',
  secondary: moment().add(2, 'days').format('L')
}, {
  value: Options.days3,
  label: '3 days',
  secondary: moment().add(3, 'days').format('L')
}, {
  value: Options.days5,
  label: '5 days',
  secondary: moment().add(5, 'days').format('L')
}, {
  value: Options.days7,
  label: '7 days',
  secondary: moment().add(7, 'days').format('L')
}, {
  value: Options.custom,
  label: 'Custom date'
}]

export const FollowUpSelector = ({onChange, value, error, initialValue}: Props) => (
  <ConnectClientDateSelector value={value}
                             label={'Next contact date'}
                             initialValue={initialValue}
                             onChange={onChange}
                             error={error}
                             options={options}
  />
)