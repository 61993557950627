import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSaveChannelAttributionArgs} from "../../enrollment-types";

export default function useSaveChannelAttribution(options?: MutationHookOptions<{saveChannelAttribution: string}, MutationSaveChannelAttributionArgs>) {
  return useMutation<{saveChannelAttribution: string}, MutationSaveChannelAttributionArgs>(gql(save), options)
}

const save = `
mutation ($clientId: UUID!, $marketingCampaign: String, $marketingSource: String) {
  saveChannelAttribution(clientId: $clientId, marketingCampaign: $marketingCampaign, marketingSource: $marketingSource, )
}
`;

