import {GridRenderEditCellParams} from "@mui/x-data-grid";
import FormSelect from "../../../../../shared/FormSelect";
import {MenuItem} from "@mui/material";
import {CallHistoryResultInput} from "../../../../../enrollment-types";
import React from "react";
import { stringifyCallResult } from "../lib";

export function renderResultEditInputCell(params: GridRenderEditCellParams) {
  return <ResultSelect {...params} />;
}

const ResultSelect = (props: GridRenderEditCellParams) => {
  const { id, value, field, api } = props;

  const handleChange = (event: any) => {
    api.setEditCellValue({ id, field, value: event.target.value }, event);
    // Check if the event is not from the keyboard
    // https://github.com/facebook/react/issues/7407
    /*api.commitCellChange({ id, field });
    api.setCellMode(id, field, 'view');*/
  };

  return <FormSelect value={value?.toString()}
                     onChange={handleChange}
  >
    <MenuItem value={CallHistoryResultInput.SuccessfulSeeNotesForCallDetails}>{stringifyCallResult(CallHistoryResultInput.SuccessfulSeeNotesForCallDetails)}</MenuItem>
    <MenuItem value={CallHistoryResultInput.SuccessfulBookedCall}>{stringifyCallResult(CallHistoryResultInput.SuccessfulBookedCall)}</MenuItem>
    <MenuItem value={CallHistoryResultInput.SuccessfulWarmTransfer}>{stringifyCallResult(CallHistoryResultInput.SuccessfulWarmTransfer)}</MenuItem>
    <MenuItem value={CallHistoryResultInput.UnsuccessfulInvalidNumber}>{stringifyCallResult(CallHistoryResultInput.UnsuccessfulInvalidNumber)}</MenuItem>
    <MenuItem value={CallHistoryResultInput.UnsuccessfulLeftText}>{stringifyCallResult(CallHistoryResultInput.UnsuccessfulLeftText)}</MenuItem>
    <MenuItem value={CallHistoryResultInput.UnsuccessfulLeftVm}>{stringifyCallResult(CallHistoryResultInput.UnsuccessfulLeftVm)}</MenuItem>
    <MenuItem value={CallHistoryResultInput.UnsuccessfulLeftEmail}>{stringifyCallResult(CallHistoryResultInput.UnsuccessfulLeftEmail)}</MenuItem>
  </FormSelect>
}