import useSessionStorageState from "../../shared/useSessionStorageState";
import {PageInput} from "../../types";


export default function() {
  return useSessionStorageState<PageInput>(
    'attemptedLeadsPage',
    {page: 0, size: 20}
  )
}
