import {useChatAccessTokenLazyQuery} from "../../chat-types";
import {useEffect} from "react";
import {chatClient} from "../../Apollo/ChatApolloClient";

export default function useGetAuthToken(chatIdentityId?: string) {
  const [getToken, {data}] = useChatAccessTokenLazyQuery({
    client: chatClient
  });

  useEffect(() => {
    if (chatIdentityId) {
      getToken({variables: {chatIdentityId}})
    }
  }, [chatIdentityId]);

  return data?.chatAccessToken;
}
