// @flow
import * as React from 'react';
import {ChangeEvent} from "react";
import TextInput from "../../../shared/TextInput";
import {useDebouncedEffect} from "../../../shared/hooks/useDebouncedEffect";

type Props = {
  onChange: (value: string) => void;
};

export function SearchField({onChange}: Props) {
  const [term, setTerm] = React.useState('');

  useDebouncedEffect(() => {
    onChange(term)
  }, 500, [term, onChange])

  return (
    <TextInput
      fullWidth
      label="Search"
      value={term}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setTerm(e.target.value || '')}
    />
  );
}