import {DrugInfoWithPack, useDrugsWithPackageQuery} from "../../../../../types";
import {client as apolloClient} from "../../../../../Apollo/ApolloClient";
import {ClientViewOutput} from "../../../../../enrollment-types";

export function useDrugs(client?: ClientViewOutput): DrugInfoWithPack[] {
  const {data} = useDrugsWithPackageQuery({
    client: apolloClient,
    variables: {
      pairs: client?.preferredDrugs.map(d => ({packageRxcui: d.dosageRxcui, productRxcui: d.productRxcui})) || [],
    },
    skip: !client?.preferredDrugs.length,
    returnPartialData: true
  })

  return data?.drugsWithPackage || [];
}