import { enqueueSnackbar } from 'notistack';
import _ from 'lodash';
import {
  DrugInfoWithPack,
  DrugInfoWithPackFieldsFragmentDoc,
  DrugOutput,
  DrugsWithPackageDocument, ProviderOutputFieldsFragmentDoc
} from "../../../../../types";
import {
  CalcDrugFrequencyPeriodInput, ClientViewOutput,
  FrequencyPeriodInput,
  PreferredDrugInput,
  usePatchClientPersonalInfoMutation
} from "../../../../../enrollment-types";
import {cache} from "../../../../../Apollo/ApolloClient";

export function useSaveDrugs(client?: ClientViewOutput) {
  const [mutation] = usePatchClientPersonalInfoMutation();

  return async (drugs: DrugOutput[]) => {
    if (client) {
      const preferredDrugs = drugs.map(convertPreferredDrugInput);

      try {
        await mutation({
          variables: {
            clientId: client.id,
            preferredDrugs,
          },
          optimisticResponse: () => {
            return {
              patchClientPersonalInfo: true,
            };
          },
          update: (c) => {
            // add DrugInfoWithPack in cache if something not exists yet
            drugs.forEach(drug => {
              const info = convertToInfo(drug);
              cache.writeFragment({
                fragment: ProviderOutputFieldsFragmentDoc,
                id: cache.identify(info),
                data: info
              })
            })

            // update DrugsWithPackage query with new added drugs
            cache.writeQuery({
              query: DrugsWithPackageDocument,
              variables: {
                pairs: drugs.map(d => ({packageRxcui: d.packRxcui, productRxcui: d.rxcui}))
              },
              data: {
                drugsWithPackage: drugs.map(convertToInfo)
              }
            })
          },
        });

        enqueueSnackbar('Drugs has been saved', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Error saving drugs', { variant: 'error' });
      }
    } else {
      throw Error('Medicare quote is not exists');
    }
  };
}

function convertPreferredDrugInput(
  drugInfo: DrugOutput
): PreferredDrugInput {
  return {
    dosageRxcui: drugInfo.packRxcui,
    productRxcui: drugInfo.rxcui,
    quantity: typeof drugInfo.quantity === 'number' ? drugInfo.quantity.toFixed(2) : drugInfo.quantity,
    frequency: drugInfo.frequency as unknown as CalcDrugFrequencyPeriodInput,
    purchaseFrequency:
      drugInfo.purchaseFrequency as unknown as FrequencyPeriodInput,
  };
}

const convertToInfo = (drug: DrugOutput): DrugInfoWithPack => ({
  __typename: "DrugInfoWithPack",
  rxcui: drug.rxcui,
  packRxcui: drug.packRxcui,
  packName: drug.packName,
  name: drug.name,
  isGeneric: drug.isGeneric,
  genericRxcui: drug.genericRxcui,
  genericName: drug.genericName,
  notSupported: drug.notSupported,
})