import {LazyQueryHookOptions, useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  AgentEmailOutput,
  QueryGmailMessageArgs
} from "../../../../enrollment-types";

export default function useGmailMessage(options?: LazyQueryHookOptions<{gmailMessage: AgentEmailOutput}, QueryGmailMessageArgs>) {
  return useQuery<{gmailMessage: AgentEmailOutput}, QueryGmailMessageArgs>(gql(query), {
    fetchPolicy: 'no-cache',
    ...options
  });
}

const query = `
query($agentId: UUID!, $messageId: String!) {
  gmailMessage(agentId: $agentId, messageId: $messageId) {
    body
    createdAt
    from
    snippet
    subject
    to
    agentId
    id
  }
}
`
