import {Route, Switch} from "react-router-dom";
import paths from "../router-paths";
import ClientList from "../pages/client-list/ClientList";
import Tags from "../Tags/Tags";
import ClientDetails from "../pages/client-details/ClientDetails";
import { AgentForm, AgentList } from "../pages/agents";
import Dashboard from "../Dashboard/Dashboard";
import FilesList from "../Files/FilesList";
import AttemptedLeads from "../AttemptedLeads/AttemptedLeads";
import UrgentCalls from "../UrgentCalls/UrgentCalls";
import TwoHourSLA from "../SLA/2HourSLA";
import MissingApps from "../MissingApps/MissingApps";
import UrgentMissingApps from "../MissingApps/UrgentMissingApps";
import CreateClientForm from "../Client/CreateClientForm";
import Integrations, {GmailVerificationCode} from "../Integrations/Integrations";
import Chat from "../Chat";
import React from "react";
import {EmailTemplateList} from "../pages/email-templates";
import {EmailTemplateForm} from "../pages/email-template-form";
import PlanCoverage from "../pages/plan-coverage";
import {CsrTrackingReport} from "../pages/csr-tracking-report";

export function Router() {
  return <Switch>
    <Route exact path={paths.home} component={ClientList}/>
    <Route exact path={paths.tags} component={Tags}/>
    <Route exact path={paths.clients} component={ClientList}/>
    <Route exact path={paths.client + '/:id/:tab?/:agentId?/:emailId?'} component={ClientDetails}/>
    <Route exact path={paths.agent + '/:id?'} component={AgentForm}/>
    <Route exact path={paths.agents} component={AgentList}/>
    <Route exact path={paths.dashboard} component={Dashboard}/>
    <Route exact path={paths.files + '/:id'} component={FilesList}/>
    <Route exact path={paths.files} component={FilesList}/>
    <Route exact path={paths.attemptedLeads} component={AttemptedLeads}/>
    <Route exact path={paths.planCoverage} component={PlanCoverage}/>
    <Route exact path={paths.urgentCalls} component={UrgentCalls}/>
    <Route exact path={paths.twoHourSla} component={TwoHourSLA}/>
    <Route exact path={paths.missingApps} component={MissingApps}/>
    <Route exact path={paths.urgentMissingApps} component={UrgentMissingApps}/>
    <Route path={paths.createClient} component={CreateClientForm}/>
    <Route exact path={paths.integrations} component={Integrations}/>
    <Route exact path={paths.integrations + '/gmail'} component={GmailVerificationCode}/>
    <Route exact path={paths.emailTemplates} component={EmailTemplateList}/>
    <Route path={paths.emailTemplateForm + '/:id?'} component={EmailTemplateForm}/>
    <Route path={paths.chats + '/:id?/:userId?'} component={Chat}/>
    <Route path={paths.csrTrackingReport} component={CsrTrackingReport}/>
  </Switch>
}