import {forwardRef} from "react";
import * as React from "react";
import * as _ from "lodash";
import TextInput, {TextInputProps} from "./TextInput";

interface FormikTextInputProps extends TextInputProps {
  formik: any,
  name: string,
  'data-test'?: string,
}

export const FormikTextInput = forwardRef((props: FormikTextInputProps, ref: any) => {
  const {formik, name, ...rest} = props;

  return <TextInput
    ref={ref}
    value={formik.values[name]}
    onChange={formik.handleChange}
    error={formik.touched[name] && Boolean(formik.errors[name])}
    helperText={formik.touched[name] && formik.errors[name]}
    name={name}
    label={rest.label || _.startCase(name)}
    data-test={props['data-test'] || _.snakeCase(name)}
    placeholder={rest.placeholder || _.startCase(name)}
    {...rest}
  />
})
