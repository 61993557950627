import {IconButton, Typography} from "@mui/material";
import React from "react";
import moment from "moment";
import {EnrollmentListItemOutput} from "../../../../enrollment-types";
import {config} from "../../../../config/config";
import Param from "../../../../shared/Param";
import Card from "../../../../shared/Card";
import {getUserName} from "../../../../shared/tools";

interface PdpEnrollmentInfoProps {
  enrollment: EnrollmentListItemOutput,
  onDeleteClick?: () => void,
  onEditClick?: () => void,
  viewOnly?: boolean
}

const PdpEnrollmentInfo = ({enrollment, onDeleteClick, viewOnly, onEditClick}: PdpEnrollmentInfoProps) => {

  const birthDate = React.useMemo(() => {
    return enrollment?.birthDate ? moment.utc(enrollment?.birthDate).format(config.dateFormat) : 'N/A'
  }, [enrollment?.birthDate])

  return <Card className={'w-260'}>
    <div className={'flex-space-between flex-align-center'}>
      <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>Pdp Enrollment</Typography>
      {!viewOnly && <div className={'flex-space-between flex-align-center'}>
        <IconButton size={'small'} onClick={onEditClick}><img src={'/img/mode.svg'}/></IconButton>
        <IconButton size={'small'} className={'ml-10'} onClick={onDeleteClick}><img src={'/img/delete-icon.svg'}/></IconButton>
      </div>}
    </div>
    <Param className={'flex-column'} label={"NAME"} value={getUserName(enrollment)}/>
    <div className={"flex-space-between"}>
      <Param className={'flex-column'} label={"DATE OF BIRTH"} value={birthDate}/>
      <Param className={'flex-column'} label={"PHONE"} value={enrollment?.phoneNumber || 'N/A'}/>
    </div>
    <div className={"flex-space-between"}>
      {enrollment?.bidId && <Param className={'flex-column'} label={"PLAN"} value={enrollment?.bidId}/>}
      {enrollment?.started && <Param className={'flex-column'} label={"ENROLLMENT STARTED"} value={moment(enrollment?.started).format('MM/DD/YYYY')}/>}
    </div>
    <div className={"flex-space-between"}>
      {enrollment?.carrierName && <Param className={'flex-column'} label={"CARRIER"} value={enrollment?.carrierName}/>}
      {enrollment?.status && <Param className={'flex-column'} label={"STATUS"} valueClassName={'yellow'} value={enrollment?.status}/>}
    </div>
    <div className={"flex-space-between"}>
      {enrollment?.planName && <Param className={'flex-column'} label={"PLAN NAME"} value={enrollment?.planName}/>}
    </div>
  </Card>
}

export {PdpEnrollmentInfo};
