import {
  Box,
  Card,
  Divider,
  IconButton, Typography
} from "@mui/material";
import React, {useState} from "react";
import {LoadingButton} from "@mui/lab";
import moment from "moment/moment";
import {useFormik} from "formik";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import TextInput from "../TextInput";
import {WithCalls} from "../tools";
import {ClientViewOutput, useSaveExpectedCloseDateMutation} from "../../enrollment-types";

export default function ExpectedCloseDateDrawerContent({client, onClose}: {client: WithCalls<ClientViewOutput>, onClose: (refresh?: boolean) => void}) {
  const [loading, setLoading] = useState(false);
  const [save] = useSaveExpectedCloseDateMutation();

  const formik = useFormik({
    initialValues: {
      expectedCloseDate: client.expectedCloseDate,
    },
    onSubmit: values => {
      if (!values.expectedCloseDate) {
        formik.setFieldError('expectedCloseDate', 'Date is required')
      } else {
        setLoading(true);
        save({ variables: {
            id: client.id,
            expectedCloseDate: moment(values.expectedCloseDate).format('YYYY-MM-DD'),
          }
        })
          .finally(() => {
            setLoading(false);
            onClose(true);
          })
      }
    }
  });


  return <Card sx={{p: 4, width: 594, overflowY: 'auto'}}>
    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      <IconButton size={'small'} onClick={() => onClose()}>
        <img className={'w-32 h-32'} src={'/img/X.svg'}/>
      </IconButton>
    </Box>
    <Typography variant={'h3'}>Schedule Expected Close Date</Typography>
    <Divider sx={{mb: 3}} />
    <form onSubmit={formik.handleSubmit}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          slotProps={{
            textField: {
              error: formik.touched.expectedCloseDate && Boolean(formik.errors.expectedCloseDate),
              helperText: formik.touched.expectedCloseDate && formik.errors.expectedCloseDate,
              inputProps: {
                placeholder: 'MM / DD / YYYY'
              }
            }
          }}
          sx={{mt: 1}}
          slots={{
            textField: TextInput as any,
          }}
          format="MM/dd/yyyy"
          label={'Expected Close Date'}
          value={moment(formik.values.expectedCloseDate).toDate()}
          onChange={value => formik.setFieldValue("expectedCloseDate", value)}
        />
      </LocalizationProvider>

      <LoadingButton variant={'contained'}
                     loading={loading}
                     type={'submit'}
                     color={'primary'}>Save</LoadingButton>
    </form>
  </Card>
}
