import {ComparablePlan, ComparablePlanType, Plan} from "../../../../types";
import {PlanYear} from "../../../../enrollment-types";
import {client as apolloClient} from "../../../../Apollo/ApolloClient";
import {gql} from "@apollo/client";
import * as _ from "lodash";

export const getTypeColor = (type: ComparablePlanType) => type === ComparablePlanType.Ma ? '#3879b1' : type === ComparablePlanType.Mg ? '#38b17b' : '#b19338';

export const decoratePlans = async (defaultYear: PlanYear, plans?: ComparablePlan[]): Promise<Array<ComparablePlan & {title: string}>> => {
  function getPromises(plans: ComparablePlan[], year: PlanYear) {
    const promises = [];
    const maPlans = plans.filter(p => p.type === ComparablePlanType.Ma);
    if (maPlans.length) {
      promises.push(apolloClient.query({
        query: gql(`{${getMAPlansQuery(maPlans, defaultYear)}}`),
      }).then(res => Object.values(res.data)))
    }
    const mgPlans = plans.filter(p => p.type === ComparablePlanType.Mg);
    if (mgPlans.length) {
      promises.push(apolloClient.query({
        query: gql(`{${getMGPlansQuery(mgPlans.map(p => p.bidId))}}`),
      }).then(res => Object.values(res.data).map((p: any) => ({bidId: p.key, planName: `${p.title} (${p.planName})`}))))
    }
    const pdpPlans = plans.filter(p => p.type === ComparablePlanType.Pdp);
    if (pdpPlans.length) {
      promises.push(apolloClient.query({
        query: gql(`{${getPDPPlansQuery(pdpPlans, defaultYear)}}`),
      }).then(res => Object.values(res.data)))
    }
    return promises;
  }

  if (plans && plans.length) {
    let results: Plan[] = [];
    try {
      results = (_.flatten(await Promise.all(getPromises(plans, defaultYear)).catch(e => {
        throw Error();
      })) || []) as Plan[];
    } catch (e) {
      results = [];
    }

    return _.orderBy(plans, 'type').map(plan => (
      {...plan, title: results.find(p => p.bidId === plan.bidId)?.planName || plan.bidId}
    ));
  }
  return [];
}

const getMAPlansQuery = (plans: ComparablePlan[], defaultYear: PlanYear): string => (
  plans.map(plan => `plan${plan.bidId}: plan(bidId: "${plan.bidId}", planYear: ${plan.planYear || defaultYear}, countyName: "${plan.county}", zip: "${plan.zip}") {
      bidId
      planName
   }`).join(' ')
)

const getPDPPlansQuery = (plans: ComparablePlan[], defaultYear: PlanYear): string => (
  plans.map(plan => `plan${plan.bidId}: PdpPlan(bidId: "${plan.bidId}", planYear: ${plan.planYear || defaultYear}, countyName: "${plan.county}", zip: "${plan.zip}") {
      bidId
      planName
   }`).join(' ')
)

const getMGPlansQuery = (keys: string[]): string => (
  keys.map((key, i) => `plan${i}: medigapPlan(key: "${key}") {
      key
      title
      planName
   }`).join(' ')
)

