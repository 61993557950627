import {useContext, useEffect, useState} from "react";
import {ChatContext} from "./chat-context";
import {Conversation} from "@twilio/conversations";

export default function useParticipantSid(conversation?: Conversation) {
  const [{chatIdentityId}] = useContext(ChatContext);
  const [participantSid, setParticipantSid] = useState<string>()

  useEffect(() => {
    conversation?.getParticipantByIdentity(chatIdentityId).then(p => setParticipantSid(p?.sid))
  }, [conversation, chatIdentityId]);

  return participantSid;

}