import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A type representing a string identifier */
  UUID: { input: any; output: any; }
};

export type Query = {
  __typename?: 'Query';
  chatAccessToken: Scalars['String']['output'];
  chatIdentityId?: Maybe<Scalars['UUID']['output']>;
  test: Scalars['Boolean']['output'];
};


export type QueryChatAccessTokenArgs = {
  chatIdentityId: Scalars['UUID']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  bindChatIdentityId: Scalars['Boolean']['output'];
  createConversation: Scalars['Boolean']['output'];
  createConversationForClient: Scalars['Boolean']['output'];
};


export type MutationBindChatIdentityIdArgs = {
  chatIdentityId: Scalars['UUID']['input'];
};


export type MutationCreateConversationForClientArgs = {
  clientId: Scalars['UUID']['input'];
  clientUserId: Scalars['UUID']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  testSubscription: Scalars['Int']['output'];
};

export enum UserRole {
  RoleAdmin = 'ROLE_ADMIN',
  RoleAgentManager = 'ROLE_AGENT_MANAGER',
  RoleCsr = 'ROLE_CSR',
  RoleNone = 'ROLE_NONE',
  RoleTechStaff = 'ROLE_TECH_STAFF',
  RoleUnauthorized = 'ROLE_UNAUTHORIZED',
  RoleUser = 'ROLE_USER'
}

export type ChatIdentityIdQueryVariables = Exact<{ [key: string]: never; }>;


export type ChatIdentityIdQuery = { __typename?: 'Query', chatIdentityId?: any | null };

export type ChatAccessTokenQueryVariables = Exact<{
  chatIdentityId: Scalars['UUID']['input'];
}>;


export type ChatAccessTokenQuery = { __typename?: 'Query', chatAccessToken: string };

export type BindChatIdentityIdMutationVariables = Exact<{
  chatIdentityId: Scalars['UUID']['input'];
}>;


export type BindChatIdentityIdMutation = { __typename?: 'Mutation', bindChatIdentityId: boolean };

export type CreateConversationForClientMutationVariables = Exact<{
  clientId: Scalars['UUID']['input'];
  clientUserId: Scalars['UUID']['input'];
}>;


export type CreateConversationForClientMutation = { __typename?: 'Mutation', createConversationForClient: boolean };


export const ChatIdentityIdDocument = gql`
    query chatIdentityId {
  chatIdentityId
}
    `;

/**
 * __useChatIdentityIdQuery__
 *
 * To run a query within a React component, call `useChatIdentityIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatIdentityIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatIdentityIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useChatIdentityIdQuery(baseOptions?: Apollo.QueryHookOptions<ChatIdentityIdQuery, ChatIdentityIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatIdentityIdQuery, ChatIdentityIdQueryVariables>(ChatIdentityIdDocument, options);
      }
export function useChatIdentityIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatIdentityIdQuery, ChatIdentityIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatIdentityIdQuery, ChatIdentityIdQueryVariables>(ChatIdentityIdDocument, options);
        }
export function useChatIdentityIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChatIdentityIdQuery, ChatIdentityIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChatIdentityIdQuery, ChatIdentityIdQueryVariables>(ChatIdentityIdDocument, options);
        }
export type ChatIdentityIdQueryHookResult = ReturnType<typeof useChatIdentityIdQuery>;
export type ChatIdentityIdLazyQueryHookResult = ReturnType<typeof useChatIdentityIdLazyQuery>;
export type ChatIdentityIdSuspenseQueryHookResult = ReturnType<typeof useChatIdentityIdSuspenseQuery>;
export type ChatIdentityIdQueryResult = Apollo.QueryResult<ChatIdentityIdQuery, ChatIdentityIdQueryVariables>;
export const ChatAccessTokenDocument = gql`
    query chatAccessToken($chatIdentityId: UUID!) {
  chatAccessToken(chatIdentityId: $chatIdentityId)
}
    `;

/**
 * __useChatAccessTokenQuery__
 *
 * To run a query within a React component, call `useChatAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatAccessTokenQuery({
 *   variables: {
 *      chatIdentityId: // value for 'chatIdentityId'
 *   },
 * });
 */
export function useChatAccessTokenQuery(baseOptions: Apollo.QueryHookOptions<ChatAccessTokenQuery, ChatAccessTokenQueryVariables> & ({ variables: ChatAccessTokenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatAccessTokenQuery, ChatAccessTokenQueryVariables>(ChatAccessTokenDocument, options);
      }
export function useChatAccessTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatAccessTokenQuery, ChatAccessTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatAccessTokenQuery, ChatAccessTokenQueryVariables>(ChatAccessTokenDocument, options);
        }
export function useChatAccessTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChatAccessTokenQuery, ChatAccessTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChatAccessTokenQuery, ChatAccessTokenQueryVariables>(ChatAccessTokenDocument, options);
        }
export type ChatAccessTokenQueryHookResult = ReturnType<typeof useChatAccessTokenQuery>;
export type ChatAccessTokenLazyQueryHookResult = ReturnType<typeof useChatAccessTokenLazyQuery>;
export type ChatAccessTokenSuspenseQueryHookResult = ReturnType<typeof useChatAccessTokenSuspenseQuery>;
export type ChatAccessTokenQueryResult = Apollo.QueryResult<ChatAccessTokenQuery, ChatAccessTokenQueryVariables>;
export const BindChatIdentityIdDocument = gql`
    mutation bindChatIdentityId($chatIdentityId: UUID!) {
  bindChatIdentityId(chatIdentityId: $chatIdentityId)
}
    `;
export type BindChatIdentityIdMutationFn = Apollo.MutationFunction<BindChatIdentityIdMutation, BindChatIdentityIdMutationVariables>;

/**
 * __useBindChatIdentityIdMutation__
 *
 * To run a mutation, you first call `useBindChatIdentityIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBindChatIdentityIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bindChatIdentityIdMutation, { data, loading, error }] = useBindChatIdentityIdMutation({
 *   variables: {
 *      chatIdentityId: // value for 'chatIdentityId'
 *   },
 * });
 */
export function useBindChatIdentityIdMutation(baseOptions?: Apollo.MutationHookOptions<BindChatIdentityIdMutation, BindChatIdentityIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BindChatIdentityIdMutation, BindChatIdentityIdMutationVariables>(BindChatIdentityIdDocument, options);
      }
export type BindChatIdentityIdMutationHookResult = ReturnType<typeof useBindChatIdentityIdMutation>;
export type BindChatIdentityIdMutationResult = Apollo.MutationResult<BindChatIdentityIdMutation>;
export type BindChatIdentityIdMutationOptions = Apollo.BaseMutationOptions<BindChatIdentityIdMutation, BindChatIdentityIdMutationVariables>;
export const CreateConversationForClientDocument = gql`
    mutation createConversationForClient($clientId: UUID!, $clientUserId: UUID!) {
  createConversationForClient(clientId: $clientId, clientUserId: $clientUserId)
}
    `;
export type CreateConversationForClientMutationFn = Apollo.MutationFunction<CreateConversationForClientMutation, CreateConversationForClientMutationVariables>;

/**
 * __useCreateConversationForClientMutation__
 *
 * To run a mutation, you first call `useCreateConversationForClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversationForClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversationForClientMutation, { data, loading, error }] = useCreateConversationForClientMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      clientUserId: // value for 'clientUserId'
 *   },
 * });
 */
export function useCreateConversationForClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateConversationForClientMutation, CreateConversationForClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConversationForClientMutation, CreateConversationForClientMutationVariables>(CreateConversationForClientDocument, options);
      }
export type CreateConversationForClientMutationHookResult = ReturnType<typeof useCreateConversationForClientMutation>;
export type CreateConversationForClientMutationResult = Apollo.MutationResult<CreateConversationForClientMutation>;
export type CreateConversationForClientMutationOptions = Apollo.BaseMutationOptions<CreateConversationForClientMutation, CreateConversationForClientMutationVariables>;