import React from "react";
import useAgentPerformanceAnalytics from "../Agent/hooks/useAgentPerformanceAnalytics";
import {keycloak, KeycloakRoles} from "../shared/keycloak";
import {Box, CircularProgress, Grid, Stack} from "@mui/material";
import AgentSelector from "../Agent/AgentSelector";
import {AgentOutput, useAgentByContextLazyQuery} from "../enrollment-types";
import CSRDashboard from "../CSR/CSRDashboard";
import AgentDashboard from "../Agent/AgentDashboard";

const Dashboard = () => {
  const [getAnalytics, {data: requestData, loading: analyticsLoading, called: analyticsCalled}] = useAgentPerformanceAnalytics();
  const [agent, setAgent] = React.useState<AgentOutput>();
  const [getAgent, {loading: agentLoading}] = useAgentByContextLazyQuery({
    onCompleted: res => setAgent(res.agentByContext),
  });

  const showAgentSelector = React.useMemo(() => {
    return keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_AGENT_MANAGER) ||
      keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_ADMIN_QA)
  }, [keycloak])

  React.useEffect(() => {
    getAgent()
  }, []);

  React.useEffect(() => {
    if (agent) {
      getAnalytics({variables: {agentId: agent.id}})
    }
  }, [agent]);

  const isCSR = React.useMemo(() => {
    return !!agent?.isCSR;
  }, [agent]);

  const data = React.useMemo(() => {
    return requestData?.agentPerformanceAnalytics;
  }, [requestData]);

  const loading = React.useMemo(() => {
    return analyticsCalled ? analyticsLoading || agentLoading : true;
  }, [analyticsLoading, analyticsCalled, agentLoading]);

  return <Stack spacing={2} >
    {showAgentSelector && <Box sx={{width: 300}}>
        <AgentSelector onChange={() => {}}
                       onChangeAgent={v => setAgent(v as AgentOutput)} value={agent?.id} />
      </Box>
    }
    {loading && <Box sx={{display: 'flex', justifyContent: 'center', width: 1, alignItems: 'center', height: 200}} >
      <CircularProgress/>
    </Box>}
    {!loading && <>
      {isCSR && <CSRDashboard data={data || []} />}
      {!isCSR && <AgentDashboard data={data || []} agentId={agent?.id} />}
    </>}

  </Stack>
}

export default Dashboard;
