import {Box, Button, CircularProgress, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {EnrollmentInfo} from "./ui/EnrollmentInfo";
import {PdpEnrollmentInfo} from "./ui/PdpEnrollmentInfo";
import {MedigapEnrollmentInfo} from "./ui/MedigapEnrollmentInfo";
import {
  ClientViewOutput,
  EnrollmentListItemOutput,
  useDeleteEnrollmentMutation, useDeleteMedigapEnrollmentMutation,
  useDeletePdpEnrollmentMutation
} from "../../../enrollment-types";
import useClientEnrollments from "./api/useClientEnrollments";
import {useAgentId} from "./api/useAgentId";
import MaEnrollmentEditForm from "./ui/ma-enrollment-form";
import PdpEnrollmentForm from "./ui/pdp-enrollment-form";
import MgEnrollmentForm from "./ui/mg-enrollment-form";

enum ViewMode {
  Preview,
  MaForm,
  MgForm,
  PdpForm
}

interface ClientEnrollmentsProps {
  client?: ClientViewOutput
}

const ClientEnrollments = (props: ClientEnrollmentsProps) => {
  const agentId = useAgentId()
  const [mode, setMode] = useState(ViewMode.Preview)
  const {loading, refetch, pdpEnrollments, maEnrollments, mgEnrollments} = useClientEnrollments(true, props.client?.id);
  const [enrollmentToEdit, setEnrollmentToEdit] = useState<EnrollmentListItemOutput>()

  const [deleteMa] = useDeleteEnrollmentMutation()
  const [deletePdp] = useDeletePdpEnrollmentMutation()
  const [deleteMg] = useDeleteMedigapEnrollmentMutation();

  const enrollmentToEditWithAgent = enrollmentToEdit ? {agentId, ...enrollmentToEdit} : undefined;

  if (loading) {
    return <Box sx={{display: 'flex', justifyContent: 'center', width: 1, alignItems: 'center', height: 200}} >
      <CircularProgress/>
    </Box>
  }


  return <div className={'relative'}>
    {mode === ViewMode.Preview && <>
      <div className={'flex flex-align-center h-80'}>
        <Typography color={"textPrimary"} variant={"h4"}>Medicare Advantage</Typography>
        <Button onClick={() => {
                  setEnrollmentToEdit(undefined)
                  setMode(ViewMode.MaForm)
                }}
                className={'ml-15'}
                variant={'contained'}
                color={'primary'}>CREATE</Button>
      </div>
      <Stack direction={'row'} spacing={2}>
        {maEnrollments.map(e => (
          <EnrollmentInfo enrollment={e}
                          key={e.id}
                          email={props.client?.email}
                          onDeleteClick={async () => {
                            await deleteMa({variables: {enrollmentId: e.id}})
                            await refetch();
                          }}
                          onEditClick={() => {
                            setEnrollmentToEdit(e)
                            setMode(ViewMode.MaForm)
                          }}
                          scopeOfAppointmentStatus={props.client?.scopeOfAppointmentStatus} />
        ))}
      </Stack>


      <div className={'flex flex-align-center h-80'}>
        <Typography color={"textPrimary"} variant={"h4"}>Medigap</Typography>
        <Button onClick={() => {
                  setEnrollmentToEdit(undefined)
                  setMode(ViewMode.MgForm)
                }}
                className={'ml-15'}
                variant={'contained'}
                color={'primary'}>CREATE</Button>
      </div>
      <Stack direction={'row'} spacing={2}>
        {mgEnrollments.map(e => (
          <MedigapEnrollmentInfo enrollment={e}
                                 key={e.id}
                                 onEditClick={() => {
                                   setEnrollmentToEdit(e)
                                   setMode(ViewMode.MgForm)
                                 }}
                                 onDeleteClick={async () => {
                                   await deleteMg({variables: {medigapEnrollmentId: e.id}})
                                   await refetch();
                                 }} />
        ))}
      </Stack>



      <div className={'flex flex-align-center h-80'}>
        <Typography color={"textPrimary"} variant={"h4"}>Prescription Drug Plan</Typography>
        <Button onClick={() => {
          setEnrollmentToEdit(undefined)
          setMode(ViewMode.PdpForm)
        }}
                className={'ml-15'}
                variant={'contained'}
                color={'primary'}>CREATE</Button>
      </div>
      <Stack direction={'row'} spacing={2}>
        {pdpEnrollments.map(e => (
          <PdpEnrollmentInfo enrollment={e}
                             key={e.id}
                             onEditClick={() => {
                               setEnrollmentToEdit(e)
                               setMode(ViewMode.PdpForm)
                             }}
                             onDeleteClick={async () => {
                               await deletePdp({variables: {enrollmentId: e.id}})
                               await refetch();
                             }} />
        ))}
      </Stack>
    </>}

    {mode === ViewMode.MaForm && (
      <MaEnrollmentEditForm onCancel={() => {
                              setMode(ViewMode.Preview)
                              setEnrollmentToEdit(undefined)
                            }}
                            onSave={() => {
                              setMode(ViewMode.Preview)
                              refetch();
                            }}
                            client={props.client}
                            initialData={enrollmentToEditWithAgent} />
    )}

    {mode === ViewMode.MgForm && (
      <MgEnrollmentForm onCancel={() => {
                           setMode(ViewMode.Preview)
                           setEnrollmentToEdit(undefined)
                         }}
                         onSave={() => {
                           setMode(ViewMode.Preview)
                           refetch();
                         }}
                         client={props.client}
                         initialData={enrollmentToEditWithAgent} />
    )}

    {mode === ViewMode.PdpForm && (
      <PdpEnrollmentForm onCancel={() => {
                           setMode(ViewMode.Preview)
                           setEnrollmentToEdit(undefined)
                         }}
                         onSave={() => {
                           setMode(ViewMode.Preview)
                           refetch();
                         }}
                         client={props.client}
                         initialData={enrollmentToEditWithAgent} />
    )}
    </div>

}

export {ClientEnrollments};