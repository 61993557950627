import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationDeleteClientNoteArgs} from "../../../../enrollment-types";

export default function useDeleteClientNote(options?: MutationHookOptions<{deleteClientNote: boolean}, MutationDeleteClientNoteArgs>) {
  return useMutation<{deleteClientNote: boolean}, MutationDeleteClientNoteArgs>(gql(save), options)
}

const save = `
mutation ($noteId: UUID!) {
  deleteClientNote(noteId: $noteId)
}
`;

