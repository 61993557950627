import React from "react";
import Card from "../../shared/Card";
import {useHistory, useParams} from "react-router-dom";
import {
  useDeleteEmailTemplateMutation,
  useEmailTemplateQuery,
  useSaveEmailTemplateMutation
} from "../../enrollment-types";
import {Box, Button, CircularProgress} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import paths from "../../router-paths";
import TextInput from "../../shared/TextInput";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const validationSchema = yup.object({
  title: yup.string().required('County is required'),
  template: yup.string().required('County is required'),
});

interface FormValues {
  title: string;
  template: string;
}

interface Props {
  initialValues?: FormValues,
  onSubmit: (values: FormValues) => void,
  onCancel: () => void,
  onRemove?: () => void,
  loading: boolean,
}

const Form = ({initialValues, onSubmit, loading, onCancel, onRemove}: Props) => {
  const formik = useFormik({
    initialValues: {
      title: initialValues?.title || '',
      template: initialValues?.template || '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      if (formik.isValid) {
        onSubmit(values);
      }
    },
  });

  return  <Box component={'form'}
               sx={{display: 'flex', flexDirection: 'column', gap: 2}}
               onSubmit={formik.handleSubmit}>
    <TextInput
      fullWidth
      autoFocus
      name="title"
      label="Title"
      value={formik.values.title}
      onChange={formik.handleChange}
      error={formik.touched.title && Boolean(formik.errors.title)}
      helperText={formik.touched.title && formik.errors.title}
    />
    <CKEditor
      editor={ ClassicEditor }
      data={formik.values.template}
      onReady={ (editor: any) => {
        // You can store the "editor" and use when it is needed.
        console.log( 'Editor is ready to use!', editor );
      } }
      onChange={ ( event: any, editor: any ) => {
        const data = editor.getData();
        formik.setFieldValue('template', data)
      } }
    />

    <Box sx={{display: 'flex', gap: 2, justifyContent: 'space-between'}}>
      <Box sx={{display: 'flex', gap: 2}}>
        <Button disabled={loading} variant={'outlined'} onClick={onCancel}>CANCEL</Button>
        <Button disabled={loading} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
      </Box>
      {onRemove && <Button disabled={loading} onClick={onRemove} variant={'contained'} color={'error'}>REMOVE</Button>}
    </Box>

  </Box>
}

export function EmailTemplateForm() {
  const {id} = useParams<{ id?: string }>()
  const history = useHistory();
  const {loading, data} = useEmailTemplateQuery({variables: {id}, skip: !id});
  const [save, {loading: saveLoading}] = useSaveEmailTemplateMutation()
  const [remove, {loading: removeLoading}] = useDeleteEmailTemplateMutation()

  if (loading) {
    return <CircularProgress/>
  }

  const handleSubmitClick = async (values: FormValues) => {
    try {
      await save({variables: {emailTemplate: {id, ...values}}});
      history.push(paths.emailTemplates)
    } catch (error) {}
  }

  const handleRemoveClick = async () => {
    try {
      if (id) {
        await remove({variables: {id}});
      }
      history.push(paths.emailTemplates)
    } catch (error) {}
  }

  return <Card sx={{maxWidth: 800}}>
    <Form onCancel={() => history.push(paths.emailTemplates)}
          onSubmit={handleSubmitClick}
          onRemove={id ? handleRemoveClick : undefined}
          initialValues={data?.emailTemplate}
          loading={saveLoading || removeLoading}/>
  </Card>
}