import React from "react";
import {Box, Typography} from "@mui/material";
import moment from "moment";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {useEmailStatsQuery} from "../../../types";
import Card from "../../../shared/Card";
import {client} from "../../../Apollo/ApolloClient";

const columns: GridColDef[] = [
  { field: 'eventDate', headerName: 'Event Date', width: 150,
    renderCell: (params) => moment(params.value?.toString()).format('L')
  },
  { field: 'eventType', headerName: 'Event Type', width: 150 },
  { field: 'emailToken', headerName: 'Email Token', width: 250 },
  { field: 'messageId', headerName: 'Message Id', width: 150 },
  { field: 'subject', headerName: 'Subject', width: 150 },
  { field: 'templateId', headerName: 'Template Id', width: 150 },
];


interface EmailStatsProps {
  loading: boolean;
  email?: string | null;
}

const EmailStats = (props: EmailStatsProps) => {
  const {loading, data} = useEmailStatsQuery({
    fetchPolicy: 'no-cache',
    skip: !props.email,
    variables: {email: props.email as string},
    client
  });
  const rows = data?.emailStats || [];

  return <div>
    <Typography color={'textPrimary'} className={'fs-16 medium'}>Email Stats</Typography>
    <Card padding={0} className={'relative'}>
      <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
        <div style={{height: 300, width: '100%'}}>
          <DataGrid rows={rows.map((i, index) => ({...i, id: index}))}
                    columns={columns}
                    loading={loading}
                    paginationModel={{
                      page: 0,
                      pageSize: 100
                    }}
                    paginationMode={'client'}
                    rowCount={rows.length}
          />
        </div>
      </Box>
    </Card>
  </div>
  ;
}

export default EmailStats;
