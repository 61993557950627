import {getToken} from "./ApolloClient";
import {errorTracker, graphQLErrorHandler} from "../GraphQLErrorHandler";
import { split, HttpLink, ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError, ErrorResponse } from "@apollo/client/link/error";

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import {getMainDefinition} from "@apollo/client/utilities";


const httpLink = new HttpLink({
  uri: process.env.REACT_APP_ENROLLMENT_GRAPHQL,
  headers: {
    "content-type": "application/json",
  }
});


const authLink = setContext(async (_, { headers }: any) => {
  const token = await getToken().catch(() => {});
  if (token) {
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
        ...headers,
      }
    };
  } else {
    return {
      headers
    };
  }
});

const wsLink = new GraphQLWsLink(createClient({
  url: process.env.REACT_APP_ENROLLMENT_SUBSCRIPTIONS as string,
  connectionParams: async () => {
    const token = await getToken();
    return {
      Authorization: token ? token : "",
    }
  },
  lazy: true,
}));

const elink = onError((errResponse: ErrorResponse) => {
  try {
    getToken().then(token => errorTracker(errResponse, token as any)).catch(() => errorTracker(errResponse));
  } catch (e) {
    errorTracker(errResponse);
  }
  if (graphQLErrorHandler) {
    graphQLErrorHandler(errResponse);
  }
});

const requestLink =
  from([
    authLink,
    elink,
    httpLink,
  ])
;

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  requestLink
);

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    typePolicies: {
      DoctorViewOutput: {
        keyFields: ['npi']
      }
    }
  })
});

export enum GraphQLErrorType {
  NO_CONNECTION = 'NO_CONNECTION',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  ERROR_STATUS = 'ERROR_STATUS'
}
