import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationCreateClientNoteArgs} from "../../../../enrollment-types";

export default function useCreateClientNote(options?: MutationHookOptions<{createClientNote: boolean}, MutationCreateClientNoteArgs>) {
  return useMutation<{createClientNote: boolean}, MutationCreateClientNoteArgs>(gql(save), options)
}

const save = `
mutation ($clientId: UUID!, $note: String!) {
  createClientNote(clientId: $clientId, note: $note)
}
`;

