import React from "react";
import {Box, CircularProgress, Fade, FadeProps} from "@mui/material";

export default function Preloader({className, ...props}: {children?: React.ReactNode, className?: string, in?: boolean}) {

  return (
    <Fade in={props.in} appear={false}>
      <Box sx={{
        zIndex: 1000,
        background: '#fff',
        position: 'fixed',
        height: '100%',
        maxHeight: '100vh',
        width: '100%',
        top: 0,
        bottom: 0,
        left: 160,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }} className={className}>
        {props.children}
        <CircularProgress/>
      </Box>
    </Fade>
  );
}

export function ProfilePreloader(props: FadeProps) {

  return (
    <Fade {...props} appear={false}>
      <Box sx={{
        zIndex: 1000,
        background: '#fff',
        position: 'fixed',
        marginLeft: 70,
        height: '100%',
        maxHeight: '100vh',
        width: '100%',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <CircularProgress/>
      </Box>
    </Fade>
  );
}


