import {ClientViewTimeZone} from "../../enrollment-types";
import useSessionStorageState from "../../shared/useSessionStorageState";


export default function () {
  return useSessionStorageState<ClientViewTimeZone | undefined>(
    'attemptedLeadTimezone',
    undefined
  )
}
