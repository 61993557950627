import React, {useState} from "react";
import {
  Box,
  Typography
} from "@mui/material";
import moment from "moment";
import {
  ClientViewOutput,
  EnrollmentListItemOutput,
  MedigapEnrollmentPlanName,
  MedigapEnrollmentStatus,
  MedigapEnrollmentType,
  useChangeMedigapEnrollmentStatusMutation,
  useSaveMedigapEnrollmentMutation
} from "../../../../../../enrollment-types";
import {Gender} from "../../../../../../medigap-types";
import _ from "lodash";
import Card from "../../../../../../shared/Card";
import {IMgEnrollmentForm} from "../../../lib/types";
import {MgEnrollmentForm} from "./MgEnrollmentForm";
import {getAge} from "../../../../../../shared/utils";

interface Props {
  client?: ClientViewOutput,
  initialData?: Partial<EnrollmentListItemOutput>,
  onCancel: () => void,
  onSave: () => void,
}

export const MgEnrollmentFormDataWrapper = (props: Props) => {
  const [saveEnrollment] = useSaveMedigapEnrollmentMutation();
  const [changeStatus] = useChangeMedigapEnrollmentStatusMutation();
  const [loading, setLoading] = useState(false);

  const [initialData, setInitialData] = React.useState<Partial<EnrollmentListItemOutput | undefined>>(props.initialData);

  const save = async (values: IMgEnrollmentForm) => {
    setLoading(true)
    const fields = _.pick(values, ['zip', 'county', 'firstName', 'lastName', 'email', 'agentId', 'carrierName', 'planName', 'naic', 'planKey']);
    try {
      const result = await saveEnrollment({
        variables: {
          input: {
            ...fields,
            clientId: initialData?.id ? undefined : props.client?.id,
            id: initialData?.id,
            planName: values.planName as MedigapEnrollmentPlanName,
            gender: values.gender as Gender,
            age: parseFloat(values.age),
            tobacco: values.tobacco,
            phoneNumber: values.phoneNumber || initialData?.phoneNumber,
            enrollmentType: values.enrollmentType as MedigapEnrollmentType,
            applicationDate: moment(values.applicationDate).format('YYYY-MM-DD'),
            effectiveDate: moment(values.effectiveDate).format('YYYY-MM-DD'),
            birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
          }
        }
      })

      await changeStatus({
        variables: {
          status: values.status as unknown as MedigapEnrollmentStatus,
          enrollmentId: result.data?.saveMedigapEnrollment
        }
      })

      props.onSave();
    } finally {
      setLoading(false)
    }
  }

  const fillFromClient = () => {
    setInitialData( prev => ({
      ...prev,
      zip: props.client?.zip,
      county: props.client?.countyName,
      email: props.client?.email || undefined,
      firstName: props.client?.firstName,
      lastName: props.client?.lastName,
      birthDate: props.client?.birthDate,
      phoneNumber: props.client?.phoneNumber,
      tobacco: props.client?.tobacco,
      gender: props.client?.gender as Gender || undefined,
      age: props.client?.birthDate ? getAge(props.client?.birthDate) : undefined,
    }))
  }

  return <Card sx={{maxWidth: 530, '& > *': {opacity: loading ? 0.5 : 1, pointerEvents: loading ? 'none' : undefined}}}>
    <Box sx={{display: 'flex', alignItems: 'center', width: 500, justifyContent: 'space-between'}}>
      <Typography color={'textPrimary'} variant={'h4'} className={'medium'}>{props.initialData ? 'Edit' : 'Create'} MG enrollment</Typography>
      <Typography className={'pointer underline'} variant={'body1'} color={'textPrimary'} onClick={fillFromClient}>Fill from the client data</Typography>
    </Box>
    <MgEnrollmentForm input={initialData} onSubmit={save} onCancel={props.onCancel} loading={loading} />
  </Card>
}
