import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  AgentViewOutput,
} from "../../enrollment-types";

export default function useAvailableAgents(options?: LazyQueryHookOptions<{availableAgents: AgentViewOutput[]}>) {
  return useLazyQuery<{availableAgents: AgentViewOutput[]}>(gql(query), {...options, fetchPolicy: 'no-cache'});
}

const query = `
query {
  availableAgents {
    email
    firstName
    lastName
    id
  }
}
`;
