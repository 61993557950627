import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  AffiliationPartnershipOutput,
} from "../../enrollment-types";

export default function useAffiliationPartnerships(options?: LazyQueryHookOptions<{affiliationPartnerships: AffiliationPartnershipOutput[]}>) {
  return useLazyQuery<{affiliationPartnerships: AffiliationPartnershipOutput[]}>(gql(query), {
    fetchPolicy: 'no-cache',
    ...options
  });
}

const query = `
query {
  affiliationPartnerships {
    campaign
    source
  }
}
`;
