// @flow
import * as React from 'react';
import { MenuItem } from '@mui/material';
import FormSelect from "../../../../../shared/FormSelect";
import {CalcDrugFrequencyPeriod, FrequencyPeriod} from "../../../../../types";

type Props = {
  purchaseFrequency?: FrequencyPeriod,
  frequency?: string,
  onChange: (value: FrequencyPeriod) => void,
};

export function PurchaseFrequencySelect(props: Props) {
  return (
    <FormSelect
      label={'Purchase frequency'}
      placeholder={'Please select'}
      data-test={'purchase-frequency-select'}
      className={'mb-0'}
      value={props.purchaseFrequency || FrequencyPeriod.Monthly}
      onChange={(event) => {
        props.onChange(event.target.value as FrequencyPeriod);
      }}
    >
      <MenuItem
        value={FrequencyPeriod.Monthly}
        className={'regular'}
        disabled={
          props.frequency === CalcDrugFrequencyPeriod.Quarterly ||
          props.frequency === CalcDrugFrequencyPeriod.HalfYearly ||
          props.frequency === CalcDrugFrequencyPeriod.Yearly
        }
      >
        Monthly
      </MenuItem>

      <MenuItem value={FrequencyPeriod.Quarterly}
                disabled={
                  props.frequency === CalcDrugFrequencyPeriod.HalfYearly || props.frequency === CalcDrugFrequencyPeriod.Yearly
                }
                className={'regular'}>
        Quarterly
      </MenuItem>

      <MenuItem value={FrequencyPeriod.HalfYearly}
                disabled={
                  props.frequency === CalcDrugFrequencyPeriod.Yearly
                }
                className={'regular'}>
        Half Yearly
      </MenuItem>

      <MenuItem value={FrequencyPeriod.Yearly} className={'regular'}>
        Yearly
      </MenuItem>

    </FormSelect>
  );
}
