// @flow
import * as React from 'react';
import Card from "../../../shared/Card";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  List, ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@mui/material";
import {
  ClientViewOutput,
  useClientCalendlyAppointmentsQuery,
  useSetCalendlyAppointmentNoShowMutation
} from "../../../enrollment-types";
import {getUserName} from "../../../shared/tools";
import moment from "moment";
import {LoadingButton} from "@mui/lab";
import Param from "../../../shared/Param";

type Props = {
  client?: ClientViewOutput
};

export function CalendlyAppointments({client}: Props) {
  const [setNoShow, {loading: saveLoading}] = useSetCalendlyAppointmentNoShowMutation({
    refetchQueries: ['clientCalendlyAppointments'],
    awaitRefetchQueries: true
  })
  const {loading, data} = useClientCalendlyAppointmentsQuery({
    variables: {clientId: client?.id},
    skip: !client?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })
  const appointments = data?.clientCalendlyAppointments || [];

  return (
    <Card>
      <Typography variant={'h4'} className={'mb-5'}>Calendly Appointments:</Typography>
      {loading && <CircularProgress size={15} />}
      <List>
        {appointments.map((a, i) => (
          <React.Fragment key={a.url}>
            <ListItem sx={{px: 0}}>
              <Box sx={{display: 'flex', alignItems: 'flex-start', gap: 2}}>
                <div>
                  <Param label={'Agent'} value={getUserName(a.agent || {})}/>
                  <Param label={'Start time'} value={moment(a.startTime).format('L LT')}/>
                  <ShowedUpToggle loading={saveLoading} noShow={a.noShow} onClick={noShow => {
                    setNoShow({variables: {id: a.url, noShow}})
                  }}/>
                </div>
                <div>
                  <Param label={'CSR'} value={a.createdBy}/>
                  <Param label={'Created at'} value={moment(a.createdAt).format('L LT')}/>
                </div>
              </Box>
            </ListItem>
            {i < appointments.length - 1 && <Divider sx={{my: 3}}/>}
          </React.Fragment>
        ))}

      </List>
    </Card>
  )
}

interface ShowedUpToggleProps {
  loading: boolean,
  noShow?: boolean | null,
  onClick: (value: boolean) => void
}

const ShowedUpToggle = ({loading, onClick, noShow}: ShowedUpToggleProps) => {
  return <div>
    <Typography>Showed up</Typography>
    <ButtonGroup sx={{ mt: .3}}>
      <LoadingButton onClick={() => onClick(false)}
                     loading={loading}
                     size={'small'}
                     variant={noShow === false ? 'contained' : 'outlined'}
                     color={noShow !== null ? 'success' : 'primary'}>
        Yes
      </LoadingButton>

      <LoadingButton onClick={() => onClick(true)}
                     loading={loading}
                     size={'small'}
                     variant={noShow ? 'contained' : 'outlined'}
                     color={noShow !== null ? 'error' : 'primary'}>
        No
      </LoadingButton>
    </ButtonGroup>
  </div>
}