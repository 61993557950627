import {IconButton, Menu} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

interface Props {
  onSetUnreadClick: () => void
}

export default function MessageMenu({onSetUnreadClick}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (callback?: () => void) => {
    setAnchorEl(null);
    callback && callback();
  };

  return <>
    <IconButton onClick={handleClick} >
      <MoreVertIcon/>
    </IconButton>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={() => handleClose()}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={() => handleClose(onSetUnreadClick)}>Set unread</MenuItem>
    </Menu>
  </>
}