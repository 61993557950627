import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {QueryCompareYearToYearByClientIdArgs, CompareYearToYearOutput} from "../../types";
import {client} from "../../Apollo/ApolloClient";

export default function useCompareYearToYearByClientId(options?: LazyQueryHookOptions<{compareYearToYearByClientId: CompareYearToYearOutput}, QueryCompareYearToYearByClientIdArgs>) {
  return useLazyQuery<{compareYearToYearByClientId: CompareYearToYearOutput}, QueryCompareYearToYearByClientIdArgs>(gql(query), {...options, client, fetchPolicy: 'no-cache'})
}

const query = `
query($clientId: UUID!) {
  compareYearToYearByClientId(clientId: $clientId) {
    clientId
    county
    createdAt
    currentMaPlanId
    currentMgNaic
    currentMgPlanName
    currentMgPlanKey
    currentPdpPlanId
    currentYear
    id
    suggestedMaPlanId
    suggestedMgNaic
    suggestedMgPlanName
    suggestedPdpPlanId
    updatedAt
    zip
  }
}
`;
