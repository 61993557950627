import React, {useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import paths from "../../router-paths";
import {useHistory} from "react-router";
import Card from "../../shared/Card";
import {DataGrid, GridColDef, GridPaginationModel, GridSortDirection} from "@mui/x-data-grid";
import {formatPhoneLink} from "../../shared/utils";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import MenuItem from "@mui/material/MenuItem";
import FormSelect from "../../shared/FormSelect";
import {AgentField, AgentOutput, AgentSortInput, useAgentsLazyQuery} from "../../enrollment-types";
import {client} from "../../Apollo/EnrollmentApolloClient";
import {gql} from "graphql-tag";
import useSessionStorageState from "../../shared/useSessionStorageState";


const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'Name', width: 350, disableColumnMenu: true, sortable: false,
    valueGetter: params => {
      const firstName = params.row.firstName;
      const lastName = params.row.lastName;
      let name = '';
      if (!firstName && !lastName) {
        name = 'Unknown agent';
      } else {
        name = firstName + ' ' + lastName || '';
      }
      return name
    }
  },
  { field: 'email', headerName: 'Email', width: 250, disableColumnMenu: true, sortable: false,
    valueGetter: params => {
      return params.row.email
    }
  },
  { field: 'phone', headerName: 'Phone', width: 250, disableColumnMenu: true, sortable: false,
    valueGetter: params => {
      if (params.row.phone) {
        return formatPhoneLink(params.row.phone, true)
      }
      return ''
    }
  },
  { field: 'enabled', headerName: 'Enabled', width: 150, sortable: true, disableColumnMenu: true,
    renderCell: params =>
      params.row.enabled ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <ClearIcon style={{color: 'red'}} fontSize={'small'} />
  },
  { field: 'receiveEmails', headerName: 'Receive emails', width: 150, sortable: false, disableColumnMenu: true,
    renderCell: params =>
      params.row.receiveEmails ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <ClearIcon style={{color: 'red'}} fontSize={'small'} />
  },
  { field: 'gmail', headerName: 'Gmail connected', width: 150, sortable: false, disableColumnMenu: true,
    renderCell: params =>
      params.row.gmail ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <div />
  },
  { field: 'isDefault', headerName: 'Is Default', width: 150, sortable: false, disableColumnMenu: true,
    renderCell: params =>
      params.row.isDefault ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <div />
  },
];

export const AgentList = () => {
  const [page, setPage] = usePage();
  let history = useHistory();
  const [enabled, setEnabled] = useEnabled();
  const [sorting, setSorting] = useSorting();

  const [loading, setLoading] = useState(false)
  const [searchRequest] = useAgentsLazyQuery();
  const [total, setTotal] = useState(0)

  const [agents, setAgents] = React.useState<(AgentOutput & {gmail: boolean})[]>([]);

  React.useEffect(() => {
    (async function () {
      setLoading(true)
      const data = await searchRequest({
        variables: {
          filter: {
            enabled: enabled === 'null' ? null : enabled === 'true' ? true : false,
          },
          page: {
            page: page.page,
            size: page.pageSize
          },
          sort: sorting && {
            field: sorting.field!.toUpperCase() as AgentField,
            direction: sorting.sort!.toUpperCase()
          } as AgentSortInput
        }
      });

      const agents = data.data?.agents?.data || [];

      const query = `
      query {
        ${agents.map((a, i) => 'a' + i + `: isAgentGmailSetUp(agentId: "${a.id}")`).join(' ')}
      }
    `;

      const setup = await client.query({fetchPolicy: 'no-cache', query: gql(query)}).then(res => res.data || {});

      setTotal(data.data?.agents.totalElements || 0);
      setAgents(agents.map((a, i) => ({...a, gmail: setup['a' + i]})))
      setLoading(false)
    })()
  }, [page, enabled, sorting]);


  return <Card padding={0}>
    <Box display={'flex'} p={'30px'} justifyContent={'space-between'} alignItems={'top'} width={'100%'}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography color={'textPrimary'} variant={'h4'}>Agent list</Typography>
        <Button className={'ml-40'} variant={'contained'} size={'small'} color={'primary'} onClick={() => history.push(paths.agent)}>Create new agent</Button>
      </Box>
      <Box>
        <FormSelect className={'mb-0 w-120'}
                    label={'Enabled'}
                    value={String(enabled)}
                    onChange={event => {
                      setEnabled(event.target.value as string)
                    }}
        >
          <MenuItem value={'null'}>All</MenuItem>
          <MenuItem value={'true'}>Enabled</MenuItem>
          <MenuItem value={'false'}>Disabled</MenuItem>
        </FormSelect>
      </Box>
    </Box>
    <div style={{ height: 'calc(100vh - 270px)', width: '100%' }}>
      <DataGrid rows={agents}
                paginationMode={"client"}
                columns={columns}
                disableRowSelectionOnClick
                loading={loading}
                paginationModel={page}
                onPaginationModelChange={setPage}
                onRowClick={(param, event) => {
                  history.push(paths.agent + '/' + param.row.id)
                }}
                sortModel={sorting ? [sorting] : []}
                onSortModelChange={(sortModel) => {
                  setSorting(sortModel[0])
                }}
                pageSizeOptions={[20, 50, 100]}
                rowCount={total}
                pagination
      />
    </div>
  </Card>;
}


function usePage() {
  return useSessionStorageState<GridPaginationModel>(
    'agentListPage',
    {
      page: 0,
      pageSize: 20
    }
  )
}

function useSorting() {
  return useSessionStorageState<{field: string, sort: GridSortDirection} | undefined>(
    'agentListSorting',
    undefined
  )
}

function useEnabled() {
    return useSessionStorageState<string>(
      'agentListEnabled',
      'null'
    )
}
