import {MedigapEnrollmentType} from "../../../../../enrollment-types";

export const getEnrollmentTypeTitle = (type: MedigapEnrollmentType): string => ({
  [MedigapEnrollmentType.MgOep]: 'MG - OEP',
  [MedigapEnrollmentType.MgUnderwritten]: 'MG - Underwritten',
  [MedigapEnrollmentType.MgGi]: 'MG - GI',
}[type])

export const getEnrollmentTypeSubTitle = (type: MedigapEnrollmentType): string => ({
  [MedigapEnrollmentType.MgGi]: 'Enrolling in Medigap during a Guaranteed Issue period (e.g., Birthday Rule (CA), continuous guaranteed States (NY, CT, MA) or other GI period)',
  [MedigapEnrollmentType.MgOep]: 'Enrolling in Medigap within first 6 months of obtaining Part B',
  [MedigapEnrollmentType.MgUnderwritten]: 'Enrolling in Medigap with OEP or GI eligiblity'
}[type])