import React, {ChangeEvent, useState} from "react";
import moment from "moment";
import {Box, Card, Typography} from "@mui/material";
import useSessionStorageState from "../../shared/useSessionStorageState";
import useSalesQualified from "../hooks/useSalesQualified";
import {assignCallsToClients, WithCalls} from "../../shared/tools";
import ClientViewTable from "../../shared/ClientViewTable/ClientViewTable";
import {ClientViewOutput, Field} from "../../enrollment-types";
import {useDebouncedEffect} from "../../shared/hooks/useDebouncedEffect";
import TextInput from "../../shared/TextInput";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import {TagSelector} from "./TagSelector";
import {DataGridSortModel} from "../../shared/DataGrid";

const SalesQualified = (props: {agentId?: string, future?: boolean, onRefetch?: () => void}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(1000);
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = React.useState<WithCalls<ClientViewOutput>[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [sort, setSort] = useState<DataGridSortModel<Field>>();

  const decorateSalesQualified = async (input: ClientViewOutput[]) => {
    const salesQualified = props.future ?
      input.filter(item  => moment(item.followUpDate).isSameOrAfter(moment().add({day: 1}).startOf('day'))) :
      input.filter(item  => moment(item.followUpDate).isBefore(moment().add({day: 1}).startOf('day')));

    setRows(await assignCallsToClients(salesQualified))
    setLoading(false)
  }

  const [getSalesQualified, {data, refetch}] = useSalesQualified({
    fetchPolicy: 'no-cache',
    onCompleted: res => res.salesQualified && decorateSalesQualified(res.salesQualified.data),
    notifyOnNetworkStatusChange: true,
  });

  useDebouncedEffect(() => {
    if (props.agentId) {
      setLoading(true)
      getSalesQualified({variables: {
          agentId: props.agentId,
          searchTerm,
          includedTagsIds: tags,
          sort,
          pageInput: {
            page: 0,
            size: 1000
          },
        }
      }).catch(() => {
        setLoading(false)
      })
    }
  },  400,[props.agentId, props.future, searchTerm, tags, sort]);

  const onRefetch = () => {
    refetch();
    props.onRefetch && props.onRefetch();
  }

  const total = data?.salesQualified.totalElements ? parseFloat(data?.salesQualified.totalElements) : 0;

  return <Card sx={{p: 4}}>
    <Box display={'flex'} alignItems={'center'} gap={2} sx={{mb: 3}}>
      <Typography className={'fs-18'}>Filter by</Typography>
      <TextInput
        className={'mb-0 w-250'}
        fullWidth
        name="search"
        label=""
        placeholder={'Name, email, phone #...'}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              {!!searchTerm && <CloseIcon cursor={'pointer'} onClick={() => {
                setSearchTerm('');
              }} fontSize={'small'} color={'inherit'} />}
            </InputAdornment>,
        }}
        value={searchTerm}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value || '')}
      />

      <TagSelector selectedValues={tags} onChange={setTags} />
    </Box>
    <ClientViewTable rows={rows}
                     loading={loading}
                     page={page}
                     rowsPerPage={pageSize}
                     total={total}
                     sort={sort}
                     onSortModelChange={setSort}
                     onRefetch={onRefetch}
    />
  </Card>
}

export default SalesQualified

function usePage() {
  return useSessionStorageState<number>(
    'salesQualifiedPage',
    0
  )
}

function usePageSize() {
  return useSessionStorageState<number>(
    'salesQualifiedPageSize',
    20
  )
}
