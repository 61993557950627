import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSetUrgentCallRequestedArgs} from "../../enrollment-types";
import {client} from "../../Apollo/EnrollmentApolloClient";

export default function useSetUrgentCalRequested(options?: MutationHookOptions<{setUrgentCallRequested: boolean}, MutationSetUrgentCallRequestedArgs>) {
  return useMutation<{setUrgentCallRequested: boolean}, MutationSetUrgentCallRequestedArgs>(gql(saveNoteMutation), {
    ...options,
    client
  })
}

const saveNoteMutation = `
mutation ($clientId: UUID!, $urgentCallRequested: Boolean!) {
  setUrgentCallRequested(clientId: $clientId, urgentCallRequested: $urgentCallRequested)
}
`;
