import {ClientViewOutput, ProfileOption} from "../../enrollment-types";
import {useSnackbar} from "notistack";
import React from "react";
import useSetClientUserId from "../hooks/useSetClientUserId";
import {Box, Button, Grid} from "@mui/material";
import TextInput from "../../shared/TextInput";

const SetUserIdForm = ({client}: {client?: ClientViewOutput}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [userId, setUserId] = React.useState('');

  React.useEffect(() => {
    if (client?.userId) {
      setUserId(client?.userId)
    }
  }, [client?.userId])

  const [setClientUserId, {loading}] = useSetClientUserId()

  const create = () => {
    setClientUserId({variables: {userId: userId || null, clientId: client?.id}})
      .then(() => enqueueSnackbar('Success!', {variant: "success"}))
      .catch(() => enqueueSnackbar('Error!', {variant: "error"}))
  }

  if (!client)
    return null;

  return <div className={'mt-20'}>
    <TextInput
      fullWidth
      label="USER_ID"
      value={userId}
      onChange={e => setUserId(e.target.value)}
    />
    <Box justifyContent={'flex-end'}>
      <Button disabled={loading} variant={'contained'} color={'primary'} onClick={create}>SAVE</Button>
    </Box>
  </div>
};

export default SetUserIdForm;
