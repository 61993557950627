import Card from "../../shared/Card";
import {Box, Button, CircularProgress, IconButton, Typography} from "@mui/material";
import Param from "../../shared/Param";
import React from "react";
import PersonalInfoForm, {PersonalInfoFormValues} from "../components/PersonalInfoForm";
import {GraphQLErrorType} from "../../Apollo/EnrollmentApolloClient";
import {useSnackbar} from "notistack";
import {
  ClientViewOutput,
  ProfileOption,
  ScopeOfAppointmentStatusDto,
  useResetPasswordMutation, useSaveUserProfileMutation
} from "../../enrollment-types";
import moment from "moment";
import useCreateScopeOfAppointment from "../hooks/useCreateScopeOfAppointment";
import * as _ from 'lodash';
import {formatPhoneLink, getAgeByBirthday} from "../../shared/utils";
import useSetClientUserId from "../hooks/useSetClientUserId";
import {LoadingButton} from "@mui/lab";
import {useConfirm} from "../../shared/ConfirmDialog/ConfirmDialogContext";
import {useHistory} from "react-router";

interface PersonalInfoProps {
  client?: ClientViewOutput
}

const PersonalInfo = ({client}: PersonalInfoProps) => {
  const [showPersonalInfo, setShowPersonalInfo] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const [create, data] = useSaveUserProfileMutation({
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.ALREADY_EXISTS:
          text = 'Looks like that email is already in use.';
          break;
        case GraphQLErrorType.NO_CONNECTION:
          text = "Service is not available";
          break;
        default:
          text = "Unknown error"
      }
      enqueueSnackbar(text, {variant: "error"});
    },
    onCompleted: () => {
      enqueueSnackbar('User saved!', {variant: "success"});
      setShowPersonalInfo(false);
    }
  })

  const onSubmit = React.useCallback((values: PersonalInfoFormValues) => {
    return create({
      variables: {
        data: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          quoteId: client?.quoteId,
          birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
          gender: values.gender,
          zip: values.zip,
          countyName: values.county,
          state: values.state,
          phoneNumber: values.phoneNumber,
          tobacco: values.tobacco,
          profileId: client?.profileId,
        }
      }
    })
  }, [client]);

  return <Card>
    <div className={'flex-space-between flex-align-center mb-10'}>
      <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>Personal information</Typography>
      {(!!client?.profileId && !showPersonalInfo) && <IconButton size={'small'} onClick={() => setShowPersonalInfo(true)}><img src={'/img/mode.svg'}/></IconButton>}
    </div>
    {!showPersonalInfo && <PersonalInfoView client={client} />}
    {(!client?.profileId && !showPersonalInfo) && <NewPersonalInfo phone={client?.phoneNumber || undefined} onCreateClick={() => setShowPersonalInfo(true)}/>}
    {showPersonalInfo && <PersonalInfoForm onSubmit={onSubmit}
                                           onCancel={() => setShowPersonalInfo(false)}
                                           loading={data.loading}
                                           initialBirthDate={client?.birthDate || ''}
                                           initialEmail={client?.email || ''}
                                           initialFirstName={client?.firstName || ''}
                                           initialLastName={client?.lastName || ''}
                                           initialTobacco={client?.tobacco}
                                           initialPhoneNumber={client?.phoneNumber?.replace('+1', '') || ''}
                                           gender={client?.gender || ''}
                                           county={client?.countyName || ''}
                                           zip={client?.zip || ''}
    />}
  </Card>
}

export default PersonalInfo;

const NewPersonalInfo = ({onCreateClick, phone}: {phone?: string, onCreateClick: () => void }) => {
  return <div className={"flex-column mt-35"}>
    {!!phone && <Param label={"PHONE FOR FOLLOW UP"} value={phone}/>}
    <Box display={"flex"} alignItems={"center"} flex={1} justifyContent={"center"} className={"mb-70"}>
      <Button variant={"contained"} color={"primary"} onClick={onCreateClick}>CREATE PROFILE</Button>
    </Box>
  </div>
}

const PersonalInfoView = ({client}: {client?: ClientViewOutput}) => {
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();

  const isOOS = React.useMemo(() => {
    return !GetLicensedStates().includes(client?.state || '')
  }, [client?.state])

  const [saveProfile, {loading}] = useSaveUserProfileMutation()
  const [setClientUserId, {loading: setClientUserIdLoading}] = useSetClientUserId()

  const create = () => {
    saveProfile({
      variables: {
        data: {
          email: client?.email,
          firstName: client?.firstName,
          lastName: client?.lastName,
          phoneNumber: client?.phoneNumber,
          profileId: client?.profileId,
          options: [ProfileOption.RegisterUser],
          quoteId: client?.quoteId,
          medigapQuoteId: client?.medigapQuote?.id,
          pdpQuoteId: client?.pdpQuote?.id,
          gender: client?.gender,
          birthDate: client?.birthDate,
          tobacco: typeof client?.tobacco !== 'undefined' ? client?.tobacco : undefined,
          zip: client?.zip,
          countyName: client?.countyName,
          state: client?.state,
          adviserEmail: client?.adviserEmail,
          enrolled: client?.enrolled,
          adviserName: client?.adviserName
        }
      }
    })
      .then(res => setClientUserId({variables: {userId: res.data?.saveUserProfile?.userId, clientId: client?.id}}))
      .then(() => enqueueSnackbar('User created!', {variant: "success"}))
      .catch(() => enqueueSnackbar('Error in saving user!', {variant: "error"}))
  }

  if (!client)
    return null;

  const addSpouseClickHandle = () => {
    const params = new URLSearchParams();
    params.append('zip', client.zip || '');
    params.append('countyName', client.countyName || '');
    params.append('state', client.state || '');
    params.append('gender', client.gender === 'M' ? 'F' : 'M');
    params.append('firstName', client.firstName || '');
    params.append('lastName', client.lastName + '-Spouse');
    params.append('phoneNumber', client.phoneNumber ? client.phoneNumber.slice(2) : '');
    params.append('birthDate', moment(client.birthDate).format('YYYY-MM-DD'));
    params.append('status', client.status);
    params.append('spouse', client.id);

    if (client.followUpDate) {
    params.append('followUpDate', moment(client.followUpDate).format('YYYY-MM-DD'));
    }
    history.push(`/create-client?` + params.toString())
  }

  const location = `${client.zip} ${client.countyName}${client.state ? ', ' + client.state : ''}`

  return <>
    {client.createdAt && <Param label={"CREATED AT"} value={moment(client.createdAt).format('MM/DD/YYYY HH:mm')}/>}
    <div className={"flex-space-between"}>
      <Param valueClassName={isOOS ? 'red' : ''} label={"LOCATION"} value={location}/>
      {typeof client.tobacco === 'boolean' && <Param label={"TOBACCO"} value={client.tobacco ? 'Yes' : 'No'}/>}
    </div>
    {(client.firstName || client.lastName) && <div className={"flex-space-between"}>
      <Param label={"NAME"} value={(client.firstName || '') + ' ' + (client.lastName || '')}/>
      <Param label={"GENDER"} value={_.upperFirst(client.gender || '')}/>
    </div>}
    <div className={"flex-space-between"}>
      {client.phoneNumber && <Param label={"PHONE"} value={formatPhoneLink(client.phoneNumber, true)}/>}
      {client.email && <Param label={"EMAIL"} value={client.email}/>}
    </div>
    {client.birthDate && <Param label={"BIRTH DATE / AGE"} value={client.birthDate ? `${moment(client.birthDate).format('MM/DD/YYYY')} (${getAgeByBirthday(client.birthDate)} y.o.)` : 'N/A'}/>}
    <div className={"flex-space-between"}>
      {client.adviserName && <Param label={"ADVISOR NAME"} value={client.adviserName}/>}
      {client.adviserEmail && <Param label={"ADVISOR EMAIL"} value={client.adviserEmail}/>}
    </div>
    {client.enrolled && <Param label={"ENROLLED"} value={client.enrolled}/>}
    {client.profileId && <>
      <div className={"flex-space-between"}>
        <Param label={"LOGIN STATUS"} value={client.userId ? 'Yes' : 'No'}/>

        {!client.userId && <div>
          <div className={"flex flex-align-center"}>
            <Button onClick={create}
                    disabled={loading || !client.email}
                    variant={'contained'}
                    size={'small'}
                    color={'primary'}>CREATE USER</Button>
            {!!(loading || setClientUserIdLoading) && <CircularProgress className={'ml-15'} size={15} />}
          </div>
          {!client.email && <Typography className={'red fs-10'} align={'center'}>Wrong email</Typography>}
        </div>}
      </div>
      <div className={"flex-column"}/>
      <Param label={"Eligible "} value={"Yes"} valueClassName={"dark-green"}/>
      {client.cId && <Param label={"CID"} value={client.cId}/>}
      <SoaStatus client={client}/>
      <ResetPasswordButton client={client}/>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', xl: 'row'}, justifyContent: 'space-between', alignItems: 'flex-start', gap: 2, mt: 2}}>
        <Button variant={'contained'}
                size={'small'}
                onClick={() => history.push(`/chats/${client.id}/${client.userId}`)}>
          Chat with client
        </Button>
        <Button variant={'outlined'}
                size={'small'}
                onClick={addSpouseClickHandle}>
          Add spouse
        </Button>
      </Box>
    </>}
  </>
};

const ResetPasswordButton = ({client}: {client: ClientViewOutput}) => {
  const {enqueueSnackbar} = useSnackbar();
  const confirm = useConfirm();

  const [reset, {loading}] = useResetPasswordMutation({
    variables: {clientId: client.id},
    onCompleted: () => enqueueSnackbar('Success', {variant: "success"})
  });

  const noClick = () => (
    confirm({
      title: 'Warning',
      content: 'Are you sure you want to reset password to this user?',
      okButtonTitle: 'Reset password'
    })
      .then(async val => {
        if (val) {
          await reset();
        }
      })
  )

  return <div>
    <LoadingButton onClick={noClick}
                   loading={loading}
                   variant={'contained'}
                   size={'small'}
                   sx={{mt: 2}}
                   color={'primary'}>Reset password</LoadingButton>
  </div>
}

const SoaStatus = ({client}: {client: ClientViewOutput}) => {
  const {enqueueSnackbar} = useSnackbar();

  const [create, data] = useCreateScopeOfAppointment({
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.NO_CONNECTION:
          text = "Service is not available";
          break;
        default:
          text = "Unknown error"
      }
      enqueueSnackbar(text, {variant: "error"});
    },
    onCompleted: () => {
      enqueueSnackbar('Link sent!', {variant: "success"});
    }
  });

  const sendLink = React.useCallback(() => {
    create({
      variables: {
        data: {
          email: client.email || '',
          firstName: client.firstName || '',
          lastName: client.lastName || '',
          phoneNumber: client.phoneNumber,
          profileId: client.profileId
        }
      }
    })
  }, [client]);

  const sent = !!client.scopeOfAppointmentStatus;

  const signed = client.scopeOfAppointmentStatus == ScopeOfAppointmentStatusDto.Signed;

  const disabled = data.loading || !client?.email;

  return <div className={"flex flex-align-center"}>
    <div>
      <div className={'mb-8'}>
        <Param label={"Soa "}
               value={sent ? client?.scopeOfAppointmentStatus : null}
               valueClassName={signed ? "dark-green" : undefined}/>
      </div>
      {!sent && <div>
        <Button disabled variant={'contained'} color={'primary'} onClick={sendLink} >Send link</Button>
      </div>}
    </div>
    {(sent && !signed) && <Button disabled={disabled}
                        onClick={sendLink}
                        className={'ml-15'}
                        variant={'contained'}
                        color={'primary'} >Resend again</Button>}
  </div>
}

export function GetLicensedStates() {
  return [
    'NY',
    'AZ',
    'FL',
    'GA',
    'IL',
    'IN',
    'MO',
    'NC',
    'NJ',
    'OH',
    'PA',
    'SC',
    'TN',
    'TX',
    'VA',
    'CA',
    'NV',
    'KS',
    'KY',
    'LA',
    'ME',
    'MI',
    'MT',
    'UT',
    'CO',
    'NM',
    'WV',
    'MD',
    'OR',
    'CT',
    'MS',
    'AL',
    'WA',
    'IA',
    'MA',
    'NE',
    'AR',
    'DE',
    'ID',
    'NH',
    'OK',
    'RI',
    'VT',
    'WI',
    'WY',
  ]
}
