import useUrgentCallRequests from "../UrgentCalls/hooks/useUrgentCallRequests";
import {useHistory} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import paths from "../router-paths";
import React from "react";
import useIsAdmin from "../shared/hooks/useIsAdmin";

const UrgentCallRequests = () => {
  const {data: urgentCallData} = useUrgentCallRequests()
  let history = useHistory();
  const isAdmin = useIsAdmin();

  if (!isAdmin || !urgentCallData?.urgentCallRequests?.length) {
    return null
  }

  return <>
    {!!urgentCallData?.urgentCallRequests?.length && <Box onClick={() => history.push(paths.urgentCalls)} className={'pointer'} display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{backgroundColor: '#C00000'}}  p={'10px 16px'}>
      <Typography className={'bold white'}>URGENT CALL REQUESTS: {urgentCallData?.urgentCallRequests?.length}</Typography>
    </Box>}
  </>
}

export default UrgentCallRequests;
