// @flow
import * as React from 'react';
import useInterval from "../../../shared/useInterval";
import {calcDurationToNow} from "../../../shared/tools";

type Props = {
  createdAt: string
};

export function ClientListTimer({createdAt}: Props) {
  const [time, setTime] = React.useState<string>();
  useInterval(() => {
    setTime(calcDurationToNow(createdAt));
  }, 1000);

  return (
    <div>
      {time}
    </div>
  );
}