import { useCallback, useEffect, useState } from 'react';

export const useDebouncedMemo = <T>(input: T, delay:number) => {
  const [value, setValue] = useState<T>(input);

  useEffect(() => {
    const handler = setTimeout(() => {
      setValue(input);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [input, delay]);

  return value;
}

export const useDebouncedMemoF = <T>(input: () => T, delay:number, deps: any[]) => {
  const callback = useCallback(input, deps);
  const [value, setValue] = useState<T>(input);

  useEffect(() => {
    const handler = setTimeout(() => {
      setValue(callback());
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);

  return value;
}
