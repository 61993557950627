import {client} from "../../../../../../../Apollo/ApolloClient";
import {FindProvidersByFilterQueryVariables, useFindProvidersByFilterQuery} from "../../../../../../../types";

export function useDoctors(filterInput: FindProvidersByFilterQueryVariables['filterInput']) {
  const {data, loading} = useFindProvidersByFilterQuery({
    client,
    skip: !filterInput.searchTerm || !filterInput.zipCode,
    variables: { filterInput },
  });

  return {loading, data: data?.findProvidersByFilter || []};
}