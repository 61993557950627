import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  PageableAgentOutput, QueryAgentsArgs,
} from "../../enrollment-types";

export default function useAgents(options?: LazyQueryHookOptions<{agents: PageableAgentOutput}, QueryAgentsArgs>) {
  return useLazyQuery<{agents: PageableAgentOutput}, QueryAgentsArgs>(gql(query), {...options, fetchPolicy: 'no-cache'});
}

const query = `
query($page: PageInput!, $filter: AgentFilterInput!, $sort: AgentSortInput) {
  agents(page: $page, filter: $filter, sort: $sort) {
    data {
      consultationUrl
      email
      enabled
      enrollmentMeetingUrl
      firstName
      id
      lastName
      phone
      picUrl
      planReviewUrl
      receiveEmails
      isDefault
      isCSR
    }
    totalElements
  }
}
`;
