import {
  DisqualifiedReasonDto,
  StatusDto, useSaveDisqualifiedReasonMutation,
  useSaveExpectedCloseDateMutation,
  useSaveStatusMutation
} from "../../../../../enrollment-types";
import moment from "moment/moment";
import {GraphQLErrorType} from "../../../../../Apollo/EnrollmentApolloClient";
import {enqueueSnackbar} from "notistack";
import {useFollowUpDateGroupModal} from "../../../../../Client/components/FollowUpDateGroupModal";
import {useState} from "react";



export function useSaveStatus(onCompleted: () => void, clientId?: string) {
  const [loading, setLoading] = useState(false)
  const [saveExpectedCloseDate] = useSaveExpectedCloseDateMutation();
  const [saveDisqualifiedReason] = useSaveDisqualifiedReasonMutation();
  const changeFollowUpDateForGroup =  useFollowUpDateGroupModal();
  const [saveStatus, data] = useSaveStatusMutation({
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.NO_CONNECTION:
          text = "Service is not available";
          break;
        default:
          text = "Unknown error"
      }
      enqueueSnackbar(text, {variant: "error"});
    },
    onCompleted: () => {
      enqueueSnackbar('Status saved!', {variant: "success"});
      onCompleted();
    }
  });

  const save = async (status: StatusDto, agentId?: string, followUpDate?: string | null, expectedCloseDate?: string | null, disqualifiedReason?: DisqualifiedReasonDto) => {
    setLoading(true);
    try {
      if ([StatusDto.FollowUp, StatusDto.Contacted, StatusDto.SalesQualified, StatusDto.ApplicationSubmitted].includes(status) ) {
        if (!!followUpDate) {
          const followUpDateStr = moment(followUpDate).format('YYYY-MM-DD');
          await saveStatus({
            variables: {
              id: clientId,
              status,
              followUpDate: followUpDateStr,
              agentId,
            }
          });
          if (!!expectedCloseDate) {
            const expectedCloseDateStr = moment(expectedCloseDate).format('YYYY-MM-DD');
            await saveExpectedCloseDate({
              variables: {
                id: clientId,
                expectedCloseDate: expectedCloseDateStr,
              }
            })
          }
          if (clientId) {
            await changeFollowUpDateForGroup(clientId, followUpDateStr, status, agentId);
          }
        } else {
          return;
        }
      } else if (status === StatusDto.Disqualified && disqualifiedReason) {
        await saveStatus({
          variables: {
            id: clientId,
            status,
            agentId,
          }
        })
        await saveDisqualifiedReason({
          variables: {
            id: clientId,
            disqualifiedReason,
          }
        })
      } else {
        await saveStatus({
          variables: {
            id: clientId,
            status,
            agentId,
          }
        })
      }
    } finally {
      setLoading(false);
    }

  };

  return {save, loading}
}