import Card from "../../shared/Card";
import React from "react";
import useCompareYearToYearByClientId from "../hooks/useCompareYearToYearByClientId";
import {ClientViewOutput} from "../../enrollment-types";
import {Box, Button, Typography} from "@mui/material";
import {config} from "../../config/config";
import {useSnackbar} from "notistack";

interface YearToYearCompareProps {
  client?: ClientViewOutput
}

export const YearToYearCompare = ({client}: YearToYearCompareProps) => {
  const {enqueueSnackbar} = useSnackbar();
  const [getData, data] = useCompareYearToYearByClientId();

  React.useEffect(() => {
    if (client?.id) {
      getData({variables: {clientId: client.id}})
    }
  }, [client])

  if (!data.data?.compareYearToYearByClientId) {
    return null;
  }

  return <Card>
    <Typography color={"textPrimary"} variant={"h4"} className={"mb-5"}>Year to year compare</Typography>
    <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
      <Button onClick={() => {
        window.open(config.userFlowUrl + `year-to-year?clientId=${client?.id}`)
      }} className={'mv-10'} variant={'contained'} color={'primary'}>Preview</Button>
      <Typography onClick={() => {
        navigator.clipboard.writeText(`${config.userFlowUrl}year-to-year`)
        enqueueSnackbar('Copied!', {variant: "info"});
      }} className={'pointer fs-12 ml-20 underline'} color={'textPrimary'}>Copy link</Typography>

    </Box>
  </Card>
}
