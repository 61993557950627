import Card from "../../shared/Card";
import {Box, Divider, Grid, Theme, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";
import * as _ from 'lodash';
import {ClientViewOutput} from "../../enrollment-types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    }
  }),
);

interface QuizAnswersProps {
  answers: any,
  client?: ClientViewOutput,
  county?: string
}

const QuizAnswers = ({client, county, answers: inputAnswers}: QuizAnswersProps) => {
  const classes = useStyles();
  const answers: QA[] = React.useMemo(() => {
      return getAnswersPairs(inputAnswers)
  }, [inputAnswers]);

  return <Card className={classes.root}>
    <div className={'flex'}>
      <Typography color={'textPrimary'} variant={'h4'} className={'mb-16'}>Quiz answers</Typography>
    </div>
    {!answers.length && <Box display={'flex'} minHeight={100} alignItems={'center'} justifyContent={'center'} flex={1}>
      <Typography color={'textSecondary'} variant={'h3'} className={'bold'}>No data</Typography>
    </Box>}
    {!!answers.length && <Grid container spacing={2}>
      {answers.map((answer, index) => <Grid key={index} item sm={12} md={6}>
        <Answer answer={answer} />
      </Grid>)}
    </Grid>}
  </Card>
}

export default QuizAnswers;

const getAnswersPairs = (data: any): QA[] => {
  let result: QA[] = [];

  if (!data) {
    return result;
  }

  Object.keys(data).forEach(key => {
    if (_.isObject(data[key])) {
      if (data[key].answerText && data[key].questionText) {
        result.push({
          answer: data[key].answerText,
          question: data[key].questionText
        })
      } else if (['phone', 'email'].includes(data[key].value) && data[key].questionText) {
        result.push({
          answer: _.upperFirst(data[key].value),
          question: data[key].questionText
        })
      } else {
        result = [...result, ...getAnswersPairs(data[key])];
      }
    }
  })

  return result;
}

interface QA {
  question: string;
  answer: string;
}

const Answer = ({answer}: {answer: QA}) => {
  return <div>
    <Typography color={'textSecondary'} variant={'body2'} className={'medium'}>{answer.question}</Typography>
    <Box marginLeft={'24px'} display={'flex'} justifyContent={'flex-end'}>
      <Typography color={'textPrimary'} variant={'body1'} className={''}>{answer.answer}</Typography>
    </Box>
  </div>
}
