import DataGrid, {DataGridColumn} from "../../shared/DataGrid";
import React from "react";
import {EmailTemplateDto, useEmailTemplatesQuery} from "../../enrollment-types";
import Card from "../../shared/Card";
import {Box, Button, Typography} from "@mui/material";
import paths from "../../router-paths";
import {useHistory} from "react-router-dom";

export function EmailTemplateList() {
  const history = useHistory();

  const {data, loading} = useEmailTemplatesQuery({
    variables: { pageInput: {page: 0, size: 1000} },
    fetchPolicy: "cache-and-network"
  })

  const rows = data?.emailTemplates.data || [];

  return <Card>
    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
      <Typography color={'textPrimary'} variant={'h4'}>Email template list</Typography>
      <Button variant={'contained'} size={'small'} color={'primary'} onClick={() => history.push(paths.emailTemplateForm)}>Create new template</Button>
    </Box>
    <DataGrid columns={columns}
              page={0}
              hidePaginator
              rowsPerPage={1000}
              onRowClick={t => history.push(`${paths.emailTemplateForm}/${t.id}`)}
              loading={loading}
              total={data?.emailTemplates.totalElements}
              rows={rows}  />
  </Card>
}

const columns: DataGridColumn<EmailTemplateDto, keyof EmailTemplateDto>[] = [
  {
    title: 'Template name',
    value: v => v.title,
    width: 500
  },
  {
    title: 'Preview',
    value: v => <Typography sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 400}}>{v.template}</Typography>
  }
]