import React from "react";
import moment from "moment";
import {ConnectClientDateSelector} from "./ConnectClientDateSelector";

interface Props {
  value: Date | null,
  initialValue?: Date,
  onChange: (value: Date | null) => void,
  error?: string
}

const options: {value: number, label: string, secondary?: string}[] = (
  new Array(5).fill(1).reduce((previousValue, currentValue, currentIndex) => {
    const month =  moment().endOf('month').add(currentIndex, 'month');

    return [
      ...previousValue,
      {
        value: month.diff(moment().startOf('day'), "days"),
        label: month.format('MMMM'),
        secondary: month.format('L')
      }
    ];
  }, [])
)

options.push({
  value: 0,
  label: 'Custom date'
})

export const ExpectedCloseDateSelector = ({onChange, value, error, initialValue}: Props) => (
  <ConnectClientDateSelector value={value}
                             label={'Expected Close Date'}
                             initialValue={initialValue}
                             onChange={onChange}
                             error={error}
                             options={options}
  />
)

