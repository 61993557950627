import {DisqualifiedReasonDto, StatusDto} from "../../../../../enrollment-types";
import React from "react";
import {useFormik} from "formik";
import AgentSelector from "../../../../../Agent/AgentSelector";
import {Box, Button, MenuItem} from "@mui/material";
import {ExpectedCloseDateSelector, FollowUpSelector, ContactedDateSelector, StatusView, DisqualifiedReasonModal} from "../../../../../features";
import moment from "moment/moment";
import {FormikSelectInput} from "../../../../../shared/formik-select-input";

interface StatusFormProps {
  status: string;
  agentId?: string;
  followUpDate?: string | null;
  expectedCloseDate?: string | null;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (status: StatusDto, agentId?: string, followUpDate?: string | null, expectedCloseDate?: string | null, disqualifiedReason?: DisqualifiedReasonDto) => void;
}

export const StatusForm = (props: StatusFormProps) => {
  const [disqualifiedReason, setDisqualifiedReason] = React.useState<DisqualifiedReasonDto>();
  const [disqualifiedOpen, setDisqualifiedOpen] = React.useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      status: props.status,
      followUpDate: props.followUpDate,
      expectedCloseDate: props.expectedCloseDate,
      agentId: props.agentId
    },
    onSubmit: values => {
      let error = false;
      if ([StatusDto.FollowUp, StatusDto.Contacted, StatusDto.SalesQualified, StatusDto.ApplicationSubmitted].includes(formik.values.status as StatusDto)) {
        if (!values.followUpDate) {
          formik.setFieldError('followUpDate', 'Date is required')
          error = true;
        }

        if ([StatusDto.FollowUp, StatusDto.SalesQualified].includes(formik.values.status as StatusDto) && !values.agentId) {
          formik.setFieldError('agentId', 'Agent is required for this status')
          error = true;
        }

        if ([StatusDto.SalesQualified].includes(formik.values.status as StatusDto) && !values.expectedCloseDate) {
          formik.setFieldError('expectedCloseDate', 'Date is required')
          error = true;
        }
      }

      if (!error) {
        props.onSubmit(values.status as StatusDto, values.agentId, values.followUpDate, values.expectedCloseDate, StatusDto.Disqualified === values.status && disqualifiedReason ? disqualifiedReason : undefined)
      }
    }
  });

  return <form onSubmit={formik.handleSubmit} className={'mt-15'}>
    <DisqualifiedReasonModal onClose={value => {
      setDisqualifiedOpen(false)
      setDisqualifiedReason(value)
      if (value) {
        formik.setFieldValue("status", StatusDto.Disqualified as string);
      }
    }} open={disqualifiedOpen} />

    <AgentSelector
      value={formik.values.agentId}
      onChange={(agentId) => {
        formik.setFieldValue("agentId", agentId);
      }}
      error={formik.touched.agentId && Boolean(formik.errors.agentId) ? formik.errors.agentId as string : undefined}/>

    <FormikSelectInput formik={formik}
                       name={'status'}
                      onChange={event => {
                        if (event.target.value as StatusDto === StatusDto.Disqualified) {
                          setDisqualifiedReason(DisqualifiedReasonDto.NoLongerInterestedInSwitching)
                          setDisqualifiedOpen(true)
                        } else {
                          formik.setFieldValue("status", event.target.value as string);
                        }
                      }}
    >
      <MenuItem value={StatusDto.NotYetEngaged}> <StatusView status={StatusDto.NotYetEngaged} /></MenuItem>
      <MenuItem value={StatusDto.Attempted}> <StatusView status={StatusDto.Attempted} /></MenuItem>
      <MenuItem value={StatusDto.Contacted}> <StatusView status={StatusDto.Contacted} /></MenuItem>
      <MenuItem value={StatusDto.FollowUp}> <StatusView status={StatusDto.FollowUp} /></MenuItem>
      <MenuItem value={StatusDto.SalesQualified}> <StatusView status={StatusDto.SalesQualified} /></MenuItem>
      <MenuItem value={StatusDto.ApplicationSubmitted}> <StatusView status={StatusDto.ApplicationSubmitted} /></MenuItem>
      <MenuItem value={StatusDto.Disenrolled}> <StatusView status={StatusDto.Disenrolled} /></MenuItem>
      <MenuItem value={StatusDto.Disqualified}> <StatusView status={StatusDto.Disqualified} /></MenuItem>
      <MenuItem value={StatusDto.Test}> <StatusView status={StatusDto.Test} /></MenuItem>
      <MenuItem value={StatusDto.Duplicated}> <StatusView status={StatusDto.Duplicated} /></MenuItem>
      <MenuItem value={StatusDto.OutOfService}> <StatusView status={StatusDto.OutOfService} /></MenuItem>
    </FormikSelectInput>
    {
      [StatusDto.FollowUp, StatusDto.SalesQualified, StatusDto.ApplicationSubmitted].includes(formik.values.status as StatusDto) && <div className={'mb-15'}>
            <FollowUpSelector value={moment(formik.values.followUpDate).isValid() ? moment(formik.values.followUpDate).toDate() : null}
                              error={formik.touched.followUpDate ? formik.errors.followUpDate : undefined}
                              onChange={value => formik.setFieldValue("followUpDate", value)} />
        </div>
    }
    {
      formik.values.status === StatusDto.Contacted && <div className={'mb-15'}>
            <ContactedDateSelector value={moment(formik.values.followUpDate).isValid() ? moment(formik.values.followUpDate).toDate() : null}
                              error={formik.touched.followUpDate ? formik.errors.followUpDate : undefined}
                              onChange={value => formik.setFieldValue("followUpDate", value)} />
        </div>
    }
    {
      formik.values.status === StatusDto.SalesQualified && <div className={'mb-15'}>
            <ExpectedCloseDateSelector value={moment(formik.values.expectedCloseDate).isValid() ? moment(formik.values.expectedCloseDate).toDate() : null}
                                       error={formik.touched.expectedCloseDate ? formik.errors.expectedCloseDate : undefined}
                                       onChange={value => formik.setFieldValue("expectedCloseDate", value)} />
        </div>
    }
    <Box sx={{display: 'flex', gap: 2}}>
      <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
      <Button disabled={props.loading} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
    </Box>
  </form>
};