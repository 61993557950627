import {SubscriptionHookOptions, useSubscription} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  AppointmentOutput, SubscriptionAppointmentsArgs,
} from "../../enrollment-types";

export default function useAppointments(options?: SubscriptionHookOptions<{appointments: AppointmentOutput[]}, SubscriptionAppointmentsArgs>) {
  return useSubscription<{appointments: AppointmentOutput[]}, SubscriptionAppointmentsArgs>(gql(query), options);
}

const query = `
subscription($agentId: UUID!) {
  appointments(agentId: $agentId) {
    endTime
    name
    startTime
    url
    invitee {
      clientId
      email
      firstName
      lastName
      name
      url
    }
  }
}
`;
