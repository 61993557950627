import {
  CalcDrugFrequencyPeriod,
  DrugInfoWithPack,
  DrugInfoWithPackagesFlags, DrugOutput, DrugsQuery, FrequencyPeriod
} from '../../../../../types';

export const hasNoPackages = (
  isGeneric = false,
  drugPackagesInfo?: DrugInfoWithPackagesFlags | null
) =>
  (!isGeneric && !drugPackagesInfo?.hasBrandedPackages) ||
  (isGeneric && !drugPackagesInfo?.hasGenericPackages);

export const toOutput = (
  result: DrugDosage,
  drugsWithPackage: DrugInfoWithPack
): DrugOutput => ({
  frequency: result.frequency as CalcDrugFrequencyPeriod,
  quantity: result.quantity as number,
  purchaseFrequency: result.purchaseFrequency as FrequencyPeriod,
  isGeneric: !!drugsWithPackage.isGeneric,
  name: drugsWithPackage.name,
  notSupported: false,
  packName: drugsWithPackage.packName,
  packRxcui: drugsWithPackage.packRxcui as string,
  rxcui: drugsWithPackage.rxcui as string,
  genericName: null,
  genericRxcui: null,
});

export interface DrugDosage {
  dosage?: string;
  quantity?: number;
  frequency?: string;
  purchaseFrequency?: FrequencyPeriod;
}

export type Drug = DrugsQuery['drugs'][0] & DrugDosage
