import React, {useState} from "react";
import {
  Box,
  Typography
} from "@mui/material";
import moment from "moment";
import {
  ClientViewOutput,
  EnrollmentListItemOutput,
  EnrollmentStatusInput, MedicareEnrollmentType,
  PlanYear, useChangeEnrollmentStatusMutation,
  useSaveEnrollmentMutation
} from "../../../../../../enrollment-types";
import Card from "../../../../../../shared/Card";
import {IEnrollmentForm} from "../../../lib/types";
import {MaEnrollmentForm} from "./MaEnrollmentForm";
import _ from "lodash";

interface Props {
  client?: ClientViewOutput,
  initialData?: Partial<EnrollmentListItemOutput>,
  onCancel: () => void,
  onSave: () => void,
}

export const MaEnrollmentFormDataWrapper = (props: Props) => {
  const [saveEnrollment] = useSaveEnrollmentMutation();
  const [changeStatus] = useChangeEnrollmentStatusMutation();
  const [loading, setLoading] = useState(false);

  const [initialData, setInitialData] = React.useState<Partial<EnrollmentListItemOutput> | undefined>(props.initialData);

  const fillFromClient = () => {
    setInitialData( prev => ({
      ...prev,
      zip: props.client?.zip,
      county: props.client?.countyName,
      email: props.client?.email || undefined,
      firstName: props.client?.firstName,
      lastName: props.client?.lastName,
      birthDate: props.client?.birthDate,
      phoneNumber: props.client?.phoneNumber,
    }))
  }

  const save = async (values: IEnrollmentForm) => {
    setLoading(true)
    const fields = _.pick(values, ['bidId', 'zip', 'county', 'firstName', 'lastName', 'email', 'agentId', 'carrierName', 'planName', 'phoneNumber']);
    try {
      const result = await saveEnrollment({
        variables: {
          clientId: props.client?.id,
          input: {
            ...fields,
            id: initialData?.id,
            enrollmentType: values.enrollmentType as MedicareEnrollmentType,
            applicationDate: moment(values.applicationDate).format('YYYY-MM-DD'),
            effectiveDate: moment(values.effectiveDate).format('YYYY-MM-DD'),
            birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
            planYear: (values.planYear as PlanYear) || 'Year' + moment(values.effectiveDate).format('YYYY'),
          }
        }
      });
      await changeStatus({
        variables: {
          status: values.status as unknown as EnrollmentStatusInput,
          enrollmentId: result.data?.saveEnrollment
        }
      })

      props.onSave();
    } finally {
      setLoading(false)
    }
  }

  return <Card sx={{maxWidth: 530, '& > *': {opacity: loading ? 0.5 : 1, pointerEvents: loading ? 'none' : undefined}}}>
    <Box sx={{display: 'flex', alignItems: 'center', width: 500, justifyContent: 'space-between'}}>
      <Typography color={'textPrimary'} variant={'h4'} className={'medium'}>{props.initialData ? 'Edit' : 'Create'} MA enrollment</Typography>
      <Typography className={'pointer underline'} variant={'body1'} color={'textPrimary'} onClick={fillFromClient}>Fill from the client data</Typography>
    </Box>
    <MaEnrollmentForm input={initialData} onSubmit={save} onCancel={props.onCancel} loading={loading} />
  </Card>
};
