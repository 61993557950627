import {StatusDto} from "../../enrollment-types";
import FormSelect from "../FormSelect";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {StatusView} from "../../features";
import React from "react";

const StatusFilter = ({value, onChange}: FilterProps<StatusDto>) => {
  return <FormSelect className={'mb-0'}
                     label={'Status'}
                     value={value}
                     onChange={event => {
                       onChange(event.target.value as StatusDto)
                     }}
  >
    <MenuItem value={''}>-</MenuItem>
    <MenuItem value={StatusDto.NotYetEngaged}> <StatusView status={StatusDto.NotYetEngaged} /></MenuItem>
    <MenuItem value={StatusDto.Attempted}> <StatusView status={StatusDto.Attempted} /></MenuItem>
    <MenuItem value={StatusDto.Contacted}> <StatusView status={StatusDto.Contacted} /></MenuItem>
    <MenuItem value={StatusDto.FollowUp}> <StatusView status={StatusDto.FollowUp} /></MenuItem>
    <MenuItem value={StatusDto.SalesQualified}> <StatusView status={StatusDto.SalesQualified} /></MenuItem>
    <MenuItem value={StatusDto.ApplicationSubmitted}> <StatusView status={StatusDto.ApplicationSubmitted} /></MenuItem>
    <MenuItem value={StatusDto.Disenrolled}> <StatusView status={StatusDto.Disenrolled} /></MenuItem>
    <MenuItem value={StatusDto.Disqualified}> <StatusView status={StatusDto.Disqualified} /></MenuItem>
    <MenuItem value={StatusDto.Test}> <StatusView status={StatusDto.Test} /></MenuItem>
    <MenuItem value={StatusDto.Duplicated}> <StatusView status={StatusDto.Duplicated} /></MenuItem>
    <MenuItem value={StatusDto.OutOfService}> <StatusView status={StatusDto.OutOfService} /></MenuItem>
  </FormSelect>
}

export default StatusFilter;
