import React from "react";
import {config} from "../../../../../config/config";
import {keycloak} from "../../../../../shared/keycloak";
import {CircularProgress, IconButton} from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

export const AudioButton = ({id}: {id?: string}) => {
  const [link, setLink] = React.useState<string>();
  const [showLoading, setShowLoading] = React.useState(false);

  React.useEffect(() => {
    if (id) {
      setLink(config.aircallUrl + '/' + id)
    }
  }, [id])

  const onClick = React.useCallback(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        authorization: 'Bearer ' + keycloak?.token
      },
    };
    if (link) {
      setShowLoading(true);
      fetch(link, requestOptions).then(response => {
        return response.blob().then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', id + '.mpeg');
          document.body.appendChild(link);
          link.click();
        })
      }).finally(() => setShowLoading(false))
    }
  }, [link])

  if (showLoading) {
    return <CircularProgress size={15} />
  }

  if (!id) {
    return null;
  }

  return <>
    <IconButton component="span" disabled={!link} onClick={onClick}>
      <PlayCircleOutlineIcon fontSize={'small'} />
    </IconButton>
  </>
}