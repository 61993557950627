import {AgentViewOutput} from "../../../../../enrollment-types";
import React from "react";
import {List, ListItem, ListItemText, Popover, Typography} from "@mui/material";
import {getUserName} from "../../../../../shared/tools";

export const Watchers = ({watchers, onClick}: {watchers?: AgentViewOutput[], onClick: () => void}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!watchers?.length) {
    return null
  }

  return <>
    <Typography className={'pointer underline'}
                onClick={onClick}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}>+ {watchers.length} more</Typography>
    <Popover
      sx={{
        pointerEvents: 'none',
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <List>
        {watchers.map(watcher => <ListItem dense key={watcher.id}>
          <ListItemText primary={getUserName(watcher)} secondary={watcher.email}/>
        </ListItem>)}
      </List>
    </Popover>
  </>
}
