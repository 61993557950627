import {gql, LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {unathorizedClient} from "../../../Apollo/ApolloClient";

const useZipCounties = (options?: LazyQueryHookOptions<ICityResponse, {zip: string}>) => {
  return useLazyQuery<ICityResponse, {zip: string}>(gql(cityRequest), {
    ...options,
    client: unathorizedClient,
  })
}

export default useZipCounties;

interface ICityResponse {
  plans_zip_county_fips: ICity[]
}

export interface ICity {
  city: string,
  state: string,
  countyname: string
}

const cityRequest = `
  query ($zip: String!) {
    plans_zip_county_fips(where: {zip: {_eq: $zip}}) {
      city
      state
      countyname
    }
  }
`
