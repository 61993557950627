import {MedicareQuoteFilterExtraBenefit, useCreateMedicareQuoteForClientMutation} from "../../types";
import {client as apolloClient} from "../../Apollo/ApolloClient";
import {ClientViewOutput} from "../../enrollment-types";

export const useCreateMedicareQuoteForClient = () => {
  const [createQuote] = useCreateMedicareQuoteForClientMutation({client: apolloClient});

  return async (client: ClientViewOutput) => {
    const result = await createQuote({
      variables: {
        clientId: client.id,
        data: {
          county: client.countyName,
          zip: client.zip,
          filters: {
            extraBenefits: [MedicareQuoteFilterExtraBenefit.DrugCoverage],
          },
        }
      }
    });
    return result.data?.createMedicareQuoteForClient.maQuoteID;
  };
}