import React, {useEffect} from "react";
import {useFormik} from "formik";
import {
  Box,
  Button,
  Divider,
  ListItemText,
  MenuItem, Typography
} from "@mui/material";
import {gql, useLazyQuery} from "@apollo/client";
import moment from "moment";
import {
  fillFormData,
  isEffectiveDateValid,
  mgEnrollmentFormValidationSchema as validationSchema
} from "../../../lib/lib";
import {IMgEnrollmentForm} from "../../../lib/types";
import {
  EnrollmentListItemOutput,
  EnrollmentStatusDto,
  MedigapEnrollmentType
} from "../../../../../../enrollment-types";
import {FormikTextInput} from "../../../../../../shared/formik-text-input";
import {FormikDateInput} from "../../../../../../shared/formik-date-input";
import {FormikSelectInput} from "../../../../../../shared/formik-select-input";
import {Gender} from "../../../../../../medigap-types";
import {ZipSelector} from "../../../../../../features/zip-selector";
// import {getAge} from "../../../../../../PlanList/utils";
import AgentSelector from "../../../../../../Agent/AgentSelector";
import {MgCarrierSelector} from "../../carrier-selector/MgCarrierSelector";
import {getAge} from "../../../../../../shared/utils";
import _ from "lodash";
import {getEnrollmentTypeSubTitle, getEnrollmentTypeTitle} from "../lib";
import {MgPlanSelector} from "../../PlanSelector";


interface FormProps {
  input?: Partial<EnrollmentListItemOutput>,
  onSubmit: (data: IMgEnrollmentForm) => void,
  onCancel: () => void,
  loading: boolean,
}


export const MgEnrollmentForm = ({input, onSubmit, onCancel}: FormProps) => {
  const formik = useFormik({
    initialValues: fillFormData(input, {planKey: input?.mgPlanKey}, validationSchema.default()),
    validationSchema,
    onSubmit,
  });

  const reset = (input: any) => {
    formik.resetForm({
      errors: [] as any,
      values: fillFormData(input, formik.values),
    })
  }

  useEffect(() => {
    if (input) {
      reset(input)
    }
  }, [input]);

  useEffect(() => {
    if (isEffectiveDateValid(formik.values.effectiveDate) && isEffectiveDateValid(formik.values.birthDate)) {
      formik.setFieldValue('age', getAge(formik.values.birthDate as string, formik.values.effectiveDate as string));
    }
  }, [formik.values.effectiveDate, formik.values.birthDate]);

  const effectiveDateStr = formik.values.effectiveDate ? moment.utc(formik.values.effectiveDate).format('YYYY-MM-DD') : undefined;

  return (
    <form onSubmit={formik.handleSubmit} className={'mt-15 w-500 relative'}>
      <Box sx={{display: 'flex', gap: 1}}>
        <FormikTextInput formik={formik}
                         fullWidth
                         name={'firstName'} />
        <FormikTextInput formik={formik}
                         fullWidth
                         name={'lastName'} />
      </Box>

      <Box sx={{display: 'flex', gap: 1}}>
        <FormikDateInput formik={formik}
                         name={'birthDate'} />
        <FormikTextInput formik={formik}
                         fullWidth
                         name={'age'} />
      </Box>

      <FormikTextInput formik={formik}
                       fullWidth
                       name={'email'} />

      <Box sx={{display: 'flex', gap: 1}}>
        <FormikSelectInput formik={formik} name={'tobacco'}
                           value={formik.values.tobacco !== null ? String(formik.values.tobacco) : null}
                           onChange={event => {
                             formik.setFieldValue('tobacco', event.target.value === 'true');
                           }}>
          <MenuItem value={'true'}>Yes</MenuItem>
          <MenuItem value={'false'}>No</MenuItem>
        </FormikSelectInput>

        <FormikSelectInput formik={formik} name={'gender'}>
          <MenuItem value={Gender.M}>Male</MenuItem>
          <MenuItem value={Gender.F}>Female</MenuItem>
        </FormikSelectInput>
      </Box>

      <Divider />

      <Box sx={{display: 'flex', mt: 3, gap: 1}}>
        <FormikDateInput formik={formik}
                         onChange={value => {
                           if (value === null || value > new Date(2000, 1, 1)) {
                             formik.setFieldValue("effectiveDate", value)
                           }
                         }}
                         name={'effectiveDate'} />
        <FormikDateInput formik={formik}
                         onChange={value => {
                           if (value === null || value > new Date(2000, 1, 1)) {
                             formik.setFieldValue("applicationDate", value)
                           }
                         }}
                         name={'applicationDate'} />
      </Box>

      <ZipSelector zip={formik.values.zip}
                   county={formik.values.county}
                   zipError={formik.touched.zip ? formik.errors.zip : undefined}
                   countyError={formik.touched.county ? formik.errors.county : undefined}
                   onChange={data => {
                     formik.setFieldValue('zip', data.zip);
                     formik.setFieldValue('county', data.county);
                     if (formik.values.county !== data.county) {
                       formik.setFieldValue("naic", '');
                       formik.setFieldValue("plan", '');
                     }
                   }} />

      <Box sx={{display: 'flex', gap: 1}}>
        <MgCarrierSelector zip={formik.values.zip}
                           county={formik.values.county}
                           gender={formik.values.gender as Gender}
                           tobacco={formik.values.tobacco}
                           value={formik.values.carrierName}
                           effectiveDate={formik.values.effectiveDate ? moment(formik.values.effectiveDate).format('YYYY-MM-DD') : undefined}
                           disabled={!(formik.values.zip && formik.values.county && formik.values.effectiveDate && formik.values.tobacco !== null && formik.values.gender)}
                           onChange={data => {
                             formik.setFieldValue("carrierName", data.name);
                             formik.setFieldValue("naic", data.naic);
                             formik.setFieldValue("plan", '');
                             formik.setFieldValue("planKey", '');
                           }}
                           age={adjustAge(formik.values.age, formik.values.birthDate, formik.values.effectiveDate)}
        />

        <FormikSelectInput formik={formik} name={'status'}>
          <MenuItem value={EnrollmentStatusDto.Draft}>Draft</MenuItem>
          <MenuItem value={EnrollmentStatusDto.Enrolled}>Enrolled</MenuItem>
          <MenuItem value={EnrollmentStatusDto.New}>New</MenuItem>
          <MenuItem value={EnrollmentStatusDto.Rejected}>Rejected</MenuItem>
          <MenuItem value={EnrollmentStatusDto.Sent}>Sent</MenuItem>
        </FormikSelectInput>
      </Box>

      <MgPlanSelector zip={formik.values.zip}
                      county={formik.values.county}
                      value={formik.values.planKey}
                      onChange={plan => {
                        formik.setFieldValue("planKey", plan.key);
                        formik.setFieldValue("naic", plan.naic)
                        formik.setFieldValue("planName", plan.planName)
                      }}
                      gender={formik.values.gender as Gender}
                      tobacco={formik.values.tobacco}
                      effectiveDate={effectiveDateStr}
                      carrierName={formik.values.carrierName}
                      disabled={!formik.values.carrierName}
                      age={adjustAge(formik.values.age, formik.values.birthDate, formik.values.effectiveDate)}
      />

      <AgentSelector value={formik.values.agentId || undefined}
                     error={formik.touched.agentId && Boolean(formik.errors.agentId) ? formik.errors.agentId as string : undefined}
                     onChange={id => formik.setFieldValue("agentId", id)} />

      <FormikSelectInput formik={formik}
                         sx={{
                           '& p': {display: 'none'},
                           '& .MuiListItemText-primary': {
                             overflow: 'hidden',
                             textOverflow: 'ellipsis'
                           }
                         }}
                         onChange={event => {
                           formik.setFieldValue("enrollmentType", event.target.value as string);
                         }}
                         name={'enrollmentType'}>
        {[MedigapEnrollmentType.MgOep, MedigapEnrollmentType.MgUnderwritten, MedigapEnrollmentType.MgGi].map(type => <MenuItem key={type} onClick={() => {
          formik.setFieldValue("enrollmentType", type)
        }} value={type as string}>
          <ListItemText primary={getEnrollmentTypeTitle(type)} secondary={getEnrollmentTypeSubTitle(type)} />
        </MenuItem>)}
      </FormikSelectInput>

      {!!formik.values['enrollmentType'] && <Typography color={'textPrimary'} variant={'body1'} className={'mb-20'}>
        Commission paid as and when premiums are paid by multiplying commission rate % by monthly premium
      </Typography>}

      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Button variant={'outlined'} onClick={onCancel}>CANCEL</Button>
        <Button variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
      </Box>
    </form>
);
}

const adjustAge = _.memoize(
  (initialAge: string, birthDate: Nullable<string>, effectiveDate: Nullable<string>) => {
    const eligibleDate = moment.utc(birthDate).add(65, 'years');
    const diff = moment.duration(eligibleDate.diff(effectiveDate)).asDays();
    let age = parseFloat(initialAge);
    if (diff < 31 && diff > 0) {
      age = 65
    }
    return age;
  }
)