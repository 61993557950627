import {useSendComparisonEmail} from "../../../../../Client/hooks/use-send-comparison-email/useSendComparisonEmail";
import {config} from "../../../../../config/config";
import {enqueueSnackbar} from "notistack";
import React from "react";
import {LoadingButton} from "@mui/lab";

export function SendEmailButton({clientId}: {clientId?: string}) {
  const [sendEmail, {loading}] = useSendComparisonEmail();

  const handleClick = async () => {
    try {
      if (clientId) {
        await sendEmail({
          variables: {
            clientId,
            link: `${config.userFlowUrl}compare-offer?magic-link=true`
          }
        })
        enqueueSnackbar('Email sent!', {variant: "info"})
      }
    } catch (error) {}
  }

  return <LoadingButton onClick={handleClick}
                        size={'small'}
                        variant={'contained'}
                        color={'primary'}>
    Send email
  </LoadingButton>
}