import { ErrorResponse } from "@apollo/client/link/error";
import { print } from 'graphql';

export let graphQLErrorHandler: ( errResponse: ErrorResponse) => void;

export const setErrorHandler = (callback: (errResponse: ErrorResponse) => void) => graphQLErrorHandler = callback;

export const errorTracker = (errResponse: ErrorResponse, token?: string) => {
  const errorObj: any = {
    graphQLErrors: errResponse.graphQLErrors,
    networkError: errResponse.networkError,
    request: {
      query: print(errResponse.operation.query),
      variables: errResponse.operation.variables
    },
    response: errResponse.response,
    version: (window as any).version,
    token
  }

  if (localStorage.getItem('filters')) {
    errorObj['maFilters'] = JSON.parse(localStorage.getItem('filters') as string);
  }

  if (localStorage.getItem('medigapFilters')) {
    errorObj['mgFilters'] = JSON.parse(localStorage.getItem('medigapFilters') as string);
  }

  if ((window as any).track) {
    (window as any)?.track('error', {
      message: 'request error',
      ...errorObj
    });
  }

  console.error(errorObj)
}
