import React from "react";
import {
  FormControl, FormControlProps,
  FormHelperText, InputLabel,
  MenuItem,
  Select, SelectProps, TextField,
  Theme
} from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';

import clsx from "clsx";
import {colors} from "../app/AppTheme";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '20px',
      width: '100%',
      '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
        transform: 'translate(14px, 13px) scale(1)'
      },
    },
    inputLabel: {
      fontSize: '13px',
      lineHeight: '20px',
      marginBottom: '10px',
      color: colors.text.primary,
      fontWeight: 500,
    },
    select: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `rgba(2, 144, 148, 1)!important`,
        borderWidth: '1px!important',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #CCD8DC',
        borderRadius: '5px',
        backgroundColor: 'white',
      },
      '& .MuiOutlinedInput-input': {
        fontSize: '13px',
        padding: '10.5px 14px!important',
        zIndex: 1,
      },
      '& .MuiSelect-iconOutlined': {
        zIndex: 1
      }
    },
    paper: {
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: 4,
      '& ul': {
        padding: '10px 0',
      },
      '& li': {
        fontSize: 13,
        padding: '9px 20px',
      }
    }
  }),
);

export type FormSelectProps = FormControlProps<'select', SelectProps> & {helperText?: string | React.ReactElement, placeholder?: string};

export default function FormSelect(props: FormSelectProps) {
  const classes = useStyles();
  const {helperText, ...rest} = props;

  return (
    <div className={clsx(classes.root, props.className)}>
      <FormControl disabled={props.disabled} fullWidth variant={'outlined'} error={props.error}>
        <InputLabel>
          {props.label}
        </InputLabel>
        <Select
          {...rest}
          displayEmpty={!!props.placeholder}
          className={classes.select}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            PopoverClasses: {
              paper: classes.paper
            }
          }}
        >
          {!!props.placeholder && <MenuItem value="" disabled>
            {props.placeholder}
          </MenuItem>}
          {props.children}
        </Select>
        {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
}


