import {Conversation, Participant} from "@twilio/conversations";
import {useEffect, useState} from "react";
import {getAgentsLastReadIndex} from "./lib";

export default function useLastReadIndex(conversation?: Conversation) {
  const [lastReadMessageIndex, setLastReadMessageIndex] = useState(-1)

  const updateIndex = async ({participant}: { participant: { conversation: Conversation } }) => {
    const index = await getAgentsLastReadIndex(participant.conversation)
    setLastReadMessageIndex(index)
  }

  useEffect(() => {
    if (conversation) {
      updateIndex({participant: {conversation}})
      conversation.on('participantUpdated', updateIndex)
    }
    return () => {
      conversation?.off('participantUpdated', updateIndex)
    }
  }, [conversation]);

  return lastReadMessageIndex
}