import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationUpdateClientNoteArgs} from "../../../../enrollment-types";

export default function useUpdateClientNote(options?: MutationHookOptions<{updateClientNote: boolean}, MutationUpdateClientNoteArgs>) {
  return useMutation<{updateClientNote: boolean}, MutationUpdateClientNoteArgs>(gql(save), options)
}

const save = `
mutation ($noteId: UUID!, $note: String!) {
  updateClientNote(noteId: $noteId, note: $note)
}
`;

