import React from "react";
import {
  Box,
  Chip,
  Typography
} from "@mui/material";
import * as _ from "lodash";
import Card from "../../../shared/Card";
import {ClientViewOutput} from "../../../enrollment-types";
import ChipWrapper from "../../../shared/ChipWrapper";

interface UserPrioritiesProps {
  client?: ClientViewOutput,
}

const UserPriorities = ({client}: UserPrioritiesProps) => {
  return <Card className={'fill-width'}>
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography color={'textPrimary'} variant={'h4'} className={'mb-15'}>Selected extra benefits</Typography>
    </Box>
    <ChipWrapper title={'Top priority'}>
      {client?.filters?.extraBenefits.map(b => <Chip key={b} size="small" label={_.upperFirst(b.toLowerCase())}/>)}
    </ChipWrapper>
  </Card>;
}

export default UserPriorities;
