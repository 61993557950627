import {Prompt} from "react-router-dom";
import React, {useEffect} from "react";
import {ClientViewOutput, StatusDto} from "../../../enrollment-types";
import moment from "moment/moment";
import {keycloak} from "../../../shared/keycloak";
import {createConfirmModal} from ".";

export function CloseClientPrompt({client}: {client: ClientViewOutput}) {
  const open = client && [StatusDto.FollowUp, StatusDto.SalesQualified, StatusDto.Contacted].includes(client.status) &&
    client.followUpDate && moment(client.followUpDate).isSameOrBefore(moment().endOf('day')) &&
    keycloak.tokenParsed.sub === client?.agent?.id;

  useEffect(() => {
    if (open) {
      window.onbeforeunload = function () {
        setTimeout(() => createConfirmModal(client), 1000)
        return 'Reload';
      }
    }
    return () => {
      window.onbeforeunload = null;
    }
  }, [open]);

  return (
    <Prompt message={JSON.stringify(client)} when={open}/>
  )
}
