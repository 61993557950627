import {Conversation, Message} from "@twilio/conversations";
import {useEffect, useState} from "react";

export default function useMessagesForConversation(conversation?: Conversation) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false)

  const loadMessagesFor =  async (conv: Conversation) => {
    setLoading(true)
    setMessages([])
    try {
      const messagePaginator = await conv.getMessages();
      setMessages(messagePaginator.items)
    } catch (err) {
      console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
    }
    setLoading(false)
  };

  const messageAddedHandler = async (m: Message) => {
    await conversation?.setAllMessagesRead();
    setMessages(prev => [...prev, m]);
  }

  useEffect(() => {
    if (conversation) {
      loadMessagesFor(conversation);
      conversation.on('messageAdded', messageAddedHandler);
    }
    return () => {
      conversation?.off('messageAdded', messageAddedHandler);
    }
  }, [conversation]);

  return {messages, loading};
}
/*
const participants = await conversation?.getParticipants();
    if (participants) {
      const lastAgentReadIndex = (
        participants.filter(p => p.sid !== (conversation?.attributes as any).clientPaticipantSid)
          .reduce((prev, current) => {
            return (current.lastReadMessageIndex ?? -1) > prev ? (current.lastReadMessageIndex ?? -1) : prev
          }, -1)
      );
      await conversation?.advanceLastReadMessageIndex(lastAgentReadIndex);
    }
 */
