import React from "react";
import {Box, Typography} from "@mui/material";
import moment from "moment";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {useLoginStatsQuery} from "../../../types";
import {client} from "../../../Apollo/ApolloClient";
import Card from "../../../shared/Card";

const columns: GridColDef[] = [
  { field: 'eventDate', headerName: 'Event Date', width: 150,
    renderCell: (params) => moment(params.value?.toString()).format('L')
  },
  { field: 'event', headerName: 'Event', width: 150 },
  { field: 'emailToken', headerName: 'Email Token', width: 250 },
];

interface LoginStatsProps {
  loading: boolean;
  email?: string | null;
}

const LoginStats = (props: LoginStatsProps) => {
  const {loading, data} = useLoginStatsQuery({
    fetchPolicy: 'no-cache',
    client,
    skip: !props.email,
    variables: {email: props.email as string}
  });

  const rows = data?.loginStats || [];

  return <div>
    <Typography color={'textPrimary'} className={'fs-16 medium'}>Login Stats</Typography>
    <Card padding={0} className={'relative'}>
      <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
        <div style={{height: 300, width: '100%'}}>
          <DataGrid rows={rows.map((i, index) => ({...i, id: index}))}
                    columns={columns}
                    loading={props.loading || loading}
                    paginationModel={{
                      page: 0,
                      pageSize: 100
                    }}
                    paginationMode={'client'}
                    rowCount={rows.length}
          />
        </div>
      </Box>
    </Card>
  </div>;
}

export default LoginStats;
