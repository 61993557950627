import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  PageableClientViewOutput,
  QuerySalesQualifiedArgs
} from "../../enrollment-types";

export default function useSalesQualified(options?: LazyQueryHookOptions<{salesQualified: PageableClientViewOutput}, QuerySalesQualifiedArgs>) {
  return useLazyQuery<{salesQualified: PageableClientViewOutput}, QuerySalesQualifiedArgs>(gql(query), {
    fetchPolicy: 'no-cache',
    ...options
  });
}

const query = `
  query ($agentId: UUID!, $pageInput: PageInput!, $excludedTagsIds: [UUID!], $includedTagsIds: [UUID!], $searchTerm: String, $sort: ClientViewSortInput) {
    salesQualified(agentId: $agentId, pageInput: $pageInput, includedTagsIds: $includedTagsIds, excludedTagsIds: $excludedTagsIds, searchTerm: $searchTerm, sort: $sort) {
      data {
        id
        firstName
        email
        birthDate
        status
        createdAt
        watchers {
          id
        }
        agent {
          id
        }
        notes {
          createdAt
          createdByAgent {
            email
            firstName
            id
            lastName
          }
          id
          note
          updatedAt
        }
        lastName
        phoneNumber
        state
        followUpDate
        expectedCloseDate
        tags {
          expiresAt
          tag {
            color
            id
            name
          }
        }
      }
      totalElements
      isLast
    }
  }
`
