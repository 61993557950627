import {
  Box,
  Card,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton, MenuItem, Radio, RadioGroup,
  Typography
} from "@mui/material";
import React, {useCallback, useState} from "react";
import {WithCalls, getUserName} from "../tools";
import {LoadingButton} from "@mui/lab";
import {
  ClientViewOutput,
  DisqualifiedReasonDto,
  StatusDto,
  useSaveDisqualifiedReasonMutation, useSaveExpectedCloseDateMutation, useSaveStatusMutation
} from "../../enrollment-types";
import FormSelect from "../FormSelect";
import {useConfirm} from "../ConfirmDialog/ConfirmDialogContext";
import moment from "moment/moment";
import {labelByStatus} from "../../features";
import {useFormik} from "formik";
import AgentSelector from "../../Agent/AgentSelector";
import {ExpectedCloseDateSelector, FollowUpSelector} from "../../features/connect-client-date-selector";

export default function StatusDrawerContent({client, onClose}: {client: WithCalls<ClientViewOutput>, onClose: (refresh?: boolean) => void}) {
  const [disqualifiedReason, setDisqualifiedReason] = React.useState<DisqualifiedReasonDto>();
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  const [saveExpectedCloseDate] = useSaveExpectedCloseDateMutation();
  const [saveDisqualifiedReason] = useSaveDisqualifiedReasonMutation();
  const [save] = useSaveStatusMutation();

  const submit = useCallback(async (status: StatusDto, agentId?: string, followUpDate?: string | null, expectedCloseDate?: string | null, disqualifiedReason?: DisqualifiedReasonDto) => {
    const saveStatus = () => {
      const variables: any = {
        id: client?.id,
        status,
        agentId,
      }
      if (!!followUpDate) {
        variables.followUpDate = moment(followUpDate).format('YYYY-MM-DD');
      }
      return save({ variables })
    }



    if ([StatusDto.FollowUp, StatusDto.Contacted, StatusDto.SalesQualified, StatusDto.ApplicationSubmitted].includes(status) ) {
      if (!!followUpDate) {
        await saveStatus();

        if (!!expectedCloseDate) {
          await saveExpectedCloseDate({
            variables: {
              id: client?.id,
              expectedCloseDate: moment(expectedCloseDate).format('YYYY-MM-DD'),
            }
          })
        }
      } else {
        throw Error('followUpDate missing!')
      }
    } else if (status === StatusDto.Disqualified && disqualifiedReason) {
      await saveStatus();
      await saveDisqualifiedReason({
        variables: {
          id: client?.id,
          disqualifiedReason,
        }
      });
    } else {
      await saveStatus();
    }

  }, [client]);

  const formik = useFormik({
    initialValues: {
      status: client.status,
      followUpDate: client.followUpDate,
      expectedCloseDate: client.expectedCloseDate,
      agentId: client.agent?.id,
      disqualifiedReason: undefined
    },
    onSubmit: values => {
      let error = false;
      if ([StatusDto.FollowUp, StatusDto.Contacted, StatusDto.SalesQualified, StatusDto.ApplicationSubmitted].includes(formik.values.status as StatusDto)) {
        if (!values.followUpDate) {
          formik.setFieldError('followUpDate', 'Date is required')
          error = true;
        }

        if ([StatusDto.Contacted, StatusDto.FollowUp, StatusDto.SalesQualified].includes(formik.values.status as StatusDto) && !values.agentId) {
          formik.setFieldError('agentId', 'Agent is required for this status')
          error = true;
        }

        if ([StatusDto.SalesQualified].includes(formik.values.status as StatusDto) && !values.expectedCloseDate) {
          formik.setFieldError('expectedCloseDate', 'Date is required')
          error = true;
        }
      }

      if (!error) {
        setLoading(true);

        submit(
          values.status as StatusDto,
          values.agentId,
          values.followUpDate,
          values.expectedCloseDate,
          StatusDto.Disqualified === values.status && disqualifiedReason ? disqualifiedReason : undefined
        )
          .finally(() => {
            setLoading(false);
            onClose(true);
          })
      }
    }
  });

  const onDisqualifiedReasonSelect = () => (
    confirm({
      content: props => (
        <Box sx={{pt: 2}}>
          <ExpiresAtSelect onChange={props.onValueChange} />
        </Box>
      ),
      title: 'Select Disqualified Reason',
      okButtonTitle: 'Save',
      okButtonColor: 'primary',
      cancelButtonTitle: 'Close',
      width: 500
    })
      .then(val => {
        if (typeof val === "string") {
          setDisqualifiedReason(val as DisqualifiedReasonDto)
          formik.setFieldValue("status", StatusDto.Disqualified);
        }
      })
  )


  return <Card sx={{p: 4, width: 594, overflowY: 'auto'}}>
    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      <IconButton size={'small'} onClick={() => onClose()}>
        <img className={'w-32 h-32'} src={'/img/X.svg'}/>
      </IconButton>
    </Box>
    <Typography variant={'h3'}>{getUserName(client)}’s Status</Typography>
    <Divider sx={{mb: 3}} />
    <Box component={'form'} sx={{flexDirection: 'column', gap: 2, display: 'flex'}} onSubmit={formik.handleSubmit}>
      <RadioGroup sx={{mx: -.5, mb: 3, display: 'block'}}
                  value={formik.values.status}
                  onChange={e => {
                    if (e.target.value as StatusDto === StatusDto.Disqualified) {
                      onDisqualifiedReasonSelect();
                    } else {
                      formik.setFieldValue("status", e.target.value as string);
                    }
                  }}
      >
        {Object.values(StatusDto).map((status, i) => (
          <Box sx={{width: 'calc(50% - 8px)', display: 'inline-block', mx: .5, mt: 1}} key={status}>
            <DrawerRadio value={status}
                         checked={formik.values.status === status}
                         label={labelByStatus(status)} />
          </Box>
        ))}
      </RadioGroup>



      <Collapse in={[StatusDto.Contacted, StatusDto.FollowUp,StatusDto.SalesQualified].includes(formik.values.status as StatusDto)}>
        <AgentSelector
          value={formik.values.agentId}
          onChange={(agentId) => {
            formik.setFieldValue("agentId", agentId);
          }}
          error={formik.touched.agentId && Boolean(formik.errors.agentId) ? formik.errors.agentId as string : undefined}/>
      </Collapse>


      <Collapse in={[StatusDto.FollowUp, StatusDto.Contacted, StatusDto.SalesQualified, StatusDto.ApplicationSubmitted].includes(formik.values.status as StatusDto)}>
        <FollowUpSelector value={moment(formik.values.followUpDate).isValid() ? moment(formik.values.followUpDate).toDate() : null}
                          error={formik.touched.followUpDate ? formik.errors.followUpDate as string : undefined}
                          onChange={value => formik.setFieldValue("followUpDate", value)} />
      </Collapse>

      <Collapse in={[StatusDto.SalesQualified].includes(formik.values.status as StatusDto)}>
        <ExpectedCloseDateSelector value={moment(formik.values.expectedCloseDate).isValid() ? moment(formik.values.expectedCloseDate).toDate() : null}
                          error={formik.touched.expectedCloseDate ? formik.errors.expectedCloseDate as string : undefined}
                          onChange={value => formik.setFieldValue("expectedCloseDate", value)} />
      </Collapse>

      <div>
        <LoadingButton variant={'contained'}
                       loading={loading}
                       type={'submit'}
                       color={'primary'}>Save</LoadingButton>
      </div>
    </Box>
  </Card>
}

const DrawerRadio = ({value, label, checked}: {label: string, value: StatusDto, checked: boolean}) => {

  return <FormControlLabel sx={{
                              borderRadius: '8px',
                              backgroundColor: checked ? '#1E95A0' : 'white',
                              color: checked ? 'white' : '#000',
                              width: 1,
                              mx: 0,
                              border: '1px solid #666',
                            }}
                           value={value}
                           control={<Radio checkedIcon={<img src={'/img/CheckCircleWhite.svg'} width={24} height={24} />}
                                           sx={{
                                             p: 1,
                                             color: '#1C434F',
                                           }} />}
                           label={label} />
}

const ExpiresAtSelect = ({onChange}: {onChange: (value: number) => void}) => (
  <FormSelect label={'Select'}
              defaultValue={DisqualifiedReasonDto.NoLongerInterestedInSwitching}
              onChange={event => {
                onChange(event.target.value as number);
              }}
  >
    <MenuItem value={DisqualifiedReasonDto.NoLongerInterestedInSwitching}>No Longer Interested In Switching</MenuItem>
    <MenuItem value={DisqualifiedReasonDto.UnableToConnect}> Unable To Connect</MenuItem>
    <MenuItem value={DisqualifiedReasonDto.WentToAnotherBroker}> Went To Another Broker</MenuItem>
  </FormSelect>
)

