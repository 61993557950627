import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationSetClientUserIdArgs} from "../../enrollment-types";

export default function useSetClientUserId(options?: MutationHookOptions<{setClientUserId: boolean}, MutationSetClientUserIdArgs>) {
  return useMutation<{setClientUserId: boolean}, MutationSetClientUserIdArgs>(gql(saveUserInfoMutation), {
    ...options
  })
}

const saveUserInfoMutation = `
mutation ($clientId: UUID!, $userId: UUID) {
  setClientUserId(clientId: $clientId, userId: $userId)
}
`;
