import React, {PropsWithChildren} from "react";
import {Box, SxProps} from "@mui/material";

const Card = (props: PropsWithChildren<any> & {padding?: number | string, className?: string, sx?: SxProps}) => {

  return <Box sx={{
    background: 'white',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    padding: typeof props.padding === 'undefined' ? 2 : props.padding,
    display: 'flex',
    flexDirection: 'column',
    ...props.sx
  }} className={`${props.className}`}>
    {props.children}
  </Box>;
}

export default Card;
