import React, {useCallback, useState} from "react";
import {
  Box,
  Grid, MenuItem,
  Typography
} from "@mui/material";
import * as _ from "lodash";
import {AddressSelectionModal} from "./ui/AddressSelectionModal";
import {DoctorForm} from "./ui/DoctorForm";
import {DoctorLine} from "./ui/DoctorLine";
import ChipWrapper from "../../../../../../shared/ChipWrapper";
import {ZipSelector} from "../../../../../../features";
import FormSelect from "../../../../../../shared/FormSelect";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import {DoctorSearchAutocomplete} from "./ui/DoctorSearchAutocomplete";
import {DoctorAddressViewOutput, DoctorViewOutput} from "../../../../../../enrollment-types";
import {useDebouncedEffect} from "../../../../../../shared/hooks/useDebouncedEffect";


type DoctorSelectionProps = {
  zip: string,
  county: string,
  selectedDoctors: DoctorViewOutput[],
  onChange: (doctors: DoctorViewOutput[]) => any
}

export const DoctorSelection = ({
                                  zip,
                                  county,
                                  selectedDoctors,
                                  onChange
                                }: DoctorSelectionProps) => {
  const [showAutocomplete, setShowAutocomplete] = React.useState(false);
  const [location, setLocation] = useState<{zip: string, county: string}>({zip, county})
  const [radius, setRadius] = useState(30)

  const [modalOpen, setModalOpen] = React.useState(false);
  const [doctor, setDoctor] = React.useState<DoctorViewOutput>();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [showForm, setShowForm] = React.useState<number>();
  const [toDelete, setToDelete] = useState<DoctorViewOutput[]>([]);

  const [addresses, setAddresses] = useState<DoctorAddressViewOutput[]>([])

  React.useEffect(() => {
    if (selectedDoctors.length) {
      setShowAutocomplete(false);
    }
    setToDelete([])
  }, [selectedDoctors]);

  React.useEffect(() => {
    if (inputRef.current && showAutocomplete) {
      (inputRef.current as any).focus();
    }
  }, [showAutocomplete, inputRef.current])

  const onModalClose = (addresses?: DoctorAddressViewOutput[]) => {
    setModalOpen(false);
    if (addresses && doctor) {
      onChange([
        ..._.differenceWith(selectedDoctors, [doctor], (v1, v2) => v1.npi === v2.npi),
        {
          ...doctor as DoctorViewOutput,
          addresses,
        }])
    } else {
      setDoctor(undefined);
    }
    setAddresses([])
  }

  useDebouncedEffect(() => {
    if (toDelete.length) {
      onChange(_.differenceWith(selectedDoctors, toDelete, (v1, v2) => v1.npi === v2.npi));
    }
  }, 600, [toDelete])

  const onDeleteClick = (doctor: DoctorViewOutput) => {
    setToDelete(prev => [...prev, doctor])
  }

  const onEditClick = (index: number) => {
    setShowForm(index);
  }

  const onSaveClick = (doctor: DoctorViewOutput) => {
    if (doctor) {
      onChange([
        ..._.differenceWith(selectedDoctors, [doctor], (v1, v2) => v1.npi === v2.npi), doctor
      ]);
    }
    setShowForm(undefined);
  }

  const handleDoctorSelect = (doctor: DoctorViewOutput) => {
    if (doctor.addressesNumber > 1) {
      setModalOpen(true)
      setDoctor({...doctor, addresses: []});
      setAddresses(doctor.addresses)
    } else {
      onChange([...selectedDoctors, doctor as DoctorViewOutput]);
      setDoctor(undefined);
      setAddresses([])
    }
    setShowAutocomplete(false);
  }

  return <>
    <AddressSelectionModal open={modalOpen} doctor={doctor} onClose={onModalClose} addresses={addresses}/>

    {selectedDoctors.map((doctor, index) => (
      <Box key={doctor.npi} display={'flex'} className={'mb-10'}>
        <Typography variant={'body1'} color={'textPrimary'} className={`w-15`}>{index + 1}.</Typography>
        {showForm !== index && <DoctorLine doctor={doctor} showPCP showDelete onEditClick={() => onEditClick(index)}
                                           loading={toDelete.includes(doctor)}
                                           onDeleteClick={() => onDeleteClick(doctor)}/>}
        {showForm === index &&
            <DoctorForm doctor={doctor} onCancel={() => setShowForm(undefined)} onSaveClick={onSaveClick}/>}
      </Box>
    ))}

    <div className={'h-16'}/>
    {showAutocomplete && <ChipWrapper title={'Add Doctor'} >
        <IconButton size={'small'}
                    onClick={() => setShowAutocomplete(false)}
                    sx={{position: 'absolute', right: -16, top: -16, backgroundColor: '#FFF'}}>
            <Close />
        </IconButton>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ZipSelector zip={location.zip}
                       county={location.county}
                       onChange={setLocation}
                       sx={{'& > div': {mb: 0} }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect label={'Radius'} onChange={e => setRadius(e.target.value as number)} value={radius} className={'mb-0'}>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={200}>200</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </FormSelect>
        </Grid>

        <Grid item xs={12}>
          <DoctorSearchAutocomplete zip={zip} radius={radius}
                                    onChange={handleDoctorSelect}
                                    selectedNpi={selectedDoctors.map(d => d.npi)} />
        </Grid>
      </Grid>
    </ChipWrapper>}
    {!showAutocomplete && <Typography className={'dark-green pointer'}
                                      variant={'body2'}
                                      onClick={() => setShowAutocomplete(true)}>
        <img src={'/img/add-icon.svg'}/> Add Doctor
    </Typography>}
  </>
}

