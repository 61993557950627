import {withStyles} from "@mui/styles";
import {Autocomplete} from "@mui/material"
import React from "react";


const AscendAutocomplete = withStyles({
  inputRoot: {
    padding: '0!important',
  },
  popupIndicator: {
    marginRight: 11
  },
  popper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    marginTop: 10,
  },
  paper: {
    boxShadow: 'none',
    borderRadius: 2,
    margin: 0
  },
  listbox: {

  },
  endAdornment: {
    display: 'none',
  },
  option: {
    padding: '9px 15px',
    borderBottom: '1px solid #DDDDDD',
  }
})(Autocomplete)

export default AscendAutocomplete;



