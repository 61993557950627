import {Box, Button, Card, Divider, IconButton, Stack, Typography} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {getUserName, WithCalls} from "../tools";
import {ClientNoteOutput, ClientViewOutput} from "../../enrollment-types";
import moment from "moment";
import AddIcon from '@mui/icons-material/Add';
import TextInput from "../TextInput";
import useCreateClientNote from "../../Client/widgets/notes/hooks/useCreateClientNote";
import useUpdateClientNote from "../../Client/widgets/notes/hooks/useUpdateClientNote";
import useDeleteClientNote from "../../Client/widgets/notes/hooks/useDeleteClientNote";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {useConfirm} from "../ConfirmDialog/ConfirmDialogContext";
import * as _ from 'lodash';


export default function NotesDrawerContent({client, onClose}: {client: WithCalls<ClientViewOutput>, onClose: (refresh?: boolean) => void}) {
  const [notes, setNotes] = React.useState<Partial<ClientNoteOutput>[]>(client.notes ? _.orderBy(client.notes, 'createdAt', 'desc') : []);
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false)
  const confirm = useConfirm();
  const [shouldRefresh, setShouldRefresh] = useState(false)

  const onError = () => {
    enqueueSnackbar("Something wrong", {variant: "error"});
  }

  const [create, {loading: createLoading}] = useCreateClientNote({onError, onCompleted: () => enqueueSnackbar('Saved', {variant: "success"})});
  const [update, {loading: updateLoading}] = useUpdateClientNote({onError, onCompleted: () => enqueueSnackbar('Saved', {variant: "success"})});
  const [deleteNote] = useDeleteClientNote({onError, onCompleted: () => enqueueSnackbar('Note deleted!', {variant: "info"})});

  const onDelete = (index: number) => (
    confirm({
      title: 'Are you sure you want to delete this note?',
      content: 'You will not able to restore it later!',
    })
      .then(async val => {
        if (val) {
          const note = notes[index];
          setNotes(prev => {
            const res = [...prev];
            res.splice(index, 1);
            return res;
          })
          if (note.id) {
            await deleteNote({variables: {noteId: note.id}});
            setShouldRefresh(true)
          }
        }
      })
  )

  const save = async () => {
    setLoading(true);
    for (const note of notes) {
      if (!!note) {
        if (!note.id) {
          await create({variables: note as any})
        } else {
          await update({
            variables: {
              noteId: note.id,
              note: note.note || ''
            }
          })
        }
      }
    }
    setLoading(false);
    onClose(true);
  }

  const hasUnsavedNewNote = useMemo(() => {
    return notes.some(n => !n.id)
  }, [notes]);

  return <Card sx={{p: 4, width: 594, overflowY: 'auto'}}>
    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      <IconButton size={'small'} onClick={() => onClose(shouldRefresh)}>
        <img className={'w-32 h-32'} src={'/img/X.svg'}/>
      </IconButton>
    </Box>
    <Typography variant={'h3'}>{getUserName(client)}’s Notes</Typography>
    <Divider sx={{mb: 3, mt: 2}} />
    <Stack spacing={2}>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <LoadingButton loading={loading} variant={'contained'}
                onClick={save}>
          Save
        </LoadingButton>
        <Button variant={'outlined'}
                startIcon={<AddIcon />}
                disabled={hasUnsavedNewNote}
                onClick={() => {
                  setNotes(prevState => [{note: '', clientId: client.id}, ...prevState.filter(n => !!n.note)])
                }}>
          Add new
        </Button>
      </Box>

      {notes.map((note, i) => <Note key={note.id + i}
                                    onChange={text => setNotes(prev => {
                                      prev[i].note = text;
                                      return prev;
                                    })}
                                    onDeleteClick={() => onDelete(i)}
                                    note={note} />)}
    </Stack>
  </Card>
}

const Note = ({note, onChange, onDeleteClick}: {note?: Partial<ClientNoteOutput>, onChange: (text: string) => void, onDeleteClick: () => void}) => {
  const [value, setValue] = useState(note?.note || '')

  useEffect(() => {
    onChange(value)
  }, [value])

  return <div>
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Typography className={'medium'}>{moment(note?.createdAt).format('MMMM D')} Notes {note?.createdByAgent?.firstName ? 'by ' + note?.createdByAgent?.firstName : ''}</Typography>
      <IconButton size={'small'} onClick={onDeleteClick}>
        <img className={'w-24 h-24'} src={'/img/X.svg'}/>
      </IconButton>
    </Box>
    <TextInput
      fullWidth
      multiline
      rows={10}
      name="note"
      defaultValue={value}
      onChange={e => setValue(e.target.value as string)}
    />
  </div>
}
