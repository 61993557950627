import {Box, Card,  Typography} from "@mui/material";
import React from "react";
import {ChatMessage, MessageMenuType} from "../lib/types";
import {getUserName} from "../../shared/tools";
import MessageMenu from "./message-menu";

interface Props {
  message: ChatMessage,
  direction: "outgoing" | "incoming",
  onMenuClick: (type: MessageMenuType) => void
}

export default function Message({message, direction, onMenuClick}: Props) {
  return <Box className={`message-wrapper ${direction}`} sx={{display: 'flex', flexDirection: direction === 'outgoing' ? 'row-reverse' : 'row', gap: 1, alignItems: 'flex-start', width: 1}}>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: .5, alignItems: direction === 'outgoing' ? 'flex-end' : 'flex-start', maxWidth: '65%', }}>
      <Card sx={{p: 1}}>
        <Typography className={'bold fs-12'} sx={{textAlign: direction === 'outgoing' ? 'right' : 'left'}}>{getUserName(message.attributes)}</Typography>
        <Typography sx={{whiteSpace: 'pre-wrap'}}>{message.body}</Typography>
      </Card>
      <Box>
        <Typography className={'medium fs-10'}>{message.dateCreated?.toLocaleTimeString()}</Typography>
      </Box>
    </Box>
    {direction === 'incoming' && <MessageMenu onSetUnreadClick={() => onMenuClick("setUnread")}/>}
  </Box>
}
