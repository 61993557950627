import useSessionStorageState from "../../shared/useSessionStorageState";
import {SlaFilterInput} from "../../enrollment-types";
import moment from "moment";

export default function() {
  return useSessionStorageState<SlaFilterInput>(
    'SLAFilter',
    {range: {
        from: moment().subtract(2, 'days').toISOString(),
        to: moment().toISOString()
      }}
  )
}
