import React from "react";
import {PlanCoverage} from "./ui/PlanCoverage";
import {usePdpStates} from "./hooks/use-pdp-states";
import {usePdpContracts} from "./hooks/use-pdp-contracts";

export function PdpPlanCoverage() {
  const contractsApi = usePdpContracts();
  const statesApi = usePdpStates();

  return <PlanCoverage statesApi={statesApi}
                       contractsApi={contractsApi} />
}