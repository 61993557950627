import {CallHistoryCallType} from "../../../../../enrollment-types";
import {GridRenderEditCellParams} from "@mui/x-data-grid";
import FormSelect from "../../../../../shared/FormSelect";
import {MenuItem} from "@mui/material";
import React from "react";
import {stringifyCallType} from "../lib";



const CallTypeSelect = (props: GridRenderEditCellParams) => {
  const { id, value, field, api } = props;

  const handleChange = (event: any) => {
    api.setEditCellValue({ id, field, value: event.target.value }, event);
    // Check if the event is not from the keyboard
    // https://github.com/facebook/react/issues/7407
    /*api.commitCellChange({ id, field });
    api.setCellMode(id, field, 'view');*/
  };

  return <FormSelect value={value?.toString()}
                     onChange={handleChange}
  >
    <MenuItem value={CallHistoryCallType.Inbound}>{stringifyCallType(CallHistoryCallType.Inbound)}</MenuItem>
    <MenuItem value={CallHistoryCallType.Outbound}>{stringifyCallType(CallHistoryCallType.Outbound)}</MenuItem>
  </FormSelect>
}

export function renderCallTypeEditInputCell(params: GridRenderEditCellParams) {
  return <CallTypeSelect {...params} />;
}