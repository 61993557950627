import useTags from "../../Tags/hooks/useTags";
import React, {useMemo} from "react";
import {Box, Chip, MenuItem} from "@mui/material";
import FormSelect from "../../shared/FormSelect";
import * as _ from "lodash";
import {config} from "../../config/config";

interface TagSelectorProps {
  onChange: (values: string[]) => void;
  selectedValues: string[];
}
export const TagSelector = (props: TagSelectorProps) => {
  const {data, loading} = useTags();

  const tags = useMemo(() => {
    return data?.tags.filter(t => t.id !== config.missingAppsTagId) || []
  }, [data?.tags]);

  const getTagById = React.useCallback((id: string) => {
    return tags.find(t => t.id === id)
  }, [tags]);

  return <div>
    <FormSelect label={'Tags'}
                value={props.selectedValues}
                onChange={event => {
                  props.onChange(event.target.value as string[])
                }}
                sx={{minWidth: 200}}
                className={'mb-0'}
                multiple
                renderValue={(selected) => (
                  <div>
                    {(selected as string[]).map((value) => (
                      <Chip style={{backgroundColor: getTagById(value)?.color as string}}
                            className={'ml-8 mt-5'}
                            size="small" key={value}
                            label={_.upperFirst(getTagById(value)?.name.toLowerCase()) || ''}  />
                    ))}
                  </div>
                )}
    >
      {tags.map(tag => <MenuItem key={tag.id} value={tag.id}>
        <div className={'flex'}>
          <Box sx={{backgroundColor: tag.color}} marginRight={2} width={15} height={15} borderRadius={10} /> {tag.name}
        </div>
      </MenuItem>)}
    </FormSelect>
  </div>
}
