import {RouteComponentProps} from "react-router-dom";
import {useSnackbar} from "notistack";
import useAddClientFiles from "../../../Client/hooks/useAddClientFiles";
import {useDropzone} from "react-dropzone";
import {keycloak} from "../../../shared/keycloak";
import {config} from "../../../config/config";
import {Box, Typography} from "@mui/material";
import React from "react";

export const withDropZone = (WrappedComponent: any) => (props: RouteComponentProps<{ id: string }>) => {
  const {enqueueSnackbar} = useSnackbar();
  const [addClientFiles] = useAddClientFiles();
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: files => {
      if (files.length) {
        const formData = new FormData();
        files.forEach(file => formData.append('files', file, file.name))
        formData.append('clientId', props.match.params.id)

        const requestOptions = {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + keycloak?.token
          },
          body: formData
        };

        const url = config.clientFileUploadUrl + '/api/client-file'

        fetch(url, requestOptions)
          .then(res => res.json())
          .then(res => addClientFiles({variables: {
              clientId: props.match.params.id,
              clientFiles: res.links
            }}))
          .catch(() => enqueueSnackbar('Error in uploading file!', {variant: "error"}))
      }
    }
  })
  return <Box {...getRootProps()} position={'relative'}>
    <input {...getInputProps()} />
    {isDragActive && <Box position={'absolute'} zIndex={1001} width={'100%'} height={'100%'} sx={{backgroundColor: 'white'}}  borderRadius={3} border={'5px dashed #eaeaea'}>
        <Box position={'fixed'} ml={'35%'} mt={'100px'}>
            <Typography color={'textSecondary'} className={'fs-18 bold'}>Drop files to upload</Typography>
        </Box>
    </Box>}
    <WrappedComponent {...props} />
  </Box>
}