import {Box} from "@mui/material";
import React from "react";
import {PlanYear} from "../../../../../types";
import Tooltip from "@mui/material/Tooltip";

export function QuoteYear({quote}: {quote?: Nullable<{planYear?: Nullable<PlanYear>}>}) {
  if (!quote) {
    return null;
  }

  const tooltip = quote?.planYear ? 'The user last saw prices for this year' : 'Year for this quote not set for some reason. Open quote to set it.'

  return <Tooltip title={tooltip}>
    <Box sx={{
      backgroundColor: quote?.planYear ? '#5ECB9E' : '#fd201a',
      color: 'white',
      position: 'absolute',
      fontWeight: 500,
      padding: '2px 9px',
      right: -20,
      top: -20,
      borderRadius: '3px'
    }}>
      {quote?.planYear ? String(quote?.planYear).replace('Year', '') : 'Unknown year'}
    </Box>
  </Tooltip>

}