import {LazyQueryHookOptions, useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  QueryIsAgentGmailSetUpArgs
} from "../../../../enrollment-types";

export default function useIsAgentGmailSetUp(options?: LazyQueryHookOptions<{isAgentGmailSetUp: boolean}, QueryIsAgentGmailSetUpArgs>) {
  return useQuery<{isAgentGmailSetUp: boolean}, QueryIsAgentGmailSetUpArgs>(gql(query), {
    fetchPolicy: 'no-cache',
    ...options
  });
}

const query = `
query($agentId: UUID!) {
  isAgentGmailSetUp(agentId: $agentId)
}
`
