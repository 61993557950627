import React, {useState} from "react";
import {
  ClientViewOutput,
  StatusDto,
  useSaveDisqualifiedReasonMutation,
  useSaveExpectedCloseDateMutation, useSaveStatusMutation
} from "../../../enrollment-types";
import moment from "moment/moment";
import FollowUpModal, {ExpiredFollowUpDateModalCallback} from "./ExpiredFollowUpDateModal";
import {client as apolloClient, GraphQLErrorType} from "../../../Apollo/EnrollmentApolloClient";
import {enqueueSnackbar} from "notistack";
import {getTagByName} from "../../../Tags/hooks/useTags";
import useSaveClientTag from "../../hooks/useSaveClientTag";
import _ from 'lodash';
import {useFollowUpDateGroupModal} from "../FollowUpDateGroupModal";

interface Props {
  client: ClientViewOutput;
  followUpOpen?: boolean,
  onClose: (value: boolean) => void
}

export function ExpiredFollowUpDateModalDataWrapper({client, followUpOpen = false, onClose}: Props) {
  const [followUpModalOpen, setFollowUpModalOpen] = React.useState(followUpOpen);
  const [saveExpectedCloseDate] = useSaveExpectedCloseDateMutation({client: apolloClient});
  const [saveDisqualifiedReason] = useSaveDisqualifiedReasonMutation({client: apolloClient});
  const [saveClientTags] = useSaveClientTag({client: apolloClient});
  const changeFollowUpDateForGroup =  useFollowUpDateGroupModal()

  const [loading, setLoading] = useState(false)
  const [saveStatus] = useSaveStatusMutation({
    client: apolloClient,
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.NO_CONNECTION:
          text = "Service is not available";
          break;
        default:
          text = "Unknown error"
      }
      enqueueSnackbar(text, {variant: "error"});
    },
    onCompleted: () => {
      enqueueSnackbar('Next contact date changed!', {variant: "success"});
    }
  });

  const onFollowUpModalClose = async (data?: ExpiredFollowUpDateModalCallback) => {
    if (data) {
      const status = data.status ? data.status : data.followUpDate ? StatusDto.FollowUp : (client?.status || StatusDto.FollowUp)

      const followUpDate = data.followUpDate ? moment(data.followUpDate).format('YYYY-MM-DD') : undefined;

      await saveStatus({
        variables: {
          id: client?.id,
          followUpDate,
          agentId: client?.agent?.id,
          status,
        }
      });

      if (data.expectedCloseDate) {
        const expectedCloseDateStr = moment(data.expectedCloseDate).format('YYYY-MM-DD');
        await saveExpectedCloseDate({
          variables: {
            id: client?.id,
            expectedCloseDate: expectedCloseDateStr,
          }
        })
      }

      if (data.status === StatusDto.Disqualified && data.disqualifiedReason) {
        await saveDisqualifiedReason({
          variables: {
            id: client?.id,
            disqualifiedReason: data.disqualifiedReason,
          }
        })
      }

      if (data.tagAsMissed) {
        const tag = await getTagByName('Missed Appt –CSR Follow Up')
        if (tag) {
          await saveClientTags({
            variables: {
              input: {
                clientId: client?.id,
                tags: _.uniqBy([...client.tags.map(t => ({id: t.tag.id, expiresAt: t.expiresAt})), {id: tag.id}], 'id')
              }
            }
          });
        }
      }

      if (followUpDate) {
        await changeFollowUpDateForGroup(client!.id, followUpDate, status);
      }

      onClose(true);
    } else {
      onClose(false);
    }

    setFollowUpModalOpen(false)
  }

  return (
    <FollowUpModal open={followUpModalOpen}
                   loading={loading}
                   status={client?.status}
                   onClose={data => {
                     setLoading(true)
                     onFollowUpModalClose(data).finally(() => setLoading(false))
                   }} />
  )
}