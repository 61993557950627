import {useEnabledPdpStatesQuery, useSaveEnabledPdpStateMutation} from "../../../types";
import {client} from "../../../Apollo/ApolloClient";
import * as _ from "lodash";

export function usePdpStates() {
  const {data, loading} = useEnabledPdpStatesQuery({client, notifyOnNetworkStatusChange: true});
  const [save, {loading: saveStateLoading}] = useSaveEnabledPdpStateMutation({client, refetchQueries: ['enabledPdpStates']})

  return {
    data: data?.enabledPdpStates ? _.orderBy(data?.enabledPdpStates, 'state') : [],
    loading: loading || saveStateLoading,
    save
  };
}