import {IconButton, Typography} from "@mui/material";
import {getTypeColor} from "../lib";
import React from "react";
import {ComparablePlanType} from "../../../../../types";

interface Props {
  disabled?: boolean,
  plan: { bidId: string, type: ComparablePlanType, title: string },
  onRemove: () => void,
}

export function CompareOfferPlanItem({plan, onRemove, disabled}: Props) {
  return <div className={'flex flex-space-between flex-align-center mt-8'}>
    <div key={plan.bidId} className={'flex flex-align-center'}>
      <Typography style={{color: getTypeColor(plan.type)}} variant={"body1"}><b>{plan.type}</b></Typography>
      <Typography color={"textSecondary"} className={'ml-10'} variant={"h5"}>{plan.title}</Typography>
    </div>
    <IconButton size={'small'}
                sx={{ml: 1, opacity: disabled ? .6 : 1}}
                disabled={disabled}
                onClick={onRemove}>
      <img className={'w-10 h-10'} src={'/img/delete-icon.svg'}/>
    </IconButton>
  </div>
}