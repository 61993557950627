import React, {useState} from "react";
import {DataGrid, GridColDef, GridSortDirection, GridValueGetterParams} from "@mui/x-data-grid";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import moment from "moment";
import {Box, Chip, Typography} from "@mui/material";
import * as _ from 'lodash';
import paths from "../router-paths";
import {
  AssignedTagViewOutput,
  CallHistoryOutput,
  CallHistoryResultOutput,
  LeadSourceOutput, StatusDto, useUrgentMissingAppLazyQuery
} from "../enrollment-types";
import {colors} from "../app/AppTheme";
import {formatPhoneLink} from "../shared/utils";
import CheckIcon from "@mui/material/SvgIcon/SvgIcon";
import {getAgentDisplayName} from "../Agent/AgentSelector";
import {StatusView} from "../features";
import {getTagLabel} from "../Client/widgets/ClientTags";
import ClearIcon from '@mui/icons-material/Clear';
import useSessionStorageState from "../shared/useSessionStorageState";
import Card from "../shared/Card";
import {config} from "../config/config";
import {assignCallsToClients} from "../shared/tools";
import StatusFilter from "../shared/filters/StatusFilter";


const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'Name', width: 150, disableColumnMenu: false, sortable: false,
    renderCell: params => {
      const firstName = params.row.firstName;
      const lastName = params.row.lastName;
      let name = '';
      if (!firstName && !lastName) {
        name = 'Unknown user';
      } else {
        name = firstName + ' ' + lastName || '';
      }
      return <Link style={{color: colors.text.primary}} onClick={event => event.stopPropagation()} to={'/client/' + params.row.id}>{name}</Link>
    }
  },
  { field: 'email', headerName: 'Email', width: 200, disableColumnMenu: true, sortable: false,
    renderCell: params => {
      return <Link style={{color: colors.text.primary}} onClick={event => event.stopPropagation()} to={'/client/' + params.row.id}>{params.row.email}</Link>
    }
  },
  { field: 'phoneNumber', headerName: 'Phone', width: 140, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.phoneNumber) {
        return formatPhoneLink(params.row.phoneNumber, true)
      }
      return ''
    }
  },
  { field: 'birthDate', headerName: 'BirthDate', width: 150, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const birthDateStr = params.row.birthDate as string;
      if (birthDateStr) {
        return moment(birthDateStr).format('L')
      }
      return ''
    }
  },
  { field: 'age', headerName: 'Age', width: 100, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const birthDateStr = params.row.birthDate as string;
      if (birthDateStr) {
        return Math.floor(moment.duration(moment().diff(moment(birthDateStr))).asYears())
      }
      return ''
    }
  },
  { field: 'createdAt', headerName: 'Quote submitted timestamp', width: 200, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const createdAtStr = params.row.createdAt as string;
      if (createdAtStr) {
        return moment(createdAtStr).format('L H:mm:ss')
      }
      return ''
    }
  },
  { field: 'userId', headerName: 'Auth', width: 70, sortable: false, disableColumnMenu: true,
    renderCell: params =>
      params.row.userId ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <ClearIcon style={{color: 'red'}} fontSize={'small'} />
  },
  { field: 'state', headerName: 'State', width: 70, disableColumnMenu: true, sortable: false},
  { field: 'agent', headerName: 'Agent responsible', width: 150, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const agent = params.row.agent;
      if (agent) {
        return getAgentDisplayName(agent)
      } else {
        return '-'
      }
    }
  },
  { field: 'status', headerName: 'Status', width: 200, disableColumnMenu: true, sortable: false,
    renderCell: params => {
      return <StatusView status={params.row.status || undefined} />
    }
  },
  { field: 'calls', headerName: 'Calls', width: 70, sortable: false, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const calls = params.row.calls ?? [];
      return calls.filter((c: CallHistoryOutput) => c.result === CallHistoryResultOutput.SuccessfulSeeNotesForCallDetails).length + '/' + calls.length
    }
  },
  { field: 'mostRecentCall', headerName: 'Most Recent Call', width: 200, sortable: false, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const lastCallTime = params.row.lastCall?.createdAt;
      if (!lastCallTime) {
        return ''
      }
      return moment(lastCallTime).format('L H:mm:ss')
    }
  },
  { field: 'followUpDate', headerName: 'Next contact date', width: 200, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const followUpDateStr = params.row.followUpDate;
      if (followUpDateStr) {
        return moment(followUpDateStr).format('L')
      }
      return ''
    }
  },
  { field: 'expectedCloseDate', headerName: 'Expected close date', width: 200, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.expectedCloseDate) {
        return moment(params.row.expectedCloseDate).format('L')
      }
      return ''
    }
  },
  { field: 'tags', headerName: 'Tags', width: 200, disableColumnMenu: true, sortable: false,
    renderCell: params => {
      return <div className={'chips-wrapper'}>
        {params.row.tags.map((value: AssignedTagViewOutput) => (
          <Chip style={{backgroundColor: value.tag.color as string}} className={'ml-8 mt-5'} size="small" key={value.tag.id} label={getTagLabel(value)}  />
        ))}
      </div>
    }
  },
  { field: 'leadSources', headerName: 'Lead Sources', width: 200, disableColumnMenu: true, sortable: false,
    renderCell: params => {
      const sources = params.row.leadSources?.map((value: LeadSourceOutput) => value.source) || [];
      return <Typography color={'textPrimary'}>{_.uniq(sources).join(', ')}</Typography>
    }
  },
  { field: 'profileSource', headerName: 'Profile Source', width: 200, disableColumnMenu: true, sortable: false,
    renderCell: params => {
      return <Typography color={'textPrimary'}>{ _.startCase(_.lowerCase(params.row.profileSource))}</Typography>
    }
  },
  { field: 'adviserName', headerName: 'Advisor', width: 200, disableColumnMenu: true, sortable: false},
];

const MissingApps = () => {
  const [searchRequest, {data}] = useUrgentMissingAppLazyQuery({fetchPolicy: "no-cache"});
  let history = useHistory();
  const [rows, setRows] = React.useState<any[]>([]);
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useStatus();


  React.useEffect(() => {
    setLoading(true)
    searchRequest({
      variables: {
        missingAppTagId: config.missingAppsTagId
      }
    })
      .then(res => {
        const clients = res.data?.clientViews.data;
        const total = res.data?.clientViews.totalElements;

        //take 20% as Connor asking......
        return clients ? clients.slice(0, Math.round(total * .2)).filter(c => status ? c.status === status : true) : []
      })
      .then(assignCallsToClients)
      .then(setRows)
      .finally(() => setLoading(false))
  }, [status])

  const total = React.useMemo(() => {
    return data?.clientViews.totalElements ? parseFloat(data?.clientViews.totalElements) : 0;
  }, [data]);

  const getRowClassName = (followUpDate: string, lastCall: string, tags?: AssignedTagViewOutput[]) => {
    const matches = /[0-9]+d/g.exec(lastCall);
    if (matches?.length) {
      if (tags?.map(v => v.tag.name).includes('C1')) {
        if (parseFloat(matches[0].replace('d', '')) && parseFloat(matches[0].replace('d', '')) > 3) {
          return 'warning-data-grid-row';
        }
      } else if (tags?.map(v => v.tag.name).includes('C2')) {
        if (parseFloat(matches[0].replace('d', '')) && parseFloat(matches[0].replace('d', '')) > 5) {
          return 'warning-data-grid-row';
        }
      } else if (tags?.map(v => v.tag.name).includes('C3')) {
        if (parseFloat(matches[0].replace('d', '')) && parseFloat(matches[0].replace('d', '')) > 10) {
          return 'warning-data-grid-row';
        }
      } else if (tags?.map(v => v.tag.name).includes('C4')) {
        if (parseFloat(matches[0].replace('d', '')) && parseFloat(matches[0].replace('d', '')) > 14) {
          return 'warning-data-grid-row';
        }
      }
    }
    if (moment(followUpDate).isBefore(moment())) {
      return 'deactivated-data-grid-row';
    }
    return '';
  }

  return <Card padding={0}>
    <Box p={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
      <Typography color={'textPrimary'} variant={'h4'}>Urg. Missed Apps - CSR Follow Up  (Oldest 20%)</Typography>
      <Box sx={{minWidth: 300}}>
        <StatusFilter value={status} onChange={setStatus} />
      </Box>
    </Box>
    <div style={{ height: (52 * rows.length + 150) + 'px', minHeight: 200, width: '100%' }}>
        <DataGrid rows={rows}
                  loading={loading}
                  columns={columns}
                  disableRowSelectionOnClick
                  getRowClassName={params => getRowClassName(String(params.row.followUpDate), params.row.lastCall, params.row.tags)}
                  onRowClick={(param, event) => {
                    history.push(paths.client + '/' + param.row.id)
                  }}
                  rowCount={total}
                  hideFooterPagination
        />
    </div>
  </Card>
}

export default MissingApps;

const usePage = () => {
  return useSessionStorageState<number>(
    'missingAppsPage',
    0
  )
}

const usePageSize = () => {
  return useSessionStorageState<number>(
    'missingAppsPageSize',
    20
  )
}

const useStatus = () => {
  return useSessionStorageState<StatusDto | undefined>(
    'missingAppsStatus',
    undefined
  )
}

const useSorting = () => {
  return useSessionStorageState<{field: string, sort: GridSortDirection} | undefined>(
    'missingAppsSorting',
    {field: 'createdAt', sort: "desc"}
  )
}
