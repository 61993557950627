import React, {useState} from "react";
import {
  List,
  Typography
} from "@mui/material";
import { ClientViewOutput } from "../../../../enrollment-types";
import Card from "../../../../shared/Card";
import {DrugSelectionModal} from "./drug-selection-modal";
import {DrugRow} from "./DrugRow";
import {DrugOutput, FrequencyPeriod} from "../../../../types";
import {useDrugs} from "./lib/use-drugs";
import {useSaveDrugs} from "./lib/use-save-drugs";
import {toOutput} from "./drug-selection-modal/lib";


interface UserPrioritiesProps {
  client?: ClientViewOutput
}

const Drugs = ({client}: UserPrioritiesProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const saveDrugs = useSaveDrugs(client);
  const [editDrug, setEditDrug] = useState<DrugOutput>()
  const drugInfoWithPacks = useDrugs(client)

  const drugs: DrugOutput[] = drugInfoWithPacks.map(dInfo => {
    const dosage = client?.preferredDrugs.find(d => d.dosageRxcui === dInfo.packRxcui && d.productRxcui === dInfo.rxcui)
    if (dosage) {
      return toOutput({
        dosage: dosage.dosageRxcui,
        quantity: dosage.quantity,
        frequency: dosage.frequency as unknown as FrequencyPeriod,
        purchaseFrequency: dosage.purchaseFrequency as unknown as FrequencyPeriod,
      }, dInfo);
    }
    return undefined
  }).filter(Boolean) as DrugOutput[]

  const handleDrugChange  = (drug?: DrugOutput) => {
    if (drug) {
      const result = [...drugs];
      // edit mode
      if (editDrug !== undefined) {
        const index = drugs.findIndex((d: DrugOutput) => editDrug.rxcui === d.rxcui && editDrug.packRxcui === d.packRxcui);
        if (index > -1) {
          result[index] = drug;
        }
      } else {
        result.push(drug)
      }
      saveDrugs(result);
    }
    setEditDrug(undefined)
    setModalOpen(false)
  };

  const handleDeleteClick = (drug: DrugOutput) => {
    saveDrugs(drugs.filter(
      (v) => v.rxcui !== drug.rxcui || v.packRxcui !== drug.packRxcui
    ))
  };

  if (!client?.zip) {
    return null
  }

  return <>
    <DrugSelectionModal zip={client.zip}
                        open={modalOpen}
                        drug={editDrug}
                        onClose={handleDrugChange}  />
    <Card>
      <Typography variant={'h4'} className={'mb-5'}>Drugs:</Typography>

      <List>
        {drugs.map((item, index) =>
          <DrugRow key={item.packRxcui} drug={item} n={index + 1}
                   onDeleteClick={() => handleDeleteClick(item)}
                   onEditClick={() => {
                     setEditDrug(item);
                     setModalOpen(true)
                   }} />)}
      </List>

      <Typography className={'dark-green pointer mt-10'}
                  variant={'body2'}
                  sx={{width: 'fit-content'}}
                  onClick={() => setModalOpen(true)}>
        <img src={'/img/add-icon.svg'}/> Add Drug
      </Typography>
    </Card>
  </>;
}

export default Drugs;
