import * as React from 'react';
import {MenuItem} from "@mui/material";
import FormSelect from "../../../../../shared/FormSelect";

type Props = {
  value: string;
  onChange: (value: string) => void;
  error?: string,
  label?: string,
  disabled?: boolean;
  companies: string[];
};

export const CarrierSelector = ({companies, value, error, disabled, onChange, label = 'Carrier'}: Props) => (
  <FormSelect label={label}
              value={value}
              onChange={event => {
                onChange(event.target.value as string);
              }}
              disabled={disabled}
              error={Boolean(error)}
              helperText={error ? error : undefined}
  >
    {companies.map(c => <MenuItem key={c} value={c.slice(0, c.indexOf('(') - 1)}>{c}</MenuItem>)}
  </FormSelect>
)