import {
  Box, Button, ButtonGroup,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  MenuItem,
  Typography
} from "@mui/material";
import React, {useState} from "react";
import {ExpectedCloseDateSelector, FollowUpSelector} from "../../../features/connect-client-date-selector";
import moment from "moment";
import {LoadingButton} from "@mui/lab";
import FormSelect from "../../../shared/FormSelect";
import {DisqualifiedReasonDto, StatusDto} from "../../../enrollment-types";
import {DisqualifiedReasonModal} from "../../../features";

export interface ExpiredFollowUpDateModalCallback {
  followUpDate?: Date | null,
  expectedCloseDate?: Date | null,
  disqualifiedReason?: DisqualifiedReasonDto,
  status?: StatusDto,
  tagAsMissed?: boolean
}

type ExpiredFollowUpDateModal = {
  onClose: (data?: ExpiredFollowUpDateModalCallback) => void,
  open: boolean,
  loading?: boolean,
  status?: StatusDto,
}

const ExpiredFollowUpDateModal = (props: ExpiredFollowUpDateModal) => {
  const [followUpDate, setFollowUpDate] = React.useState<Date | null>(moment().add(1, 'day').toDate());
  const [expectedCloseDate, setExpectedCloseDate] = React.useState<Date | null>(null);
  const [showStatusSelector, setShowStatusSelector] = useState('');
  const [status, setStatus] = useState<StatusDto | ''>( props.status || '')
  const [disqualifiedOpen, setDisqualifiedOpen] = useState(false)
  const [disqualifiedReason, setDisqualifiedReason] = useState<DisqualifiedReasonDto>()

  const isDateValid = status === StatusDto.Disqualified || !!followUpDate && moment(followUpDate).isSameOrAfter(moment());

  const expectedCloseDateValid = status !== StatusDto.SalesQualified || !!expectedCloseDate && moment(expectedCloseDate).isSameOrAfter(moment());

  console.log(!isDateValid || !expectedCloseDateValid || !showStatusSelector)

  const disableSaveButton = !isDateValid || !expectedCloseDateValid || (status !== StatusDto.SalesQualified && !showStatusSelector) || (showStatusSelector === 'yes' && !status);

  const onClose = () => {
    props.onClose({
      followUpDate: status === StatusDto.Disqualified ? null : followUpDate,
      expectedCloseDate,
      status: status || undefined,
      disqualifiedReason,
      tagAsMissed: showStatusSelector === 'no'
    })
  }

  return <>
    <DisqualifiedReasonModal onClose={value => {
      setDisqualifiedOpen(false)
      setDisqualifiedReason(value)
      if (value) {
        setStatus(StatusDto.Disqualified)
      }
    }} open={disqualifiedOpen} />

    <Dialog
      open={props.open}
      maxWidth={'sm'}
      id={'expired-follow-up-date-modal'}
    >
      <DialogTitle sx={{p: '16px 24px 4px'}}>
        Next contact date for this client has expired.
      </DialogTitle>

      <DialogContent dividers>
        {props.status === StatusDto.FollowUp && <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2, mb: 3}}>
          <Typography className={'fs-18 medium'}>Did you speak with this customer?</Typography>

          <ButtonGroup disableElevation variant="contained" aria-label="Basic button group">
            <Button onClick={() => setShowStatusSelector('no')} size={'small'}
                    variant={showStatusSelector === 'no' ? 'contained' : 'outlined'}>No</Button>
            <Button onClick={() => setShowStatusSelector('yes')} size={'small'}
                    variant={showStatusSelector === 'yes' ? 'contained' : 'outlined'}>Yes</Button>
          </ButtonGroup>
        </Box>}

        <Collapse in={showStatusSelector ==='no'}>
          <Typography><i>This customer will be tagged as 'missed appointment'</i></Typography>
        </Collapse>

        <Collapse in={showStatusSelector ==='yes'}>
          <FormSelect label={'Select status of customer'}
                      value={status}
                      onChange={event => {
                        if (event.target.value === StatusDto.Disqualified) {
                          setDisqualifiedOpen(true)
                        } else {
                          setStatus(event.target.value as StatusDto);
                        }
                      }}
          >
            <MenuItem value={StatusDto.SalesQualified}>
              <ListItemText>Sales Qualified</ListItemText>
            </MenuItem>
            <MenuItem value={StatusDto.Contacted}>
              <ListItemText>Contacted</ListItemText>
            </MenuItem>
            <MenuItem value={StatusDto.Disqualified}>
              <ListItemText>Disqualified</ListItemText>
            </MenuItem>
          </FormSelect>
        </Collapse>

        <Collapse in={status === StatusDto.Contacted || status === StatusDto.SalesQualified}>
          <FollowUpSelector value={followUpDate} onChange={setFollowUpDate} />
        </Collapse>

        <Collapse in={status === StatusDto.SalesQualified}>
          <div className={'mt-16'}>
            <ExpectedCloseDateSelector value={moment(expectedCloseDate).isValid() ? moment(expectedCloseDate).toDate() : null}
                                  onChange={setExpectedCloseDate}/>
          </div>
        </Collapse>



      </DialogContent>

      <DialogActions>
        <LoadingButton variant={'outlined'}
                       loading={props.loading}
                       className={'mr-16'}
                       onClick={() => {props.onClose()}}>
          Cancel
        </LoadingButton>
        <LoadingButton variant={'contained'}
                       loading={props.loading}
                       color={'primary'}
                       disabled={disableSaveButton}
                       onClick={onClose}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  </>
}

export default ExpiredFollowUpDateModal;