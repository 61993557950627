import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import TextInput from "../../shared/TextInput";

export default function ChatListFilter({onChange, value}: {value: string, onChange: (term: string) => void}) {
  return <TextInput
    className={'mb-0 w-380'}
    fullWidth
    sx={{m: 1, width: 236}}
    name="search"
    label="Search"
    InputProps={{
      endAdornment:
        <InputAdornment position="end">
          {!!value && <CloseIcon cursor={'pointer'} onClick={() => {
            onChange('');
          }} fontSize={'small'} color={'inherit'} />}
        </InputAdornment>,
    }}
    value={value}
    onChange={(e: any) => onChange(e.target.value || '')}
  />
}