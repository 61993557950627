import React, {useState} from "react";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  MenuItem,
  Typography
} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import * as _ from 'lodash';
import {ClientViewOutput, PlanYear} from "../../../../enrollment-types";
import {GetNextPlansYear, GetPlansYear} from "../../../../shared/tools";
import Card from "../../../../shared/Card";
import {config} from "../../../../config/config";
import FormSelect from "../../../../shared/FormSelect";
import {useGetCompareOfferPlans} from "./api/useGetCompareOfferPlans";
import {SendEmailButton} from "./ui/SendEmailButton";
import {CompareOfferPlanItem} from "./ui/CompareOfferPlanItem";
import {useSaveCompareOfferMutation} from "../../../../types";
import {client as apolloClient} from "../../../../Apollo/ApolloClient";
import {useDebouncedEffect} from "../../../../shared/hooks/useDebouncedEffect";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from "@mui/material/Tooltip";

interface CompareOfferProps {
  client?: ClientViewOutput
}

const CompareOffer = ({client}: CompareOfferProps) => {
  const [defaultYear, setDefaultYear] = useState(GetPlansYear())
  const {plans, loading, offerId, refetch, plansHasUnreachableYear} = useGetCompareOfferPlans(defaultYear, client?.id);

  const [saveCompareOffer] = useSaveCompareOfferMutation({client: apolloClient});

  const [plansToDelete, setPlansToDelete] = useState<string[]>([])

  useDebouncedEffect(() => {
    if (plansToDelete.length && offerId) {
      saveCompareOffer({
        variables: {
          compareOffer: {
            clientId: client?.id,
            id: offerId,
            plans: plans.filter(p => !plansToDelete.includes(p.bidId))
              .map(o => _.omit(o, 'title'))
          }
        }
      }).then(refetch).finally(() => {
        setPlansToDelete([])
      })
    }
  }, 700, [saveCompareOffer, offerId, plansToDelete, plans])

  return <Card className={'relative'}>
    <div className={'flex-space-between flex-align-center mb-8'}>
      <Typography color={"textPrimary"} variant={"h4"}>Compare offer</Typography>
      <RefreshButton disabled={loading} onClick={() => refetch()}/>
    </div>

    <ButtonGroup variant="outlined" aria-label="Basic button group" sx={{mt: 1, mb: 3}}>
      <SendEmailButton clientId={client?.id}/>
      <PreviewButton clientId={client?.id}/>
      <CopyLinkButton />
    </ButtonGroup>

    <Collapse in={plansHasUnreachableYear}>
      <Box sx={{display: 'flex', alignItems: 'center', gap: 2, mb: 3}}>
        <div className={'w-150'}>
          <FormSelect label={'Default Year'}
                      value={defaultYear}
                      className={'mb-0'}
                      onChange={event => {
                        setDefaultYear(event.target.value as PlanYear);
                      }}
          >
            <MenuItem value={GetPlansYear()}>{String(GetPlansYear()).replace('Year', '')}</MenuItem>
            <MenuItem value={GetNextPlansYear()}>{String(GetNextPlansYear()).replace('Year', '')}</MenuItem>
          </FormSelect>
        </div>
        <Tooltip title={'We cannot determine the plan year for some plans in the comparison offer, so this year will be used to find details for these plans'}>
          <InfoIcon color={'disabled'} />
        </Tooltip>

      </Box>

    </Collapse>


    {(!plans.length && loading) && <CircularProgress size={20}/>}


    {plans.filter(p => !plansToDelete.includes(p.bidId)).map((p, i) => (
      <React.Fragment key={p.bidId}>
        <CompareOfferPlanItem plan={p} disabled={loading} onRemove={() => setPlansToDelete(prev => [...prev, p.bidId])}/>
        {i < plans.length - 1 && <Divider className={'mt-8'}/>}
      </React.Fragment>
    ))}
  </Card>
};

export {CompareOffer};

const RefreshButton = ({disabled, onClick}: { disabled: boolean, onClick: () => void }) => (
  <IconButton sx={{
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1000,
    opacity: disabled ? 0.6 : 1,
  }}
              disabled={disabled}
              onClick={onClick}>
    <img src={'/img/replay.svg'}/>
  </IconButton>
)

const PreviewButton = ({clientId}: { clientId?: string }) => (
  <Button onClick={() => window.open(config.userFlowUrl + `compare-offer?clientId=${clientId}`)} size={'small'}>
    Preview
  </Button>
)

const CopyLinkButton = () => (
  <Button onClick={() => {
    navigator.clipboard.writeText(`${config.userFlowUrl}compare-offer?magic-link=true`)
    enqueueSnackbar('Copied!', {variant: "info"});
  }} size={'small'}>
    Copy link
  </Button>
)