import * as React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import {getUserName} from "../../shared/tools";
import {
  StatusDto,
  useAgentAssignedToClientLazyQuery,
  useClientGroupByClientIdQuery,
  useSaveStatusMutation
} from "../../enrollment-types";
import {createRoot} from "react-dom/client";
import AppTheme from "../../app/AppTheme";
import {getUserId} from "../../shared/keycloak";
import {client} from "../../Apollo/EnrollmentApolloClient";

type Props = {
  open: boolean;
  onClose: (result: boolean) => void;
  loading: boolean;
  names: string[];
};

const Modal = (props: Props) => {
  const profileStr = props.names.length > 1 ? 'profiles' : 'profile'

  return (
    <Dialog
      open={props.open}
      maxWidth={'sm'}
      id={'FollowUpDateGroupModal-modal'}
    >
      <DialogTitle sx={{p: '16px 24px 4px'}}>
        There is a {profileStr} tied to this client:
      </DialogTitle>
      <DialogContent dividers sx={{p: '16px 24px'}}>



        <ol>
          {props.names.map(name => <li className={'fs-18'}>{name}</li>)}
        </ol>

        <Typography className={'fs-18'}>
         <b> Do you want to update the Next contact date and status for that {profileStr} too?</b>
        </Typography>

      </DialogContent>
      <DialogActions>
        <LoadingButton variant={'outlined'}
                       loading={props.loading}
                       className={'mr-16'}
                       onClick={() => {props.onClose(false)}}>
          No
        </LoadingButton>
        <LoadingButton variant={'contained'}
                       loading={props.loading}
                       color={'primary'}
                       onClick={() => {props.onClose(true)}}>
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

interface FollowUpDateGroupModalProps {
  clientId: string,
  followUpDate: string,
  agentId?: string,
  status: StatusDto,
  onClose: () => void
}

const FollowUpDateGroupModal = ({clientId, followUpDate, status, agentId, onClose}: FollowUpDateGroupModalProps) => {
  const {data} = useClientGroupByClientIdQuery({
    variables: {clientId},
    skip: !clientId,
    client
  });
  const [saveStatus] = useSaveStatusMutation({client});
  const [getAgent] = useAgentAssignedToClientLazyQuery({client})
  const [loading, setLoading] = useState(false)

  const clients = data?.clientGroupByClientId?.clients || [];

  if (data?.clientGroupByClientId === null) {
    onClose();
  }

  const closeHandle = async (result: boolean) => {
    if (result) {
      setLoading(true);
      for (const client of clients) {
        const agent = await getAgent({variables: {clientId: client.clientId}}).then(res => res.data?.agentAssignedToClient);
        await saveStatus({
          variables: {
            id: client.clientId,
            followUpDate,
            status,
            agentId: agent?.id ? agent.id : agentId ? agentId : getUserId(),
          }
        })
      }
    }
    onClose();
  }

  return <Modal loading={loading}
                names={clients.filter(c => c.clientId !== clientId).map(c => getUserName(c)) || []}
                open={!!clients.length}
                onClose={closeHandle} />
}

export function useFollowUpDateGroupModal() {
  return (clientId: string, followUpDate: string, status: StatusDto, agentId?: string): Promise<void> => {
    return new Promise((resolve) => {
      const container = getContainer();
      const root = createRoot(container);
      root.render(
        <ThemeProvider theme={AppTheme}>
          <FollowUpDateGroupModal clientId={clientId}
                                  followUpDate={followUpDate}
                                  status={status}
                                  agentId={agentId}
                                  onClose={() => {
                                    root.unmount()
                                    document.body.removeChild(container);
                                    resolve()
                                  }}
          />
        </ThemeProvider>
      )
    })
  }
}

const getContainer = () => {
  const id = 'FollowUpDateGroupModal-container'
  const prev = document.getElementById(id);
  if (prev) {
    document.body.removeChild(prev);
  }
  const modal = document.getElementById('FollowUpDateGroupModal-modal');
  if (modal) {
    document.body.removeChild(modal);
  }
  const div = document.createElement('div');
  div.id = id;
  document.body.appendChild(div);
  return div;
}