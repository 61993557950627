import React, {ChangeEvent} from "react";
import {
  Box,
  Button, Collapse, Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@mui/material";
import Card from "../shared/Card";
import TextInput from "../shared/TextInput";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import useTags from "./hooks/useTags";
import useSaveTag from "./hooks/useSaveTag";
import useDeleteTag from "./hooks/useDeleteTag";
import {TagOutput} from "../enrollment-types";
import {useSnackbar} from "notistack";

interface TagsProps {

}

const Tags = (props: TagsProps) => {
  const [open, setOpen] = React.useState(false);
  const [tagToEdit, setTagToEdit] = React.useState<TagOutput>();
  const {data, refetch} = useTags();
  const [deleteTag] = useDeleteTag();
  const {enqueueSnackbar} = useSnackbar();

  const onDelete = (id: string) => {
    deleteTag({variables: {id}})
      .then(refetch)
      .catch(() => enqueueSnackbar('You have to remove this tag from all related clients first!', {variant: "error"}))
    ;
  }

  const onEdit = (tag: TagOutput) => {
    setTagToEdit(tag);
    setOpen(true);
  }

  return <Box display={'flex'} flex={1}>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
      <Card>
        <Typography color={'textPrimary'} variant={'h4'}>Tags</Typography>
        <Collapse in={!open}>
          <Box width={325} display={'flex'} justifyContent={'flex-end'}>
            <IconButton component="span" onClick={() => setOpen(true)}>
              <AddIcon />
            </IconButton>
          </Box>
        </Collapse>
        <Collapse in={open}>
          <AddForm
            default={tagToEdit}
            onClose={() => {
              setOpen(false);
              setTagToEdit(undefined);
              refetch();
            }} />
        </Collapse>

        <List className={'w-330'}>
          {data?.tags?.map((tag, index, tags) => <>
            <ListItem>
              <Box sx={{backgroundColor: tag.color}} marginRight={2} width={15} height={15} borderRadius={10} />
              <ListItemText
                primary={tag.name}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => onEdit(tag)} edge="end" aria-label="delete">
                  <img src={'/img/mode.svg'}/>
                </IconButton>
                <IconButton onClick={() => onDelete(tag.id)} edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {index < tags.length - 1 && <Divider />}
          </>)}
        </List>
      </Card>
    </Box>
  </Box>;
}

export default Tags;

const AddForm = (props: {onClose: () => void, default?: TagOutput}) => {
  const [color, setColor] = React.useState<any>();
  const [name, setName] = React.useState('');
  const [save] = useSaveTag();

  const onSubmit = () => {
    const c = typeof color === 'string' ? color : (color as any)?.css.backgroundColor;
    save({variables: {input: {color: c, name, id: props.default?.id}}}).finally(close);
  }

  React.useEffect(() => {
    if (props.default) {
      setName(props.default.name);
      setColor(props.default.color as string);
    } else {
      reset();
    }
  }, [props.default]);

  const close = () => {
    reset();
    props.onClose();
  }

  const reset = () => {
    setName('');
    setColor(undefined);
  }

  return <div className={'w-410 mt-20'}>
    <Typography color={'textPrimary'} variant={'h4'}>Color / Name</Typography>
    <div className={'mt-10'}>
      <TextInput
        name="color"
        label="Color"
        value={color}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setColor(e.target.value || '')}
      />
      <TextInput
        name="phoneNumber"
        label="Name"
        value={name}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value || '')}
      />
      <div className={'mt-2 ml-16'}>
        <div className={'flex'}>
          <Button onClick={onSubmit} disabled={!color || !name} variant={'contained'} color={'primary'}>SAVE</Button>
          <Button onClick={close} variant={'outlined'} className={'ml-16'} >CLOSE</Button>
        </div>
      </div>
    </div>
  </div>
}
