import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {MutationDeleteTagArgs} from "../../enrollment-types";

export default function useDeleteTag(options?: MutationHookOptions<any, MutationDeleteTagArgs>) {
  return useMutation<any, MutationDeleteTagArgs>(gql(saveMutation), options)
}

const saveMutation = `
mutation ($id: UUID!) {
  deleteTag(id: $id)
}
`;
