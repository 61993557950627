import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {config} from "../../config/config";
import {useEffect, useState} from "react";
import moment from "moment";

interface AgentDashboardCounters {
  todaySQL?: number,
  futureSQL?: number,
  todayFollowUps?: number,
  futureFollowUps?: number,
  missingApps?: number,
  contacted?: number,
}

export default function useAgentDashboardCounters(agentId?: string) {
  const [counters, setCounters] = useState<AgentDashboardCounters>({});

  const [getCounters] = useLazyQuery(gql(query), { fetchPolicy: 'no-cache', });

  const load = () => {
    if (agentId) {
      getCounters({variables: {agentId}}).then(res => {
        if (res.data) {
          setCounters({
            contacted: parseFloat(res.data.contacted.totalElements),
            missingApps: parseFloat(res.data.missingApps.totalElements),
            todayFollowUps: res.data.followUps.data.filter((item: {followUpDate: string}) => moment(item.followUpDate).isBefore(moment().add({day: 1}).startOf('day'))).length,
            futureFollowUps: res.data.followUps.data.filter((item: {followUpDate: string}) => moment(item.followUpDate).isSameOrAfter(moment().add({day: 1}).startOf('day'))).length,
            todaySQL: res.data.salesQualified.data.filter((item: {followUpDate: string}) => moment(item.followUpDate).isBefore(moment().add({day: 1}).startOf('day'))).length,
            futureSQL: res.data.salesQualified.data.filter((item: {followUpDate: string}) => moment(item.followUpDate).isSameOrAfter(moment().add({day: 1}).startOf('day'))).length,
          })
        }
      })
    }
  }

  useEffect(() => {
    load()
  }, [agentId]);

  return {counters, refetch: load};
}

const query = `
  query ($agentId: UUID!) {
    salesQualified(agentId: $agentId, pageInput: {page: 0, size: 1000}) {
      data {
        followUpDate
      }
    }
    followUps(agentId: $agentId, excludedTagsIds: ["${config.missingAppsTagId}"], pageInput: {page: 0, size: 1000}) {
      data {
        followUpDate
      }
    }
    missingApps: followUps(agentId: $agentId, includedTagsIds: ["${config.missingAppsTagId}"], pageInput: {page: 0, size: 1000}) {
      totalElements
    }
    contacted(agentId: $agentId, pageInput: {page: 0, size: 1000}) {
      totalElements
    }
  }
`
