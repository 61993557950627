import {forwardRef} from "react";
import * as React from "react";
import FormSelect, {FormSelectProps} from "./FormSelect";
import * as _ from "lodash";

interface Props extends FormSelectProps {
  formik: any,
  name: string,
  'data-test'?: string,
}

export const FormikSelectInput = forwardRef((props: Props, ref: any) => {
  const {formik, name, children, ...rest} = props;

  return <FormSelect ref={ref}
                     value={formik.values[name]}
                     error={formik.touched[name] && Boolean(formik.errors[name])}
                     helperText={formik.touched[name] && formik.errors[name]}
                     name={name}
                     label={rest.label || _.startCase(name)}
                     data-test={props['data-test'] || _.snakeCase(name)}
                     onChange={event => {
                       formik.setFieldValue(name, event.target.value as string);
                     }}
                     {...rest}
  >
    {children}
  </FormSelect>
})
