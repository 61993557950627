import React, {Context, PropsWithChildren, useEffect, useReducer} from "react";
import {useConversationsClient} from "../api/use-conversations-client";
import {ChatAction, ChatActionTypes, ChatState} from "./types";
import useChatIdentityId from "../api/use-chat-identity-id";
import _ from "lodash";
import {checkNewMessages} from "./lib";


const initialValue: ChatState = {
  status: 'default',
  conversations: [],
  hasNewMessages: false,
}

export const ChatContext: Context<[ChatState, React.Dispatch<ChatAction>]> = React.createContext([initialValue, (() => {}) as any])


const ChatContextProvider = (props: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer<(state: ChatState, action: ChatAction) => ChatState>(reducer, initialValue);

  const chatIdentityId = useChatIdentityId();
  const conversationsClient = useConversationsClient(chatIdentityId);

  function reducer(state: ChatState, {type, payload}: ChatAction) {
    let newState: ChatState = {...state};

    switch (type) {
      case ChatActionTypes.SET_STATUS: {
        newState.status = payload;
        break;
      }
      case ChatActionTypes.ADD_CONVERSATION: {
        newState.conversations = [..._.without(newState.conversations, payload), payload];
       // newState.hasNewMessages = newState.conversations.some(conversationHasNewMessages);
        break;
      }
      case ChatActionTypes.UPDATE_CONVERSATION: {
        const convIndex = newState.conversations.findIndex(c => c.sid === payload.sid);
        newState.conversations.splice(convIndex, 1, payload);
     //   newState.hasNewMessages = newState.conversations.some(conversationHasNewMessages);
        break;
      }
      case ChatActionTypes.SET_HAS_NEW_MESSAGES: {
        newState.hasNewMessages = payload;
        break;
      }
      case ChatActionTypes.SET_CHAT_IDENTITY_ID: {
        newState.chatIdentityId = payload;
        break;
      }
    }
    return newState;
  }

  useEffect(() => {
    if (chatIdentityId) {
      dispatch({
        type: ChatActionTypes.SET_CHAT_IDENTITY_ID,
        payload: chatIdentityId
      });
    }
  }, [chatIdentityId]);

  useEffect(() => {
    if (conversationsClient) {
      conversationsClient.on("connectionStateChanged", (state) => {
        switch (state) {
          case "connected":
            dispatch({
              type: ChatActionTypes.SET_STATUS,
              payload: 'success'
            });
            break;
          case "denied":
            dispatch({
              type: ChatActionTypes.SET_STATUS,
              payload: 'error'
            });
            break;
          default:
            dispatch({
              type: ChatActionTypes.SET_STATUS,
              payload: 'default'
            });
        }
      });

      conversationsClient.on("conversationJoined", (conversation) => {
        dispatch({
          type: ChatActionTypes.ADD_CONVERSATION,
          payload: conversation
        });
        checkNewMessages(conversation)?.then(payload => {
          dispatch({
            type: ChatActionTypes.SET_HAS_NEW_MESSAGES,
            payload
          });
        })

        conversation.on('updated', ({conversation}) => {
          checkNewMessages(conversation)?.then(payload => {
            dispatch({
              type: ChatActionTypes.SET_HAS_NEW_MESSAGES,
              payload
            });
          })
          dispatch({
            type: ChatActionTypes.UPDATE_CONVERSATION,
            payload: conversation
          });
        })
        conversation.on('participantUpdated', ({participant}) => {
          checkNewMessages(participant.conversation)?.then(payload => {
            dispatch({
              type: ChatActionTypes.SET_HAS_NEW_MESSAGES,
              payload
            });
          })
          dispatch({
            type: ChatActionTypes.UPDATE_CONVERSATION,
            payload: participant.conversation
          });
        })
      });
    }
  }, [conversationsClient]);

  return <ChatContext.Provider value={[state, dispatch]}>
    {props.children}
  </ChatContext.Provider>
}


export const withChatContext = (WrappedComponent: any) => (props: any) => {
  return <ChatContextProvider>
    <WrappedComponent {...props} />
  </ChatContextProvider>
}
