import React from "react";
import {Typography} from "@mui/material";


interface ParamProps {
  label: string;
  value?: string | null;
  valueClassName?: string;
  className?: string;
}

const Param = (props: ParamProps) => {
  return <div className={props.className}>
    <Typography color={'textSecondary'} sx={{fontSize: '10px!important', textTransform: 'uppercase'}}>{props.label}</Typography>
    {props.value && <Typography color={'textPrimary'} sx={{fontSize: '13px!important'}} className={`mb-16 ${props.valueClassName}`}>{props.value}</Typography>}
  </div>;
}

export default Param;
