import {Box, Button, Divider,  Theme, Typography} from "@mui/material";
import React from "react";
import {AgentEmailOutput, ClientViewOutput, useGmailMessagesByEmailQuery} from "../../../enrollment-types";
import { useHistory } from "react-router-dom";
import Card from "../../../shared/Card";
import paths from "../../../router-paths";
import useGmailMessagesByClients from "./hooks/useGmailMessagesByClients";
import moment from "moment";
import useGmailMessage from "./hooks/useGmailMessage";
import {makeStyles,} from "@mui/styles"
import * as _ from "lodash";

interface ClientEmailsProps {
  client?: ClientViewOutput,
  agentId?: string,
  emailId?: string,
}

const ClientEmails = (props: ClientEmailsProps) => {
  const history = useHistory();

  const isSingle = React.useMemo(() => {
    return props.agentId && props.emailId;
  }, [props.agentId, props.emailId])

  if (!props.client?.email) {
    return null;
  }

  return <Card padding={24} className={'relative'}>
    {!isSingle && <EmailList email={props.client.email} />}
    {isSingle && <SingleEmail clientId={props.client.id} agentId={props.agentId!} messageId={props.emailId!} />}
  </Card>
}

export default ClientEmails;

const SingleEmail = ({agentId, messageId, clientId}: {clientId: string, agentId: string, messageId: string}) => {
  const {data, loading} = useGmailMessage({variables: {messageId}});
  const history = useHistory();

  return <Box sx={{display: 'flex', minHeight: 500, flexDirection: 'column'}}>
    {data?.gmailMessage && <>
      <div>
        <Button onClick={() => history.push(paths.client + '/' + clientId + '/email')} className={'mb-8'} color={'primary'} variant={'text'}>Back to list</Button>
      </div>
      <Email email={data?.gmailMessage} fullText />
    </>}
  </Box>
}

const EmailList = ({email}: {email: string}) => {
  const {data} = useGmailMessagesByEmailQuery({variables: {email}})
  const history = useHistory();
  const [selected, setSelected] = React.useState<AgentEmailOutput>();

  const emails = React.useMemo(() => {
    return _.orderBy(data?.gmailMessagesByEmail || [], 'createdAt', 'desc');
  }, [data])

  return <Box sx={{display: 'flex', minHeight: 500}}>
    <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, minWidth: 400, maxWidth: selected ? 400 : 'unset'}}>
      {emails.map((item, i) => <>
        <Email email={item} key={i} onClick={() => {
          // history.push(paths.client + '/' + clientId + '/email/' + item.agentId + '/' + item.id)
          setSelected(item)
        }} />
        {i < emails.length - 1 && <Box my={3}><Divider /></Box>}
      </>)}
    </Box>
    {selected && <>
      <Box mx={3}><Divider orientation={'vertical'} /></Box>
      <Email email={selected} fullText />
    </>}
  </Box>
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    transition: 'all 0.3s',
    padding: 8,
  },
  clickableWrapper: {
    cursor: 'pointer',
    '&:hover': {backgroundColor: '#efefef'},
  }
}));

const Email = ({email, fullText, onClick}: {email: AgentEmailOutput, fullText?: boolean, onClick?: () => void}) => {
  const classes = useStyles();

  return <Box className={`${classes.wrapper} ${onClick ? classes.clickableWrapper : ''}`} onClick={onClick}>
    <Typography className={'fs-11 mb-4'}>From: <b>{email.from}</b></Typography>
    <Typography className={'fs-11 mb-8'}>To: <b>{email.to}</b></Typography>
    <Typography variant={'h3'}><b>{email.subject}</b></Typography>
    <Typography className={'fs-11 mt-4'} color={'textSecondary'}>{moment(email.createdAt).format('L H:mm:ss')}</Typography>
    <Box sx={{width: 180, mt: .5, mb: 1}}>
      <Divider />
    </Box>
    <Typography dangerouslySetInnerHTML={{__html: fullText ? email.body! : email.snippet!}} />
  </Box>
}

